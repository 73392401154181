import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { get, post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'
import './css/list-select.css'

class CreateDriverPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      productCategoryData: []
    }
  }

  componentDidMount () {
    this.fetchProductCategory()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  fetchProductCategory () {
    get('/1/admin/product_categories?limit=99')
      .then(r => {
        if (r.success) {
          this.setState({
            productCategoryData: r.data
          })
        }
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/driver', {
      name: this.input.name.value(),
      productCategory: this.input.productCategory.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/drivers' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <section className='container'>
        <Helmet>
          <title>建立驅動程式類別</title>
        </Helmet>

        <h1 className='page-header'>建立驅動程式類別</h1>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <TextControl
                ref={(c) => (this.input.name = c)}
                name='name'
                label='名稱'
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />
              <SelectControl
                ref={(c) => (this.input.productCategory = c)}
                name='productCategory'
                label='產品線'
                hasError={this.state.hasError.productCategory}
                help={this.state.help.productCategory}
                disabled={this.state.loading}
              >
                {this.state.productCategoryData.map(pc => (
                  <option value={pc._id} key={pc._id}>
                    {pc.name}
                  </option>
                ))}
              </SelectControl>
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-success': true }}
                  disabled={this.state.loading}
                >
                  建立
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateDriverPage
