import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import { put, get, postFormData } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import FileControl from '../components/file-control'
import DateTimeControl from '../components/datetime-control'
import config from '../config'

class EditShareholderPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      loadingForNoticeTw: false,
      loadingForAgendaTw: false,
      loadingForMinutesTw: false,
      loadingForNoticeEn: false,
      loadingForAgendaEn: false,
      loadingForMinutesEn: false,
      success: false,
      successForNoticeTw: false,
      successForAgendaTw: false,
      successForMinutesTw: false,
      successForNoticeEn: false,
      successForAgendaEn: false,
      successForMinutesEn: false,
      error: undefined,
      hasError: {},
      help: {},
      id: '',
      years: this.props.match.params.years,
      meetingNoticeTw: '',
      meetingAgendaTw: '',
      meetingMinutesTw: '',
      meetingVideoTw: '',
      meetingNoticeEn: '',
      meetingAgendaEn: '',
      meetingMinutesEn: '',
      meetingVideoEn: '',
      chashDividend: 0,
      stockDividend: 0,
      recordDate: '',
      exDividendDate: '',
      cashDividendPaymentDate: '',
      stockDividendPaymentDate: ''
    }
    this.handleFileSubmit = this.handleFileSubmit.bind(this)
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.years) {
      this.input.years.focus()
    }
  }

  fetchData () {
    get(`/1/shareholder/${this.props.match.params.years}`)
      .then(r => {
        this.setState({
          id: r.data._id,
          years: r.data.years,
          meetingNoticeTw: r.data.meetingNotice.tw,
          meetingAgendaTw: r.data.meetingAgenda.tw,
          meetingMinutesTw: r.data.meetingMinutes.tw,
          meetingVideoTw: r.data.meetingVideo.tw,
          meetingNoticeEn: r.data.meetingNotice.en,
          meetingAgendaEn: r.data.meetingAgenda.en,
          meetingMinutesEn: r.data.meetingMinutes.en,
          meetingVideoEn: r.data.meetingVideo.en,
          chashDividend: r.data.chashDividend,
          stockDividend: r.data.stockDividend,
          recordDate: r.data.recordDate ? new Date(r.data.recordDate) : '',
          exDividendDate: r.data.exDividendDate ? new Date(r.data.exDividendDate) : '',
          cashDividendPaymentDate: r.data.cashDividendPaymentDate ? new Date(r.data.cashDividendPaymentDate) : '',
          stockDividendPaymentDate: r.data.stockDividendPaymentDate ? new Date(r.data.stockDividendPaymentDate) : ''
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/shareholder/${this.state.id}`, {
      years: this.input.years.value(),
      chashDividend: this.input.chashDividend.value(),
      stockDividend: this.input.stockDividend.value(),
      recordDate: this.state.recordDate ? this.state.recordDate.getTime() : '',
      exDividendDate: this.state.exDividendDate ? this.state.exDividendDate.getTime() : '',
      cashDividendPaymentDate: this.state.cashDividendPaymentDate ? this.state.cashDividendPaymentDate.getTime() : '',
      stockDividendPaymentDate: this.state.stockDividendPaymentDate ? this.state.stockDividendPaymentDate.getTime() : '',
      meetingVideo: {
        tw: this.state.meetingVideoTw,
        en: this.state.meetingVideoEn
      }
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  handleFileSubmit (name = '', lang = 'tw') {
    const langText = lang.substring(0, 1).toUpperCase() + lang.substring(1)
    let state = {}
    let type = 0
    let fileFieldName = ''
    const loadingStateName = `loadingFor${name}${langText}`
    const successStateName = `successFor${name}${langText}`

    if (name === 'Notice') {
      type = 5
      fileFieldName = 'meetingNotice' + langText
    } else if (name === 'Agenda') {
      type = 6
      fileFieldName = 'meetingAgenda' + langText
    } else if (name === 'Minutes') {
      type = 7
      fileFieldName = 'meetingMinutes' + langText
    }
    state[loadingStateName] = true
    this.setState(state)

    postFormData('/1/admin/shareholder/storage', {
      years: this.props.match.params.years,
      file: this.input[fileFieldName].value(),
      lang: lang,
      type: type
    }).then(r => {
      state = {
        hasError: {},
        help: {}
      }
      state[loadingStateName] = false

      if (r.success === true) {
        state[successStateName] = true
        state[fileFieldName] = r.data.uri
      } else {
        state[successStateName] = false
        state.hasError[fileFieldName] = true
        if (r.errfor) {
          if (r.errfor.file) {
            state.help[fileFieldName] = r.errfor.file
          }
        } else {
          state.help[fileFieldName] = '發生未知的錯誤'
        }
      }

      this.setState(state)
    })
  }

  render () {
    let alert = []
    const alertForFile = (
      <Alert
        type='success'
        message='上傳成功'
      />
    )

    if (this.state.success) {
      return (<Redirect to='/app/admin/shareholders' />)
    }

    if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>{this.props.match.params.years} 年股東服務資訊</title>
        </Helmet>

        <h1 className='page-header'>編輯 {this.state.years} 年股東服務資訊</h1>
        <div className='row'>
          <div className='col-md-4'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <fieldset>
                <TextControl
                  ref={(c) => (this.input.years = c)}
                  name='years'
                  label='年份'
                  value={this.state.years}
                  onChange={(e) => (this.setState({ years: e.target.value }))}
                  hasError={this.state.hasError.years}
                  help={this.state.help.years}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.chashDividend = c)}
                  name='chashDividend'
                  label='現金股利'
                  value={this.state.chashDividend}
                  onChange={(e) => (this.setState({ chashDividend: e.target.value }))}
                  hasError={this.state.hasError.chashDividend}
                  help={this.state.help.chashDividend}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.stockDividend = c)}
                  name='stockDividend'
                  label='股票股利'
                  value={this.state.stockDividend}
                  onChange={(e) => (this.setState({ stockDividend: e.target.value }))}
                  hasError={this.state.hasError.stockDividend}
                  help={this.state.help.stockDividend}
                  disabled={this.state.loading}
                />
                <DateTimeControl
                  ref={(c) => (this.input.recordDate = c)}
                  name='recordDate'
                  label='除權息基準日'
                  placeholder=''
                  timeFormat={false}
                  value={this.state.recordDate}
                  onChange={(e) => this.setState({ recordDate: new Date(e.valueOf()) })}
                  hasError={this.state.hasError.recordDate}
                  help={this.state.help.recordDate}
                  disabled={this.state.loading}
                />

                <DateTimeControl
                  ref={(c) => (this.input.exDividendDate = c)}
                  name='exDividendDate'
                  label='除權息交易日'
                  placeholder=''
                  timeFormat={false}
                  value={this.state.exDividendDate}
                  onChange={(e) => this.setState({ exDividendDate: new Date(e.valueOf()) })}
                  hasError={this.state.hasError.exDividendDate}
                  help={this.state.help.exDividendDate}
                  disabled={this.state.loading}
                />

                <DateTimeControl
                  ref={(c) => (this.input.cashDividendPaymentDate = c)}
                  name='cashDividendPaymentDate'
                  label='現金發放日'
                  placeholder=''
                  timeFormat={false}
                  value={this.state.cashDividendPaymentDate}
                  onChange={(e) => this.setState({ cashDividendPaymentDate: new Date(e.valueOf()) })}
                  hasError={this.state.hasError.cashDividendPaymentDate}
                  help={this.state.help.cashDividendPaymentDate}
                  disabled={this.state.loading}
                />

                <DateTimeControl
                  ref={(c) => (this.input.stockDividendPaymentDate = c)}
                  name='stockDividendPaymentDate'
                  label='股票發放日'
                  placeholder=''
                  timeFormat={false}
                  value={this.state.stockDividendPaymentDate}
                  onChange={(e) => this.setState({ stockDividendPaymentDate: new Date(e.valueOf()) })}
                  hasError={this.state.hasError.stockDividendPaymentDate}
                  help={this.state.help.stockDividendPaymentDate}
                  disabled={this.state.loading}
                />

                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-success': true }}
                    disabled={this.state.loading}
                  >
                    更新
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </ControlGroup>
              </fieldset>
            </form>
          </div>
          <div className='col-md-4'>
            <Card>
              <CardBody>
                {this.state.successForNoticeTw ? alertForFile : ''}
                <FileControl
                  ref={(c) => (this.input.meetingNoticeTw = c)}
                  name='meetingNoticeTw'
                  label='開會通知 (中文繁體)'
                  hasError={this.state.hasError.meetingNoticeTw}
                  help={this.state.help.meetingNoticeTw}
                  disabled={this.state.loadingForNoticeTw}
                />

                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForNoticeTw}
                  onClick={e => this.handleFileSubmit('Notice', 'tw')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForNoticeTw} />
                </Button>
                &nbsp;
                {this.state.meetingNoticeTw &&
                  <a href={`${config.awsUrl}${this.state.meetingNoticeTw}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>}
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForAgendaTw ? alertForFile : ''}
                <FileControl
                  ref={(c) => (this.input.meetingAgendaTw = c)}
                  name='meetingAgendaTw'
                  label='會議事手冊 (中文繁體)'
                  hasError={this.state.hasError.meetingAgendaTw}
                  help={this.state.help.meetingAgendaTw}
                  disabled={this.state.loadingForAgendaTw}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForAgendaTw}
                  onClick={e => this.handleFileSubmit('Agenda', 'tw')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForAgendaTw} />
                </Button>
                &nbsp;
                {this.state.meetingAgendaTw &&
                  <a href={`${config.awsUrl}${this.state.meetingAgendaTw}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>}
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForMinutesTw ? alertForFile : ''}
                <FileControl
                  ref={(c) => (this.input.meetingMinutesTw = c)}
                  name='meetingMinutesTw'
                  label='會議事錄 (中文繁體)'
                  hasError={this.state.hasError.meetingMinutesTw}
                  help={this.state.help.meetingMinutesTw}
                  disabled={this.state.loadingForMinutesTw}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForMinutesTw}
                  onClick={e => this.handleFileSubmit('Minutes', 'tw')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForMinutesTw} />
                </Button>
                &nbsp;
                {this.state.meetingMinutesTw &&
                  <a href={`${config.awsUrl}${this.state.meetingMinutesTw}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>}
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                <TextControl
                  ref={(c) => (this.input.meetingVideoTw = c)}
                  name='meetingVideoTw'
                  label='股東會實況錄影 (中文繁體)'
                  value={this.state.meetingVideoTw}
                  onChange={(e) => (this.setState({ meetingVideoTw: e.target.value }))}
                  hasError={this.state.hasError.meetingVideoTw}
                  help={this.state.help.meetingVideoTw}
                  disabled={this.state.loading}
                />
              </CardBody>
            </Card>
          </div>

          <div className='col-md-4'>
            <Card>
              <CardBody>
                {this.state.successForNoticeEn ? alertForFile : ''}
                <FileControl
                  ref={(c) => (this.input.meetingNoticeEn = c)}
                  name='meetingNoticeEn'
                  label='開會通知 (英文)'
                  hasError={this.state.hasError.meetingNoticeEn}
                  help={this.state.help.meetingNoticeEn}
                  disabled={this.state.loadingForNoticeEn}
                />

                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForNoticeEn}
                  onClick={e => this.handleFileSubmit('Notice', 'en')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForNoticeEn} />
                </Button>
                &nbsp;
                {this.state.meetingNoticeEn &&
                  <a href={`${config.awsUrl}${this.state.meetingNoticeEn}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>}
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForAgendaEn ? alertForFile : ''}
                <FileControl
                  ref={(c) => (this.input.meetingAgendaEn = c)}
                  name='meetingAgendaEn'
                  label='會議事手冊 (英文)'
                  hasError={this.state.hasError.meetingAgendaEn}
                  help={this.state.help.meetingAgendaEn}
                  disabled={this.state.loadingForAgendaEn}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForAgendaEn}
                  onClick={e => this.handleFileSubmit('Agenda', 'en')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForAgendaEn} />
                </Button>
                &nbsp;
                {this.state.meetingAgendaEn &&
                  <a href={`${config.awsUrl}${this.state.meetingAgendaEn}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>}
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForMinutesEn ? alertForFile : ''}
                <FileControl
                  ref={(c) => (this.input.meetingMinutesEn = c)}
                  name='meetingMinutesEn'
                  label='會議事錄 (英文)'
                  hasError={this.state.hasError.meetingMinutesEn}
                  help={this.state.help.meetingMinutesEn}
                  disabled={this.state.loadingForMinutesEn}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForMinutesEn}
                  onClick={e => this.handleFileSubmit('Minutes', 'en')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForMinutesEn} />
                </Button>
                &nbsp;
                {this.state.meetingMinutesEn &&
                  <a href={`${config.awsUrl}${this.state.meetingMinutesEn}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>}
              </CardBody>
            </Card>
            <Card className='mt-4'>
              <CardBody>
                <TextControl
                  ref={(c) => (this.input.meetingVideoEn = c)}
                  name='meetingVideoEn'
                  label='股東會實況錄影 (英文)'
                  value={this.state.meetingVideoEn}
                  onChange={(e) => (this.setState({ meetingVideoEn: e.target.value }))}
                  hasError={this.state.hasError.meetingVideoEn}
                  help={this.state.help.meetingVideoEn}
                  disabled={this.state.loading}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
    )
  }
}

export default EditShareholderPage
