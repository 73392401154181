import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { get } from '../utils/httpAgent'
import { Container, Row, Col, Table, Label, Input, Form, FormGroup } from 'reactstrap'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  BarChart,
  Bar
} from 'recharts'

class ProductCategoryDownloadDashboardPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      products: [],
      year: new Date().getUTCFullYear()
    }
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  fetchData () {
    get(`/1/admin/product_category/${this.props.match.params.id}/year/${this.state.year}/count`)
      .then(r => {
        const perMonthData = new Array(12).fill({})
        perMonthData.forEach((_, i) => {
          perMonthData[i] = {
            _id: `${i + 1}月`
          }
          r.data.product.forEach(p => {
            perMonthData[i][p._id] = p.sum[i] || 0
          })
        })

        this.setState({
          name: r.data.name,
          products: r.data.product.map((p, i) => {
            return {
              _id: p._id,
              name: p.name,
              month: p.sum,
              sum: p.sum.reduce((acc, cur) => acc + cur, 0),
              checked: true,
              color: this.createColor(i)
            }
          }),
          perMonthData
        })
      })
  }

  createColor (i) {
    const colors = [
      '#007bff',
      '#6610f2',
      '#6f42c1',
      '#e83e8c',
      '#dc3545',
      '#fd7e14',
      '#ffc107',
      '#28a745',
      '#20c997',
      '#17a2b8',
      '#343a40',
      '#6c757d',
      '#007bff',
      '#6610f2',
      '#6f42c1',
      '#e83e8c',
      '#dc3545',
      '#fd7e14',
      '#ffc107',
      '#28a745',
      '#20c997',
      '#17a2b8',
      '#343a40',
      '#6c757d'
    ]
    return colors[i % colors.length]
  }

  render () {
    return (
      <Container className='section-home'>
        <Helmet>
          <title>{this.state.name} 下載次數</title>
        </Helmet>
        <h1 className='page-header'>{this.state.name} 下載次數</h1>
        <Row>
          <Col md='12'>
            <Form inline>
              <FormGroup className='mb-2 mr-sm-2 mb-3'>
                <Label className='mr-sm-2'>年度</Label>
                <Input
                  type='select'
                  name='year'
                  value={this.state.year}
                  onChange={e => {
                    this.setState({
                      year: e.target.value
                    }, () => {
                      this.fetchData()
                    })
                  }}
                >
                  <option value={new Date().getUTCFullYear()}>{new Date().getUTCFullYear()}</option>
                  {(new Date().getUTCFullYear() - 1) > 2022 &&
                    <option value={new Date().getUTCFullYear() - 1}>{new Date().getUTCFullYear() - 1}</option>}
                  {(new Date().getUTCFullYear() - 2) > 2022 &&
                    <option value={new Date().getUTCFullYear() - 2}>{new Date().getUTCFullYear() - 2}</option>}
                </Input>
              </FormGroup>
            </Form>
          </Col>
          <Col md='12'>
            <Table striped>
              <thead>
                <tr>
                  <th className='p-2'>
                    <Label check className='ml-4'>
                      <Input
                        type='checkbox'
                        checked={this.state.products.length === this.state.products.filter(p => p.checked).length}
                        onClick={() => {
                          this.setState({
                            products: this.state.products.map(p => {
                              p.checked = !p.checked
                              return p
                            })
                          })
                        }}
                      />
                      {' '}
                      產品名稱
                    </Label>
                  </th>
                  {[...Array(12).keys()].map(i => (
                    <th key={`th-${i}`} className='p-2'>{i + 1}月</th>
                  ))}
                  <th className='p-2'>總次數</th>
                </tr>
              </thead>
              <tbody>
                {this.state.products.map((product, i) => (
                  <tr key={`tbody-${i}`}>
                    <td className='p-2'>
                      <Label check className='ml-4'>
                        <Input
                          type='checkbox'
                          checked={product.checked}
                          onClick={() => {
                            this.setState({
                              products: this.state.products.map(p => {
                                if (p.name === product.name) {
                                  p.checked = !p.checked
                                }
                                return p
                              })
                            })
                          }}
                        />
                        {' '}
                        {product.name}
                      </Label>
                    </td>
                    {product.month.map((m, j) => (
                      <td key={`td-${j}`} className='p-2'>{m}</td>
                    ))}
                    <td className='p-2'>
                      {product.month.reduce((acc, cur) => acc + cur, 0)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className='p-2'>總計</td>
                  {this.state.products.filter(p => p.checked).reduce((acc, cur) => {
                    return acc.map((m, i) => m + cur.month[i])
                  }, new Array(12).fill(0)).map((m, i) => (
                    <td key={`td-${i}`} className='p-2'>{m}</td>
                  ))}

                  <td className='p-2'>
                    {this.state.products.filter(p => p.checked).reduce((acc, cur) => {
                      return acc + cur.month.reduce((acc, cur) => acc + cur, 0)
                    }, 0)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col md='12'>
            <ResponsiveContainer height={480}>
              <ComposedChart data={this.state.perMonthData} margin={{ top: 20, left: -15 }}>
                <XAxis dataKey='_id' tickLine={false} padding={{ left: 20 }} />
                <YAxis tickLine={false} />
                <Tooltip />
                <CartesianGrid vertical={false} />
                {this.state.products.filter(p => p.checked).map((product, i) => (
                  <Line
                    key={`line-${i}`}
                    type='linear'
                    name={product.name}
                    dataKey={product._id}
                    strokeWidth={2}
                    stroke={product.color}
                  />
                ))}
                <Legend height={50} />
              </ComposedChart>
            </ResponsiveContainer>
          </Col>
          <Col md='12' className='mt-3'>
            <ResponsiveContainer width='100%' height={500}>
              <BarChart layout='vertical' data={this.state.products.filter(p => p.checked)}>
                <XAxis type='number' />
                <YAxis dataKey='name' type='category' scale='band' tick={{ fontSize: 10 }} />
                <Tooltip />
                <CartesianGrid vertical />
                <Bar
                  dataKey='sum'
                  fill='#546a79'
                  barSize={15}
                  label={({ x, y, value, width }) => (
                    <text
                      x={x + width}
                      dx={-5}
                      y={y}
                      dy={12}
                      fontSize='12'
                      fill='#fff'
                      textAnchor='end'
                    >{value > 0 ? value : ''}
                    </text>
                  )}
                />
              </BarChart>
            </ResponsiveContainer>
          </Col>
          <Col md='12' className='mt-3'>
            <p className='mb-0'>
              統計限制：
            </p>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                僅能統計於官網上傳的檔案下載的次數，如該檔案是使用檔案網址形式，則不在此統計內。
              </li>
              <li>
                僅能統計當前納入產品線的產品下載次數，若該產品已設為不啟用、遭移除該產線或遭刪除，則不在此統計內。
              </li>
              <li>
                僅能統計當前納入產品檔案之下載次數，若該產品檔案已設為不啟用、遭移除該產品或刪除，則不在此統計內。
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ProductCategoryDownloadDashboardPage
