import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Navbar, Container } from 'reactstrap'
import { post, put } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import HealthInsurance from './entryFlow2/health-insurance'
import RaisingRelatives from './entryFlow2/raising-relatives'
import EmployeeConsent from './entryFlow2/employee-consent'
import EmployeeGuarantee from './entryFlow2/employee-guarantee'
import EmployeeInfoSecurityPolicy from './entryFlow2/employee-info-security-policy'
import PhotoUpoad from './entryFlow2/photo-upoad'

const year = new Date().getFullYear()
class EntryFlow2Page extends Component {
  constructor () {
    super()
    this.input = {}
    this.setPersonnel = this.setPersonnel.bind(this)
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      page: 0,
      name: '',
      id: ''
    }
  }

  componentDidMount () {
    if (this.input.email) {
      this.input.email.focus()
    }
  }

  handleGetData (event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      loading: true,
      email: this.input.email.value(),
      fillingToken: this.input.fillingToken.value()
    })

    post('/1/personnel', {
      email: this.input.email.value(),
      fillingToken: this.input.fillingToken.value()
    }).then(
      r => {
        if (r.success === true) {
          const pageCompleteList = [
            r.data.healthInsurance.isComplete,
            r.data.raisingRelatives.isComplete,
            r.data.employeeConsent.isComplete,
            r.data.employeeGuarantee.isComplete,
            r.data.employeeInfoSecurityPolicy.isComplete,
            !!r.data.photo]

          let page = 1
          for (let i = 0; i < pageCompleteList.length; i++) {
            if (pageCompleteList[i]) {
              page++
            } else {
              i = pageCompleteList.length
            }
          }

          this.setState({
            error: '',
            loading: false,
            page: page,
            id: r.data._id,
            photo: r.data.photo,
            idNumber: r.data.idNumber,
            birthday: r.data.birthday ? new Date(r.data.birthday) : '',
            residenceAddress: r.data.residenceAddress,
            healthInsurance: r.data.healthInsurance,
            raisingRelatives: r.data.raisingRelatives,
            employeeConsent: r.data.employeeConsent,
            employeeGuarantee: r.data.employeeGuarantee,
            employeeInfoSecurityPolicy: r.data.employeeInfoSecurityPolicy ? r.data.employeeInfoSecurityPolicy : { isComplete: false }
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  setPersonnel (data, currentPage, targetPage, isTemporary = false) {
    this.handleSubmit(data, currentPage, targetPage, isTemporary)
    this.setState({ ...data })
  }

  handleSubmit (data, currentPage, targetPage, isTemporary = false) {
    let allComplete = false
    if (targetPage === 7) {
      allComplete = true
    }
    put('/1/personnel/entry2', {
      page: currentPage,
      email: this.state.email,
      fillingToken: this.state.fillingToken,
      isTemporary: isTemporary,
      allComplete: allComplete,
      ...data
    }).then(
      r => {
        this.setState({
          loading: false,
          hasError: {},
          help: {},
          error: ''
        })
        if (r.success) {
          this.setState({
            page: targetPage
          })
          window.scrollTo(0, 0)
        } else if (r.success !== true) {
          const state = {
            hasError: {},
            help: {},
            error: ''
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }
          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
          window.scrollTo(0, 250)
        }
      }
    )
  }

  render () {
    let alerts = []

    if (this.state.success) {
      window.location.href = `${this.state.callback}?token=${this.state.appToken}`
    } else if (this.state.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <div>
        <Helmet>
          <title>人事資料表</title>
        </Helmet>

        <Navbar expand='md' className={`fixed-top ${this.state.page < 1 || this.state.page === 8 ? 'personnel-nav-1' : ''}${this.state.page > 0 && this.state.page < 8 ? 'personnel-nav-2' : ''}`}>
          <Container fluid>
            <a href='/' className='navbar-brand'>
              <img className='navbar-logo' src='/media/logo.svg' alt='' />
            </a>
          </Container>
        </Navbar>

        {this.state.page === 0 &&
          <div className='container-fluid personnel-1'>
            <div className='row justify-content-md-center'>
              <div className='col-sm-6 personnel-form'>
                <form onSubmit={this.handleGetData.bind(this)}>
                  <h3>歡迎您加入信驊大家庭</h3>
                  <p>
                    請完成健保轉入申請表、薪資所得受領人扶養親屬申報表、員工同意書、員工保證書。謝謝您的配合！
                  </p>
                  {alerts}
                  <TextControl
                    ref={(c) => (this.input.email = c)}
                    name='email'
                    placeholder='email'
                    hasError={this.state.hasError.email}
                    help={this.state.help.email}
                    disabled={this.state.loading}
                  />
                  <TextControl
                    ref={(c) => (this.input.fillingToken = c)}
                    name='fillingToken'
                    placeholder='填表秘鑰'
                    type='password'
                    hasError={this.state.hasError.fillingToken}
                    help={this.state.help.fillingToken}
                    disabled={this.state.loading}
                  />
                  <ControlGroup hideLabel hideHelp>
                    <Button
                      type='submit'
                      inputClasses={{ 'btn-personnel': true }}
                      disabled={this.state.loading}
                    >
                      開始填寫
                      <Spinner space='left' show={this.state.loading} />
                    </Button>
                  </ControlGroup>
                </form>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {[1, 2, 3, 4, 5, 6].includes(this.state.page) &&
          <div className='personnel-hero'>
            <div className='main-img d-none d-lg-block' />
            <div className='main-img d-lg-none' />
            <div className='content'>
              {this.state.page === 1 &&
                <h1>健保轉入申請表</h1>}
              {this.state.page === 2 &&
                <h1>薪資所得受領人扶養親屬申報表</h1>}
              {this.state.page === 3 &&
                <h1>員工同意書</h1>}
              {this.state.page === 4 &&
                <h1>員工保證書</h1>}
              {this.state.page === 5 &&
                <h1>員工資訊安全政策同意書</h1>}
              {this.state.page === 6 &&
                <h1>證件照上傳</h1>}
            </div>
          </div>}

        {this.state.page === 1 &&
          <HealthInsurance
            idNumber={this.state.idNumber}
            birthday={this.state.birthday}
            hasSubsidyEligible={this.state.healthInsurance.hasSubsidyEligible}
            subsidyItem={this.state.subsidyItem}
            hasDependent={this.state.healthInsurance.hasDependent}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 2 &&
          <RaisingRelatives
            residenceAddress={this.state.residenceAddress}
            spouseName={this.state.raisingRelatives.spouseName}
            spouseBirthday={this.state.raisingRelatives.spouseBirthday}
            spouseIdNumber={this.state.raisingRelatives.spouseIdNumber}
            spouseResidenceAddress={this.state.raisingRelatives.spouseResidenceAddress}
            linealRelativesByBlood={this.state.raisingRelatives.linealRelativesByBlood}
            children={this.state.raisingRelatives.children}
            sibling={this.state.raisingRelatives.sibling}
            relativesByBloodOfTheEqualRank={this.state.raisingRelatives.relativesByBloodOfTheEqualRank}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 3 &&
          <EmployeeConsent
            name={this.state.name}
            idNumber={this.state.idNumber}
            residenceAddress={this.state.residenceAddress}
            isComplete={this.state.employeeConsent.isComplete}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 4 &&
          <EmployeeGuarantee
            name={this.state.employeeGuarantee.name}
            employeeDate={this.state.employeeGuarantee.employeeDate}
            idNumber={this.state.idNumber}
            isComplete={this.state.employeeGuarantee.isComplete}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 5 &&
          <EmployeeInfoSecurityPolicy
            name={this.state.name}
            idNumber={this.state.idNumber}
            residenceAddress={this.state.residenceAddress}
            isComplete={this.state.employeeInfoSecurityPolicy?.isComplete}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 6 &&
          <PhotoUpoad
            photo={this.state.photo}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 7 &&
          <div className='container-fluid personnel-2'>
            <div className='row justify-content-md-center'>
              <div className='col-lg-6 col-sm-8 col-11 personnel-form'>
                <h3>
                  感謝您，填寫完成！
                </h3>
                <p>
                  期待與您一同共創美好未來，信驊科技誠摯歡迎您的加入。
                </p>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {this.state.page > 0 && this.state.page < 2 &&
          <div className='footer personnel-footer'>
            <div className='container'>
              <span className='copyright float-right'>© {year} ASPEED Technology Inc. All rights reserved.</span>

              <div className='clearfix' />
            </div>
          </div>}
      </div>
    )
  }
}

export default EntryFlow2Page
