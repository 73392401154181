import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang'
import en from './utils/translations/en.json'
import zh from './utils/translations/zh-TW.json'

const getParameterByName = function (name) {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(window.location.href)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

let defaultLanguage = getParameterByName('lang')

if (!defaultLanguage) {
  if (localStorage.getItem('lang')) {
    defaultLanguage = localStorage.getItem('lang')
  } else {
    defaultLanguage = navigator.language || navigator.userLanguage.includes('zh') ? 'zh' : 'en'
  }
}

setDefaultTranslations({ zh, en })
if (defaultLanguage !== 'undefined') {
  setDefaultLanguage(defaultLanguage)
} else {
  setDefaultLanguage('en')
}

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
