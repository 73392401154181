import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'
import DateTimeControl from '../../components/datetime-control'

class RaisingRelativesPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.imageRef = {}
    this.state = {
      residenceAddress: this.props.residenceAddress ? this.props.residenceAddress : '',
      spouseName: this.props.spouseName ? this.props.spouseName : '',
      spouseBirthday: this.props.spouseBirthday ? new Date(this.props.spouseBirthday) : '',
      spouseIdNumber: this.props.spouseIdNumber ? this.props.spouseIdNumber : '',
      spouseResidenceAddress: this.props.spouseResidenceAddress ? this.props.spouseResidenceAddress : '',
      linealRelativesByBlood: this.props.linealRelativesByBlood ? this.props.linealRelativesByBlood : [{
        name: '',
        title: '',
        birthday: '',
        idNumber: '',
        residenceAddress: '',
        eligibleCondition: ''
      }],
      children: this.props.children ? this.props.children : [{
        name: '',
        title: '',
        birthday: '',
        idNumber: '',
        residenceAddress: '',
        eligibleCondition: ''
      }],
      sibling: this.props.sibling ? this.props.sibling : [{
        name: '',
        title: '',
        birthday: '',
        idNumber: '',
        residenceAddress: '',
        eligibleCondition: ''
      }],
      relativesByBloodOfTheEqualRank: this.props.relativesByBloodOfTheEqualRank ? this.props.relativesByBloodOfTheEqualRank : [{
        name: '',
        title: '',
        birthday: '',
        idNumber: '',
        residenceAddress: '',
        eligibleCondition: ''
      }]
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 2, isTemporary) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      residenceAddress: this.state.residenceAddress,
      spouseName: this.state.spouseName,
      spouseBirthday: this.state.spouseBirthday ? this.state.spouseBirthday.getTime() : '',
      spouseIdNumber: this.state.spouseIdNumber,
      spouseResidenceAddress: this.state.spouseResidenceAddress,
      linealRelativesByBlood: this.state.linealRelativesByBlood ? JSON.stringify(this.state.linealRelativesByBlood) : '',
      children: this.state.children ? JSON.stringify(this.state.children) : '',
      sibling: this.state.children ? JSON.stringify(this.state.children) : '',
      relativesByBloodOfTheEqualRank: this.state.relativesByBloodOfTheEqualRank ? JSON.stringify(this.state.relativesByBloodOfTheEqualRank) : '',
      isComplete: this.state.residenceAddress !== ''
    }, 2, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>2 / 6</p>
                <Progress value='60' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.residenceAddress = c)}
                    name='residenceAddress'
                    label='本人戶籍地址'
                    value={this.state.residenceAddress}
                    onChange={(e) => (this.setState({ residenceAddress: e.target.value }))}
                    hasError={this.props.hasError.residenceAddress}
                    help={this.props.help.residenceAddress}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <h3 className='text-center mt-4'>配偶</h3>
                </Col>
                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.spouseName = c)}
                    name='spouseName'
                    label='姓名'
                    value={this.state.spouseName}
                    onChange={(e) => (this.setState({ spouseName: e.target.value }))}
                    hasError={this.props.hasError.spouseName}
                    help={this.props.help.spouseName}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='4'>
                  <DateTimeControl
                    ref={(c) => (this.input.spouseBirthday = c)}
                    name='spouseBirthday'
                    label='出生日期'
                    timeFormat={false}
                    value={this.state.spouseBirthday}
                    onChange={(e) => {
                      this.setState({ spouseBirthday: e.valueOf() ? new Date(e.valueOf()) : null })
                    }}
                    hasError={this.props.hasError.spouseBirthday}
                    help={this.props.help.spouseBirthday}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.spouseIdNumber = c)}
                    name='spouseIdNumber'
                    label='身分證字號'
                    value={this.state.spouseIdNumber}
                    onChange={(e) => (this.setState({ spouseIdNumber: e.target.value }))}
                    hasError={this.props.hasError.spouseIdNumber}
                    help={this.props.help.spouseIdNumber}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.spouseResidenceAddress = c)}
                    name='spouseResidenceAddress'
                    label='戶籍地址'
                    value={this.state.spouseResidenceAddress}
                    onChange={(e) => (this.setState({ spouseResidenceAddress: e.target.value }))}
                    hasError={this.props.hasError.spouseResidenceAddress}
                    help={this.props.help.spouseResidenceAddress}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row>
                <Col md='12'>
                  <p>
                    一、依照所得稅法第十七條規定，納稅義務人及其配偶之直系尊親屬合於下列條件之一者，每年每人得減除扶養親屬免稅額。<br />
                    (1) 年滿六十歲；<br />
                    (2) 未滿六十歲者，但無謀生能力受納稅義務人扶養者。
                  </p>
                </Col>

                {this.state.linealRelativesByBlood.length < 6 &&
                  <Col md='12'>
                    <Button
                      type='button'
                      inputClasses={{ 'btn-primary': true }}
                      onClick={(e) => (
                        this.setState({
                          linealRelativesByBlood: [...this.state.linealRelativesByBlood, {
                            name: '',
                            title: '',
                            birthday: '',
                            idNumber: '',
                            residenceAddress: '',
                            eligibleCondition: ''
                          }]
                        })
                      )}
                      disabled={this.props.loading}
                    >
                      新增一筆
                    </Button>
                  </Col>}

                <Col md='12'>
                  {this.state.linealRelativesByBlood.map((item, i) => (
                    <Card body className='mt-4' key={item}>
                      <Row>
                        <Button
                          type='button'
                          inputClasses={{ close: true }}
                          onClick={(e) => (
                            this.setState({
                              linealRelativesByBlood: this.state.linealRelativesByBlood.filter((_item, j) => i !== j)
                            })
                          )}
                          disabled={this.props.loading}
                        >
                          ✕
                        </Button>
                        <Col md='3'>
                          <TextControl
                            name='name'
                            label='姓名'
                            value={this.state.linealRelativesByBlood[i].name}
                            onChange={(e) => (
                              this.setState({ linealRelativesByBlood: this.state.linealRelativesByBlood.map((item, _i) => _i === i ? { ...item, name: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='title'
                            label='稱謂'
                            value={this.state.linealRelativesByBlood[i].title}
                            onChange={(e) => (
                              this.setState({ linealRelativesByBlood: this.state.linealRelativesByBlood.map((item, _i) => _i === i ? { ...item, title: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <DateTimeControl
                            name='birthday'
                            label='出生日期'
                            timeFormat={false}
                            value={this.state.linealRelativesByBlood[i].birthday ? new Date(this.state.linealRelativesByBlood[i].birthday) : ''}
                            onChange={(e) => (
                              this.setState({ linealRelativesByBlood: this.state.linealRelativesByBlood.map((item, _i) => _i === i ? { ...item, birthday: e.valueOf() ? new Date(e.valueOf()) : null } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='idNumber'
                            label='身分證字號'
                            value={this.state.linealRelativesByBlood[i].idNumber}
                            onChange={(e) => (
                              this.setState({ linealRelativesByBlood: this.state.linealRelativesByBlood.map((item, _i) => _i === i ? { ...item, idNumber: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='8'>
                          <TextControl
                            name='residenceAddress'
                            label='戶籍地址'
                            value={this.state.linealRelativesByBlood[i].residenceAddress}
                            onChange={(e) => (
                              this.setState({ linealRelativesByBlood: this.state.linealRelativesByBlood.map((item, _i) => _i === i ? { ...item, residenceAddress: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='4'>
                          <SelectControl
                            name='eligibleCondition'
                            label='符合之條件'
                            value={this.state.linealRelativesByBlood[i].eligibleCondition}
                            onChange={(e) => (
                              this.setState({ linealRelativesByBlood: this.state.linealRelativesByBlood.map((item, _i) => _i === i ? { ...item, eligibleCondition: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          >
                            <option value='1'>
                              年滿六十歲
                            </option>
                            <option value='2'>
                              未滿六十歲者，但無謀生能力受納稅義務人扶養者
                            </option>
                          </SelectControl>
                        </Col>
                      </Row>
                    </Card>)
                  )}
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row>
                <Col md='12'>
                  <p>
                    二、依照所得稅法第十七條規定，納稅義務人之子女，合於下列條件之一者，每年每人得減除其扶養親屬免稅額。<br />
                    (1) 未滿二十歲；<br />
                    (2) 已滿二十歲者，因在校就學受納稅義務人扶養者；<br />
                    (3) 已滿二十歲者，因身心殘障受納稅義務人扶養者；<br />
                    (4) 已滿二十歲者，因無謀生能力受納稅義務人扶養者；
                  </p>
                </Col>

                {this.state.children.length < 12 &&
                  <Col md='12'>
                    <Button
                      type='button'
                      inputClasses={{ 'btn-primary': true }}
                      onClick={(e) => (
                        this.setState({
                          children: [...this.state.children, {
                            name: '',
                            title: '',
                            birthday: '',
                            idNumber: '',
                            residenceAddress: '',
                            eligibleCondition: ''
                          }]
                        })
                      )}
                      disabled={this.props.loading}
                    >
                      新增一筆
                    </Button>
                  </Col>}

                <Col md='12'>
                  {this.state.children.map((item, i) => (
                    <Card body className='mt-4' key={item}>
                      <Row>
                        <Button
                          type='button'
                          inputClasses={{ close: true }}
                          onClick={(e) => (
                            this.setState({
                              children: this.state.children.filter((_item, j) => i !== j)
                            })
                          )}
                          disabled={this.props.loading}
                        >
                          ✕
                        </Button>
                        <Col md='3'>
                          <TextControl
                            name='name'
                            label='姓名'
                            value={this.state.children[i].name}
                            onChange={(e) => (
                              this.setState({ children: this.state.children.map((item, _i) => _i === i ? { ...item, name: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='title'
                            label='稱謂'
                            value={this.state.children[i].title}
                            onChange={(e) => (
                              this.setState({ children: this.state.children.map((item, _i) => _i === i ? { ...item, title: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <DateTimeControl
                            name='birthday'
                            label='出生日期'
                            timeFormat={false}
                            value={this.state.children[i].birthday ? new Date(this.state.children[i].birthday) : ''}
                            onChange={(e) => (
                              this.setState({ children: this.state.children.map((item, _i) => _i === i ? { ...item, birthday: e.valueOf() ? new Date(e.valueOf()) : null } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='idNumber'
                            label='身分證字號'
                            value={this.state.children[i].idNumber}
                            onChange={(e) => (
                              this.setState({ children: this.state.children.map((item, _i) => _i === i ? { ...item, idNumber: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='9'>
                          <SelectControl
                            name='eligibleCondition'
                            label='符合之條件'
                            value={this.state.children[i].eligibleCondition}
                            onChange={(e) => (
                              this.setState({ children: this.state.children.map((item, _i) => _i === i ? { ...item, eligibleCondition: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          >
                            <option value='1'>
                              未滿二十歲
                            </option>
                            <option value='2'>
                              已滿二十歲者，因在校就學受納稅義務人扶養者
                            </option>
                            <option value='3'>
                              已滿二十歲者，因身心殘障受納稅義務人扶養者
                            </option>
                            <option value='4'>
                              已滿二十歲者，因無謀生能力受納稅義務人扶養者
                            </option>
                          </SelectControl>
                        </Col>
                      </Row>
                    </Card>)
                  )}
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row>
                <Col md='12'>
                  <p>
                    三、依照所得稅法第十七條規定，納稅義務人及其配偶之同胞兄弟姐妹合於下列條件之一者，每年每人得減除扶養親屬免稅額。<br />
                    (1) 未滿二十歲；<br />
                    (2) 已滿二十歲者，因在校就學受納稅義務人扶養者；<br />
                    (3) 已滿二十歲者，因身心殘障受納稅義務人扶養者；<br />
                    (4) 已滿二十歲者，因無謀生能力受納稅義務人扶養者
                  </p>
                </Col>

                {this.state.sibling.length < 12 &&
                  <Col md='12'>
                    <Button
                      type='button'
                      inputClasses={{ 'btn-primary': true }}
                      onClick={(e) => (
                        this.setState({
                          sibling: [...this.state.sibling, {
                            name: '',
                            title: '',
                            birthday: '',
                            idNumber: '',
                            residenceAddress: '',
                            eligibleCondition: ''
                          }]
                        })
                      )}
                      disabled={this.props.loading}
                    >
                      新增一筆
                    </Button>
                  </Col>}

                <Col md='12'>
                  {this.state.sibling.map((item, i) => (
                    <Card body className='mt-4' key={item}>
                      <Row>
                        <Button
                          type='button'
                          inputClasses={{ close: true }}
                          onClick={(e) => (
                            this.setState({
                              sibling: this.state.sibling.filter((_item, j) => i !== j)
                            })
                          )}
                          disabled={this.props.loading}
                        >
                          ✕
                        </Button>
                        <Col md='3'>
                          <TextControl
                            name='name'
                            label='姓名'
                            value={this.state.sibling[i].name}
                            onChange={(e) => (
                              this.setState({ sibling: this.state.sibling.map((item, _i) => _i === i ? { ...item, name: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='title'
                            label='稱謂'
                            value={this.state.sibling[i].title}
                            onChange={(e) => (
                              this.setState({ sibling: this.state.sibling.map((item, _i) => _i === i ? { ...item, title: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <DateTimeControl
                            name='birthday'
                            label='出生日期'
                            timeFormat={false}
                            value={this.state.sibling[i].birthday ? new Date(this.state.sibling[i].birthday) : ''}
                            onChange={(e) => (
                              this.setState({ sibling: this.state.sibling.map((item, _i) => _i === i ? { ...item, birthday: e.valueOf() ? new Date(e.valueOf()) : null } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='idNumber'
                            label='身分證字號'
                            value={this.state.sibling[i].idNumber}
                            onChange={(e) => (
                              this.setState({ sibling: this.state.sibling.map((item, _i) => _i === i ? { ...item, idNumber: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='9'>
                          <SelectControl
                            name='eligibleCondition'
                            label='符合之條件'
                            value={this.state.sibling[i].eligibleCondition}
                            onChange={(e) => (
                              this.setState({ sibling: this.state.sibling.map((item, _i) => _i === i ? { ...item, eligibleCondition: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          >
                            <option value='1'>
                              未滿二十歲
                            </option>
                            <option value='2'>
                              已滿二十歲者，因在校就學受納稅義務人扶養者
                            </option>
                            <option value='3'>
                              已滿二十歲者，因身心殘障受納稅義務人扶養者
                            </option>
                            <option value='4'>
                              已滿二十歲者，因無謀生能力受納稅義務人扶養者
                            </option>
                          </SelectControl>
                        </Col>
                      </Row>
                    </Card>)
                  )}
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row>
                <Col md='12'>
                  <p>
                    四、依照所得稅法第十七條規定，納稅義務人之其他親屬或家屬，合於下列條件之一者，每年每人得減除其扶養親屬免稅額，但受扶養者之父或母如屬第四條第一款及第二款之免稅額者，不得列報減除。<br />
                    (1) 合於民法第一千一百十四條第四款未滿二十歲或滿六十歲以上無謀生能力確係受納稅義務人扶養者。<br />
                    (2) 合於民法第一千一百二十三條第三項未滿二十歲或滿六十歲以上無謀生能力確係受納稅義務人扶養者。
                  </p>
                </Col>

                {this.state.relativesByBloodOfTheEqualRank.length < 12 &&
                  <Col md='12'>
                    <Button
                      type='button'
                      inputClasses={{ 'btn-primary': true }}
                      onClick={(e) => (
                        this.setState({
                          relativesByBloodOfTheEqualRank: [...this.state.relativesByBloodOfTheEqualRank, {
                            name: '',
                            title: '',
                            birthday: '',
                            idNumber: '',
                            residenceAddress: '',
                            eligibleCondition: ''
                          }]
                        })
                      )}
                      disabled={this.props.loading}
                    >
                      新增一筆
                    </Button>
                  </Col>}

                <Col md='12'>
                  {this.state.relativesByBloodOfTheEqualRank.map((item, i) => (
                    <Card body className='mt-4' key={item}>
                      <Row>
                        <Button
                          type='button'
                          inputClasses={{ close: true }}
                          onClick={(e) => (
                            this.setState({
                              relativesByBloodOfTheEqualRank: this.state.relativesByBloodOfTheEqualRank.filter((_item, j) => i !== j)
                            })
                          )}
                          disabled={this.props.loading}
                        >
                          ✕
                        </Button>
                        <Col md='3'>
                          <TextControl
                            name='name'
                            label='姓名'
                            value={this.state.relativesByBloodOfTheEqualRank[i].name}
                            onChange={(e) => (
                              this.setState({ relativesByBloodOfTheEqualRank: this.state.relativesByBloodOfTheEqualRank.map((item, _i) => _i === i ? { ...item, name: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='title'
                            label='稱謂'
                            value={this.state.relativesByBloodOfTheEqualRank[i].title}
                            onChange={(e) => (
                              this.setState({ relativesByBloodOfTheEqualRank: this.state.relativesByBloodOfTheEqualRank.map((item, _i) => _i === i ? { ...item, title: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <DateTimeControl
                            name='birthday'
                            label='出生日期'
                            timeFormat={false}
                            value={this.state.relativesByBloodOfTheEqualRank[i].birthday ? new Date(this.state.relativesByBloodOfTheEqualRank[i].birthday) : ''}
                            onChange={(e) => (
                              this.setState({ relativesByBloodOfTheEqualRank: this.state.relativesByBloodOfTheEqualRank.map((item, _i) => _i === i ? { ...item, birthday: e.valueOf() ? new Date(e.valueOf()) : null } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='idNumber'
                            label='身分證字號'
                            value={this.state.relativesByBloodOfTheEqualRank[i].idNumber}
                            onChange={(e) => (
                              this.setState({ relativesByBloodOfTheEqualRank: this.state.relativesByBloodOfTheEqualRank.map((item, _i) => _i === i ? { ...item, idNumber: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='9'>
                          <SelectControl
                            name='eligibleCondition'
                            label='符合之條件'
                            value={this.state.relativesByBloodOfTheEqualRank[i].eligibleCondition}
                            onChange={(e) => (
                              this.setState({ relativesByBloodOfTheEqualRank: this.state.relativesByBloodOfTheEqualRank.map((item, _i) => _i === i ? { ...item, eligibleCondition: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          >
                            <option value='1'>
                              合於民法第一千一百十四條第四款未滿二十歲或滿六十歲以上無謀生能力確係受納稅義務人扶養者
                            </option>
                            <option value='2'>
                              合於民法第一千一百二十三條第三項未滿二十歲或滿六十歲以上無謀生能力確係受納稅義務人扶養者
                            </option>
                          </SelectControl>
                        </Col>
                      </Row>
                    </Card>)
                  )}
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 2, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 1, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 3, false)}
                    disabled={this.props.loading}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default RaisingRelativesPage
