import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { loginSuccess, loginError } from './userAction'
import { useHistory, useLocation } from 'react-router-dom'

const requireAdminAuth = (Component) => {
  const AuthenticatedComponent = ({ loginError, ...restProps }) => {
    const history = useHistory()
    const location = useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const handleLoginError = useCallback(() => {
      loginError()
      if (window.location.pathname === '/' || window.location.pathname === '/app/') {
        window.location.href = '/app/'
      } else {
        if (window.location.search) {
          window.location.href = `/app/?returnUrl=${window.location.pathname}?${window.location.search.substr(1)}`
        } else {
          window.location.href = `/app/?returnUrl=${window.location.pathname}`
        }
      }
    }, [loginError, history, location])

    useEffect(() => {
      const token = localStorage.getItem('token')
      if (token) {
        const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
        if (!jwtPayload._id || !jwtPayload.roles.admin) {
          handleLoginError()
        } else {
          restProps.loginSuccess({
            authenticated: true,
            user: jwtPayload.username,
            email: jwtPayload.email,
            adminRole: jwtPayload.adminRole,
            userRole: jwtPayload.userRole
          })
          setIsAuthenticated(true)
        }
      } else {
        handleLoginError()
      }
    }, [handleLoginError, restProps])

    if (!isAuthenticated) {
      return null
    }

    return <Component {...restProps} />
  }

  const mapDispatchToProps = (dispatch) => ({
    loginSuccess: (user) => dispatch(loginSuccess(user)),
    loginError: () => dispatch(loginError())
  })

  return connect(null, mapDispatchToProps)(AuthenticatedComponent)
}

export default requireAdminAuth
