import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import { post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import config from '../config'

const ForgotPage = ({ t, role }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [help, setHelp] = useState({})
  const emailRef = useRef(null)
  const recaptchaRef = useRef()

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus()
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    const recaptcha = recaptchaRef.current.getValue()

    post('/login/forgot/', {
      email: emailRef.current.value(),
      recaptcha
    }).then(
      r => {
        recaptchaRef.current.reset()
        if (r.success === true) {
          setSuccess(true)
          setError('')
          setLoading(false)
        } else {
          const newHasError = {}
          const newHelp = {}
          for (const key in r.errfor) {
            newHasError[key] = true
            newHelp[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            setError(r.errors[0])
          }
          setHasError(newHasError)
          setHelp(newHelp)
          setLoading(false)
        }
      }
    )
  }

  let alert = []

  if (role === 'account') {
    return (<Redirect to='/app/account' />)
  } else if (role === 'admin') {
    return (<Redirect to='/app/admin' />)
  }

  if (success) {
    alert = (
      <div key='success'>
        <Alert
          type='success'
          message={t('reset_success')}
        />
        <Link to='/app/' className='btn btn-link'>{t('back_login')}</Link>
      </div>
    )
  } else if (error) {
    alert = (
      <Alert
        type='danger'
        message={error}
      />
    )
  }

  return (
    <section className='container'>
      <Helmet>
        <title>{t('forgot_password')}</title>
      </Helmet>
      <div className='container'>
        <h1 className='page-header'>{t('forgot_password')}</h1>
        <div className='row'>
          <div className='col-sm-6'>
            <form onSubmit={handleSubmit}>
              {alert}
              {!success &&
                <span>
                  <TextControl
                    ref={emailRef}
                    name='email'
                    label={t('your_email')}
                    hasError={hasError.email}
                    help={help.email}
                    disabled={loading}
                  />
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={config.reCAPTCHA}
                  />
                  <ControlGroup hideLabel hideHelp>
                    <Button
                      type='submit'
                      inputClasses={{ 'btn-primary mt-3': true }}
                      disabled={loading}
                    >
                      {t('send_reset')}
                      <Spinner space='left' show={loading} />
                    </Button>
                    <Link to='/app/' className='btn btn-link'>{t('back_login')}</Link>
                  </ControlGroup>
                </span>}
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

export default connect(mapStateToProps, null)(ForgotPage)
