import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Navbar, Container } from 'reactstrap'
import { post, put } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import EmploymentConfirmation from './entryFlow1/employment-confirmation'
import EntryInfo from './entryFlow1/entry-info'

const year = new Date().getFullYear()
class EntryFlow1Page extends Component {
  constructor () {
    super()
    this.input = {}
    this.setPersonnel = this.setPersonnel.bind(this)
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      page: 0,
      name: '',
      id: ''
    }
  }

  componentDidMount () {
    if (this.input.email) {
      this.input.email.focus()
    }
  }

  handleGetData (event) {
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      loading: true,
      email: this.input.email.value(),
      fillingToken: this.input.fillingToken.value()
    })

    post('/1/personnel', {
      email: this.input.email.value(),
      fillingToken: this.input.fillingToken.value()
    }).then(
      r => {
        if (r.success === true) {
          let page = 1
          if (r.data.employmentConfirmation.isComplete) {
            page = 2
            if (r.data.entryInfo.isComplete) {
              page = 3
            }
          }
          this.setState({
            error: '',
            loading: false,
            page: page,
            id: r.data._id,
            name: r.data.employmentConfirmation.name,
            idNumber: r.data.employmentConfirmation.idNumber,
            residenceAddress: r.data.employmentConfirmation.residenceAddress,
            chName: r.data.chName,
            enName: r.data.enName,
            workPlace: r.data.entryInfo.workPlace,
            hasBankAccount: r.data.entryInfo.hasBankAccount ? r.data.entryInfo.hasBankAccount.toString() : '',
            branchBank: r.data.entryInfo.branchBank,
            bankAccount: r.data.entryInfo.bankAccount,
            custodyAccount: r.data.entryInfo.custodyAccount,
            username: r.data.entryInfo.username,
            needParking: r.data.entryInfo.needParking.toString(),
            carType: r.data.entryInfo.carType,
            licensePlateNumber: r.data.entryInfo.licensePlateNumber,
            needPensionProvisiong: r.data.entryInfo.needPensionProvisiong.toString(),
            pensionProvisionProportion: r.data.entryInfo.pensionProvisionProportion,
            hasHealthReport: r.data.entryInfo.hasHealthReport?.toString(),
            emergencyContact: r.data.entryInfo.emergencyContact,
            emergencyTelephone: r.data.entryInfo.emergencyTelephone
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  setPersonnel (data, page, isTemporary = false) {
    this.handleSubmit(data, page, isTemporary)
    this.setState({ ...data })
  }

  handleSubmit (data, page, isTemporary = false) {
    put('/1/personnel/entry1', {
      page: page,
      email: this.state.email,
      fillingToken: this.state.fillingToken,
      isTemporary: isTemporary,
      ...data
    }).then(
      r => {
        this.setState({
          loading: false,
          hasError: {},
          help: {},
          error: ''
        })
        if (r.success && !isTemporary) {
          this.setState({
            page: page + 1
          })
          window.scrollTo(0, 0)
        } else if (r.success !== true) {
          const state = {
            hasError: {},
            help: {},
            error: ''
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }
          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
          window.scrollTo(0, 250)
        }
      }
    )
  }

  render () {
    let alerts = []

    if (this.state.success) {
      window.location.href = `${this.state.callback}?token=${this.state.appToken}`
    } else if (this.state.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <div>
        <Helmet>
          <title>人事資料表</title>
        </Helmet>

        <Navbar expand='md' className={`fixed-top ${this.state.page < 1 || this.state.page === 8 ? 'personnel-nav-1' : ''}${this.state.page > 0 && this.state.page < 8 ? 'personnel-nav-2' : ''}`}>
          <Container fluid>
            <a href='/' className='navbar-brand'>
              <img className='navbar-logo' src='/media/logo.svg' alt='' />
            </a>
          </Container>
        </Navbar>

        {this.state.page === 0 &&
          <div className='container-fluid personnel-1'>
            <div className='row justify-content-md-center'>
              <div className='col-sm-6 personnel-form'>
                <form onSubmit={this.handleGetData.bind(this)}>
                  <h3>歡迎您加入信驊大家庭</h3>
                  <p>
                    請完成聘僱確認書、新進同仁基本資料建立申請表。謝謝您的配合！
                  </p>
                  {alerts}
                  <TextControl
                    ref={(c) => (this.input.email = c)}
                    name='email'
                    placeholder='email'
                    hasError={this.state.hasError.email}
                    help={this.state.help.email}
                    disabled={this.state.loading}
                  />
                  <TextControl
                    ref={(c) => (this.input.fillingToken = c)}
                    name='fillingToken'
                    placeholder='填表秘鑰'
                    type='password'
                    hasError={this.state.hasError.fillingToken}
                    help={this.state.help.fillingToken}
                    disabled={this.state.loading}
                  />
                  <ControlGroup hideLabel hideHelp>
                    <Button
                      type='submit'
                      inputClasses={{ 'btn-personnel': true }}
                      disabled={this.state.loading}
                    >
                      開始填寫
                      <Spinner space='left' show={this.state.loading} />
                    </Button>
                  </ControlGroup>
                </form>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {(this.state.page === 1 || this.state.page === 2) &&
          <div className='personnel-hero'>
            <div className='main-img d-none d-lg-block' />
            <div className='main-img d-lg-none' />
            <div className='content'>
              {this.state.page === 1 &&
                <h1>聘僱確認書</h1>}
              {this.state.page === 2 &&
                <h1>新進同仁基本資料建立申請表</h1>}
            </div>
          </div>}

        {this.state.page === 1 &&
          <EmploymentConfirmation
            name={this.state.name}
            idNumber={this.state.idNumber}
            residenceAddress={this.state.residenceAddress}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 2 &&
          <EntryInfo
            chName={this.state.chName}
            enName={this.state.enName}
            workPlace={this.state.workPlace}
            hasBankAccount={this.state.hasBankAccount}
            branchBank={this.state.branchBank}
            bankAccount={this.state.bankAccount}
            custodyAccount={this.state.custodyAccount}
            username={this.state.username}
            needParking={this.state.needParking}
            carType={this.state.carType}
            licensePlateNumber={this.state.licensePlateNumber}
            needPensionProvisiong={this.state.needPensionProvisiong}
            pensionProvisionProportion={this.state.pensionProvisionProportion}
            hasHealthReport={this.state.hasHealthReport}
            emergencyContact={this.state.emergencyContact}
            emergencyTelephone={this.state.emergencyTelephone}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 3 &&
          <div className='container-fluid personnel-2'>
            <div className='row justify-content-md-center'>
              <div className='col-lg-6 col-sm-8 col-11 personnel-form'>
                <h3>
                  感謝您，填寫完成！
                </h3>
                <p>
                  期待與您一同共創美好未來，信驊科技誠摯歡迎您的加入。
                </p>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {this.state.page > 0 && this.state.page < 2 &&
          <div className='footer personnel-footer'>
            <div className='container'>
              <span className='copyright float-right'>© {year} ASPEED Technology Inc. All rights reserved.</span>

              <div className='clearfix' />
            </div>
          </div>}
      </div>
    )
  }
}

export default EntryFlow1Page
