import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'
import DateTimeControl from '../../components/datetime-control'

class EducationPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      highSchool: this.props.highSchool, // 高中名
      highSchoolDepartment: this.props.highSchoolDepartment, // 高中科系
      highSchoolPart: this.props.highSchoolPart, // 高中日夜間部
      highSchoolStatus: this.props.highSchoolStatus, // 高中就學狀態
      highSchoolStartYear: this.props.highSchoolStartYear, // 高中起年
      highSchoolStartMonth: this.props.highSchoolStartMonth, // 高中起月
      highSchoolEndYear: this.props.highSchoolEndYear, // 高中訖年
      highSchoolEndMonth: this.props.highSchoolEndMonth, // 高中訖月
      highSchoolLocation: this.props.highSchoolLocation, // 高中地點
      juniorCollege: this.props.juniorCollege, // 專科名
      juniorCollegeDepartment: this.props.juniorCollegeDepartment, // 專科科系
      juniorCollegeType: this.props.juniorCollegeType, // 專科屬性 二專、五專、三專
      juniorCollegePart: this.props.juniorCollegePart, // 專科日夜間部
      juniorCollegeStatus: this.props.juniorCollegeStatus, // 專科就學狀態
      juniorCollegeStartYear: this.props.juniorCollegeStartYear, // 專科起年
      juniorCollegeStartMonth: this.props.juniorCollegeStartMonth, // 專科起月
      juniorCollegeEndYear: this.props.juniorCollegeEndYear, // 專科訖年
      juniorCollegeEndMonth: this.props.juniorCollegeEndMonth, // 專科訖月
      juniorCollegeLocation: this.props.juniorCollegeLocation, // 專科地點
      university: this.props.university, // 大學名
      universityDepartment: this.props.universityDepartment, // 大學科系
      universityType: this.props.universityType, // 大學屬性 大學部、二技部
      universityPart: this.props.universityPart, // 大學日夜間部
      universityStatus: this.props.universityStatus, // 大學就學狀態
      universityStartYear: this.props.universityStartYear, // 大學起年
      universityStartMonth: this.props.universityStartMonth, // 大學起月
      universityEndYear: this.props.universityEndYear, // 大學訖年
      universityEndMonth: this.props.universityEndMonth, // 大學訖月
      universityLocation: this.props.universityLocation, // 大學地點
      graduateSchool: this.props.graduateSchool, // 研究所名
      graduateSchoolDepartment: this.props.graduateSchoolDepartment, // 研究所科系
      graduateSchoolPart: this.props.graduateSchoolPart, // 研究所日夜間部
      graduateSchoolStatus: this.props.graduateSchoolStatus, // 研究所就學狀態
      graduateSchoolStartYear: this.props.graduateSchoolStartYear, // 研究所起年
      graduateSchoolStartMonth: this.props.graduateSchoolStartMonth, // 研究所起月
      graduateSchoolEndYear: this.props.graduateSchoolEndYear, // 研究所訖年
      graduateSchoolEndMonth: this.props.graduateSchoolEndMonth, // 研究所訖月
      graduateSchoolLocation: this.props.graduateSchoolLocation, // 研究所地點
      otherSchool: this.props.otherSchool, // 其他名
      otherSchoolDepartment: this.props.otherSchoolDepartment, // 其他科系
      otherSchoolPart: this.props.otherSchoolPart, // 其他日夜間部
      otherSchoolStatus: this.props.otherSchoolStatus, // 其他就學狀態
      otherSchoolStartYear: this.props.otherSchoolStartYear, // 其他起年
      otherSchoolStartMonth: this.props.otherSchoolStartMonth, // 其他起月
      otherSchoolEndYear: this.props.otherSchoolEndYear, // 其他訖年
      otherSchoolEndMonth: this.props.otherSchoolEndMonth, // 其他訖月
      otherSchoolLocation: this.props.otherSchoolLocation, // 其他地點
      masterThesisTitle: this.props.masterThesisTitle, // 碩士論文篇名
      masterThesisPublicationDate: this.props.masterThesisPublicationDate ? new Date(this.props.masterThesisPublicationDate) : '', // 碩士論文發表日期
      masterAdvisor: this.props.masterAdvisor, // 碩士指導教授
      masterThesisPublication: this.props.masterThesisPublication, // 碩士論文發表處
      phdTitle: this.props.phdTitle, // 博士論文篇名
      phdPublicationDate: this.props.phdPublicationDate ? new Date(this.props.phdPublicationDate) : '', // 博士論文發表日期
      doctoralSupervisor: this.props.doctoralSupervisor, // 博士指導教授
      phdThesisPublication: this.props.phdThesisPublication // 博士論文發表處
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 2, isTemporary) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      highSchool: this.state.highSchool,
      highSchoolDepartment: this.state.highSchoolDepartment,
      highSchoolPart: this.state.highSchoolPart,
      highSchoolStatus: this.state.highSchoolStatus,
      highSchoolStartYear: this.state.highSchoolStartYear,
      highSchoolStartMonth: this.state.highSchoolStartMonth,
      highSchoolEndYear: this.state.highSchoolEndYear,
      highSchoolEndMonth: this.state.highSchoolEndMonth,
      highSchoolLocation: this.state.highSchoolLocation,
      juniorCollege: this.state.juniorCollege,
      juniorCollegeDepartment: this.state.juniorCollegeDepartment,
      juniorCollegeType: this.state.juniorCollegeType,
      juniorCollegeStatus: this.state.juniorCollegeStatus,
      juniorCollegePart: this.state.juniorCollegePart,
      juniorCollegeStartYear: this.state.juniorCollegeStartYear,
      juniorCollegeStartMonth: this.state.juniorCollegeStartMonth,
      juniorCollegeEndYear: this.state.juniorCollegeEndYear,
      juniorCollegeEndMonth: this.state.juniorCollegeEndMonth,
      juniorCollegeLocation: this.state.juniorCollegeLocation,
      university: this.state.university,
      universityDepartment: this.state.universityDepartment,
      universityType: this.state.universityType,
      universityPart: this.state.universityPart,
      universityStatus: this.state.universityStatus,
      universityStartYear: this.state.universityStartYear,
      universityStartMonth: this.state.universityStartMonth,
      universityEndYear: this.state.universityEndYear,
      universityEndMonth: this.state.universityEndMonth,
      universityLocation: this.state.universityLocation,
      graduateSchool: this.state.graduateSchool,
      graduateSchoolDepartment: this.state.graduateSchoolDepartment,
      graduateSchoolPart: this.state.graduateSchoolPart,
      graduateSchoolStatus: this.state.graduateSchoolStatus,
      graduateSchoolStartYear: this.state.graduateSchoolStartYear,
      graduateSchoolStartMonth: this.state.graduateSchoolStartMonth,
      graduateSchoolEndYear: this.state.graduateSchoolEndYear,
      graduateSchoolEndMonth: this.state.graduateSchoolEndMonth,
      graduateSchoolLocation: this.state.graduateSchoolLocation,
      otherSchool: this.state.otherSchool,
      otherSchoolDepartment: this.state.otherSchoolDepartment,
      otherSchoolPart: this.state.otherSchoolPart,
      otherSchoolStatus: this.state.otherSchoolStatus,
      otherSchoolStartYear: this.state.otherSchoolStartYear,
      otherSchoolStartMonth: this.state.otherSchoolStartMonth,
      otherSchoolEndYear: this.state.otherSchoolEndYear,
      otherSchoolEndMonth: this.state.otherSchoolEndMonth,
      otherSchoolLocation: this.state.otherSchoolLocation,
      masterThesisTitle: this.state.masterThesisTitle,
      masterThesisPublicationDate: this.state.masterThesisPublicationDate ? new Date(this.state.masterThesisPublicationDate) : '',
      masterAdvisor: this.state.masterAdvisor,
      masterThesisPublication: this.state.masterThesisPublication,
      phdTitle: this.state.phdTitle,
      phdPublicationDate: this.state.phdPublicationDate ? new Date(this.state.phdPublicationDate) : '',
      doctoralSupervisor: this.state.doctoralSupervisor,
      phdThesisPublication: this.state.phdThesisPublication
    }, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>2 / 7</p>
                <Progress value='26' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>高中資訊</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.highSchool = c)}
                    name='highSchool'
                    label='名稱'
                    value={this.state.highSchool}
                    onChange={(e) => (this.setState({ highSchool: e.target.value }))}
                    hasError={this.props.hasError.highSchool}
                    help={this.props.help.highSchool}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.highSchoolDepartment = c)}
                    name='highSchoolDepartment'
                    label='科系'
                    value={this.state.highSchoolDepartment}
                    onChange={(e) => (this.setState({ highSchoolDepartment: e.target.value }))}
                    hasError={this.props.hasError.highSchoolDepartment}
                    help={this.props.help.highSchoolDepartment}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.highSchoolLocation = c)}
                    name='highSchoolLocation'
                    label='地點'
                    value={this.state.highSchoolLocation}
                    onChange={(e) => (this.setState({ highSchoolLocation: e.target.value }))}
                    hasError={this.props.hasError.highSchoolLocation}
                    help={this.props.help.highSchoolLocation}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.highSchoolPart = c)}
                    name='highSchoolPart'
                    label='日夜間部'
                    value={this.state.highSchoolPart}
                    onChange={(e) => (this.setState({ highSchoolPart: e.target.value }))}
                    hasError={this.props.hasError.highSchoolPart}
                    help={this.props.help.highSchoolPart}
                    disabled={this.props.loading}
                  >
                    <option value='日'>
                      日間部
                    </option>
                    <option value='夜'>
                      夜間部
                    </option>
                    <option value='其他'>
                      其他
                    </option>
                  </SelectControl>
                </Col>

                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.highSchoolStatus = c)}
                    name='highSchoolStatus'
                    label='就學狀態'
                    value={this.state.highSchoolStatus}
                    onChange={(e) => (this.setState({ highSchoolStatus: e.target.value }))}
                    hasError={this.props.hasError.highSchoolStatus}
                    help={this.props.help.highSchoolStatus}
                    disabled={this.props.loading}
                  >
                    <option value='畢業'>
                      畢業
                    </option>
                    <option value='肄業'>
                      肄業
                    </option>
                    <option value='就學中'>
                      就學中
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.highSchoolStartYear = c)}
                    name='highSchoolStartYear'
                    label='起年'
                    value={this.state.highSchoolStartYear}
                    onChange={(e) => (this.setState({ highSchoolStartYear: e.target.value }))}
                    hasError={this.props.hasError.highSchoolStartYear}
                    help={this.props.help.highSchoolStartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.highSchoolStartMonth = c)}
                    name='highSchoolStartMonth'
                    label='起月'
                    value={this.state.highSchoolStartMonth}
                    onChange={(e) => (this.setState({ highSchoolStartMonth: e.target.value }))}
                    hasError={this.props.hasError.highSchoolStartMonth}
                    help={this.props.help.highSchoolStartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.highSchoolEndYear = c)}
                    name='highSchoolEndYear'
                    label='訖年'
                    value={this.state.highSchoolEndYear}
                    onChange={(e) => (this.setState({ highSchoolEndYear: e.target.value }))}
                    hasError={this.props.hasError.highSchoolEndYear}
                    help={this.props.help.highSchoolEndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.highSchoolEndMonth = c)}
                    name='highSchoolEndMonth'
                    label='訖月'
                    value={this.state.highSchoolEndMonth}
                    onChange={(e) => (this.setState({ highSchoolEndMonth: e.target.value }))}
                    hasError={this.props.hasError.highSchoolEndMonth}
                    help={this.props.help.highSchoolEndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>專科資訊</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.juniorCollege = c)}
                    name='juniorCollege'
                    label='名稱'
                    value={this.state.juniorCollege}
                    onChange={(e) => (this.setState({ juniorCollege: e.target.value }))}
                    hasError={this.props.hasError.juniorCollege}
                    help={this.props.help.juniorCollege}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.juniorCollegeDepartment = c)}
                    name='juniorCollegeDepartment'
                    label='科系'
                    value={this.state.juniorCollegeDepartment}
                    onChange={(e) => (this.setState({ juniorCollegeDepartment: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegeDepartment}
                    help={this.props.help.juniorCollegeDepartment}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.juniorCollegeLocation = c)}
                    name='juniorCollegeLocation'
                    label='地點'
                    value={this.state.juniorCollegeLocation}
                    onChange={(e) => (this.setState({ juniorCollegeLocation: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegeLocation}
                    help={this.props.help.juniorCollegeLocation}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.juniorCollegeType = c)}
                    name='juniorCollegeType'
                    label='屬性'
                    value={this.state.juniorCollegeType}
                    onChange={(e) => (this.setState({ juniorCollegeType: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegeType}
                    help={this.props.help.juniorCollegeType}
                    disabled={this.props.loading}
                  >
                    <option value='二專'>
                      二專
                    </option>
                    <option value='五專'>
                      五專
                    </option>
                    <option value='三專'>
                      三專
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.juniorCollegePart = c)}
                    name='juniorCollegePart'
                    label='日夜間部'
                    value={this.state.juniorCollegePart}
                    onChange={(e) => (this.setState({ juniorCollegePart: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegePart}
                    help={this.props.help.juniorCollegePart}
                    disabled={this.props.loading}
                  >
                    <option value='日'>
                      日間部
                    </option>
                    <option value='夜'>
                      夜間部
                    </option>
                    <option value='其他'>
                      其他
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.juniorCollegeStatus = c)}
                    name='juniorCollegeStatus'
                    label='就學狀態'
                    value={this.state.juniorCollegeStatus}
                    onChange={(e) => (this.setState({ juniorCollegeStatus: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegeStatus}
                    help={this.props.help.juniorCollegeStatus}
                    disabled={this.props.loading}
                  >
                    <option value='畢業'>
                      畢業
                    </option>
                    <option value='肄業'>
                      肄業
                    </option>
                    <option value='就學中'>
                      就學中
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.juniorCollegeStartYear = c)}
                    name='juniorCollegeStartYear'
                    label='起年'
                    value={this.state.juniorCollegeStartYear}
                    onChange={(e) => (this.setState({ juniorCollegeStartYear: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegeStartYear}
                    help={this.props.help.juniorCollegeStartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.juniorCollegeStartMonth = c)}
                    name='juniorCollegeStartMonth'
                    label='起月'
                    value={this.state.juniorCollegeStartMonth}
                    onChange={(e) => (this.setState({ juniorCollegeStartMonth: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegeStartMonth}
                    help={this.props.help.juniorCollegeStartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.juniorCollegeEndYear = c)}
                    name='juniorCollegeEndYear'
                    label='訖年'
                    value={this.state.juniorCollegeEndYear}
                    onChange={(e) => (this.setState({ juniorCollegeEndYear: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegeEndYear}
                    help={this.props.help.juniorCollegeEndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.juniorCollegeEndMonth = c)}
                    name='juniorCollegeEndMonth'
                    label='訖月'
                    value={this.state.juniorCollegeEndMonth}
                    onChange={(e) => (this.setState({ juniorCollegeEndMonth: e.target.value }))}
                    hasError={this.props.hasError.juniorCollegeEndMonth}
                    help={this.props.help.juniorCollegeEndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>大學資訊</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.university = c)}
                    name='university'
                    label='名稱'
                    value={this.state.university}
                    onChange={(e) => (this.setState({ university: e.target.value }))}
                    hasError={this.props.hasError.university}
                    help={this.props.help.university}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.universityDepartment = c)}
                    name='universityDepartment'
                    label='科系'
                    value={this.state.universityDepartment}
                    onChange={(e) => (this.setState({ universityDepartment: e.target.value }))}
                    hasError={this.props.hasError.universityDepartment}
                    help={this.props.help.universityDepartment}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.universityLocation = c)}
                    name='universityLocation'
                    label='地點'
                    value={this.state.universityLocation}
                    onChange={(e) => (this.setState({ universityLocation: e.target.value }))}
                    hasError={this.props.hasError.universityLocation}
                    help={this.props.help.universityLocation}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.universityType = c)}
                    name='universityType'
                    label='屬性'
                    value={this.state.universityType}
                    onChange={(e) => (this.setState({ universityType: e.target.value }))}
                    hasError={this.props.hasError.universityType}
                    help={this.props.help.universityType}
                    disabled={this.props.loading}
                  >
                    <option value='大學部'>
                      大學部
                    </option>
                    <option value='二技部'>
                      二技部
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.universityPart = c)}
                    name='universityPart'
                    label='日夜間部'
                    value={this.state.universityPart}
                    onChange={(e) => (this.setState({ universityPart: e.target.value }))}
                    hasError={this.props.hasError.universityPart}
                    help={this.props.help.universityPart}
                    disabled={this.props.loading}
                  >
                    <option value='日'>
                      日間部
                    </option>
                    <option value='夜'>
                      夜間部
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.universityStatus = c)}
                    name='universityStatus'
                    label='就學狀態'
                    value={this.state.universityStatus}
                    onChange={(e) => (this.setState({ universityStatus: e.target.value }))}
                    hasError={this.props.hasError.universityStatus}
                    help={this.props.help.universityStatus}
                    disabled={this.props.loading}
                  >
                    <option value='畢業'>
                      畢業
                    </option>
                    <option value='肄業'>
                      肄業
                    </option>
                    <option value='就學中'>
                      就學中
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.universityStartYear = c)}
                    name='universityStartYear'
                    label='起年'
                    value={this.state.universityStartYear}
                    onChange={(e) => (this.setState({ universityStartYear: e.target.value }))}
                    hasError={this.props.hasError.universityStartYear}
                    help={this.props.help.universityStartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.universityStartMonth = c)}
                    name='universityStartMonth'
                    label='起月'
                    value={this.state.universityStartMonth}
                    onChange={(e) => (this.setState({ universityStartMonth: e.target.value }))}
                    hasError={this.props.hasError.universityStartMonth}
                    help={this.props.help.universityStartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.universityEndYear = c)}
                    name='universityEndYear'
                    label='訖年'
                    value={this.state.universityEndYear}
                    onChange={(e) => (this.setState({ universityEndYear: e.target.value }))}
                    hasError={this.props.hasError.universityEndYear}
                    help={this.props.help.universityEndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.universityEndMonth = c)}
                    name='universityEndMonth'
                    label='訖月'
                    value={this.state.universityEndMonth}
                    onChange={(e) => (this.setState({ universityEndMonth: e.target.value }))}
                    hasError={this.props.hasError.universityEndMonth}
                    help={this.props.help.universityEndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>研究所資訊</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchool = c)}
                    name='graduateSchool'
                    label='名稱'
                    value={this.state.graduateSchool}
                    onChange={(e) => (this.setState({ graduateSchool: e.target.value }))}
                    hasError={this.props.hasError.graduateSchool}
                    help={this.props.help.graduateSchool}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchoolDepartment = c)}
                    name='graduateSchoolDepartment'
                    label='科系'
                    value={this.state.graduateSchoolDepartment}
                    onChange={(e) => (this.setState({ graduateSchoolDepartment: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolDepartment}
                    help={this.props.help.graduateSchoolDepartment}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchoolLocation = c)}
                    name='graduateSchoolLocation'
                    label='地點'
                    value={this.state.graduateSchoolLocation}
                    onChange={(e) => (this.setState({ graduateSchoolLocation: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolLocation}
                    help={this.props.help.graduateSchoolLocation}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.graduateSchoolPart = c)}
                    name='graduateSchoolPart'
                    label='一般生或在職專班'
                    value={this.state.graduateSchoolPart}
                    onChange={(e) => (this.setState({ graduateSchoolPart: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolPart}
                    help={this.props.help.graduateSchoolPart}
                    disabled={this.props.loading}
                  >
                    <option value='一般'>
                      一般生
                    </option>
                    <option value='在職'>
                      在職專班
                    </option>
                  </SelectControl>
                </Col>

                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.graduateSchoolStatus = c)}
                    name='graduateSchoolStatus'
                    label='就學狀態'
                    value={this.state.graduateSchoolStatus}
                    onChange={(e) => (this.setState({ graduateSchoolStatus: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolStatus}
                    help={this.props.help.graduateSchoolStatus}
                    disabled={this.props.loading}
                  >
                    <option value='畢業'>
                      畢業
                    </option>
                    <option value='肄業'>
                      肄業
                    </option>
                    <option value='就學中'>
                      就學中
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchoolStartYear = c)}
                    name='graduateSchoolStartYear'
                    label='起年'
                    value={this.state.graduateSchoolStartYear}
                    onChange={(e) => (this.setState({ graduateSchoolStartYear: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolStartYear}
                    help={this.props.help.graduateSchoolStartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.graduateSchoolStartMonth = c)}
                    name='graduateSchoolStartMonth'
                    label='起月'
                    value={this.state.graduateSchoolStartMonth}
                    onChange={(e) => (this.setState({ graduateSchoolStartMonth: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolStartMonth}
                    help={this.props.help.graduateSchoolStartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchoolEndYear = c)}
                    name='graduateSchoolEndYear'
                    label='訖年'
                    value={this.state.graduateSchoolEndYear}
                    onChange={(e) => (this.setState({ graduateSchoolEndYear: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolEndYear}
                    help={this.props.help.graduateSchoolEndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.graduateSchoolEndMonth = c)}
                    name='graduateSchoolEndMonth'
                    label='訖月'
                    value={this.state.graduateSchoolEndMonth}
                    onChange={(e) => (this.setState({ graduateSchoolEndMonth: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolEndMonth}
                    help={this.props.help.graduateSchoolEndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>其他學歷資訊</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.otherSchool = c)}
                    name='otherSchool'
                    label='名稱'
                    value={this.state.otherSchool}
                    onChange={(e) => (this.setState({ otherSchool: e.target.value }))}
                    hasError={this.props.hasError.otherSchool}
                    help={this.props.help.otherSchool}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.otherSchoolDepartment = c)}
                    name='otherSchoolDepartment'
                    label='科系'
                    value={this.state.otherSchoolDepartment}
                    onChange={(e) => (this.setState({ otherSchoolDepartment: e.target.value }))}
                    hasError={this.props.hasError.otherSchoolDepartment}
                    help={this.props.help.otherSchoolDepartment}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.otherSchoolLocation = c)}
                    name='otherSchoolLocation'
                    label='地點'
                    value={this.state.otherSchoolLocation}
                    onChange={(e) => (this.setState({ otherSchoolLocation: e.target.value }))}
                    hasError={this.props.hasError.otherSchoolLocation}
                    help={this.props.help.otherSchoolLocation}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.otherSchoolPart = c)}
                    name='otherSchoolPart'
                    label='日夜間部'
                    value={this.state.otherSchoolPart}
                    onChange={(e) => (this.setState({ otherSchoolPart: e.target.value }))}
                    hasError={this.props.hasError.otherSchoolPart}
                    help={this.props.help.otherSchoolPart}
                    disabled={this.props.loading}
                  >
                    <option value='日'>
                      日間部
                    </option>
                    <option value='夜'>
                      夜間部
                    </option>
                  </SelectControl>
                </Col>

                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.otherSchoolStatus = c)}
                    name='otherSchoolStatus'
                    label='就學狀態'
                    value={this.state.otherSchoolStatus}
                    onChange={(e) => (this.setState({ otherSchoolStatus: e.target.value }))}
                    hasError={this.props.hasError.otherSchoolStatus}
                    help={this.props.help.otherSchoolStatus}
                    disabled={this.props.loading}
                  >
                    <option value='畢業'>
                      畢業
                    </option>
                    <option value='肄業'>
                      肄業
                    </option>
                    <option value='就學中'>
                      就學中
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.otherSchoolStartYear = c)}
                    name='otherSchoolStartYear'
                    label='起年'
                    value={this.state.otherSchoolStartYear}
                    onChange={(e) => (this.setState({ otherSchoolStartYear: e.target.value }))}
                    hasError={this.props.hasError.otherSchoolStartYear}
                    help={this.props.help.otherSchoolStartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.otherSchoolStartMonth = c)}
                    name='otherSchoolStartMonth'
                    label='起月'
                    value={this.state.otherSchoolStartMonth}
                    onChange={(e) => (this.setState({ otherSchoolStartMonth: e.target.value }))}
                    hasError={this.props.hasError.otherSchoolStartMonth}
                    help={this.props.help.otherSchoolStartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.otherSchoolEndYear = c)}
                    name='otherSchoolEndYear'
                    label='訖年'
                    value={this.state.otherSchoolEndYear}
                    onChange={(e) => (this.setState({ otherSchoolEndYear: e.target.value }))}
                    hasError={this.props.hasError.otherSchoolEndYear}
                    help={this.props.help.otherSchoolEndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.otherSchoolEndMonth = c)}
                    name='otherSchoolEndMonth'
                    label='訖月'
                    value={this.state.otherSchoolEndMonth}
                    onChange={(e) => (this.setState({ otherSchoolEndMonth: e.target.value }))}
                    hasError={this.props.hasError.otherSchoolEndMonth}
                    help={this.props.help.otherSchoolEndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>碩士論文</h3>
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.masterThesisTitle = c)}
                    name='masterThesisTitle'
                    label='論文篇名'
                    value={this.state.masterThesisTitle}
                    onChange={(e) => (this.setState({ masterThesisTitle: e.target.value }))}
                    hasError={this.props.hasError.masterThesisTitle}
                    help={this.props.help.masterThesisTitle}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <DateTimeControl
                    ref={(c) => (this.input.masterThesisPublicationDate = c)}
                    name='masterThesisPublicationDate'
                    label='發表日期'
                    timeFormat={false}
                    value={this.state.masterThesisPublicationDate}
                    onChange={(e) => {
                      this.setState({ masterThesisPublicationDate: e.valueOf() ? new Date(e.valueOf()) : null })
                    }}
                    hasError={this.props.hasError.masterThesisPublicationDate}
                    help={this.props.help.masterThesisPublicationDate}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.masterAdvisor = c)}
                    name='masterAdvisor'
                    label='指導教授'
                    value={this.state.masterAdvisor}
                    onChange={(e) => (this.setState({ masterAdvisor: e.target.value }))}
                    hasError={this.props.hasError.masterAdvisor}
                    help={this.props.help.masterAdvisor}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.masterThesisPublication = c)}
                    name='masterThesisPublication'
                    label='論文發表處'
                    value={this.state.masterThesisPublication}
                    onChange={(e) => (this.setState({ masterThesisPublication: e.target.value }))}
                    hasError={this.props.hasError.masterThesisPublication}
                    help={this.props.help.masterThesisPublication}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>博士論文</h3>
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.phdTitle = c)}
                    name='phdTitle'
                    label='論文篇名'
                    value={this.state.phdTitle}
                    onChange={(e) => (this.setState({ phdTitle: e.target.value }))}
                    hasError={this.props.hasError.phdTitle}
                    help={this.props.help.phdTitle}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <DateTimeControl
                    ref={(c) => (this.input.phdPublicationDate = c)}
                    name='phdPublicationDate'
                    label='發表日期'
                    timeFormat={false}
                    value={this.state.phdPublicationDate}
                    onChange={(e) => {
                      this.setState({ phdPublicationDate: e.valueOf() ? new Date(e.valueOf()) : null })
                    }}
                    hasError={this.props.hasError.phdPublicationDate}
                    help={this.props.help.phdPublicationDate}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.doctoralSupervisor = c)}
                    name='doctoralSupervisor'
                    label='指導教授'
                    value={this.state.doctoralSupervisor}
                    onChange={(e) => (this.setState({ doctoralSupervisor: e.target.value }))}
                    hasError={this.props.hasError.doctoralSupervisor}
                    help={this.props.help.doctoralSupervisor}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.phdThesisPublication = c)}
                    name='phdThesisPublication'
                    label='論文發表處'
                    value={this.state.phdThesisPublication}
                    onChange={(e) => (this.setState({ phdThesisPublication: e.target.value }))}
                    hasError={this.props.hasError.phdThesisPublication}
                    help={this.props.help.phdThesisPublication}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 2, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 1, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 3, false)}
                    disabled={this.props.loading}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EducationPage
