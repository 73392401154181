import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { put, get } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'

class EditOpportunityPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      content: []
    }
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleChange (event, editor) {
    const content = editor.getData()
    this.setState({ content: content })
  }

  fetchData () {
    get(`/1/opportunity/${this.props.match.params.id}`)
      .then(r => {
        this.setState({
          id: r.data._id,
          name: r.data.name,
          content: r.data.content,
          isActive: r.data.isActive.toString()
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/opportunity/${this.props.match.params.id}`, {
      name: this.input.name.value(),
      content: this.state.content,
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/opportunities' />)
    } else if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    let formElements

    if (!this.state.success) {
      formElements =
        <fieldset>
          <TextControl
            ref={(c) => (this.input.name = c)}
            name='name'
            label='名稱'
            placeholder='職缺名稱'
            value={this.state.name}
            onChange={(e) => (this.setState({ name: e.target.value }))}
            hasError={this.state.hasError.name}
            help={this.state.help.name}
            disabled={this.state.loading}
          />

          <div className='form-group'>
            <label className='control-label'>
              內文
            </label>
            <div className='accordion'>
              <CKEditor
                config={{ ckfinder: {
                  uploadUrl: '/app/1/content/storage'
                } }}
                editor={ClassicEditor}
                data={this.state.content}
                onInit={editor => {}}
                onChange={this.handleChange.bind(this)}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
            </div>
          </div>

          <SelectControl
            ref={(c) => (this.input.isActive = c)}
            name='isActive'
            label='是否啟用'
            value={this.state.isActive}
            onChange={(e) => (this.setState({ isActive: e.target.value }))}
            hasError={this.state.hasError.isActive}
            help={this.state.help.isActive}
            disabled={this.state.loading}
          >
            <option value='true'>
              啟用
            </option>
            <option value='false'>
              關閉
            </option>
          </SelectControl>
          <ControlGroup hideLabel hideHelp>
            <Button
              type='submit'
              inputClasses={{ 'btn-success': true }}
              disabled={this.state.loading}
            >
              更新
              <Spinner space='left' show={this.state.loading} />
            </Button>
          </ControlGroup>
        </fieldset>
    }

    return (
      <Container fluid>
        <Helmet>
          <title>編輯職缺 {this.state.name}</title>
        </Helmet>
        <h1 className='page-header'>編輯職缺</h1>
        <Row>
          <Col md='6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              {formElements}
            </form>
          </Col>
          <Col md='6'>
            <Card>
              <CardBody>
                <div className='accordion'>
                  <div className='card'>
                    <div className='card-header'>
                      <h3 className='mb-0'>
                        <button className='btn btn-link' type='button' data-toggle='collapse' data-target='#manager-collapse-0' aria-expanded='true' aria-controls='manager-collapse-0'>
                          {this.state.name}
                        </button>
                      </h3>
                    </div>

                    <div id='manager-collapse-0' className='collapse show' aria-labelledby='manager-heading-0'>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EditOpportunityPage
