import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Card, CardBody } from 'reactstrap'
import UpdateButton from '../components/button'
import { get, post, put, deleteItem, postFormData } from '../utils/httpAgent'
import TextControl from '../components/text-control'
import DateTimeControl from '../components/datetime-control'
import FileControl from '../components/file-control'
import Alert from '../shared/alert'
import Spinner from '../components/spinner'
import config from '../config'

class CalendarsPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      loadingForConference: false,
      loadingForBriefTw: false,
      loadingForBriefEn: false,
      loadingForVideo: false,
      success: false,
      successForConference: false,
      successForBriefTw: false,
      successForBriefEn: false,
      successForVideo: false,
      error: undefined,
      hasError: {},
      help: {},
      events: [],
      calendarStart: null,
      calendarEnd: null,
      show: false,
      eventId: '',
      start: null,
      end: null,
      conference: '',
      briefTw: '',
      briefEn: '',
      video: '',
      isNew: true
    }
    this.handleSelectSlot = this.handleSelectSlot.bind(this)
    this.handleSelectEvent = this.handleSelectEvent.bind(this)
    this.onToggle = this.onToggle.bind(this)
    this.handleContentTwChange = this.handleContentTwChange.bind(this)
    this.handleContentEnChange = this.handleContentEnChange.bind(this)
    this.handleCreateEvent = this.handleCreateEvent.bind(this)
    this.handleUpdateEvent = this.handleUpdateEvent.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    const startdate = new Date()
    startdate.setDate(1)

    const enddate = new Date()
    enddate.setMonth(enddate.getMonth() + 1)
    enddate.setDate(10)
    this.fetchData(startdate, enddate)
  }

  fetchData (start = '', end = '') {
    if (start && end) {
      start = start.getTime()
      end = end.getTime()
      this.setState({
        calendarStart: start,
        calendarEnd: end
      })
    } else {
      start = this.state.calendarStart
      end = this.state.calendarEnd
    }
    get(`/1/admin/calendars?start=${start}&end=${end}`)
      .then(r => {
        if (r.success === true) {
          this.setState({ events: r.data })
        }
      })
  }

  handleSelectSlot ({ start, end }) {
    const startDate = new Date(start)
    const endDate = new Date(end)
    this.setState({
      eventId: '',
      nameTw: '',
      nameEn: '',
      show: true,
      start: startDate,
      end: endDate,
      conference: '',
      briefTw: '',
      briefEn: '',
      video: '',
      isNew: true,
      successForConference: false,
      successForBriefTw: false,
      successForBriefEn: false,
      successForVideo: false
    })
  }

  handleContentTwChange (event, editor) {
    const content = editor.getData()
    this.setState({ contentTw: content })
  }

  handleContentEnChange (event, editor) {
    const content = editor.getData()
    this.setState({ contentEn: content })
  }

  handleSelectEvent (event) {
    this.setState({
      eventId: event._id,
      nameTw: event.name.tw,
      nameEn: event.name.en,
      show: true,
      start: new Date(event.start),
      end: new Date(event.end),
      conference: event.conference,
      briefTw: event.brief.tw,
      briefEn: event.brief.en,
      video: event.video,
      isNew: false,
      successForConference: false,
      successForBriefTw: false,
      successForBriefEn: false,
      successForVideo: false
    })
  }

  onToggle () {
    this.setState({
      show: !this.state.show
    })
  }

  handleCreateEvent (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/calendar', {
      nameTw: this.input.nameTw.value(),
      nameEn: this.input.nameEn.value(),
      start: this.state.start.getTime(),
      end: this.state.end ? this.state.end.getTime() : '',
      conference: this.state.conference,
      briefTw: this.state.briefTw,
      briefEn: this.state.briefEn,
      video: this.state.video
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false,
            show: false
          })
          this.fetchData()
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  handleUpdateEvent (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/calendar/${this.state.eventId}`, {
      nameTw: this.input.nameTw.value(),
      nameEn: this.input.nameEn.value(),
      start: this.state.start.getTime(),
      end: this.state.end.getTime(),
      conference: this.state.conference,
      briefTw: this.state.briefTw,
      briefEn: this.state.briefEn,
      video: this.state.video
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false,
            show: false
          })
          this.fetchData()
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  handleFileSubmit (file) {
    const fileText = file.substring(0, 1).toUpperCase() + file.substring(1)
    let state = {}
    const loadingStateName = `loadingFor${fileText}`
    const successStateName = `successFor${fileText}`

    state[loadingStateName] = true
    this.setState(state)

    postFormData('/1/admin/calendar/storage', {
      start: this.state.start.getTime(),
      file: this.input[file].value(),
      type: file
    }).then(r => {
      state = {
        hasError: {},
        help: {}
      }
      state[loadingStateName] = false

      if (r.success === true) {
        state[successStateName] = true
        state[file] = r.uri
      } else {
        state[successStateName] = false
        state.hasError[file] = true
        if (r.errfor) {
          if (r.errfor.file) {
            state.help[file] = r.errfor.file
          }
        } else {
          state.help[file] = '發生未知的錯誤'
        }
      }

      this.setState(state)
    })
  }

  handleDelete () {
    if (!window.confirm('是否確定要刪除此活動？')) {
      return -1
    }

    deleteItem(`/1/admin/calendar/${this.state.eventId}`)
      .then(r => {
        if (r.success === true) {
          this.fetchData()
          this.setState({
            show: false,
            eventId: ''
          })
        }
      })
  }

  render () {
    const localizer = momentLocalizer(moment)
    const alertForFile = (
      <Alert
        type='success'
        message='上傳成功'
      />
    )

    return (
      <section className='section-home container'>
        <Helmet>
          <title>財務行事曆管理</title>
        </Helmet>

        <Modal
          isOpen={this.state.show}
          toggle={this.onToggle}
        >
          <ModalHeader toggle={this.onToggle}>
            {this.state.isNew ? '新增行程' : '編輯行程'}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <TextControl
                  ref={(c) => (this.input.nameTw = c)}
                  name='nameTw'
                  label='中文標題'
                  placeholder='中文活動標題'
                  value={this.state.nameTw}
                  onChange={(e) => (this.setState({ nameTw: e.target.value }))}
                  hasError={this.state.hasError.nameTw}
                  help={this.state.help.nameTw}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.nameEn = c)}
                  name='nameEn'
                  label='英文標題'
                  placeholder='英文活動標題'
                  value={this.state.nameEn}
                  onChange={(e) => (this.setState({ nameEn: e.target.value }))}
                  hasError={this.state.hasError.nameEn}
                  help={this.state.help.nameEn}
                  disabled={this.state.loading}
                />
              </Col>
              <Col md={6}>
                <DateTimeControl
                  ref={(c) => (this.input.start = c)}
                  name='start'
                  label='起始日期'
                  placeholder='活動起始日期'
                  timeFormat={false}
                  value={this.state.start}
                  onChange={(e) => {
                    this.setState({ start: e.valueOf() ? new Date(e.valueOf()) : null })
                  }}
                  hasError={this.state.hasError.start}
                  help={this.state.help.start}
                  disabled={this.state.loading}
                />
              </Col>
              <Col md={6}>
                <DateTimeControl
                  ref={(c) => (this.input.end = c)}
                  name='end'
                  label='結束日期'
                  placeholder='活動結束日期'
                  timeFormat={false}
                  value={this.state.end}
                  onChange={(e) => this.setState({ end: e.valueOf() ? new Date(e.valueOf()) : null })}
                  hasError={this.state.hasError.end}
                  help={this.state.help.end}
                  disabled={this.state.loading}
                />
              </Col>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {this.state.successForConference ? alertForFile : ''}
                    <FileControl
                      ref={(c) => (this.input.conference = c)}
                      name='conference'
                      label='電話會議'
                      hasError={this.state.hasError.conference}
                      help={this.state.help.conference}
                      disabled={this.state.loadingForConference || !this.state.start}
                    />
                    <UpdateButton
                      type='button'
                      inputClasses={{ 'btn-info': true }}
                      disabled={this.state.loadingForConference || !this.state.start}
                      onClick={e => this.handleFileSubmit('conference')}
                    >
                      上傳
                      <Spinner space='left' show={this.state.loadingForConference} />
                    </UpdateButton>
                    &nbsp;
                    {this.state.conference &&
                      <a href={`${config.awsUrl}${this.state.conference}`} target='_blank' rel='noopener noreferrer'>
                        當前檔案
                      </a>}
                  </CardBody>
                </Card>

                <Card className='mt-3'>
                  <CardBody>
                    {this.state.successForBriefTw ? alertForFile : ''}
                    <FileControl
                      ref={(c) => (this.input.briefTw = c)}
                      name='briefTw'
                      label='法說會簡報 (繁體中文)'
                      hasError={this.state.hasError.briefTw}
                      help={this.state.help.briefTw}
                      disabled={this.state.loadingForBriefTw || !this.state.start}
                    />
                    <UpdateButton
                      type='button'
                      inputClasses={{ 'btn-info': true }}
                      disabled={this.state.loadingForBriefTw || !this.state.start}
                      onClick={e => this.handleFileSubmit('briefTw')}
                    >
                      上傳
                      <Spinner space='left' show={this.state.loadingForBriefTw} />
                    </UpdateButton>
                    &nbsp;
                    {this.state.briefTw &&
                      <a href={`${config.awsUrl}${this.state.briefTw}`} target='_blank' rel='noopener noreferrer'>
                        當前檔案
                      </a>}
                  </CardBody>
                </Card>

                <Card className='mt-3'>
                  <CardBody>
                    {this.state.successForBriefEn ? alertForFile : ''}
                    <FileControl
                      ref={(c) => (this.input.briefEn = c)}
                      name='briefEn'
                      label='法說會簡報 (英文)'
                      hasError={this.state.hasError.briefEn}
                      help={this.state.help.briefEn}
                      disabled={this.state.loadingForBriefEn || !this.state.start}
                    />
                    <UpdateButton
                      type='button'
                      inputClasses={{ 'btn-info': true }}
                      disabled={this.state.loadingForBriefEn || !this.state.start}
                      onClick={e => this.handleFileSubmit('briefEn')}
                    >
                      上傳
                      <Spinner space='left' show={this.state.loadingForBriefEn} />
                    </UpdateButton>
                    &nbsp;
                    {this.state.briefEn &&
                      <a href={`${config.awsUrl}${this.state.briefEn}`} target='_blank' rel='noopener noreferrer'>
                        當前檔案
                      </a>}
                  </CardBody>
                </Card>

                <Card className='mt-3'>
                  <CardBody>
                    {this.state.successForVideo ? alertForFile : ''}
                    <FileControl
                      ref={(c) => (this.input.video = c)}
                      name='video'
                      label='法說會影片'
                      hasError={this.state.hasError.video}
                      help={this.state.help.video}
                      disabled={this.state.loadingForVideo || !this.state.start}
                    />
                    <UpdateButton
                      type='button'
                      inputClasses={{ 'btn-info mb-3': true }}
                      disabled={this.state.loadingForVideo || !this.state.start}
                      onClick={e => this.handleFileSubmit('video')}
                    >
                      上傳
                      <Spinner space='left' show={this.state.loadingForVideo} />
                    </UpdateButton>
                    <TextControl
                      ref={(c) => (this.input.video = c)}
                      name='video'
                      label='外部連結'
                      placeholder='外部連結或檔案上傳可擇一填寫'
                      value={this.state.video}
                      onChange={(e) => (this.setState({ video: e.target.value }))}
                      hasError={this.state.hasError.video}
                      help={this.state.help.video}
                      disabled={this.state.loading}
                    />
                    &nbsp;
                    {this.state.video &&
                      <a href={`${!this.state.video.includes('https://') ? config.awsUrl : ''}${this.state.video}`} target='_blank' rel='noopener noreferrer'>
                        當前檔案
                      </a>}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {!this.state.isNew ? <Button color='danger' className='mr-4' onClick={this.handleDelete}>刪除</Button> : ''}
            {this.state.isNew ? <Button color='primary' onClick={this.handleCreateEvent}>新增</Button> : ''}
            {!this.state.isNew ? <Button color='success' onClick={this.handleUpdateEvent}>更新</Button> : ''}
            <Button color='secondary' className='ml-1' onClick={this.onToggle}>取消</Button>
          </ModalFooter>
        </Modal>

        <div className='row'>
          <div className='col-md-12'>
            <h1 className='page-header'>財務行事曆管理</h1>
            <p>點擊日期方格或事件以進行編輯行程。</p>
            <Calendar
              selectable
              localizer={localizer}
              defaultDate={new Date()}
              defaultView='month'
              views={['month', 'agenda']}
              events={this.state.events}
              style={{ height: '600px' }}
              onRangeChange={event => this.fetchData(event.start, event.end)}
              onSelectEvent={event => this.handleSelectEvent(event)}
              onSelectSlot={this.handleSelectSlot}
              dayLayoutAlgorithm='no-overlap'
            />
          </div>
        </div>
      </section>
    )
  }
}

export default CalendarsPage
