import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ReactTable from 'react-table'
import moment from 'moment'
import { get } from '../utils/httpAgent'
import 'react-table/react-table.css'

class FileLogPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      pages: null
    }
    this.fetchLogList = this.fetchLogList.bind(this)
  }

  fetchLogList (state) {
    const page = state ? state.page + 1 : 1
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/storages/log?page=${page}&limit=${limit}&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD HH:mm')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD HH:mm')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          let state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '帳號',
        accessor: 'uid',
        Cell: row => (
          <span>{row.value ? row.value.username : ''}</span>
        )
      }, {
        Header: '檔案',
        accessor: 'name'
      }, {
        Header: '下載次數',
        accessor: 'times',
        width: 100
      }, {
        Header: '連結狀態',
        accessor: 'isActive',
        width: 100,
        Cell: row => (
          <span>
            <span style={{
              color: row.value ? '#0000FF'
                : !row.value ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease' }}>
              &#x25cf;
            </span>
            &nbsp;
            {row.value ? '未使用'
              : !row.value ? '已關閉'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '下載時間',
        accessor: 'updateTime',
        width: 200
      }, {
        Header: '申請時間',
        accessor: 'createTime',
        width: 200
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
          下載紀錄
          </title>
        </Helmet>
        <h1 className='page-header'>下載紀錄</h1>
        <div className='row'>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.fetchLogList}
              columns={columns}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[ { id: 'createTime', desc: true } ]} />
          </div>
        </div>
      </section>
    )
  }
}

export default FileLogPage
