import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { post, get } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import TextareaControl from '../components/textarea-control'
import SelectControl from '../components/select-control'

class index extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      productCategories: [],
      products: []
    }
    this.setProduct = this.setProduct.bind(this)
  }

  componentDidMount () {
    this.fetchProductCategoriesList()
    if (this.input.title) {
      this.input.title.focus()
    }
  }

  fetchProductCategoriesList () {
    this.setState({
      loading: true
    })

    get('/1/account/product_categories?page=1&limit=999')
      .then(r => {
        if (r.success === true) {
          this.setState({
            loading: false,
            productCategories: r.data
          })
        } else {
          const state = {
            loading: false,
            productCategories: []
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  setProduct (productCategoryId) {
    if (productCategoryId) {
      const productCategory = this.state.productCategories.filter(pc => pc._id === productCategoryId)[0]
      this.setState({
        productCategory: productCategoryId,
        products: productCategory.product
      })
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/account/client_issue', {
      product: this.input.product.value(),
      title: this.input.title.value(),
      content: this.input.content.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    const { t } = this.props
    let alert = []
    let formElements

    if (this.state.success) {
      alert = <Alert
        type='success'
        message={t('message_was_send')}
      />
    } else if (this.state.error) {
      alert = <Alert
        type='danger'
        message={this.state.error}
      />
    }

    let productCategory = []
    if (Array.isArray(this.state.productCategories)) {
      this.state.productCategories.forEach(pc => {
        productCategory.push(
          <option value={pc._id} key={pc._id}>
            {pc.name}
          </option>
        )
      })
    }

    let product = []
    if (Array.isArray(this.state.products)) {
      this.state.products.forEach(pd => {
        product.push(
          <option value={pd._id} key={pd._id}>
            {pd.name}
          </option>
        )
      })
    }

    if (!this.state.success) {
      formElements = <fieldset>
        <SelectControl
          ref={(c) => (this.input.productCategory = c)}
          name='productCategory'
          label={t('product_line')}
          value={this.state.productCategory}
          onChange={(e) => (this.setProduct(e.target.value))}
          hasError={this.state.hasError.productCategory}
          help={this.state.help.productCategory}
          disabled={this.state.loading}
        >
          {productCategory}
        </SelectControl>
        <SelectControl
          ref={(c) => (this.input.product = c)}
          name='product'
          label={t('product')}
          value={this.state.product}
          onChange={(e) => (this.setState({ product: e.target.value }))}
          hasError={this.state.hasError.product}
          help={this.state.help.product}
          disabled={this.state.loading}
        >
          {product}
        </SelectControl>
        <TextControl
          ref={(c) => (this.input.title = c)}
          name='title'
          label={t('title')}
          hasError={this.state.hasError.title}
          help={this.state.help.title}
          disabled={this.state.loading}
        />
        <TextareaControl
          ref={(c) => (this.input.content = c)}
          name='content'
          label={t('message')}
          rows='5'
          hasError={this.state.hasError.content}
          help={this.state.help.content}
          disabled={this.state.loading}
        />
        <ControlGroup hideLabel hideHelp>
          <Button
            type='submit'
            inputClasses={{ 'btn-primary': true }}
            disabled={this.state.loading}>
            {t('send')}
            <Spinner space='left' show={this.state.loading} />
          </Button>
        </ControlGroup>
      </fieldset>
    }

    return (
      <section className='section-contact container'>
        <Helmet>
          <title>{t('report_issues')}</title>
        </Helmet>
        <div className='row'>
          <div className='col-sm-6'>
            <section>
              <h1 className='page-header'>{t('report_issues')}</h1>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                {formElements}
              </form>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

export default index
