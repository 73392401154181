import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { get, deleteItem } from '../utils/httpAgent'

class DriversPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0
    }
    this.handleFetchDataList = this.handleFetchDataList.bind(this)
  }

  handleFetchDataList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/drivers?page=${page}&limit=${limit}&type=1&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')

            item.files.forEach(file => {
              file.date = moment(file.date).format('YYYY/MM/DD')
            })
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteData (uid, name) {
    if (!window.confirm(`是否確定要刪除 ${name} 驅動程式類別？`)) {
      return -1
    }

    deleteItem(`/1/admin/driver/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchDataList()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '編輯',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/driver/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '名稱',
        accessor: 'name'
      }, {
        Header: '關聯產品線',
        accessor: 'productCategory',
        Cell: row => (
          <span>{row.value ? row.value.name : ''}</span>
        )
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF' : row.value === false ? '#FF0000' : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用' : row.value === false ? '關閉' : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteData.bind(this, row.value, row.original.name)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    const columns2 = [
      {
        Header: '敘述',
        accessor: 'description'
      }, {
        Header: '版本',
        accessor: 'version',
        width: 80
      }, {
        Header: '真實路徑',
        accessor: 'file'
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF' : row.value === false ? '#FF0000' : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用' : row.value === false ? '關閉' : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'date',
        width: 100
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            驅動程式管理
          </title>
        </Helmet>

        <h1 className='page-header'>驅動程式管理</h1>
        <div className='row'>
          <div className='col-md-12'>
            <Link to='/app/admin/create_driver' className='btn btn-success'>
              建立驅動程式類別
            </Link>
            <br /><br />
          </div>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchDataList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    <ReactTable
                      data={row.original.files}
                      columns={columns2}
                      defaultPageSize={row.original.files.length}
                      showPagination={false}
                    />
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'createTime', desc: true }]}
            />
          </div>
        </div>

      </section>
    )
  }
}

export default DriversPage
