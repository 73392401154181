import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Navbar, Container } from 'reactstrap'
import { post } from '../utils/httpAgent'
import Button from '../components/button'
import BasicInfo from './basic-info'

const year = new Date().getFullYear()
class CreateResumePage extends Component {
  constructor () {
    super()
    this.input = {}
    this.setPersonnel = this.setPersonnel.bind(this)
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      page: -1,
      name: ''
    }
  }

  setPersonnel (data) {
    this.handleSubmit(data)
    this.setState({ ...data })
  }

  handleSubmit (data) {
    post('/1/resume', {
      ...data
    }).then(
      r => {
        this.setState({
          loading: false,
          hasError: {},
          help: {},
          error: ''
        })
        if (r.success) {
          this.setState({
            page: 2
          })
          window.scrollTo(0, 0)
          localStorage.removeItem('basicInfoForm')
        } else if (r.success !== true) {
          const state = {
            hasError: {},
            help: {},
            error: ''
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }
          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
          window.scrollTo(0, 250)
        }
      }
    )
  }

  render () {
    return (
      <div>
        <Helmet>
          <title>徵材：數位IC設計驗證工程師</title>
        </Helmet>

        <Navbar expand='md' className={`fixed-top ${[-1, 2].includes(this.state.page) ? 'personnel-nav-1' : ''}${this.state.page === 1 ? 'personnel-nav-2' : ''}`}>
          <Container fluid>
            <a href='/' className='navbar-brand'>
              <img className='navbar-logo' src='/media/logo.svg' alt='' />
            </a>
          </Container>
        </Navbar>

        {this.state.page === -1 &&
          <div className='container-fluid resume-container'>
            <div className='row justify-content-md-center'>
              <div className='col-lg-6 col-sm-8 col-11 resume-form'>
                <h3>
                  數位IC設計驗證工程師
                </h3>
                <h4>職務內容</h4>
                <ul>
                  <li>Implement and maintain simulation environment for IP and SOC level designs</li>
                  <li>To be familiar with System Verilog (including assertion) and UVM</li>
                  <li>To be familiar with computer architecture and AMBA protocol, e.g., AXI, AHB_Lite, APB</li>
                  <li>Write verification plan and documents</li>
                  <li>Build up simulation environment, patterns and scoreboard</li>
                </ul>

                <h4>薪資待遇</h4>
                <p>面議</p>

                <h4>工作地點</h4>
                <p>地址：高雄市鼓山區蓬萊路 23 號 (香蕉碼頭 輕軌駁二蓬萊站)</p>

                <h4>工作條件</h4>
                <ul>
                  <li>經驗要求：不拘</li>
                  <li>學歷要求：大學（理工背景尤佳）</li>
                  <li>語文條件：英文（聽、說、讀、寫皆具中等能力）</li>
                </ul>

                <h4>工作環境與其他資訊</h4>
                <ul>
                  <li>上班時段：日班</li>
                  <li>休假制度：週休二日</li>
                  <li>可上班日：不限</li>
                </ul>

                <h4>加入我們！</h4>
                <p>歡迎有興趣的應徵者加入我們的團隊！</p>

                <Button
                  inputClasses={{ 'btn-personnel mt-4': true }}
                  onClick={e => this.setState({ page: 1 })}
                >
                  填寫簡歷
                </Button>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {this.state.page === 1 &&
          <div className='personnel-hero'>
            <div className='main-img d-none d-lg-block' />
            <div className='main-img d-lg-none' />
            <div className='content'>
              <h1>校園徵材：數位IC設計驗證工程師</h1>
            </div>
          </div>}

        {this.state.page === 1 &&
          <BasicInfo
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 2 &&
          <div className='container-fluid personnel-2'>
            <div className='row justify-content-md-center'>
              <div className='col-lg-6 col-sm-8 col-11 personnel-form'>
                <h3>
                  感謝您，填寫完成！
                </h3>
                <p>
                  期待與您一同共創美好未來，信驊科技誠摯歡迎您的加入。
                </p>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {this.state.page === 1 &&
          <div className='footer personnel-footer'>
            <div className='container'>
              <span className='copyright float-right'>© {year} ASPEED Technology Inc. All rights reserved.</span>

              <div className='clearfix' />
            </div>
          </div>}
      </div>
    )
  }
}

export default CreateResumePage
