import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'

class RelativesPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      father: this.props.father, // 父親姓名
      fatherAge: this.props.fatherAge, // 父親年齡
      fatherVitalStatus: this.props.fatherVitalStatus, // 父親存歿
      fatherProfession: this.props.fatherProfession, // 父親職業
      fatherLocation: this.props.fatherLocation, // 父親居住地點
      mother: this.props.mother, // 母親姓名
      motherAge: this.props.motherAge, // 母親年齡
      motherVitalStatus: this.props.motherVitalStatus, // 母親存歿
      motherProfession: this.props.motherProfession, // 母親職業
      motherLocation: this.props.motherLocation, // 母親居住地點
      spouse: this.props.spouse, // 配偶姓名
      spouseAge: this.props.spouseAge, // 配偶年齡
      spouseVitalStatus: this.props.spouseVitalStatus, // 配偶存歿
      spouseProfession: this.props.spouseProfession, // 配偶職業
      spouseLocation: this.props.spouseLocation, // 配偶居住地點
      relative1Relative: this.props.relative1Relative, // 親屬 1 稱謂
      relative1: this.props.relative1, // 親屬 1 姓名
      relative1Age: this.props.relative1Age, // 親屬 1 年齡
      relative1VitalStatus: this.props.relative1VitalStatus, // 親屬 1 存歿
      relative1Profession: this.props.relative1Profession, // 親屬 1 職業
      relative1Location: this.props.relative1Location, // 親屬 1 居住地點
      relative2Relative: this.props.relative2Relative, // 親屬 2 稱謂
      relative2: this.props.relative2, // 親屬 2 姓名
      relative2Age: this.props.relative2Age, // 親屬 2 年齡
      relative2VitalStatus: this.props.relative2VitalStatus, // 親屬 2 存歿
      relative2Profession: this.props.relative2Profession, // 親屬 2 職業
      relative2Location: this.props.relative2Location, // 親屬 2 居住地點
      relative3Relative: this.props.relative3Relative, // 親屬 2 稱謂
      relative3: this.props.relative3, // 親屬 3 姓名
      relative3Age: this.props.relative3Age, // 親屬 3 年齡
      relative3VitalStatus: this.props.relative3VitalStatus, // 親屬 3 存歿
      relative3Profession: this.props.relative3Profession, // 親屬 3 職業
      relative3Location: this.props.relative3Location, // 親屬 3 居住地點
      relative4Relative: this.props.relative4Relative, // 親屬 2 稱謂
      relative4: this.props.relative4, // 親屬 4 姓名
      relative4Age: this.props.relative4Age, // 親屬 4 年齡
      relative4VitalStatus: this.props.relative4VitalStatus, // 親屬 4 存歿
      relative4Profession: this.props.relative4Profession, // 親屬 4 職業
      relative4Location: this.props.relative4Location // 親屬 4 居住地點
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 4, isTemporary) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      father: this.state.father,
      fatherAge: this.state.fatherAge,
      fatherVitalStatus: this.state.fatherVitalStatus,
      fatherProfession: this.state.fatherProfession,
      fatherLocation: this.state.fatherLocation,
      mother: this.state.mother,
      motherAge: this.state.motherAge,
      motherVitalStatus: this.state.motherVitalStatus,
      motherProfession: this.state.motherProfession,
      motherLocation: this.state.motherLocation,
      spouse: this.state.spouse,
      spouseAge: this.state.spouseAge,
      spouseVitalStatus: this.state.spouseVitalStatus,
      spouseProfession: this.state.spouseProfession,
      spouseLocation: this.state.spouseLocation,
      relative1Relative: this.state.relative1Relative,
      relative1: this.state.relative1,
      relative1Age: this.state.relative1Age,
      relative1VitalStatus: this.state.relative1VitalStatus,
      relative1Profession: this.state.relative1Profession,
      relative1Location: this.state.relative1Location,
      relative2Relative: this.state.relative2Relative,
      relative2: this.state.relative2,
      relative2Age: this.state.relative2Age,
      relative2VitalStatus: this.state.relative2VitalStatus,
      relative2Profession: this.state.relative2Profession,
      relative2Location: this.state.relative2Location,
      relative3Relative: this.state.relative3Relative,
      relative3: this.state.relative3,
      relative3Age: this.state.relative3Age,
      relative3VitalStatus: this.state.relative3VitalStatus,
      relative3Profession: this.state.relative3Profession,
      relative3Location: this.state.relative3Location,
      relative4Relative: this.state.relative4Relative,
      relative4: this.state.relative4,
      relative4Age: this.state.relative4Age,
      relative4VitalStatus: this.state.relative4VitalStatus,
      relative4Profession: this.state.relative4Profession,
      relative4Location: this.state.relative4Location
    }, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>4 / 7</p>
                <Progress value='52' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>父親狀況</h3>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.father = c)}
                    name='father'
                    label='姓名'
                    value={this.state.father}
                    onChange={(e) => (this.setState({ father: e.target.value }))}
                    hasError={this.props.hasError.father}
                    help={this.props.help.father}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.fatherAge = c)}
                    name='fatherAge'
                    label='年齡'
                    value={this.state.fatherAge}
                    onChange={(e) => (this.setState({ fatherAge: e.target.value }))}
                    hasError={this.props.hasError.fatherAge}
                    help={this.props.help.fatherAge}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <SelectControl
                    ref={(c) => (this.input.fatherVitalStatus = c)}
                    name='fatherVitalStatus'
                    label='存歿'
                    value={this.state.fatherVitalStatus}
                    onChange={(e) => (this.setState({ fatherVitalStatus: e.target.value }))}
                    hasError={this.props.hasError.fatherVitalStatus}
                    help={this.props.help.fatherVitalStatus}
                    disabled={this.props.loading}
                  >
                    <option value='存'>
                      存
                    </option>
                    <option value='歿'>
                      歿
                    </option>
                    <option value='不詳'>
                      不詳
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.fatherProfession = c)}
                    name='fatherProfession'
                    label='職業'
                    value={this.state.fatherProfession}
                    onChange={(e) => (this.setState({ fatherProfession: e.target.value }))}
                    hasError={this.props.hasError.fatherProfession}
                    help={this.props.help.fatherProfession}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.fatherLocation = c)}
                    name='fatherLocation'
                    label='居住地點'
                    value={this.state.fatherLocation}
                    onChange={(e) => (this.setState({ fatherLocation: e.target.value }))}
                    hasError={this.props.hasError.fatherLocation}
                    help={this.props.help.fatherLocation}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>母親狀況</h3>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.mother = c)}
                    name='mother'
                    label='姓名'
                    value={this.state.mother}
                    onChange={(e) => (this.setState({ mother: e.target.value }))}
                    hasError={this.props.hasError.mother}
                    help={this.props.help.mother}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.motherAge = c)}
                    name='motherAge'
                    label='年齡'
                    value={this.state.motherAge}
                    onChange={(e) => (this.setState({ motherAge: e.target.value }))}
                    hasError={this.props.hasError.motherAge}
                    help={this.props.help.motherAge}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <SelectControl
                    ref={(c) => (this.input.motherVitalStatus = c)}
                    name='motherVitalStatus'
                    label='存歿'
                    value={this.state.motherVitalStatus}
                    onChange={(e) => (this.setState({ motherVitalStatus: e.target.value }))}
                    hasError={this.props.hasError.motherVitalStatus}
                    help={this.props.help.motherVitalStatus}
                    disabled={this.props.loading}
                  >
                    <option value='存'>
                      存
                    </option>
                    <option value='歿'>
                      歿
                    </option>
                    <option value='不詳'>
                      不詳
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.motherProfession = c)}
                    name='motherProfession'
                    label='職業'
                    value={this.state.motherProfession}
                    onChange={(e) => (this.setState({ motherProfession: e.target.value }))}
                    hasError={this.props.hasError.motherProfession}
                    help={this.props.help.motherProfession}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.motherLocation = c)}
                    name='motherLocation'
                    label='居住地點'
                    value={this.state.motherLocation}
                    onChange={(e) => (this.setState({ motherLocation: e.target.value }))}
                    hasError={this.props.hasError.motherLocation}
                    help={this.props.help.motherLocation}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>配偶狀況</h3>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.spouse = c)}
                    name='spouse'
                    label='姓名'
                    value={this.state.spouse}
                    onChange={(e) => (this.setState({ spouse: e.target.value }))}
                    hasError={this.props.hasError.spouse}
                    help={this.props.help.spouse}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.spouseAge = c)}
                    name='spouseAge'
                    label='年齡'
                    value={this.state.spouseAge}
                    onChange={(e) => (this.setState({ spouseAge: e.target.value }))}
                    hasError={this.props.hasError.spouseAge}
                    help={this.props.help.spouseAge}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <SelectControl
                    ref={(c) => (this.input.spouseVitalStatus = c)}
                    name='spouseVitalStatus'
                    label='存歿'
                    value={this.state.spouseVitalStatus}
                    onChange={(e) => (this.setState({ spouseVitalStatus: e.target.value }))}
                    hasError={this.props.hasError.spouseVitalStatus}
                    help={this.props.help.spouseVitalStatus}
                    disabled={this.props.loading}
                  >
                    <option value='存'>
                      存
                    </option>
                    <option value='歿'>
                      歿
                    </option>
                    <option value='不詳'>
                      不詳
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.spouseProfession = c)}
                    name='spouseProfession'
                    label='職業'
                    value={this.state.spouseProfession}
                    onChange={(e) => (this.setState({ spouseProfession: e.target.value }))}
                    hasError={this.props.hasError.spouseProfession}
                    help={this.props.help.spouseProfession}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.spouseLocation = c)}
                    name='spouseLocation'
                    label='居住地點'
                    value={this.state.spouseLocation}
                    onChange={(e) => (this.setState({ spouseLocation: e.target.value }))}
                    hasError={this.props.hasError.spouseLocation}
                    help={this.props.help.spouseLocation}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>親屬 1 狀況</h3>
                </Col>

                <Col md='1' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative1Relative = c)}
                    name='relative1Relative'
                    label='稱謂'
                    value={this.state.relative1Relative}
                    onChange={(e) => (this.setState({ relative1Relative: e.target.value }))}
                    hasError={this.props.hasError.relative1Relative}
                    help={this.props.help.relative1Relative}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative1 = c)}
                    name='relative1'
                    label='姓名'
                    value={this.state.relative1}
                    onChange={(e) => (this.setState({ relative1: e.target.value }))}
                    hasError={this.props.hasError.relative1}
                    help={this.props.help.relative1}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative1Age = c)}
                    name='relative1Age'
                    label='年齡'
                    value={this.state.relative1Age}
                    onChange={(e) => (this.setState({ relative1Age: e.target.value }))}
                    hasError={this.props.hasError.relative1Age}
                    help={this.props.help.relative1Age}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <SelectControl
                    ref={(c) => (this.input.relative1VitalStatus = c)}
                    name='relative1VitalStatus'
                    label='存歿'
                    value={this.state.relative1VitalStatus}
                    onChange={(e) => (this.setState({ relative1VitalStatus: e.target.value }))}
                    hasError={this.props.hasError.relative1VitalStatus}
                    help={this.props.help.relative1VitalStatus}
                    disabled={this.props.loading}
                  >
                    <option value='存'>
                      存
                    </option>
                    <option value='歿'>
                      歿
                    </option>
                    <option value='不詳'>
                      不詳
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative1Profession = c)}
                    name='relative1Profession'
                    label='職業'
                    value={this.state.relative1Profession}
                    onChange={(e) => (this.setState({ relative1Profession: e.target.value }))}
                    hasError={this.props.hasError.relative1Profession}
                    help={this.props.help.relative1Profession}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.relative1Location = c)}
                    name='relative1Location'
                    label='居住地點'
                    value={this.state.relative1Location}
                    onChange={(e) => (this.setState({ relative1Location: e.target.value }))}
                    hasError={this.props.hasError.relative1Location}
                    help={this.props.help.relative1Location}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>親屬 2 狀況</h3>
                </Col>

                <Col md='1' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative2Relative = c)}
                    name='relative2Relative'
                    label='稱謂'
                    value={this.state.relative2Relative}
                    onChange={(e) => (this.setState({ relative2Relative: e.target.value }))}
                    hasError={this.props.hasError.relative2Relative}
                    help={this.props.help.relative2Relative}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative2 = c)}
                    name='relative2'
                    label='姓名'
                    value={this.state.relative2}
                    onChange={(e) => (this.setState({ relative2: e.target.value }))}
                    hasError={this.props.hasError.relative2}
                    help={this.props.help.relative2}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative2Age = c)}
                    name='relative2Age'
                    label='年齡'
                    value={this.state.relative2Age}
                    onChange={(e) => (this.setState({ relative2Age: e.target.value }))}
                    hasError={this.props.hasError.relative2Age}
                    help={this.props.help.relative2Age}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <SelectControl
                    ref={(c) => (this.input.relative2VitalStatus = c)}
                    name='relative2VitalStatus'
                    label='存歿'
                    value={this.state.relative2VitalStatus}
                    onChange={(e) => (this.setState({ relative2VitalStatus: e.target.value }))}
                    hasError={this.props.hasError.relative2VitalStatus}
                    help={this.props.help.relative2VitalStatus}
                    disabled={this.props.loading}
                  >
                    <option value='存'>
                      存
                    </option>
                    <option value='歿'>
                      歿
                    </option>
                    <option value='不詳'>
                      不詳
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative2Profession = c)}
                    name='relative2Profession'
                    label='職業'
                    value={this.state.relative2Profession}
                    onChange={(e) => (this.setState({ relative2Profession: e.target.value }))}
                    hasError={this.props.hasError.relative2Profession}
                    help={this.props.help.relative2Profession}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.relative2Location = c)}
                    name='relative2Location'
                    label='居住地點'
                    value={this.state.relative2Location}
                    onChange={(e) => (this.setState({ relative2Location: e.target.value }))}
                    hasError={this.props.hasError.relative2Location}
                    help={this.props.help.relative2Location}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>親屬 3 狀況</h3>
                </Col>

                <Col md='1' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative3Relative = c)}
                    name='relative3Relative'
                    label='稱謂'
                    value={this.state.relative3Relative}
                    onChange={(e) => (this.setState({ relative3Relative: e.target.value }))}
                    hasError={this.props.hasError.relative3Relative}
                    help={this.props.help.relative3Relative}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative3 = c)}
                    name='relative3'
                    label='姓名'
                    value={this.state.relative3}
                    onChange={(e) => (this.setState({ relative3: e.target.value }))}
                    hasError={this.props.hasError.relative3}
                    help={this.props.help.relative3}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative3Age = c)}
                    name='relative3Age'
                    label='年齡'
                    value={this.state.relative3Age}
                    onChange={(e) => (this.setState({ relative3Age: e.target.value }))}
                    hasError={this.props.hasError.relative3Age}
                    help={this.props.help.relative3Age}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <SelectControl
                    ref={(c) => (this.input.relative3VitalStatus = c)}
                    name='relative3VitalStatus'
                    label='存歿'
                    value={this.state.relative3VitalStatus}
                    onChange={(e) => (this.setState({ relative3VitalStatus: e.target.value }))}
                    hasError={this.props.hasError.relative3VitalStatus}
                    help={this.props.help.relative3VitalStatus}
                    disabled={this.props.loading}
                  >
                    <option value='存'>
                      存
                    </option>
                    <option value='歿'>
                      歿
                    </option>
                    <option value='不詳'>
                      不詳
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative3Profession = c)}
                    name='relative3Profession'
                    label='職業'
                    value={this.state.relative3Profession}
                    onChange={(e) => (this.setState({ relative3Profession: e.target.value }))}
                    hasError={this.props.hasError.relative3Profession}
                    help={this.props.help.relative3Profession}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative3Location = c)}
                    name='relative3Location'
                    label='居住地點'
                    value={this.state.relative3Location}
                    onChange={(e) => (this.setState({ relative3Location: e.target.value }))}
                    hasError={this.props.hasError.relative3Location}
                    help={this.props.help.relative3Location}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>親屬 4 狀況</h3>
                </Col>

                <Col md='1' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative4Relative = c)}
                    name='relative4Relative'
                    label='稱謂'
                    value={this.state.relative4Relative}
                    onChange={(e) => (this.setState({ relative4Relative: e.target.value }))}
                    hasError={this.props.hasError.relative4Relative}
                    help={this.props.help.relative4Relative}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative4 = c)}
                    name='relative4'
                    label='姓名'
                    value={this.state.relative4}
                    onChange={(e) => (this.setState({ relative4: e.target.value }))}
                    hasError={this.props.hasError.relative4}
                    help={this.props.help.relative4}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative4Age = c)}
                    name='relative4Age'
                    label='年齡'
                    value={this.state.relative4Age}
                    onChange={(e) => (this.setState({ relative4Age: e.target.value }))}
                    hasError={this.props.hasError.relative4Age}
                    help={this.props.help.relative4Age}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <SelectControl
                    ref={(c) => (this.input.relative4VitalStatus = c)}
                    name='relative4VitalStatus'
                    label='存歿'
                    value={this.state.relative4VitalStatus}
                    onChange={(e) => (this.setState({ relative4VitalStatus: e.target.value }))}
                    hasError={this.props.hasError.relative4VitalStatus}
                    help={this.props.help.relative4VitalStatus}
                    disabled={this.props.loading}
                  >
                    <option value='存'>
                      存
                    </option>
                    <option value='歿'>
                      歿
                    </option>
                    <option value='不詳'>
                      不詳
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3' className='pr-1'>
                  <TextControl
                    ref={(c) => (this.input.relative4Profession = c)}
                    name='relative4Profession'
                    label='職業'
                    value={this.state.relative4Profession}
                    onChange={(e) => (this.setState({ relative4Profession: e.target.value }))}
                    hasError={this.props.hasError.relative4Profession}
                    help={this.props.help.relative4Profession}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.relative4Location = c)}
                    name='relative4Location'
                    label='居住地點'
                    value={this.state.relative4Location}
                    onChange={(e) => (this.setState({ relative4Location: e.target.value }))}
                    hasError={this.props.hasError.relative4Location}
                    help={this.props.help.relative4Location}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 4, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 3, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 5, false)}
                    disabled={this.props.loading}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default RelativesPage
