import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, post } from '../utils/httpAgent'
import { Helmet } from 'react-helmet'
import DetailsForm from './details-form'
import UserForm from './user-form'
import PasswordForm from './password-form'
import CheckboxControl from '../components/checkbox-control'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import Alert from '../shared/alert'

class SettingsPage extends Component {
  constructor () {
    super()
    this.input = {
      product_categories: {},
      products: {}
    }
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      product_categories: [],
      application: {
        productCategory: [],
        product: []
      },
      promise: {
        productCategory: [],
        product: []
      },
      userRole: ''
    }
    this.handleSetApplication = this.handleSetApplication.bind(this)
  }

  componentDidMount () {
    this.fetchProductData()
    this.fetchData()
  }

  fetchProductData () {
    get('/1/account/product_categories')
      .then(r => {
        this.setState({
          product_categories: r.data
        })
      })
  }

  fetchData () {
    get('/1/account/user')
      .then(r => {
        if (r.success && r.data) {
          this.setState({
            application: r.data.application,
            promise: r.data.promise,
            userRole: r.data.userRole,
            error: !r.data.userRole ? this.props.t('wait_admin_review') : ''
          })
        }
      })
  }

  handleSetApplication (isProduct, pid) {
    if (isProduct) {
      let product = this.state.application.product
      if (!product.includes(pid)) {
        product.push(pid)
      } else {
        product = product.filter(pd => pd !== pid)
      }
      this.setState({
        application: {
          ...this.state.application,
          product: product
        }
      })
    } else {
      let productCategory = this.state.application.productCategory
      if (!productCategory.includes(pid)) {
        productCategory.push(pid)
      } else {
        productCategory = productCategory.filter(pc => pc !== pid)
      }
      this.setState({
        application: {
          ...this.state.application,
          productCategory: productCategory
        }
      })
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/account/user/promise', {
      productCategories: this.state.application.productCategory,
      products: this.state.application.product
    }).then(r => {
      if (r.success === true) {
        this.setState({
          success: true,
          error: '',
          loading: false,
          hasError: {}
        })
      } else {
        const state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  } // end handleSubmit

  render () {
    const { t } = this.props
    const productCategories = []
    let alerts = []

    if (this.state.success) {
      alerts = (
        <Alert
          type='success'
          message={t('application_submitted')}
        />
      )
    } else if (this.state.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    if (Array.isArray(this.state.product_categories) && this.state.userRole === 'client') {
      this.state.product_categories.forEach(pc => {
        const isCategoryChecked = this.state.application.productCategory.filter(p => p === pc._id).length > 0
        const isCategoryHighlight = this.state.promise.productCategory.filter(p => p === pc._id).length > 0
        productCategories.push(
          <h4 key={`h4-${pc._id}`} className='mt-4'>
            <CheckboxControl
              ref={(c) => (this.input[pc._id] = c)}
              name={pc._id}
              key={pc._id}
              label={pc.name}
              value={pc._id}
              checked={isCategoryChecked}
              highlight={isCategoryHighlight}
              onChange={(e) => (this.handleSetApplication(false, e.target.value))}
              disabled={this.state.loading}
            />
          </h4>)

        productCategories.push(<hr className='min-height' key={`hr-${pc._id}`} />)

        if (Array.isArray(pc.product)) {
          pc.product.forEach(pd => {
            if (pd.display) {
              const isProductChecked = this.state.application.product.filter(p => p === pd._id).length > 0
              const isProductHighlight = this.state.promise.product.filter(p => p === pd._id).length > 0

              if (isCategoryChecked && isProductChecked) {
                this.handleSetApplication(true, pd._id)
              }
              productCategories.push(
                <CheckboxControl
                  ref={(c) => (this.input[pd._id] = c)}
                  name={pd._id}
                  key={pd._id}
                  label={pd.name}
                  value={pd._id}
                  checked={isProductChecked}
                  highlight={isProductHighlight || isCategoryChecked}
                  onChange={(e) => (this.handleSetApplication(true, e.target.value))}
                  disabled={this.state.loading || isCategoryChecked}
                />)
            } // end if pd.display
          }) // end forEach product
        }
      }) // End Product Categories for
    }

    return (
      <section className='container'>
        <Helmet>
          <title>{t('account_setting')}</title>
        </Helmet>
        <h1 className='page-header'>{t('account_setting')}</h1>
        <div className='row'>
          <div className='col-sm-6'>
            <DetailsForm t={t} />
            <UserForm t={t} />
            <PasswordForm t={t} />
          </div>
          {this.props.userRole === 'client' &&
            <div className='col-sm-6'>
              <form onSubmit={this.handleSubmit.bind(this)}>
                <legend>{t('apply_doc')}</legend>
                <p>({t('blue_for_approved')})</p>
                {alerts}
                {productCategories}
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary mt-3': true }}
                    disabled={this.state.loading || this.state.userRole !== 'client'}
                  >
                    {t('submit')}
                    <Spinner
                      space='left'
                      show={this.state.loading}
                    />
                  </Button>
                </ControlGroup>
              </form>
            </div>}
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  userRole: state.index.userRole
})

export default connect(mapStateToProps, null)(SettingsPage)
