import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { put, get } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'

class EditProductPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      no: '',
      name: '',
      principalMail: '',
      files: [],
      fileIndex: []
    }
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.no) {
      this.input.no.focus()
    }
  }

  fetchData () {
    get(`/1/admin/product/${this.props.match.params.id}?type=0`)
      .then(r => {
        this.setState({
          no: r.data.no,
          name: r.data.name,
          principalMail: r.data.principalMail,
          display: r.data.display.toString(),
          isActive: r.data.isActive.toString()
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/product/${this.props.match.params.id}`, {
      no: this.input.no.value(),
      name: this.input.name.value(),
      principalMail: this.input.principalMail.value(),
      display: this.input.display.value(),
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []
    const files = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/products' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    if (this.state.files.length) {
      this.state.files.forEach(fi => {
        files.push(
          <div>
            {fi.name} ({fi.fileName})
          </div>
        )
      })
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>{this.state.name} 產品</title>
        </Helmet>

        <div className='row'>
          <div className='col-md-12'>
            <section>
              <h1 className='page-header'>編輯產品 {this.state.name}</h1>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                <TextControl
                  ref={(c) => (this.input.no = c)}
                  name='no'
                  label='編號'
                  value={this.state.no}
                  onChange={(e) => (this.setState({ no: e.target.value }))}
                  hasError={this.state.hasError.no}
                  help={this.state.help.no}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.name = c)}
                  name='name'
                  label='名稱'
                  value={this.state.name}
                  onChange={(e) => (this.setState({ name: e.target.value }))}
                  hasError={this.state.hasError.name}
                  help={this.state.help.name}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.principalMail = c)}
                  name='principalMail'
                  label='相關負責人 email (請用 ; 分隔)'
                  value={this.state.principalMail}
                  onChange={(e) => (this.setState({ principalMail: e.target.value }))}
                  hasError={this.state.hasError.principalMail}
                  help={this.state.help.principalMail}
                  disabled={this.state.loading}
                />
                <SelectControl
                  ref={(c) => (this.input.display = c)}
                  name='display'
                  label='是否開放客戶申請'
                  value={this.state.display}
                  onChange={(e) => (this.setState({ display: e.target.value }))}
                  hasError={this.state.hasError.display}
                  help={this.state.help.display}
                  disabled={this.state.loading}
                >
                  <option value='true'>
                    開放
                  </option>
                  <option value='false'>
                    關閉
                  </option>
                </SelectControl>
                <SelectControl
                  ref={(c) => (this.input.isActive = c)}
                  name='isActive'
                  label='是否啟用'
                  value={this.state.isActive}
                  onChange={(e) => (this.setState({ isActive: e.target.value }))}
                  hasError={this.state.hasError.isActive}
                  help={this.state.help.isActive}
                  disabled={this.state.loading}
                >
                  <option value='true'>
                    啟用
                  </option>
                  <option value='false'>
                    關閉
                  </option>
                </SelectControl>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-success': true }}
                    disabled={this.state.loading}
                  >
                    更新
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </ControlGroup>
              </form>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

export default EditProductPage
