import React, { Component } from 'react'
import ClassNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { setLanguage } from 'react-multi-lang'
import { Navbar, Collapse, Nav, NavbarText, NavbarToggler, Container, Button } from 'reactstrap'

const year = new Date().getFullYear()

class Default extends Component {
  constructor () {
    super()
    this.state = {
      navBarOpen: false
    }
  }

  compomentDidUpdate () {
    this.setState({ navBarOpen: false })
  }

  tabClass (tab) {
    return ClassNames({
      active: this.props.children.props.location.pathname === tab
    })
  }

  toggleMenu () {
    this.setState({ navBarOpen: !this.state.navBarOpen })
  }

  setLanguage (lang) {
    localStorage.setItem('lang', lang)
    setLanguage(lang)
  }

  render () {
    const { t } = this.props
    let roleElement = []

    if (this.props.authenticated === true) {
      roleElement = <NavLink to='/app/account'><i className='lnr lnr-user' /> {this.props.user}</NavLink>

      if (this.props.role === 'admin') {
        roleElement = <NavLink to='/app/admin' ><i className='lnr lnr-user' /> {this.props.user}</NavLink>
      }
    } else {
      roleElement =
        <NavLink exact to='/app/' activeClassName='active' className='nav-link'>
          {t('login')}
        </NavLink>
    }

    return (
      <div className='pt-5'>
        <Navbar color='light' light expand='md' className='fixed-top'>
          <Container>
            <a href='/' className='navbar-brand'>
              <img className='navbar-logo' src='/media/logo.svg' alt='' />
            </a>
            <NavbarToggler onClick={this.toggleMenu.bind(this)} />
            <Collapse isOpen={!this.state.navBarOpen} navbar>
              <Nav className='mr-auto' navbar />
            </Collapse>
            <NavbarText>
              {roleElement}
            </NavbarText>
          </Container>
        </Navbar>

        {this.props.children}

        <div className='footer'>
          <div className='container'>
            <ul className='links'>
              <li>
                <Button color='link' onClick={this.setLanguage.bind(this, 'zh')} className='p-0'>繁體中文</Button>
              </li>
              <li>
                <Button color='link' onClick={this.setLanguage.bind(this, 'en')} className='p-0'>English</Button>
              </li>
            </ul>
            <span className='copyright float-right'>© {year} ASPEED</span>

            <div className='clearfix' />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

export default connect(mapStateToProps, null)(Default)
