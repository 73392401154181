import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import { Spinner as StrapSpinner } from 'reactstrap'
import { post, get } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import config from '../config'

const SSOLogin = ({ t, match, user, authenticated, role }) => {
  const usernameRef = useRef(null)
  const passwordRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [help, setHelp] = useState({})
  const [name, setName] = useState('')
  const [errorCounter, setErrorCounter] = useState(0)
  const [callback, setCallback] = useState('')
  const [appToken, setAppToken] = useState('')
  const [type, setType] = useState('')
  const [grecaptcha, setGrecaptcha] = useState('')

  const handleReCaptchaTokenLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute(config.reCAPTCHA, { action: 'homepage' })
        .then(token => {
          setGrecaptcha(token)
        })
    })
  }

  useEffect(() => {
    fetchUser()
    if (localStorage.getItem('token')) {
      getAccessToken()
    }

    if (usernameRef.current) {
      usernameRef.current.focus()
    }

    return () => {
      // Clean up function
    }
  }, [])

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${config.reCAPTCHA}`
    script.addEventListener('load', handleReCaptchaTokenLoaded)
    document.body.appendChild(script)
  }, [])

  const fetchUser = () => {
    get(`/1/app/name/${match.params.id}`)
      .then(r => {
        if (r.success) {
          setName(r.data.name)
        }
      })
  }

  const getParameterByName = (name) => {
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(window.location.href)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    post('/1/sso_login', {
      username: usernameRef.current.value(),
      password: passwordRef.current.value() ? encrypt(passwordRef.current.value()) : '',
      hasEncrypt: 'true',
      grecaptcha
    }).then(
      r => {
        if (r.success === true && r.data) {
          localStorage.setItem('token', r.data.token)
          setTimeout(() => getAccessToken(), 500)
        } else {
          localStorage.removeItem('token')
          const newState = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {},
            type: r.type ? r.type : ''
          }
          for (const key in r.errfor) {
            newState.hasError[key] = true
            newState.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            newState.error = r.errors[0]
            setErrorCounter(errorCounter + 1)
          }
          setSuccess(newState.success)
          setError(newState.error)
          setLoading(newState.loading)
          setHasError(newState.hasError)
          setHelp(newState.help)
          setType(newState.type)
        }
      }
    )
  }

  const getAccessToken = () => {
    post('/1/account/access_token', {
      appId: match.params.id
    }).then(
      r => {
        if (r.success === true) {
          setSuccess(true)
          setError('')
          setLoading(false)
          setAppToken(r.data.token)
          setCallback(r.data.callback)
        } else {
          const newState = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            newState.hasError[key] = true
            newState.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            newState.error = r.errors[0]
            setErrorCounter(errorCounter + 1)
          }
          setSuccess(newState.success)
          setError(newState.error)
          setLoading(newState.loading)
          setHasError(newState.hasError)
          setHelp(newState.help)
        }
      }
    )
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  const backendMsg = getParameterByName('msg')
  let alerts = []

  if (success) {
    window.location.href = `${callback}?token=${appToken}`
  } else if (error) {
    if (errorCounter > 2 && type !== 'expired') {
      return <Redirect to='/app/login/forgot' />
    } else {
      alerts = (
        <Alert
          type='danger'
          message={error}
        />
      )
    }
  } else if (backendMsg === 'activate_user_error') {
    alerts = (
      <Alert
        type='danger'
        message={t('activate_user_error')}
      />
    )
  } else if (backendMsg === 'client_is_unreviewed') {
    alerts = (
      <Alert
        type='info'
        message={t('client_is_unreviewed')}
      />
    )
  }

  return (
    <section className='container'>
      <Helmet>
        <title>ASPEED SSO</title>
      </Helmet>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-sm-6'>
            <h3 className='page-header text-center'>ASPEED SSO {t('login')} {name}</h3>
            {localStorage.getItem('token') &&
              <p className='mt-5 text-center mb-5 pb-5'>
                <StrapSpinner color='primary' style={{ width: '5rem', height: '5rem' }} />
              </p>}
            {!localStorage.getItem('token') &&
              <form onSubmit={handleSubmit}>
                {alerts}
                <TextControl
                  ref={usernameRef}
                  name='username'
                  label={t('username')}
                  hasError={hasError.username}
                  help={help.username}
                  disabled={loading}
                />
                <TextControl
                  ref={passwordRef}
                  name='password'
                  label={t('password')}
                  type='password'
                  hasError={hasError.password}
                  help={help.password}
                  disabled={loading}
                  onKeyPress={handleKeyPress}
                />
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    disabled={loading}
                  >
                    {t('login')}
                    <Spinner space='left' show={loading} />
                  </Button>
                  <Link to='/app/login/forgot' className='btn btn-link'>{t('forgot_password')}</Link>
                </ControlGroup>
                <div
                  className='g-recaptcha'
                  data-sitekey={config.reCAPTCHA}
                  data-size='invisible'
                />
              </form>}
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

export default connect(mapStateToProps, null)(SSOLogin)
