import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { get, deleteItem } from '../utils/httpAgent'
import 'react-table/react-table.css'

class FilesPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      name: ''
    }
    this.fetchFileList = this.fetchFileList.bind(this)
  }

  fetchFileList () {
    this.setState({
      loading: true
    })

    get(`/1/admin/product/${this.props.match.params.id}`)
      .then(r => {
        if (r.success === true) {
          r.data.file.forEach(file => {
            file.updateTime = moment(file.updateTime).format('YYYY/MM/DD')
            file.createTime = moment(file.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data.file,
            name: r.data.name
          })
        } else {
          let state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteFile (sid) {
    if (!window.confirm('是否確定要刪除此檔案？')) {
      return -1
    }

    deleteItem(`/1/admin/storage/${sid}`)
      .then(r => {
        if (r.success === true) {
          this.fetchFileList()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '操作',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/product/${this.props.match.params.id}/storage/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '檔案標題',
        accessor: 'name'
      }, {
        Header: '版本號',
        accessor: 'version',
        width: 70
      }, {
        Header: '檔名',
        accessor: 'fileName'
      }, {
        Header: '實際位置',
        accessor: 'uri',
        Cell: row => (
          <span>
            {row.value ? row.value : row.original.url}
          </span>
        )
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF'
                : row.value === false ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用'
              : row.value === false ? '關閉'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteFile.bind(this, row.value)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            {this.state.name} 檔案管理
          </title>
        </Helmet>
        <h1 className='page-header'>{this.state.name} 檔案管理</h1>
        <div className='row'>
          <div className='col-md-12'>
            <Link to={`/app/admin/product/${this.props.match.params.id}/storage`} className='btn btn-success'>
              新增檔案
            </Link>
            <br /><br />
          </div>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              loading={this.state.loading}
              onFetchData={this.fetchFileList}
              columns={columns}
              showPagination={false}
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[ { id: 'createDate', desc: true } ]} />
          </div>
        </div>
      </section>
    )
  }
}

export default FilesPage
