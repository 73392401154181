import React, { Component } from 'react'
import ClassNames from 'classnames'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarText,
  NavbarToggler,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

const year = new Date().getFullYear()

class Default extends Component {
  constructor () {
    super()
    this.state = {
      navBarOpen: false
    }
  }

  compomentDidUpdate () {
    this.setState({ navBarOpen: false })
  }

  tabClass (tab) {
    return ClassNames({
      active: this.props.children.props.location.pathname === tab
    })
  }

  toggleMenu () {
    this.setState({ navBarOpen: !this.state.navBarOpen })
  }

  render () {
    return (
      <div className='pt-5'>
        <Navbar color='dark' dark expand='md' className='fixed-top'>
          <Container>
            <a href='/' className='navbar-brand'>
              <img className='navbar-logo' src='/media/logo-square.png' alt='' />
              <span className='navbar-brand-label'>ASPEED</span>
            </a>
            <NavbarToggler onClick={this.toggleMenu.bind(this)} />
            <Collapse isOpen={!this.state.navBarOpen} navbar>
              <Nav className='mr-auto' navbar>
                <NavItem>
                  <NavLink exact to='/app/admin' activeClassName='active' className='nav-link'> 首頁
                  </NavLink>
                </NavItem>
                {['root', 'root-info'].includes(this.props.adminRole) &&
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      新聞活動
                    </DropdownToggle>
                    <DropdownMenu right>
                      <Link to='/app/admin/news'>
                        <DropdownItem>
                          新聞管理
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/activities'>
                        <DropdownItem>
                          活動管理
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/monthly_revenue'>
                        <DropdownItem>
                          營收公告
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/partner_news'>
                        <DropdownItem>
                          夥伴新聞
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>}

                {['root', 'root-sales'].includes(this.props.adminRole) &&
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      產品相關
                    </DropdownToggle>
                    <DropdownMenu right>
                      <Link to='/app/admin/products'>
                        <DropdownItem>
                          產品線管理
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/drivers'>
                        <DropdownItem>
                          驅動程式管理
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/client_issues'>
                        <DropdownItem>
                          客戶問題
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/file_log'>
                        <DropdownItem>
                          下載紀錄
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>}

                {['root', 'root-info'].includes(this.props.adminRole) &&
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      投資人關係
                    </DropdownToggle>
                    <DropdownMenu right>
                      <Link to='/app/admin/financial_info'>
                        <DropdownItem>
                          財務資訊
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/shareholders'>
                        <DropdownItem>
                          股東服務
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/cgs'>
                        <DropdownItem>
                          公司治理
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/sustainability_reports'>
                        <DropdownItem>
                          永續報告書
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/calendars'>
                        <DropdownItem>
                          財務行事曆
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>}

                {['root', 'root-hr'].includes(this.props.adminRole) &&
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      人才招募
                    </DropdownToggle>
                    <DropdownMenu right>
                      {/* <Link to='/app/admin/opportunities'>
                        <DropdownItem>
                          職缺管理
                        </DropdownItem>
                      </Link> */}
                      <Link to='/app/admin/personnels'>
                        <DropdownItem>
                          履歷管理
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/resumes'>
                        <DropdownItem>
                          校園徵才
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>}

                {['root', 'root-sales'].includes(this.props.adminRole) &&
                  <NavItem>
                    <NavLink to='/app/admin/users' activeClassName='active' className='nav-link'> 用戶管理
                    </NavLink>
                  </NavItem>}

                {['root'].includes(this.props.adminRole) &&
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      平台相關
                    </DropdownToggle>
                    <DropdownMenu right>
                      <Link to='/app/admin/apps'>
                        <DropdownItem>
                          應用管理
                        </DropdownItem>
                      </Link>
                      <Link to='/app/admin/act_logs'>
                        <DropdownItem>
                          系統記錄
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>}
              </Nav>
              <NavbarText>
                <NavLink to='/app/logout'>
                  登出
                </NavLink>
              </NavbarText>
            </Collapse>
          </Container>
        </Navbar>

        {this.props.children}

        <div className='footer'>
          <div className='container'>
            <span className='copyright float-right'>©{year} 信驊科技股份有限公司 著作權所有</span>
            <ul className='links'>
              <li>
                <NavLink to='/app/'> 首頁
                </NavLink>
              </li>
              <li>
                <NavLink to='/app/account'> 客戶介面
                </NavLink>
              </li>
              <li>
                <NavLink to='/app/logout'> 登出
                </NavLink>
              </li>
            </ul>
            <div className='clearfix' />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  adminRole: state.index.adminRole
})

export default connect(mapStateToProps, null)(Default)
