import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { post } from '../utils/httpAgent'
import axios from 'axios'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import FileControl from '../components/file-control'
import CheckboxControl from '../components/checkbox-control'

class CreateStoragePage extends Component {
  constructor () {
    super()
    this.input = {
      subordinate: []
    }
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      needNotification: true,
      needIBIS: false,
      getType: ''
    }
  }

  componentDidMount () {
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    const file = this.input.file.value()
    const axiosArray = []
    let chunksInfo = {}
    if (file) {
      const eachSize = 5242880 //  5 * 1024 * 1024 ==> 5MB
      const blockCount = Math.ceil(file.size / eachSize)

      let ext = file.name.split('.')
      ext = ext[ext.length - 1]
      const hash = localStorage.getItem('token').split('.')[2]

      for (let i = 0; i < blockCount; i++) {
        const start = i * eachSize
        const end = Math.min(file.size, start + eachSize)

        const configOfReq = {
          method: 'post',
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data'
          },
          transformRequest: [function (data) {
            return data
          }]
        }

        const form = new FormData()
        form.append('file', file.slice(start, end))
        form.append('name', file.name)
        form.append('total', blockCount)
        form.append('ext', ext)
        form.append('index', i)
        form.append('size', file.size)
        form.append('hash', hash)

        chunksInfo = {
          fileName: file.name,
          total: blockCount,
          ext: ext,
          hash: hash
        }

        let uri = '/app/1/admin/storage/upload_chunks'
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
          uri = '/1/admin/storage/upload_chunks'
        }
        axiosArray.push(axios.post(uri, form, configOfReq))
      }
    }

    axios.all(axiosArray).then(() => {
      post('/1/admin/storage/merge_chunks', {
        pId: this.props.match.params.id,
        name: this.input.name.value(),
        version: this.input.version.value(),
        type: 1,
        needNotification: this.state.needNotification,
        needIBIS: this.state.needIBIS,
        url: this.input.url.value(),
        principalMail: this.input.principalMail.value(),
        ...chunksInfo
      }).then(
        r => {
          if (r.success === true) {
            this.setState({
              success: true,
              error: '',
              loading: false
            })
          } else {
            const state = {
              success: false,
              error: '',
              loading: false,
              hasError: {},
              help: {}
            }
            for (const key in r.errfor) {
              state.hasError[key] = true
              state.help[key] = r.errfor[key]
            }

            if (r.errors[0] !== undefined) {
              state.error = r.errors[0]
            }
            this.setState(state)
          }
        }
      )
    })
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to={`/app/admin/product/${this.props.match.params.id}/files`} />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>上傳檔案</title>
        </Helmet>

        <h1 className='page-header'>上傳檔案</h1>
        <p>
          檔案、檔案網址、負責人 Mail 擇一填寫即可。
        </p>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <TextControl
                ref={(c) => (this.input.name = c)}
                name='name'
                label='檔案標題'
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.version = c)}
                name='version'
                label='版本號'
                hasError={this.state.hasError.version}
                help={this.state.help.version}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.url = c)}
                name='url'
                label='檔案網址'
                hasError={this.state.hasError.url}
                help={this.state.help.url}
                onChange={(e) => (this.setState({ getType: e.target.value ? 'url' : '' }))}
                disabled={this.state.loading || (this.state.getType !== 'url' && this.state.getType !== '')}
              />
              <TextControl
                ref={(c) => (this.input.principalMail = c)}
                name='principalMail'
                label='負責人 Mail (手動寄發)'
                onChange={(e) => (this.setState({ getType: e.target.value ? 'principalMail' : '' }))}
                hasError={this.state.hasError.principalMail}
                help={this.state.help.principalMail}
                disabled={this.state.loading || (this.state.getType !== 'principalMail' && this.state.getType !== '')}
              />
              <FileControl
                ref={(c) => (this.input.file = c)}
                name='file'
                label='檔案'
                onChange={(e) => (this.setState({ getType: e.target.value ? 'file' : '' }))}
                hasError={this.state.hasError.file}
                help={this.state.help.file}
                disabled={this.state.loading || (this.state.getType !== 'file' && this.state.getType !== '')}
              />
              <CheckboxControl
                ref={(c) => (this.input.needNotification = c)}
                inputClasses={{ 'mb-4': true }}
                name='needNotification'
                label='是否寄發檔案更新通知信'
                checked={this.state.needNotification}
                onChange={(e) => (this.setState({ needNotification: !this.state.needNotification }))}
                disabled={this.state.loading}
              />
              <br />
              <CheckboxControl
                ref={(c) => (this.input.needIBIS = c)}
                inputClasses={{ 'mb-4': true }}
                name='needIBIS'
                label='是否需要 IBIS 確認書'
                checked={this.state.needIBIS}
                onChange={(e) => (this.setState({ needIBIS: !this.state.needIBIS }))}
                disabled={this.state.loading}
              />
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-success': true }}
                  disabled={this.state.loading}
                >
                  建立
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateStoragePage
