import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card, CardHeader, CardBody } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'

class ReferrerPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      applicationPipeline: this.props.applicationPipeline ? this.props.applicationPipeline.split('：')[0] : '', // 應徵管道
      introducer: this.props.applicationPipeline.includes('同仁介紹：') ? this.props.applicationPipeline.split('：')[1] : '',
      otherPipeline: this.props.applicationPipeline.includes('其他：') ? this.props.applicationPipeline.split('：')[1] : '',
      referrer1: this.props.referrer1, // 徵信人 1 姓名
      referrer1Company: this.props.referrer1Company, // 徵信人 1 公司
      referrer1Title: this.props.referrer1Title, // 徵信人 1 職稱
      referrer1Phone: this.props.referrer1Phone, // 徵信人 1 電話
      referrer2: this.props.referrer2, // 徵信人 2 姓名
      referrer2Company: this.props.referrer2Company, // 徵信人 2 公司
      referrer2Title: this.props.referrer2Title, // 徵信人 2 職稱
      referrer2Phone: this.props.referrer2Phone, // 徵信人 2 電話
      referrer3: this.props.referrer3, // 徵信人 3 姓名
      referrer3Company: this.props.referrer3Company, // 徵信人 3 公司
      referrer3Title: this.props.referrer3Title, // 徵信人 3 職稱
      referrer3Phone: this.props.referrer3Phone // 徵信人 3 電話
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 5, isTemporary) {
    event.preventDefault()
    event.stopPropagation()

    let pipelineString = ''
    if (this.state.applicationPipeline === '同仁介紹') {
      pipelineString = `：${this.state.introducer}`
    } else if (this.state.applicationPipeline === '其他') {
      pipelineString = `：${this.state.otherPipeline}`
    }
    this.props.setPersonnel({
      applicationPipeline: this.state.applicationPipeline + pipelineString,
      referrer1: this.state.referrer1,
      referrer1Company: this.state.referrer1Company,
      referrer1Title: this.state.referrer1Title,
      referrer1Phone: this.state.referrer1Phone,
      referrer2: this.state.referrer2,
      referrer2Company: this.state.referrer2Company,
      referrer2Title: this.state.referrer2Title,
      referrer2Phone: this.state.referrer2Phone,
      referrer3: this.state.referrer3,
      referrer3Company: this.state.referrer3Company,
      referrer3Title: this.state.referrer3Title,
      referrer3Phone: this.state.referrer3Phone
    }, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>5 / 7</p>
                <Progress value='64' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>應徵管道</h3>
                </Col>

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.applicationPipeline = c)}
                    name='applicationPipeline'
                    label='應徵管道'
                    value={this.state.applicationPipeline}
                    onChange={(e) => (this.setState({ applicationPipeline: e.target.value }))}
                    hasError={this.props.hasError.applicationPipeline}
                    help={this.props.help.applicationPipeline}
                    disabled={this.props.loading}
                  >
                    <option value='顧問公司'>
                      顧問公司
                    </option>
                    <option value='校園徵才'>
                      校園徵才
                    </option>
                    <option value='網路資訊'>
                      網路資訊
                    </option>
                    <option value='同仁介紹'>
                      同仁介紹
                    </option>
                    <option value='其他'>
                      其他
                    </option>
                  </SelectControl>
                </Col>

                {this.state.applicationPipeline === '同仁介紹' &&
                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.introducer = c)}
                      name='introducer'
                      label='介紹人'
                      value={this.state.introducer}
                      onChange={(e) => (this.setState({ introducer: e.target.value }))}
                      hasError={this.props.hasError.introducer}
                      help={this.props.help.introducer}
                      disabled={this.props.loading}
                    />
                  </Col>}

                {this.state.applicationPipeline === '其他' &&
                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.otherPipeline = c)}
                      name='otherPipeline'
                      label='其他'
                      value={this.state.otherPipeline}
                      onChange={(e) => (this.setState({ otherPipeline: e.target.value }))}
                      hasError={this.props.hasError.otherPipeline}
                      help={this.props.help.otherPipeline}
                      disabled={this.props.loading}
                    />
                  </Col>}
              </Row>
            </Card>

            <Card className='mt-4'>
              <CardHeader>
                請列舉能提供有關填表人之品行及能力資料之同事或朋友三人，並同意本公司必要時之徵信。
              </CardHeader>
              <CardBody>
                <Row className='justify-content-md-center'>
                  <Col md='12'>
                    <h3 className='text-center mt-4'>推薦人 1</h3>
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.referrer1 = c)}
                      name='referrer1'
                      label='姓名'
                      value={this.state.referrer1}
                      onChange={(e) => (this.setState({ referrer1: e.target.value }))}
                      hasError={this.props.hasError.referrer1}
                      help={this.props.help.referrer1}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.referrer1Phone = c)}
                      name='referrer1Phone'
                      label='電話'
                      value={this.state.referrer1Phone}
                      onChange={(e) => (this.setState({ referrer1Phone: e.target.value }))}
                      hasError={this.props.hasError.referrer1Phone}
                      help={this.props.help.referrer1Phone}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.referrer1Company = c)}
                      name='referrer1Company'
                      label='公司'
                      value={this.state.referrer1Company}
                      onChange={(e) => (this.setState({ referrer1Company: e.target.value }))}
                      hasError={this.props.hasError.referrer1Company}
                      help={this.props.help.referrer1Company}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.referrer1Title = c)}
                      name='referrer1Title'
                      label='職稱'
                      value={this.state.referrer1Title}
                      onChange={(e) => (this.setState({ referrer1Title: e.target.value }))}
                      hasError={this.props.hasError.referrer1Title}
                      help={this.props.help.referrer1Title}
                      disabled={this.props.loading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>推薦人 2</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.referrer2 = c)}
                    name='referrer2'
                    label='姓名'
                    value={this.state.referrer2}
                    onChange={(e) => (this.setState({ referrer2: e.target.value }))}
                    hasError={this.props.hasError.referrer2}
                    help={this.props.help.referrer2}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.referrer2Phone = c)}
                    name='referrer2Phone'
                    label='電話'
                    value={this.state.referrer2Phone}
                    onChange={(e) => (this.setState({ referrer2Phone: e.target.value }))}
                    hasError={this.props.hasError.referrer2Phone}
                    help={this.props.help.referrer2Phone}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.referrer2Company = c)}
                    name='referrer2Company'
                    label='公司'
                    value={this.state.referrer2Company}
                    onChange={(e) => (this.setState({ referrer2Company: e.target.value }))}
                    hasError={this.props.hasError.referrer2Company}
                    help={this.props.help.referrer2Company}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.referrer2Title = c)}
                    name='referrer2Title'
                    label='職稱'
                    value={this.state.referrer2Title}
                    onChange={(e) => (this.setState({ referrer2Title: e.target.value }))}
                    hasError={this.props.hasError.referrer2Title}
                    help={this.props.help.referrer2Title}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>推薦人 3</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.referrer3 = c)}
                    name='referrer3'
                    label='姓名'
                    value={this.state.referrer3}
                    onChange={(e) => (this.setState({ referrer3: e.target.value }))}
                    hasError={this.props.hasError.referrer3}
                    help={this.props.help.referrer3}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.referrer3Phone = c)}
                    name='referrer3Phone'
                    label='電話'
                    value={this.state.referrer3Phone}
                    onChange={(e) => (this.setState({ referrer3Phone: e.target.value }))}
                    hasError={this.props.hasError.referrer3Phone}
                    help={this.props.help.referrer3Phone}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.referrer3Company = c)}
                    name='referrer3Company'
                    label='公司'
                    value={this.state.referrer3Company}
                    onChange={(e) => (this.setState({ referrer3Company: e.target.value }))}
                    hasError={this.props.hasError.referrer3Company}
                    help={this.props.help.referrer3Company}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.referrer3Title = c)}
                    name='referrer3Title'
                    label='職稱'
                    value={this.state.referrer3Title}
                    onChange={(e) => (this.setState({ referrer3Title: e.target.value }))}
                    hasError={this.props.hasError.referrer3Title}
                    help={this.props.help.referrer3Title}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 5, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 4, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 6, false)}
                    disabled={this.props.loading}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ReferrerPage
