import React, { Component } from 'react'
import Login from './login/login'
import Forgot from './login/forgot'
import Logout from './login/logout'
import Reset from './login/reset'
import Reactivate from './login/reactivate'
import Signup from './login/signup'
import SSO from './login/sso'
import Store from './utils/reducer'
import Admin from './admin/home'
import AdminUsers from './admin/users'
import AdminUserSetting from './admin/user-setting'
import AdminSignup from './admin/signup'

import AdminProducts from './admin/products'
import AdminCreateProductCategory from './admin/create-product-category'
import AdminEditProductCategory from './admin/edit-product-category'
import AdminProductCategoryDownload from './admin/product-category-download-dashboard'
import AdminCreateProduct from './admin/create-product'
import AdminEditProduct from './admin/edit-product'

import AdminFiles from './admin/files'
import AdminFileLog from './admin/file-log'

import AdminCreateStorage from './admin/create-storage'
import AdminEditStorage from './admin/edit-storage'

import AdminDrivers from './admin/drivers'
import AdminCreateDriver from './admin/create-driver'
import AdminEditDriver from './admin/edit-driver'

import AdminClientIsssues from './admin/client-issues'

import AdminNews from './admin/news'
import AdminCreateNews from './admin/create-news'
import AdminEditNews from './admin/edit-news'

import AdminPartnerNews from './admin/partner-news'
import AdminCreatePartnerNews from './admin/create-partner-news'
import AdminEditPartnerNews from './admin/edit-partner-news'

import AdminActivities from './admin/activities'
import AdminCreateActivity from './admin/create-activity'
import AdminEditActivity from './admin/edit-activity'

import AdminMonthlyRevenue from './admin/monthly-revenue'
import AdminCreateMonthlyRevenue from './admin/create-monthly-revenue'
import AdminEditMonthlyRevenue from './admin/edit-monthly-revenue'

import AdminOpportunity from './admin/opportunity'
import AdminCreateOpportunity from './admin/create-opportunity'
import AdminEditOpportunity from './admin/edit-opportunity'

import AdminPersonnels from './admin/personnels'

import AdminFinancialInfo from './admin/financial-info'
import AdminCreateFinancialInfo from './admin/create-financial-info'
import AdminEditFinancialInfo from './admin/edit-financial-info'

import AdminShareholders from './admin/shareholders'
import AdminCreateShareholder from './admin/create-shareholder'
import AdminEditShareholder from './admin/edit-shareholder'

import AdminCalendars from './admin/calendars'

import AdminCorporateGovernance from './admin/corporate-governance'
import AdminCreateCorporateGovernance from './admin/create-corporate-governance'
import AdminEditCorporateGovernance from './admin/edit-corporate-governance'

import AdminSustainabilityReport from './admin/sustainability-report'
import AdminCreateSustainabilityReport from './admin/create-sustainability-report'
import AdminEditSustainabilityReport from './admin/edit-sustainability-report'

import AdminApp from './admin/apps'
import AdminCreateApp from './admin/create-app'
import AdminEditApp from './admin/edit-app'

import AdminActLogs from './admin/act-log'

import UserPersonnel from './personnel/index'
import EntryFlow1 from './personnel/entryFlow1'
import EntryFlow2 from './personnel/entryFlow2'
import ReportDocuments from './personnel/reportDocuments'
import RedirectLogin from './login/redirect_login'

import CreateResume from './resume/create'
import AdminResumes from './admin/resumes'

import Account from './account/home'
import Report from './account/report'
import Setting from './account/setting'
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import Contact from './home/contact'
import DefaultLayout from './layouts/Default'
import AppLayout from './layouts/App'
import AdminLayout from './layouts/Admin'
import AccountLayout from './layouts/Account'
import NotFoundPage from './NotFoundPage'
import { useTranslation } from 'react-multi-lang'
import requireAdminAuth from './utils/requireAdminAuth'
import requireAuthentication from './utils/requireAuthentication'
import requireWeakAuth from './utils/requireWeakAuth'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-table/react-table.css'
import 'react-datetime/css/react-datetime.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-image-crop/dist/ReactCrop.css'
import './common.css'

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const t = useTranslation()
  return (
    <Route
      {...rest} render={props => (
        <Layout t={t}>
          <Component {...props} t={t} />
        </Layout>
      )}
    />
  )
}

class App extends Component {
  render () {
    return (
      <BrowserRouter>
        <Provider store={Store}>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/app/' />
            </Route>
            <AppRoute exact path='/app/' layout={DefaultLayout} component={requireWeakAuth(Login)} />
            <AppRoute exact path='/app/sso/:id' layout={AppLayout} component={requireWeakAuth(SSO)} />
            <AppRoute exact path='/app/contact' layout={DefaultLayout} component={requireWeakAuth(Contact)} />
            <AppRoute exact path='/app/login/forgot' layout={DefaultLayout} component={requireWeakAuth(Forgot)} />
            <AppRoute exact path='/app/login/reset/:email/:key/' layout={DefaultLayout} component={Reset} />
            <AppRoute exact path='/app/login/reactivate/:email/:key/' layout={DefaultLayout} component={Reactivate} />
            <AppRoute exact path='/app/signup' layout={DefaultLayout} component={requireWeakAuth(Signup)} />
            <Route exact path='/app/personnel' component={UserPersonnel} />
            <Route exact path='/app/personnel_entry_1' component={EntryFlow1} />
            <Route exact path='/app/personnel_entry_2' component={EntryFlow2} />
            <Route exact path='/app/report_documents' component={ReportDocuments} />
            <Route exact path='/app/hunter' component={RedirectLogin} />

            <Route exact path='/app/campus-apply' component={CreateResume} />
            <AppRoute exact path='/app/admin/resumes' layout={AdminLayout} component={requireAdminAuth(AdminResumes)} />

            <AppRoute exact path='/app/account' layout={AccountLayout} component={requireAuthentication(Account)} />
            <AppRoute exact path='/app/account/report' layout={AccountLayout} component={requireAuthentication(Report)} />
            <AppRoute exact path='/app/account/setting' layout={AccountLayout} component={requireAuthentication(Setting)} />
            <AppRoute exact path='/app/logout' layout={AccountLayout} component={Logout} />
            <AppRoute exact path='/app/admin' layout={AdminLayout} component={requireAdminAuth(Admin)} />
            <AppRoute exact path='/app/admin/users' layout={AdminLayout} component={requireAdminAuth(AdminUsers)} />
            <AppRoute exact path='/app/admin/signup' layout={AdminLayout} component={requireAdminAuth(AdminSignup)} />
            <AppRoute exact path='/app/admin/user/:uid/:aid/:username' layout={AdminLayout} component={requireAdminAuth(AdminUserSetting)} />

            <AppRoute exact path='/app/admin/products' layout={AdminLayout} component={requireAdminAuth(AdminProducts)} />
            <AppRoute exact path='/app/admin/create_product_category' layout={AdminLayout} component={requireAdminAuth(AdminCreateProductCategory)} />
            <AppRoute exact path='/app/admin/product_category/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditProductCategory)} />
            <AppRoute exact path='/app/admin/product_category/:id/download_dashboard' layout={AdminLayout} component={requireAdminAuth(AdminProductCategoryDownload)} />

            <AppRoute exact path='/app/admin/drivers' layout={AdminLayout} component={requireAdminAuth(AdminDrivers)} />
            <AppRoute exact path='/app/admin/create_driver' layout={AdminLayout} component={requireAdminAuth(AdminCreateDriver)} />
            <AppRoute exact path='/app/admin/driver/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditDriver)} />

            <AppRoute exact path='/app/admin/create_product' layout={AdminLayout} component={requireAdminAuth(AdminCreateProduct)} />
            <AppRoute exact path='/app/admin/product/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditProduct)} />
            <AppRoute exact path='/app/admin/product/:id/files' layout={AdminLayout} component={requireAdminAuth(AdminFiles)} />
            <AppRoute exact path='/app/admin/product/:id/storage' layout={AdminLayout} component={requireAdminAuth(AdminCreateStorage)} />
            <AppRoute exact path='/app/admin/product/:pid/storage/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditStorage)} />

            <AppRoute exact path='/app/admin/file_log' layout={AdminLayout} component={requireAdminAuth(AdminFileLog)} />
            <AppRoute exact path='/app/admin/client_issues' layout={AdminLayout} component={requireAdminAuth(AdminClientIsssues)} />

            <AppRoute exact path='/app/admin/financial_info' layout={AdminLayout} component={requireAdminAuth(AdminFinancialInfo)} />
            <AppRoute exact path='/app/admin/create_financial_info' layout={AdminLayout} component={requireAdminAuth(AdminCreateFinancialInfo)} />
            <AppRoute exact path='/app/admin/financial_info/:years' layout={AdminLayout} component={requireAdminAuth(AdminEditFinancialInfo)} />

            <AppRoute exact path='/app/admin/shareholders' layout={AdminLayout} component={requireAdminAuth(AdminShareholders)} />
            <AppRoute exact path='/app/admin/create_shareholder' layout={AdminLayout} component={requireAdminAuth(AdminCreateShareholder)} />
            <AppRoute exact path='/app/admin/shareholder/:years' layout={AdminLayout} component={requireAdminAuth(AdminEditShareholder)} />

            <AppRoute exact path='/app/admin/news' layout={AdminLayout} component={requireAdminAuth(AdminNews)} />
            <AppRoute exact path='/app/admin/create_news' layout={AdminLayout} component={requireAdminAuth(AdminCreateNews)} />
            <AppRoute exact path='/app/admin/news/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditNews)} />

            <AppRoute exact path='/app/admin/partner_news' layout={AdminLayout} component={requireAdminAuth(AdminPartnerNews)} />
            <AppRoute exact path='/app/admin/create_partner_news' layout={AdminLayout} component={requireAdminAuth(AdminCreatePartnerNews)} />
            <AppRoute exact path='/app/admin/partner_news/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditPartnerNews)} />

            <AppRoute exact path='/app/admin/activities' layout={AdminLayout} component={requireAdminAuth(AdminActivities)} />
            <AppRoute exact path='/app/admin/create_activity' layout={AdminLayout} component={requireAdminAuth(AdminCreateActivity)} />
            <AppRoute exact path='/app/admin/activity/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditActivity)} />

            <AppRoute exact path='/app/admin/monthly_revenue' layout={AdminLayout} component={requireAdminAuth(AdminMonthlyRevenue)} />
            <AppRoute exact path='/app/admin/create_monthly_revenue' layout={AdminLayout} component={requireAdminAuth(AdminCreateMonthlyRevenue)} />
            <AppRoute exact path='/app/admin/monthly_revenue/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditMonthlyRevenue)} />

            <AppRoute exact path='/app/admin/opportunities' layout={AdminLayout} component={requireAdminAuth(AdminOpportunity)} />
            <AppRoute exact path='/app/admin/create_opportunity' layout={AdminLayout} component={requireAdminAuth(AdminCreateOpportunity)} />
            <AppRoute exact path='/app/admin/opportunity/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditOpportunity)} />

            <AppRoute exact path='/app/admin/personnels' layout={AdminLayout} component={requireAdminAuth(AdminPersonnels)} />

            <AppRoute exact path='/app/admin/cgs' layout={AdminLayout} component={requireAdminAuth(AdminCorporateGovernance)} />
            <AppRoute exact path='/app/admin/create_cg' layout={AdminLayout} component={requireAdminAuth(AdminCreateCorporateGovernance)} />
            <AppRoute exact path='/app/admin/cg/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditCorporateGovernance)} />

            <AppRoute exact path='/app/admin/calendars' layout={AdminLayout} component={requireAdminAuth(AdminCalendars)} />

            <AppRoute exact path='/app/admin/apps' layout={AdminLayout} component={requireAdminAuth(AdminApp)} />
            <AppRoute exact path='/app/admin/create_app' layout={AdminLayout} component={requireAdminAuth(AdminCreateApp)} />
            <AppRoute exact path='/app/admin/app/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditApp)} />

            <AppRoute exact path='/app/admin/act_logs' layout={AdminLayout} component={requireAdminAuth(AdminActLogs)} />

            <AppRoute exact path='/app/admin/sustainability_reports' layout={AdminLayout} component={requireAdminAuth(AdminSustainabilityReport)} />
            <AppRoute exact path='/app/admin/create_sustainability_report' layout={AdminLayout} component={requireAdminAuth(AdminCreateSustainabilityReport)} />
            <AppRoute exact path='/app/admin/sustainability_report/:id' layout={AdminLayout} component={requireAdminAuth(AdminEditSustainabilityReport)} />

            <AppRoute exact layout={DefaultLayout} component={requireWeakAuth(NotFoundPage)} />
          </Switch>
        </Provider>
      </BrowserRouter>
    )
  }
}

export default App
