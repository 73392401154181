import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import ReactCrop from 'react-image-crop'
import { postFormData } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import config from '../../config'

class PhotoUpoadPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.imageRef = {}
    this.state = {
      uid: this.props.uid,
      photoSrc: null,
      crop: {
        unit: '%',
        width: 90,
        aspect: 0.78 // 3.5 / 4.5
      },
      photoBlob: null,
      uploadLoading: false,

      photo: this.props.photo
    }
    this.onSelectFile = this.onSelectFile.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.onCropChange = this.onCropChange.bind(this)
    this.onCropComplete = this.onCropComplete.bind(this)
    this.handleUploadPhoto = this.handleUploadPhoto.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 6) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      photo: this.state.photo
    }, 6, page)
  } // end handleSubmit

  onSelectFile (e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        this.setState({ photoSrc: reader.result })
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // If you setState the crop in here you should return false.
  onImageLoaded (image) {
    this.imageRef = image
  }

  onCropComplete (crop) {
    this.makeClientCrop(crop)
  }

  onCropChange (crop, percentCrop) {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop })
    this.setState({ crop })
  }

  async makeClientCrop (crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg (image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          // reject(new Error('Canvas is empty'))
          console.error('Canvas is empty')
          return
        }
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        this.setState({
          photoBlob: blob
        })
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  handleUploadPhoto () {
    this.setState({
      uploadLoading: true
    })
    postFormData('/1/personnel/storage', {
      uid: this.props.uid,
      file: this.state.photoBlob
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            photo: r.data.uri,
            photoSrc: null,
            uploadLoading: false
          })
          this.input.file.value = null
        } else {
          this.setState({
            uploadLoading: false
          })
        }
      }
    )
  }

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>6 / 6</p>
                <Progress value='100' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <p className='text-left mt-4'>請上傳證件用大頭照，此照片將作為識別證照片，切勿使用生活照。若無電子檔大頭照，請於報到當日繳交兩張二吋大頭照。</p>
                  <input
                    ref={(c) => (this.input.file = c)}
                    type='file'
                    accept='image/*'
                    onChange={this.onSelectFile}
                  />
                  {this.state.photoSrc && (
                    <div className='mt-3'>
                      <ReactCrop
                        src={this.state.photoSrc}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                      <Col md='12' className='text-center mt-4'>
                        <ControlGroup hideLabel hideHelp>
                          <Button
                            type='button'
                            inputClasses={{ 'btn-success mr-4': true }}
                            onClick={e => this.handleUploadPhoto(e)}
                            disabled={this.state.uploadLoading}
                          >
                            剪裁
                            <Spinner
                              space='left'
                              show={this.state.uploadLoading}
                            />
                          </Button>

                          <Button
                            type='button'
                            inputClasses={{ 'btn btn-light': true }}
                            onClick={e => {
                              this.setState({ photoSrc: null })
                              this.input.file.value = null
                            }}
                            disabled={this.state.uploadLoading}
                          >
                            取消
                            <Spinner
                              space='left'
                              show={this.state.uploadLoading}
                            />
                          </Button>
                        </ControlGroup>
                      </Col>
                    </div>
                  )}

                  {this.state.photo && !this.state.photoSrc && (
                    <img alt='Crop' className='mt-3' style={{ maxWidth: '100%' }} src={`${config.awsUrl}${this.state.photo}`} />
                  )}
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 5)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 7)}
                    disabled={this.props.loading}
                  >
                    提交
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default PhotoUpoadPage
