import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'
import CheckboxControl from '../../components/checkbox-control'

class OrganizationPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      adjustPosition: this.props.adjustPosition, // 是否願意調整職務？是或否
      adjustPositionReason: this.props.adjustPositionReason, // 是否願意調整職務？否的原因
      workPlace: this.props.workPlace, // 是否願意調整工作地點？是或否
      workPlaceReason: this.props.workPlaceReason, // 是否願意調整工作地點？否的原因
      shift: this.props.shift, // 是否願意輪班？是或否
      shiftReason: this.props.shiftReason, // 是否願意輪班？否的原因
      overtime: this.props.overtime, // 是否願意加班？是或否
      overtimeReason: this.props.overtimeReason, // 是否願意加班？否的原因
      checkConfirm: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 5, isTemporary) {
    event.preventDefault()
    event.stopPropagation()

    this.props.setPersonnel({
      adjustPosition: this.state.adjustPosition,
      adjustPositionReason: this.state.adjustPosition === '否' ? this.state.adjustPositionReason : '',
      workPlace: this.state.workPlace,
      workPlaceReason: this.state.workPlace === '否' ? this.state.workPlaceReason : '',
      shift: this.state.shift,
      shiftReason: this.state.shift === '否' ? this.state.shiftReason : '',
      overtime: this.state.overtime,
      overtimeReason: this.state.overtime === '否' ? this.state.overtimeReason : '',
      isActive: page < 8 ? 'true' : 'false'
    }, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>7 / 7</p>
                <Progress value='100' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>

            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>組織需求</h3>
                </Col>

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.adjustPosition = c)}
                    name='adjustPosition'
                    label='是否願意配合組織需求調整職務內容？'
                    value={this.state.adjustPosition}
                    onChange={(e) => (this.setState({ adjustPosition: e.target.value }))}
                    hasError={this.props.hasError.adjustPosition}
                    help={this.props.help.adjustPosition}
                    disabled={this.props.loading}
                  >
                    <option value='是'>
                      是
                    </option>
                    <option value='否'>
                      否
                    </option>
                  </SelectControl>
                </Col>

                {this.state.adjustPosition === '否' &&
                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.adjustPositionReason = c)}
                      name='adjustPositionReason'
                      label='無法配合組織需求調整職務內容的原因？'
                      value={this.state.adjustPositionReason}
                      onChange={(e) => (this.setState({ adjustPositionReason: e.target.value }))}
                      hasError={this.props.hasError.adjustPositionReason}
                      help={this.props.help.adjustPositionReason}
                      disabled={this.props.loading}
                    />
                  </Col>}

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.workPlace = c)}
                    name='workPlace'
                    label='是否願意配合組織需求調整工作地點？'
                    value={this.state.workPlace}
                    onChange={(e) => (this.setState({ workPlace: e.target.value }))}
                    hasError={this.props.hasError.workPlace}
                    help={this.props.help.workPlace}
                    disabled={this.props.loading}
                  >
                    <option value='是'>
                      是
                    </option>
                    <option value='否'>
                      否
                    </option>
                  </SelectControl>
                </Col>

                {this.state.workPlace === '否' &&
                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.workPlaceReason = c)}
                      name='workPlaceReason'
                      label='無法配合組織需求調整工作地點的原因？'
                      value={this.state.workPlaceReason}
                      onChange={(e) => (this.setState({ workPlaceReason: e.target.value }))}
                      hasError={this.props.hasError.workPlaceReason}
                      help={this.props.help.workPlaceReason}
                      disabled={this.props.loading}
                    />
                  </Col>}

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.shift = c)}
                    name='shift'
                    label='是否願意配合組織需求擔任輪班工作？'
                    value={this.state.shift}
                    onChange={(e) => (this.setState({ shift: e.target.value }))}
                    hasError={this.props.hasError.shift}
                    help={this.props.help.shift}
                    disabled={this.props.loading}
                  >
                    <option value='是'>
                      是
                    </option>
                    <option value='否'>
                      否
                    </option>
                  </SelectControl>
                </Col>

                {this.state.shift === '否' &&
                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.shiftReason = c)}
                      name='shiftReason'
                      label='無法配合組織需求擔任輪班工作的原因？'
                      value={this.state.shiftReason}
                      onChange={(e) => (this.setState({ shiftReason: e.target.value }))}
                      hasError={this.props.hasError.shiftReason}
                      help={this.props.help.shiftReason}
                      disabled={this.props.loading}
                    />
                  </Col>}

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.overtime = c)}
                    name='overtime'
                    label='是否願意配合值班/加班安排？'
                    value={this.state.overtime}
                    onChange={(e) => (this.setState({ overtime: e.target.value }))}
                    hasError={this.props.hasError.overtime}
                    help={this.props.help.overtime}
                    disabled={this.props.loading}
                  >
                    <option value='是'>
                      是
                    </option>
                    <option value='否'>
                      否
                    </option>
                  </SelectControl>
                </Col>

                {this.state.overtime === '否' &&
                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.overtimeReason = c)}
                      name='overtimeReason'
                      label='無法配合值班/加班安排的原因？'
                      value={this.state.overtimeReason}
                      onChange={(e) => (this.setState({ overtimeReason: e.target.value }))}
                      hasError={this.props.hasError.overtimeReason}
                      help={this.props.help.overtimeReason}
                      disabled={this.props.loading}
                    />
                  </Col>}
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <CheckboxControl
                    ref={(c) => (this.input.checkConfirm = c)}
                    inputClasses={{ h6: true }}
                    name='checkConfirm'
                    label='填寫人就本表內容已就所知自行揭露，所填資料均為事實，且無洩漏其他公司之營業秘密，本人同意信驊科技公司審查，如有虛假情事，願受解職處分。本資料僅供本公司內部徵才使用，依個人資料保護法之規定，取得或使用求職者履歷時，絕不洩漏、交付或以其他方式使非經合法授權之任何第三人知悉。'
                    checked={this.state.checkConfirm}
                    onChange={(e) => (this.setState({ checkConfirm: !this.state.checkConfirm }))}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 7, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 6, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 8, false)}
                    disabled={this.props.loading || !this.state.checkConfirm}
                  >
                    提交
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default OrganizationPage
