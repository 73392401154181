import React, { Component } from 'react'
import { Redirect } from 'react-router'

class RedirectLogin extends Component {
  render () {
    return (<Redirect to='/app/' />)
  }
}

export default RedirectLogin
