import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ReactTable from 'react-table'
import moment from 'moment'
import { Container, Row, Col } from 'reactstrap'
import { get, deleteItem } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Spinner from '../components/spinner'
import TextControlFillet from '../components/text-control-fillet'
import HButton from '../components/button'

class AdminResumesPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      data: [],
      pageSize: 10,
      page: 0,
      search: ''
    }
    this.handleFetchData = this.handleFetchData.bind(this)
    this.handleDownload = this.handleDownload.bind(this)
  }

  search () {
    this.handleFetchData({
      page: 0,
      pageSize: this.state.pageSize,
      search: this.state.search,
      sorted: []
    })
  }

  handleFetchData (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    const search = this.state.search ? this.state.search : ''
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/resumes?page=${page}&limit=${limit}&sort=${sorted}&search=${search}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            item.birthday = item.birthday ? moment(item.birthday).format('YYYY/MM/DD') : ''
            item.createTime = moment(item.createTime).format('YYYY/MM/DD HH:mm')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteData (uid) {
    if (!window.confirm('是否確定要刪除此履歷？')) {
      return -1
    }

    deleteItem(`/1/admin/resume/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchData()
        }
      })
  }

  handleDownload (pId, chName) {
    // let uri = `/app/1/admin/personnel/${pId}/doc`

    // if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    //   uri = `/1/admin/personnel/${pId}/doc`
    // }

    // axios({
    //   url: uri,
    //   responseType: 'blob',
    //   headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    // }).then(({ data }) => {
    //   const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    //   const link = document.createElement('a')
    //   link.href = downloadUrl
    //   let ext = mimeDB[data.type]
    //   if (ext && ext.extensions.length > 0) {
    //     ext = `.${ext.extensions[0]}`
    //   } else {
    //     ext = ''
    //   }
    //   link.setAttribute('download', `人事資料表_${chName}${ext}`)
    //   document.body.appendChild(link)
    //   link.click()
    //   link.remove()
    //   const key = {}
    //   key[pId] = false
    //   this.setState(key)
    // }).catch(function (error) {
    //   console.log(error)
    // })
  }

  render () {
    const columns = [
      {
        Header: '中文名',
        accessor: 'chName'
      }, {
        Header: 'email',
        accessor: 'email'
      }, {
        Header: '手機',
        accessor: 'cellphone'
      }, {
        Header: '大學',
        accessor: 'university',
        Cell: row => (
          <div>
            {row.value} {row.original.universityDepartment}
          </div>
        )
      }, {
        Header: '研究所',
        accessor: 'graduateSchool',
        Cell: row => (
          <div>
            {row.value} {row.original.graduateSchoolDepartment}
          </div>
        )
      }, {
        Header: '身份狀況',
        accessor: 'identityStatus'
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm btn-sm' onClick={this.deleteData.bind(this, row.value)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <Container>
        <Helmet>
          <title>
            校園徵才
          </title>
        </Helmet>

        <h1 className='page-header'>校園徵才</h1>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <TextControlFillet
              ref={(c) => (this.input.search = c)}
              groupClasses={{ 'form-group-inline max-width-125': true }}
              name='search'
              value={this.state.search}
              placeholder='中文名、email'
              onChange={(e) => {
                this.setState({ search: e.target.value })
                this.search.bind(this)
              }}
              disabled={this.state.loading}
            />
            &nbsp;
            <HButton
              type='button'
              inputClasses={{ 'btn btn-primary ml-1 mb-1': true }}
              disabled={this.state.loading}
              onClick={this.search.bind(this)}
            >
              搜尋
              <Spinner space='left' show={this.state.loading} />
            </HButton>
          </Col>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchData}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <table border='1' cellpadding='8' cellspacing='0'>
                    <thead>
                      <tr>
                        <th colspan='4' className='text-center'>
                          個人簡歷 - {row.original.type}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>姓名</td>
                        <td>{row.original.chName}</td>
                        <td>應徵職位</td>
                        <td>{row.original.applyPosition}</td>
                      </tr>
                      <tr>
                        <td>出生日期</td>
                        <td>{row.original.birthday}</td>
                        <td>身份狀況</td>
                        <td>{row.original.identityStatus}</td>
                      </tr>
                      <tr>
                        <td>手機</td>
                        <td>{row.original.cellphone}</td>
                        <td>Email</td>
                        <td>{row.original.email}</td>
                      </tr>

                      <tr>
                        <th colspan='4' className='text-center'>學歷</th>
                      </tr>
                      <tr>
                        <td>大學</td>
                        <td colspan='3'>
                          {row.original.university}（{row.original.universityDepartment}，{row.original.universityPart ? `${row.original.universityPart}間部` : ''}）
                        </td>
                      </tr>
                      <tr>
                        <td>畢業年月</td>
                        <td colspan='3'>
                          {row.original.universityEndYear} 年 {row.original.universityEndMonth} 月
                        </td>
                      </tr>
                      <tr>
                        <td>研究所</td>
                        <td colspan='3'>
                          {row.original.graduateSchool}（{row.original.graduateSchoolDepartment}，{row.original.graduateSchoolPart}）
                        </td>
                      </tr>
                      <tr>
                        <td>畢業年月</td>
                        <td colspan='3'>
                          {row.original.graduateSchoolEndYear} 年 {row.original.graduateSchoolEndMonth} 月
                        </td>
                      </tr>

                      <tr>
                        <th colspan='4' className='text-center'>
                          語言能力
                        </th>
                      </tr>
                      <tr>
                        <td>英文</td>
                        <td colspan='3'>
                          聽：{row.original.englishListening}、
                          說：{row.original.englishSpeaking}、
                          讀：{row.original.englishReading}、
                          寫：{row.original.englishWriting}
                        </td>
                      </tr>
                      <tr>
                        <td>第二外語 ({row.original.foreignLanguage})</td>
                        <td colspan='3'>
                          聽：{row.original.foreignListening}、
                          說：{row.original.foreignSpeaking}、
                          讀：{row.original.foreignReading}、
                          寫：{row.original.foreignWriting}
                        </td>
                      </tr>

                      <tr>
                        <th colspan='4' className='text-center'>
                          論文與指導教授
                        </th>
                      </tr>
                      <tr>
                        <td>碩士論文篇名</td>
                        <td colspan='3'>{row.original.masterThesisTitle}</td>
                      </tr>
                      <tr>
                        <td>碩士指導教授</td>
                        <td colspan='3'>{row.original.masterAdvisor}</td>
                      </tr>
                      <tr>
                        <td>博士論文篇名</td>
                        <td colspan='3'>{row.original.phdTitle}</td>
                      </tr>
                      <tr>
                        <td>博士指導教授</td>
                        <td colspan='3'>{row.original.doctoralSupervisor}</td>
                      </tr>

                      <tr>
                        <td>填表時間</td>
                        <td colspan='3'>{row.original.createTime}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={this.state.pageSize}
              onPageSizeChange={(pageSize) => (this.setState({ pageSize: pageSize }))}
              defaultSorted={[{ id: 'updateTime', desc: true }]}
            />
          </div>
        </Row>
      </Container>
    )
  }
}

export default AdminResumesPage
