import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'

class CreateFinancialInfoPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.years) {
      this.input.years.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/financial_info', {
      years: this.input.years.value(),
      m1: this.input.m1.value(),
      m2: this.input.m2.value(),
      m3: this.input.m3.value(),
      m4: this.input.m4.value(),
      m5: this.input.m5.value(),
      m6: this.input.m6.value(),
      m7: this.input.m7.value(),
      m8: this.input.m8.value(),
      m9: this.input.m9.value(),
      m10: this.input.m10.value(),
      m11: this.input.m11.value(),
      m12: this.input.m12.value(),
      annualRevenue: this.input.annualRevenue.value(),
      annualNetProfit: this.input.annualNetProfit.value(),
      earningsPerShare: this.input.earningsPerShare.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/financial_info' />)
    } else if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    let formElements

    if (!this.state.success) {
      formElements =
        <fieldset>
          <TextControl
            ref={(c) => (this.input.years = c)}
            name='years'
            label='年份'
            hasError={this.state.hasError.years}
            help={this.state.help.years}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m1 = c)}
            name='m1'
            label='1 月'
            hasError={this.state.hasError.m1}
            help={this.state.help.m1}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m2 = c)}
            name='m2'
            label='2 月'
            hasError={this.state.hasError.m2}
            help={this.state.help.m2}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m3 = c)}
            name='m3'
            label='3 月'
            hasError={this.state.hasError.m3}
            help={this.state.help.m3}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m4 = c)}
            name='4m'
            label='4 月'
            hasError={this.state.hasError.m4}
            help={this.state.help.m4}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m5 = c)}
            name='m5'
            label='5 月'
            hasError={this.state.hasError.m5}
            help={this.state.help.m5}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m6 = c)}
            name='m6'
            label='6 月'
            hasError={this.state.hasError.m6}
            help={this.state.help.m6}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m7 = c)}
            name='m7'
            label='7 月'
            hasError={this.state.hasError.m7}
            help={this.state.help.m7}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m8 = c)}
            name='m8'
            label='8 月'
            hasError={this.state.hasError.m8}
            help={this.state.help.m8}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m9 = c)}
            name='m9'
            label='9 月'
            hasError={this.state.hasError.m9}
            help={this.state.help.m9}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m10 = c)}
            name='m10'
            label='10 月'
            hasError={this.state.hasError.m10}
            help={this.state.help.m10}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m11 = c)}
            name='m11'
            label='11 月'
            hasError={this.state.hasError.m11}
            help={this.state.help.m11}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.m12 = c)}
            name='m12'
            label='12 月'
            hasError={this.state.hasError.m12}
            help={this.state.help.m12}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.annualRevenue = c)}
            name='annualRevenue'
            label='年度營收 (可不填寫，將自動計算)'
            hasError={this.state.hasError.annualRevenue}
            help={this.state.help.annualRevenue}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.annualNetProfit = c)}
            name='annualNetProfit'
            label='年度淨利'
            hasError={this.state.hasError.annualNetProfit}
            help={this.state.help.annualNetProfit}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.earningsPerShare = c)}
            name='earningsPerShare'
            label='每股盈餘'
            hasError={this.state.hasError.earningsPerShare}
            help={this.state.help.earningsPerShare}
            disabled={this.state.loading}
          />
          <ControlGroup hideLabel hideHelp>
            <Button
              type='submit'
              inputClasses={{ 'btn-success': true }}
              disabled={this.state.loading}
            >
              建立
              <Spinner space='left' show={this.state.loading} />
            </Button>
          </ControlGroup>
        </fieldset>
    }

    return (
      <section className='container'>
        <Helmet>
          <title>建立年度財務資訊</title>
        </Helmet>

        <h1 className='page-header'>建立年度財務資訊</h1>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              {formElements}
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateFinancialInfoPage
