import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { putFormData, get } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import FileControl from '../components/file-control'
import SelectControl from '../components/select-control'
import DateTimeControl from '../components/datetime-control'
import config from '../config'

class EditSustainabilityReportPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      postTime: new Date(),
      lang: '',
      type: '',
      isActive: 'false',
      imgName: '',
      imgUri: '',
      fileName: '',
      fileUri: ''
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    get(`/1/sustainability_report/${this.props.match.params.id}`)
      .then(r => {
        this.setState({
          name: r.data.name,
          postTime: new Date(r.data.postTime),
          imgName: r.data.img.fileName,
          imgUri: r.data.img.uri,
          fileName: r.data.file.fileName,
          fileUri: r.data.file.uri,
          lang: r.data.lang,
          type: r.data.type,
          isActive: r.data.isActive.toString()
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    putFormData(`/1/admin/storage/sustainability_report/${this.props.match.params.id}`, {
      name: this.input.name.value(),
      postTime: this.state.postTime.getTime(),
      lang: this.input.lang.value(),
      type: this.input.type.value(),
      img: this.input.img.value(),
      file: this.input.file.value(),
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/sustainability_reports' />)
    } else if (this.state.error) {
      alert = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    let img = []
    if (this.state.imgUri) {
      img = (
        <div className='mb-4'>
          (當前檔案：
          <a href={`${config.awsUrl}${this.state.imgUri}`} target='_blank' rel='noopener noreferrer'>
            {this.state.imgName}
          </a>)
        </div>
      )
    }

    let file = []
    if (this.state.fileUri) {
      file = (
        <div className='mb-4'>
          (當前檔案：
          <a href={`${config.awsUrl}${this.state.fileUri}`} target='_blank' rel='noopener noreferrer'>
            {this.state.fileName}
          </a>)
        </div>
      )
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>編輯{this.state.name}</title>
        </Helmet>

        <div className='row'>
          <div className='col-md-6'>
            <section>
              <h1 className='page-header'>編輯{this.state.name}</h1>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                <TextControl
                  ref={(c) => (this.input.name = c)}
                  name='name'
                  label='名稱'
                  value={this.state.name}
                  onChange={(e) => (this.setState({ name: e.target.value }))}
                  hasError={this.state.hasError.name}
                  help={this.state.help.name}
                  disabled={this.state.loading}
                />
                <DateTimeControl
                  ref={(c) => (this.input.postTime = c)}
                  name='postTime'
                  label='發布日期'
                  placeholder='新聞發布日期'
                  timeFormat={false}
                  value={this.state.postTime}
                  onChange={(e) => this.setState({ postTime: new Date(e.valueOf()) })}
                  hasError={this.state.hasError.postTime}
                  help={this.state.help.postTime}
                  disabled={this.state.loading}
                />
                <SelectControl
                  ref={(c) => (this.input.lang = c)}
                  name='lang'
                  label='語系'
                  value={this.state.lang}
                  onChange={(e) => (this.setState({ lang: e.target.value }))}
                  hasError={this.state.hasError.lang}
                  help={this.state.help.lang}
                  disabled={this.state.loading}
                >
                  <option value='tw'>
                    繁體中文
                  </option>
                  <option value='en'>
                    英文
                  </option>
                </SelectControl>
                <SelectControl
                  ref={(c) => (this.input.type = c)}
                  name='type'
                  label='類型'
                  value={this.state.type}
                  onChange={(e) => (this.setState({ type: e.target.value }))}
                  hasError={this.state.hasError.type}
                  help={this.state.help.type}
                  disabled={this.state.loading}
                >
                  <option value='1'>
                    企業社會責任報告書
                  </option>
                  <option value='2'>
                    ESG 摘要
                  </option>
                  <option value='3'>
                    其他
                  </option>
                </SelectControl>
                <FileControl
                  ref={(c) => (this.input.img = c)}
                  name='img'
                  label='封面圖示'
                  hasError={this.state.hasError.img}
                  help={this.state.help.img}
                  disabled={this.state.loading}
                />
                {img}
                <FileControl
                  ref={(c) => (this.input.file = c)}
                  name='file'
                  label='檔案'
                  hasError={this.state.hasError.file}
                  help={this.state.help.file}
                  disabled={this.state.loading}
                />
                {file}
                <SelectControl
                  ref={(c) => (this.input.isActive = c)}
                  name='isActive'
                  label='是否啟用'
                  value={this.state.isActive}
                  onChange={(e) => (this.setState({ isActive: e.target.value }))}
                  hasError={this.state.hasError.isActive}
                  help={this.state.help.isActive}
                  disabled={this.state.loading}
                >
                  <option value='true'>
                    啟用
                  </option>
                  <option value='false'>
                    關閉
                  </option>
                </SelectControl>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-success': true }}
                    disabled={this.state.loading}
                  >
                    更新
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </ControlGroup>
              </form>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

export default EditSustainabilityReportPage
