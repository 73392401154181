import React, { Component } from 'react'
import { Container, Row, Col, Card } from 'reactstrap'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'
import DateTimeControl from '../components/datetime-control'
import CheckboxControl from '../components/checkbox-control'

class BasicInformationPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.imageRef = {}
    this.state = {
      applyPosition: '數位IC設計驗證工程師' // 應徵職位
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    let savedData = localStorage.getItem('basicInfoForm')

    if (savedData) {
      savedData = JSON.parse(savedData)
      delete savedData.applyPosition
      this.setState({ ...savedData })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState !== this.state) {
      localStorage.setItem('basicInfoForm', JSON.stringify(this.state))
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      type: 'campus recruitment',
      chName: this.state.chName,
      applyPosition: this.state.applyPosition,
      birthday: this.state.birthday,
      identityStatus: this.state.identityStatus === '其他' ? this.state.identityStatusOther : this.state.identityStatus,
      cellphone: this.state.cellphone,
      email: this.state.email,
      university: this.state.university,
      universityDepartment: this.state.universityDepartment,
      universityPart: this.state.universityPart,
      universityStartYear: this.state.universityStartYear,
      universityStartMonth: this.state.universityStartMonth,
      universityEndYear: this.state.universityEndYear,
      universityEndMonth: this.state.universityEndMonth,
      graduateSchool: this.state.graduateSchool,
      graduateSchoolDepartment: this.state.graduateSchoolDepartment,
      graduateSchoolPart: this.state.graduateSchoolPart,
      graduateSchoolStartYear: this.state.graduateSchoolStartYear,
      graduateSchoolStartMonth: this.state.graduateSchoolStartMonth,
      graduateSchoolEndYear: this.state.graduateSchoolEndYear,
      graduateSchoolEndMonth: this.state.graduateSchoolEndMonth,
      englishReading: this.state.englishReading,
      englishSpeaking: this.state.englishSpeaking,
      englishListening: this.state.englishListening,
      englishWriting: this.state.englishWriting,
      foreignLanguage: this.state.foreignLanguage,
      foreignReading: this.state.foreignReading,
      foreignSpeaking: this.state.foreignSpeaking,
      foreignListening: this.state.foreignListening,
      foreignWriting: this.state.foreignWriting,
      masterThesisTitle: this.state.masterThesisTitle,
      masterAdvisor: this.state.masterAdvisor,
      phdTitle: this.state.phdTitle,
      doctoralSupervisor: this.state.doctoralSupervisor
    })
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center mt-5'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>基本資料</h3>
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.chName = c)}
                    name='chName'
                    label='中文姓名'
                    value={this.state.chName}
                    onChange={(e) => (this.setState({ chName: e.target.value }))}
                    hasError={this.props.hasError.chName}
                    help={this.props.help.chName}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <DateTimeControl
                    ref={(c) => (this.input.birthday = c)}
                    name='birthday'
                    label='出生日期'
                    timeFormat={false}
                    value={this.state.birthday}
                    initialViewDate={new Date(new Date().getUTCFullYear() - 30, 5, 1)}
                    onChange={(e) => {
                      this.setState({ birthday: e.valueOf() ? new Date(e.valueOf()) : null })
                    }}
                    hasError={this.props.hasError.birthday ||
                      ((this.state.birthday ? this.state.birthday.toLocaleDateString() : '') !== (this.state.birthdayConfirm ? this.state.birthdayConfirm.toLocaleDateString() : ''))}
                    help={this.props.help.birthday}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.cellphone = c)}
                    name='cellphone'
                    label='手機'
                    value={this.state.cellphone}
                    onChange={(e) => (this.setState({ cellphone: e.target.value }))}
                    hasError={this.props.hasError.cellphone}
                    help={this.props.help.cellphone}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.email = c)}
                    name='email'
                    label='email'
                    value={this.state.email}
                    onChange={(e) => (this.setState({ email: e.target.value }))}
                    hasError={this.props.hasError.email}
                    help={this.props.help.email}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.identityStatus = c)}
                    name='identityStatus'
                    label='身份狀況'
                    value={this.state.identityStatus}
                    onChange={(e) => (this.setState({ identityStatus: e.target.value }))}
                    hasError={this.props.hasError.identityStatus}
                    help={this.props.help.identityStatus}
                    disabled={this.props.loading}
                  >
                    <option value='屆退'>
                      屆退
                    </option>
                    <option value='研二生'>
                      研二生
                    </option>
                    <option value='其他'>
                      其他
                    </option>
                  </SelectControl>

                  {this.state.identityStatus === '其他' &&
                    <TextControl
                      ref={(c) => (this.input.identityStatusOther = c)}
                      name='identityStatusOther'
                      label='請說明身份狀況？'
                      value={this.state.identityStatusOther}
                      onChange={(e) => (this.setState({ identityStatusOther: e.target.value }))}
                      hasError={this.props.hasError.identityStatusOther}
                      help={this.props.help.identityStatusOther}
                      disabled={this.props.loading}
                    />}
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>應徵資訊</h3>
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.applyPosition = c)}
                    name='applyPosition'
                    label='應徵職位'
                    value={this.state.applyPosition}
                    disabled
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>大學資訊</h3>
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.university = c)}
                    name='university'
                    label='名稱'
                    value={this.state.university}
                    onChange={(e) => (this.setState({ university: e.target.value }))}
                    hasError={this.props.hasError.university}
                    help={this.props.help.university}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='5'>
                  <TextControl
                    ref={(c) => (this.input.universityDepartment = c)}
                    name='universityDepartment'
                    label='科系'
                    value={this.state.universityDepartment}
                    onChange={(e) => (this.setState({ universityDepartment: e.target.value }))}
                    hasError={this.props.hasError.universityDepartment}
                    help={this.props.help.universityDepartment}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.universityPart = c)}
                    name='universityPart'
                    label='日夜間部'
                    value={this.state.universityPart}
                    onChange={(e) => (this.setState({ universityPart: e.target.value }))}
                    hasError={this.props.hasError.universityPart}
                    help={this.props.help.universityPart}
                    disabled={this.props.loading}
                  >
                    <option value='日'>
                      日間部
                    </option>
                    <option value='夜'>
                      夜間部
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.universityStartYear = c)}
                    name='universityStartYear'
                    label='起年'
                    value={this.state.universityStartYear}
                    onChange={(e) => (this.setState({ universityStartYear: e.target.value }))}
                    hasError={this.props.hasError.universityStartYear}
                    help={this.props.help.universityStartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.universityStartMonth = c)}
                    name='universityStartMonth'
                    label='起月'
                    value={this.state.universityStartMonth}
                    onChange={(e) => (this.setState({ universityStartMonth: e.target.value }))}
                    hasError={this.props.hasError.universityStartMonth}
                    help={this.props.help.universityStartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.universityEndYear = c)}
                    name='universityEndYear'
                    label='訖年'
                    value={this.state.universityEndYear}
                    onChange={(e) => (this.setState({ universityEndYear: e.target.value }))}
                    hasError={this.props.hasError.universityEndYear}
                    help={this.props.help.universityEndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.universityEndMonth = c)}
                    name='universityEndMonth'
                    label='訖月'
                    value={this.state.universityEndMonth}
                    onChange={(e) => (this.setState({ universityEndMonth: e.target.value }))}
                    hasError={this.props.hasError.universityEndMonth}
                    help={this.props.help.universityEndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>研究所資訊</h3>
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchool = c)}
                    name='graduateSchool'
                    label='名稱'
                    value={this.state.graduateSchool}
                    onChange={(e) => (this.setState({ graduateSchool: e.target.value }))}
                    hasError={this.props.hasError.graduateSchool}
                    help={this.props.help.graduateSchool}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='5'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchoolDepartment = c)}
                    name='graduateSchoolDepartment'
                    label='科系'
                    value={this.state.graduateSchoolDepartment}
                    onChange={(e) => (this.setState({ graduateSchoolDepartment: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolDepartment}
                    help={this.props.help.graduateSchoolDepartment}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.graduateSchoolPart = c)}
                    name='graduateSchoolPart'
                    label='一般生或在職專班'
                    value={this.state.graduateSchoolPart}
                    onChange={(e) => (this.setState({ graduateSchoolPart: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolPart}
                    help={this.props.help.graduateSchoolPart}
                    disabled={this.props.loading}
                  >
                    <option value='一般'>
                      一般生
                    </option>
                    <option value='在職'>
                      在職專班
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchoolStartYear = c)}
                    name='graduateSchoolStartYear'
                    label='起年'
                    value={this.state.graduateSchoolStartYear}
                    onChange={(e) => (this.setState({ graduateSchoolStartYear: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolStartYear}
                    help={this.props.help.graduateSchoolStartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.graduateSchoolStartMonth = c)}
                    name='graduateSchoolStartMonth'
                    label='起月'
                    value={this.state.graduateSchoolStartMonth}
                    onChange={(e) => (this.setState({ graduateSchoolStartMonth: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolStartMonth}
                    help={this.props.help.graduateSchoolStartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.graduateSchoolEndYear = c)}
                    name='graduateSchoolEndYear'
                    label='訖年'
                    value={this.state.graduateSchoolEndYear}
                    onChange={(e) => (this.setState({ graduateSchoolEndYear: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolEndYear}
                    help={this.props.help.graduateSchoolEndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.graduateSchoolEndMonth = c)}
                    name='graduateSchoolEndMonth'
                    label='訖月'
                    value={this.state.graduateSchoolEndMonth}
                    onChange={(e) => (this.setState({ graduateSchoolEndMonth: e.target.value }))}
                    hasError={this.props.hasError.graduateSchoolEndMonth}
                    help={this.props.help.graduateSchoolEndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>英文能力</h3>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.englishReading = c)}
                    name='englishReading'
                    label='讀'
                    value={this.state.englishReading}
                    onChange={(e) => (this.setState({ englishReading: e.target.value }))}
                    hasError={this.props.hasError.englishReading}
                    help={this.props.help.englishReading}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.englishSpeaking = c)}
                    name='englishSpeaking'
                    label='說'
                    value={this.state.englishSpeaking}
                    onChange={(e) => (this.setState({ englishSpeaking: e.target.value }))}
                    hasError={this.props.hasError.englishSpeaking}
                    help={this.props.help.englishSpeaking}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.englishListening = c)}
                    name='englishListening'
                    label='聽'
                    value={this.state.englishListening}
                    onChange={(e) => (this.setState({ englishListening: e.target.value }))}
                    hasError={this.props.hasError.englishListening}
                    help={this.props.help.englishListening}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.englishWriting = c)}
                    name='englishWriting'
                    label='寫'
                    value={this.state.englishWriting}
                    onChange={(e) => (this.setState({ englishWriting: e.target.value }))}
                    hasError={this.props.hasError.englishWriting}
                    help={this.props.help.englishWriting}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>第二外語能力</h3>
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.foreignLanguage = c)}
                    name='foreignLanguage'
                    label='名稱'
                    value={this.state.foreignLanguage}
                    onChange={(e) => (this.setState({ foreignLanguage: e.target.value }))}
                    hasError={this.props.hasError.foreignLanguage}
                    help={this.props.help.foreignLanguage}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.foreignReading = c)}
                    name='foreignReading'
                    label='讀'
                    value={this.state.foreignReading}
                    onChange={(e) => (this.setState({ foreignReading: e.target.value }))}
                    hasError={this.props.hasError.foreignReading}
                    help={this.props.help.foreignReading}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.foreignSpeaking = c)}
                    name='foreignSpeaking'
                    label='說'
                    value={this.state.foreignSpeaking}
                    onChange={(e) => (this.setState({ foreignSpeaking: e.target.value }))}
                    hasError={this.props.hasError.foreignSpeaking}
                    help={this.props.help.foreignSpeaking}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.foreignListening = c)}
                    name='foreignListening'
                    label='聽'
                    value={this.state.foreignListening}
                    onChange={(e) => (this.setState({ foreignListening: e.target.value }))}
                    hasError={this.props.hasError.foreignListening}
                    help={this.props.help.foreignListening}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.foreignWriting = c)}
                    name='foreignWriting'
                    label='寫'
                    value={this.state.foreignWriting}
                    onChange={(e) => (this.setState({ foreignWriting: e.target.value }))}
                    hasError={this.props.hasError.foreignWriting}
                    help={this.props.help.foreignWriting}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>碩士論文</h3>
                </Col>

                <Col md='10'>
                  <TextControl
                    ref={(c) => (this.input.masterThesisTitle = c)}
                    name='masterThesisTitle'
                    label='論文篇名'
                    value={this.state.masterThesisTitle}
                    onChange={(e) => (this.setState({ masterThesisTitle: e.target.value }))}
                    hasError={this.props.hasError.masterThesisTitle}
                    help={this.props.help.masterThesisTitle}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.masterAdvisor = c)}
                    name='masterAdvisor'
                    label='指導教授'
                    value={this.state.masterAdvisor}
                    onChange={(e) => (this.setState({ masterAdvisor: e.target.value }))}
                    hasError={this.props.hasError.masterAdvisor}
                    help={this.props.help.masterAdvisor}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>博士論文</h3>
                </Col>

                <Col md='10'>
                  <TextControl
                    ref={(c) => (this.input.phdTitle = c)}
                    name='phdTitle'
                    label='論文篇名'
                    value={this.state.phdTitle}
                    onChange={(e) => (this.setState({ phdTitle: e.target.value }))}
                    hasError={this.props.hasError.phdTitle}
                    help={this.props.help.phdTitle}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.doctoralSupervisor = c)}
                    name='doctoralSupervisor'
                    label='指導教授'
                    value={this.state.doctoralSupervisor}
                    onChange={(e) => (this.setState({ doctoralSupervisor: e.target.value }))}
                    hasError={this.props.hasError.doctoralSupervisor}
                    help={this.props.help.doctoralSupervisor}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <CheckboxControl
                    ref={(c) => (this.input.checkConfirm = c)}
                    inputClasses={{ h6: true }}
                    name='checkConfirm'
                    label='填寫人就本表內容已就所知自行揭露，所填資料均為事實，且無洩漏其他公司之營業秘密，本人同意信驊科技公司審查，如有虛假情事，願受解職處分。本資料僅供本公司內部徵才使用，依個人資料保護法之規定，取得或使用求職者履歷時，絕不洩漏、交付或以其他方式使非經合法授權之任何第三人知悉。'
                    checked={this.state.checkConfirm}
                    onChange={(e) => (this.setState({ checkConfirm: !this.state.checkConfirm }))}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e)}
                    inputClasses={{ 'btn-primary': true }}
                    disabled={
                      this.props.loading || !this.state.checkConfirm
                    }
                  >
                    送出
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default BasicInformationPage
