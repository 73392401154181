import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'
import DateTimeControl from '../../components/datetime-control'

class HealthInsurancePage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.imageRef = {}
    this.state = {
      idNumber: this.props.idNumber ? this.props.idNumber : '',
      birthday: this.props.birthday ? this.props.birthday : '',
      hasSubsidyEligible: this.props.hasSubsidyEligible !== null ? this.props.hasSubsidyEligible.toString() : '',
      subsidyItem: this.props.subsidyItem ? this.props.subsidyItem : '',
      hasDependent: this.props.hasDependent ? this.props.hasDependent : [{
        name: '',
        idNumber: '',
        birthday: '',
        title: '',
        hasSubsidyEligible: '',
        subsidyItem: ''
      }]
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 1, isTemporary) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      idNumber: this.state.idNumber,
      birthday: this.state.birthday ? this.state.birthday.getTime() : '',
      hasSubsidyEligible: this.state.hasSubsidyEligible,
      subsidyItem: this.state.subsidyItem,
      hasDependent: this.state.hasDependent ? JSON.stringify(this.state.hasDependent) : '',
      isComplete: this.state.idNumber !== '' && this.state.birthday !== '' && this.state.hasSubsidyEligible !== ''
    }, 1, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>1 / 6</p>
                <Progress value='30' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>本人資料</h3>
                </Col>
                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.idNumber = c)}
                    name='idNumber'
                    label='身分證字號'
                    value={this.state.idNumber}
                    onChange={(e) => (this.setState({ idNumber: e.target.value }))}
                    hasError={this.props.hasError.idNumber}
                    help={this.props.help.idNumber}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='4'>
                  <DateTimeControl
                    ref={(c) => (this.input.birthday = c)}
                    name='birthday'
                    label='出生日期'
                    timeFormat={false}
                    value={this.state.birthday}
                    onChange={(e) => {
                      this.setState({ birthday: e.valueOf() ? new Date(e.valueOf()) : null })
                    }}
                    hasError={this.props.hasError.birthday}
                    help={this.props.help.birthday}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.hasSubsidyEligible = c)}
                    name='hasSubsidyEligible'
                    label='是否符合健保補助對象'
                    value={this.state.hasSubsidyEligible}
                    onChange={(e) => (this.setState({ hasSubsidyEligible: e.target.value }))}
                    hasError={this.props.hasError.hasSubsidyEligible}
                    help={this.props.help.hasSubsidyEligible}
                    disabled={this.props.loading}
                  >
                    <option value='true'>
                      是
                    </option>
                    <option value='false'>
                      否
                    </option>
                  </SelectControl>
                </Col>

                {this.state.hasSubsidyEligible === 'true' &&
                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.subsidyItem = c)}
                      name='subsidyItem'
                      label='補助項目'
                      value={this.state.subsidyItem}
                      onChange={(e) => (this.setState({ subsidyItem: e.target.value }))}
                      hasError={this.props.hasError.subsidyItem}
                      help={this.props.help.subsidyItem}
                      disabled={this.props.loading}
                    />
                  </Col>}
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row>
                <Col md='12'>
                  <h3 className='text-center mt-4'>投保眷屬資料</h3>
                </Col>

                {this.state.hasDependent.length < 6 &&
                  <Col md='12'>
                    <Button
                      type='button'
                      inputClasses={{ 'btn-primary': true }}
                      onClick={(e) => (
                        this.setState({
                          hasDependent: [...this.state.hasDependent, {
                            name: '',
                            idNumber: '',
                            birthday: '',
                            title: '',
                            subsidyItem: ''
                          }]
                        })
                      )}
                      disabled={this.props.loading}
                    >
                      新增一筆
                    </Button>
                  </Col>}

                <Col md='12'>
                  {this.state.hasDependent.map((item, i) => (
                    <Card body className='mt-4' key={item}>
                      <Row>
                        <Button
                          type='button'
                          inputClasses={{ close: true }}
                          onClick={(e) => (
                            this.setState({
                              hasDependent: this.state.hasDependent.filter((_item, j) => i !== j)
                            })
                          )}
                          disabled={this.props.loading}
                        >
                          ✕
                        </Button>
                        <Col md='3'>
                          <TextControl
                            name='name'
                            label='姓名'
                            value={this.state.hasDependent[i].name}
                            onChange={(e) => (
                              this.setState({ hasDependent: this.state.hasDependent.map((item, _i) => _i === i ? { ...item, name: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <TextControl
                            name='idNumber'
                            label='身分證字號'
                            value={this.state.hasDependent[i].idNumber}
                            onChange={(e) => (
                              this.setState({ hasDependent: this.state.hasDependent.map((item, _i) => _i === i ? { ...item, idNumber: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <DateTimeControl
                            name='birthday'
                            label='出生日期'
                            timeFormat={false}
                            value={this.state.hasDependent[i].birthday}
                            onChange={(e) => (
                              this.setState({ hasDependent: this.state.hasDependent.map((item, _i) => _i === i ? { ...item, birthday: e.valueOf() ? new Date(e.valueOf()) : null } : item) })
                            )}
                            disabled={this.props.loading}
                          />
                        </Col>
                        <Col md='3'>
                          <SelectControl
                            name='title'
                            label='稱謂'
                            value={this.state.hasDependent[i].title}
                            onChange={(e) => (
                              this.setState({ hasDependent: this.state.hasDependent.map((item, _i) => _i === i ? { ...item, title: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          >
                            <option value='配偶'>
                              配偶
                            </option>
                            <option value='父母'>
                              父母
                            </option>
                            <option value='子女'>
                              子女
                            </option>
                            <option value='祖父母'>
                              祖父母
                            </option>
                            <option value='孫子女'>
                              孫子女
                            </option>
                            <option value='外祖父母'>
                              外祖父母
                            </option>
                            <option value='外孫子女'>
                              外孫子女
                            </option>
                            <option value='曾祖父母'>
                              曾祖父母
                            </option>
                            <option value='外曾祖父母'>
                              外曾祖父母
                            </option>
                          </SelectControl>
                        </Col>
                        <Col md='4'>
                          <SelectControl
                            name='hasSubsidyEligible'
                            label='是否符合健保補助對象'
                            value={this.state.hasDependent[i].hasSubsidyEligible}
                            onChange={(e) => (
                              this.setState({ hasDependent: this.state.hasDependent.map((item, _i) => _i === i ? { ...item, hasSubsidyEligible: e.target.value } : item) })
                            )}
                            disabled={this.props.loading}
                          >
                            <option value='true'>
                              是
                            </option>
                            <option value='false'>
                              否
                            </option>
                          </SelectControl>
                        </Col>
                        {this.state.hasDependent[i].hasSubsidyEligible === 'true' &&
                          <Col md='8'>
                            <TextControl
                              name='subsidyItem'
                              label='補助項目'
                              value={this.state.hasDependent[i].subsidyItem}
                              onChange={(e) => (
                                this.setState({ hasDependent: this.state.hasDependent.map((item, _i) => _i === i ? { ...item, subsidyItem: e.target.value } : item) })
                              )}
                              disabled={this.props.loading}
                            />
                          </Col>}
                      </Row>
                    </Card>)
                  )}
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 1, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 2, false)}
                    disabled={this.props.loading}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default HealthInsurancePage
