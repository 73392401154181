import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import CheckboxControl from '../../components/checkbox-control'

class EmployeeConsentPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      name: this.props.name ? this.props.name : '', // 立同意書人
      idNumber: this.props.idNumber ? this.props.idNumber : '', // 身分證字號
      residenceAddress: this.props.residenceAddress ? this.props.residenceAddress : '', // 戶籍地址
      isComplete: this.props.isComplete
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 3, isTemporary) {
    event.preventDefault()
    event.stopPropagation()

    this.props.setPersonnel({
      name: this.state.name,
      idNumber: this.state.idNumber,
      residenceAddress: this.state.residenceAddress,
      isComplete: this.state.name && this.state.idNumber && this.state.residenceAddress && true
    }, 3, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>3 / 6</p>
                <Progress value='70' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>

            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12' className='mb-5'>
                  <p>本人在信驊科技股份有限公司（以下簡稱公司）任職期間應恪遵公司規章，服從主管之工作調派，並同意以下事項：</p>
                  <p>一、本人對於公司之所有資訊應負保密之責，因工作所獲致之公司資訊，不論技術、產品、營運、管理等項目，且不論獲得之途徑為有意或無意、主動或被動；未經呈報核准，不得向任何人（包括自然人與法人）透露，其管道包括但不限於口頭、電訊、複印、電腦、磁片（碟）、網路等，方式不論為明示或暗示，時間不論為在職期間或離職之後，動機不論為有意或無心。</p>
                  <p>二、本人在公司營運範圍內，或利用公司設備所產生之創作、孕育之任何發明、構想、設計物、電腦程式、及與之有關之文件，其權利、利益均屬信驊所有；其為著作者以信驊為著作人。同時，本人應協助相關部門申請專利權、著作權或其他智慧財產權，並應及時將有關資料提交相關部門。</p>
                  <p>三、本人不透露給公司或造成公司使用非公司之機密；且不使用非法軟體或程式。</p>
                  <p>四、本人不利用職權或職務圖謀私人利益，如有違反以致造成公司財務損失，本人應負賠償責任。</p>
                  <p>五、本人於受僱公司期間，非經公司事前書面同意，不得以自己或他人名義經營與公司業務相關或有競爭關係之事業，或投資前述事業達該事業資本額或已發行股份總數百分之一以上。</p>
                  <p>六、本人於受僱公司期間及離職後一定年限（八職等以下一年、九職等以上兩年）內，非經公信驊科技股份有限公司 ASPEED Technology Inc. 員工同意書司事前書面同意，不得為與公司業務有競爭關係之事業、商號或個人之受僱人、受任人、承擔人或顧問。</p>
                  <p>七、本人若有違反上述事項，願無條件接受最重為解職之處分，如因而造成公司之損失，應負損害賠償之責，如再因而侵犯公司之專利權、著作權等智慧財產權者，並應負相關法律責任。</p>
                  <p>八、本人同意公司得依營運或任務需要，在不降低勞動條件之前提下，調整本人之職務、工作內容或地點。</p>
                  <p>九、本人同意公司每月發薪一次。</p>
                  <p>十、本人為確立上述事項已知悉並承諾切實遵守，特立此同意書為憑。</p>
                  <p>十一、本同意書之解釋、效力與效果悉依中華民國之法律規定辦理之。</p>
                  <p className='ml-5'>此 致</p>
                  <p className='ml-2'>信 驊 科 技 股 份 有 限 公 司</p>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.name = c)}
                    name='name'
                    label='立同意書人'
                    value={this.state.name}
                    onChange={(e) => (this.setState({ name: e.target.value }))}
                    hasError={this.props.hasError.name}
                    help={this.props.help.name}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.idNumber = c)}
                    name='idNumber'
                    label='身份證字號'
                    value={this.state.idNumber}
                    onChange={(e) => (this.setState({ idNumber: e.target.value }))}
                    hasError={this.props.hasError.idNumber}
                    help={this.props.help.idNumber}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.residenceAddress = c)}
                    name='residenceAddress'
                    label='戶籍地址'
                    value={this.state.residenceAddress}
                    onChange={(e) => (this.setState({ residenceAddress: e.target.value }))}
                    hasError={this.props.hasError.residenceAddress}
                    help={this.props.help.residenceAddress}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <CheckboxControl
                    ref={(c) => (this.input.isComplete = c)}
                    inputClasses={{ h6: true }}
                    name='isComplete'
                    label='同意上述規定'
                    checked={this.state.isComplete}
                    onChange={(e) => (this.setState({ isComplete: !this.state.isComplete }))}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 3, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 2, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='submit'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 4, false)}
                    disabled={this.props.loading || !this.state.isComplete}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EmployeeConsentPage
