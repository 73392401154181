import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import TextControlFillet from '../../components/text-control-fillet'
import CheckboxControl from '../../components/checkbox-control'

class EmploymentConfirmationPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      name: this.props.name ? this.props.name : '', // 立同意書人
      idNumber: this.props.idNumber ? this.props.idNumber : '', // 身分證字號
      residenceAddress: this.props.residenceAddress ? this.props.residenceAddress : '', // 戶籍地址
      isComplete: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 1, isTemporary) {
    event.preventDefault()
    event.stopPropagation()

    this.props.setPersonnel({
      name: this.state.name,
      idNumber: this.state.idNumber,
      residenceAddress: this.state.residenceAddress,
      isComplete1: 'true'
    }, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>1 / 2</p>
                <Progress value='50' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>

            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12' className='mb-5'>
                  本人
                  <TextControlFillet
                    ref={(c) => (this.input.name = c)}
                    groupClasses={{ 'form-group-inline ml-1 mr-1': true }}
                    name='name'
                    label=''
                    value={this.state.name}
                    onChange={(e) => (this.setState({ name: e.target.value }))}
                    hasError={this.props.hasError.name}
                    help={this.props.help.name}
                    disabled={this.props.loading}
                  />
                  同意至信驊科技股份有限公司任職，報到日期以聘僱通知書所載明之日期為基準，若到職日需變更，應經本公司同意，始成效之。
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.name = c)}
                    name='name'
                    label='立同意書人'
                    value={this.state.name}
                    onChange={(e) => (this.setState({ name: e.target.value }))}
                    hasError={this.props.hasError.name}
                    help={this.props.help.name}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.idNumber = c)}
                    name='idNumber'
                    label='身份證字號'
                    value={this.state.idNumber}
                    onChange={(e) => (this.setState({ idNumber: e.target.value }))}
                    hasError={this.props.hasError.idNumber}
                    help={this.props.help.idNumber}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.residenceAddress = c)}
                    name='residenceAddress'
                    label='戶籍地址'
                    value={this.state.residenceAddress}
                    onChange={(e) => (this.setState({ residenceAddress: e.target.value }))}
                    hasError={this.props.hasError.residenceAddress}
                    help={this.props.help.residenceAddress}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='12'>
                  <CheckboxControl
                    ref={(c) => (this.input.isComplete = c)}
                    inputClasses={{ h6: true }}
                    name='isComplete'
                    label='同意上述規定'
                    checked={this.state.isComplete}
                    onChange={(e) => (this.setState({ isComplete: !this.state.isComplete }))}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 1, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 1, false)}
                    disabled={this.props.loading || !this.state.isComplete}
                  >
                    提交
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default EmploymentConfirmationPage
