import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { put, get } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'

class EditAppPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      name: '',
      callback: ''
    }
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  fetchData () {
    get(`/1/admin/app/${this.props.match.params.id}`)
      .then(r => {
        this.setState({
          name: r.data.name,
          callback: r.data.callback,
          isActive: r.data.isActive.toString()
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/app/${this.props.match.params.id}`, {
      name: this.input.name.value(),
      callback: this.input.callback.value(),
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/apps' />)
    } else if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>{this.state.name} 應用程式</title>
        </Helmet>

        <div className='row'>
          <div className='col-md-12'>
            <section>
              <h1 className='page-header'>編輯應用程式 {this.state.name}</h1>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                <TextControl
                  ref={(c) => (this.input.name = c)}
                  name='name'
                  label='名稱'
                  value={this.state.name}
                  onChange={(e) => (this.setState({ name: e.target.value }))}
                  hasError={this.state.hasError.name}
                  help={this.state.help.name}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.callback = c)}
                  name='callback'
                  label='返回網址'
                  value={this.state.callback}
                  onChange={(e) => (this.setState({ callback: e.target.value }))}
                  hasError={this.state.hasError.callback}
                  help={this.state.help.callback}
                  disabled={this.state.loading}
                />
                <SelectControl
                  ref={(c) => (this.input.isActive = c)}
                  name='isActive'
                  label='是否啟用'
                  value={this.state.isActive}
                  onChange={(e) => (this.setState({ isActive: e.target.value }))}
                  hasError={this.state.hasError.isActive}
                  help={this.state.help.isActive}
                  disabled={this.state.loading}
                >
                  <option value='true'>
                    啟用
                  </option>
                  <option value='false'>
                    關閉
                  </option>
                </SelectControl>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-success': true }}
                    disabled={this.state.loading}
                  >
                    更新
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </ControlGroup>
              </form>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

export default EditAppPage
