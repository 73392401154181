import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'

class CreateAppPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/app', {
      name: this.input.name.value(),
      callback: this.input.callback.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/apps' />)
    } else if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>建立應用程式</title>
        </Helmet>

        <h1 className='page-header'>建立應用程式</h1>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <TextControl
                ref={(c) => (this.input.name = c)}
                name='name'
                label='名稱'
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />
              <TextControl
                ref={(c) => (this.input.callback = c)}
                name='callback'
                label='返回網址'
                hasError={this.state.hasError.callback}
                help={this.state.help.callback}
                disabled={this.state.loading}
              />
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-success': true }}
                  disabled={this.state.loading}
                >
                  建立
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateAppPage
