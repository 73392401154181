import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ReactTable from 'react-table'
import moment from 'moment'
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap'
import { get, post } from '../utils/httpAgent'
import TextControl from '../components/text-control'
import Alert from '../shared/alert'
import config from '../config'

class PersonnelsPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      data: [],
      page: 0,
      show: false
    }
    this.handleFetchData = this.handleFetchData.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.handleSendPersonnel = this.handleSendPersonnel.bind(this)
  }

  handleFetchData (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/account/personnels?page=${page}&limit=${limit}&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  handleToggle () {
    this.setState({
      show: !this.state.show,
      error: ''
    })
  }

  handleSendPersonnel (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/account/personnel', {
      email: this.input.email.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false,
            show: false
          })
          this.handleFetchData()
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    const { t } = this.props
    let alert = []
    const columns = [
      {
        Header: t('chinese_name'),
        accessor: 'chName'
      }, {
        Header: 'email',
        accessor: 'email'
      }, {
        Header: t('permanent_phone'),
        accessor: 'permanentNumber'
      }, {
        Header: t('apply_job'),
        accessor: 'applyPosition'
      // }, {
      //   Header: t('gender'),
      //   accessor: 'gender',
      //   width: 50
      }, {
        Header: t('status'),
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF'
                : row.value === false ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? t('undone')
              : row.value === false ? t('completed')
                : t('abnormal')}
          </span>
        )
      }, {
        Header: t('update_date'),
        accessor: 'updateTime',
        width: 100
      }, {
        Header: t('create_date'),
        accessor: 'createTime',
        width: 100
      }]

    if (this.state.error) {
      alert =
        <Col md={12}>
          <Alert
            type='danger'
            message={this.state.error}
          />
        </Col>
    }

    return (
      <Container>
        <Helmet>
          <title>
            {t('resume_management')}
          </title>
        </Helmet>

        <Modal
          isOpen={this.state.show}
          toggle={this.handleToggle}>
          <ModalHeader toggle={this.handleToggle}>
            {t('send_personnel')}
          </ModalHeader>
          <ModalBody>
            <Row>
              {alert}
              <Col md={12}>
                <TextControl
                  ref={(c) => (this.input.email = c)}
                  name='email'
                  label='email'
                  placeholder={t('applicant_email')}
                  hasError={this.state.hasError.email}
                  help={this.state.help.email}
                  disabled={this.state.loading}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.handleSendPersonnel}>{t('send_out')}</Button>
            <Button color='secondary' className='ml-1' onClick={this.handleToggle}>{t('cancel')}</Button>
          </ModalFooter>
        </Modal>

        <h1 className='page-header'>{t('resume_management')}</h1>
        <div className='row'>
          <div className='col-md-12'>
            <Button color='success' onClick={e => { this.setState({ show: true }) }}>{t('send_personnel')}</Button>
            <br /><br />
          </div>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchData}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    { row.original.photo &&
                      <img alt='Crop' style={{ maxWidth: '100%' }} src={`${config.awsUrl}${row.original.photo}`} />
                    }
                  </div>
                </div>
              )}
              previousText={t('previous_page')}
              nextText={t('next_page')}
              pageText={t('page')}
              ofText='/'
              rowsText={t('rows')}
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'updateTime', desc: true }]}
            />
          </div>
        </div>
      </Container>
    )
  }
}

export default PersonnelsPage
