import ClassNames from 'classnames'
import ControlGroup from './control-group'
import ObjectAssign from 'object-assign'
import PropTypes from 'prop-types'
import React from 'react'
import Datetime from 'react-datetime'
import 'moment/locale/zh-tw'

const propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  help: PropTypes.string,
  inputClasses: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date)
  ]),
  initialViewDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date)
  ])
}
const defaultProps = {
  type: 'text'
}

class DataTimeControl extends React.Component {
  focus () {
    return this.input.focus()
  }

  value () {
    return this.input.value
  }

  render () {
    const inputClasses = ClassNames(ObjectAssign({
      'form-control': true
    }, this.props.inputClasses))

    return (
      <ControlGroup groupClasses={this.props.groupClasses} hasError={this.props.hasError} label={this.props.label} help={this.props.help}>
        <Datetime
          dateFormat='YYYY-MM-DD'
          timeFormat={this.props.timeFormat !== undefined ? this.props.timeFormat : 'HH:mm'}
          locale='zh-tw'
          name={this.props.name}
          inputProps={{
            ref: (c) => (this.input = c),
            placeholder: this.props.placeholder,
            disabled: this.props.disabled ? 'disabled' : undefined,
            className: inputClasses
          }}
          onChange={this.props.onChange}
          value={this.props.value}
          initialViewDate={this.props.initialViewDate}
        />
      </ControlGroup>
    )
  }
}

DataTimeControl.propTypes = propTypes
DataTimeControl.defaultProps = defaultProps

export default DataTimeControl
