import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import ReactTable from 'react-table'
import moment from 'moment'
import TextControl from '../components/text-control-fillet'
import Button from '../components/button'
import Spinner from '../components/spinner'
import { get, deleteItem } from '../utils/httpAgent'
import 'react-table/react-table.css'

class UsersPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      pages: null,
      search: '',
      pageSize: 10,
      page: 0
    }
    this.handleFetchData = this.handleFetchData.bind(this)
  }

  search () {
    this.handleFetchData({
      page: 0,
      pageSize: this.state.pageSize,
      search: this.state.search
    })
  }

  handleFetchData (state) {
    const page = state ? state.page + 1 : 1
    const limit = state ? state.pageSize : 10
    const search = this.state.search ? this.state.search : ''
    let sorted = state.sorted ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/users?page=${page}&limit=${limit}&sort=${sorted}&search=${search}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.timeUpdated = item.timeUpdated ? moment(item.timeUpdated).format('YYYY/MM/DD') : ''
            item.timeCreated = moment(item.timeCreated).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteUser (uid, username) {
    if (!window.confirm(`是否確定要刪除使用者 ${username}？`)) {
      return -1
    }

    deleteItem('/1/admin/users/' + uid)
      .then(r => {
        if (r.success === true) {
          this.handleFetchData({
            page: 0,
            pageSize: this.state.pageSize,
            search: this.state.search
          })
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '操作',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/user/${row.value}/${row.original.roles.account._id}/${row.original.username}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '帳號',
        accessor: 'username',
        width: 100,
        Cell: row => (
          <span>
            {row.original.hasUpdated ? <i className='lnr lnr-warning mr-1 text-warning' /> : ''}
            {row.value}
          </span>
        )
      }, {
        Header: '姓名',
        accessor: 'roles',
        width: 100,
        Cell: row => {
          const isNotExpired = !row.original.roles.admin && (row.original.timeActive === null || new Date(row.original.timeActive) <= new Date().getTime())
          let expiredText = ' (待重啟)'
          if (isNotExpired && row.original.timeActive && new Date(row.original.timeActive) <= new Date().getTime()) {
            expiredText = ` (${moment(new Date(row.original.timeActive)).fromNow()}已過期)`
          }
          return <span style={{ color: isNotExpired ? '#FF0000' : '#000000' }}>{row.value.account.name.full}{isNotExpired && expiredText}</span>
        }
      }, {
        Header: '公司',
        accessor: 'roles',
        width: 100,
        Cell: row => (
          <span>{row.value.account.company}</span>
        )
      }, {
        Header: 'email',
        accessor: 'email',
        width: 200
      }, {
        Header: '電話',
        accessor: 'roles',
        width: 100,
        Cell: row => (
          <span>{row.value.account.phone}</span>
        )
      }, {
        Header: '平台角色',
        accessor: 'userRole',
        width: 100,
        Cell: row => (
          <span>
            {{
              client: '客戶',
              hunter: '獵人頭',
              cupola360ota: 'Cupola360 OTA'
            }[row.value] || '未認證'}
          </span>
        )
      }, {
        Header: '權限',
        accessor: 'adminRole',
        width: 60,
        Cell: row => (
          <span>
            {{
              root: '系統管理者',
              'root-hr': '人力資源',
              'root-info': '公開資訊',
              'root-sales': '業務'
            }[row.value] || '一般'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'timeUpdated'
      }, {
        Header: '信箱認證',
        accessor: 'isActive',
        width: 100,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === 'yes' ? '#0000FF' : row.value === 'no' ? '#FF0000' : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>
            &nbsp;
            {row.value === 'yes' ? '已認證' : row.value === 'no' ? '未認證' : '異常狀態'}
          </span>
        )
      }, {
        Header: '申請日期',
        accessor: 'timeCreated'
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteUser.bind(this, row.value, row.original.username)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            用戶管理
          </title>
        </Helmet>
        <h1 className='page-header'>用戶管理</h1>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Link to='/app/admin/signup' className='btn btn-success mb-1'>
              建立用戶
            </Link>
            <TextControl
              ref={(c) => (this.input.search = c)}
              groupClasses={{ 'form-group-inline max-width-125 ml-5': true }}
              name='search'
              value={this.state.search}
              placeholder='帳號、email'
              onChange={(e) => {
                this.setState({ search: e.target.value })
                this.search.bind(this)
              }}
              disabled={this.state.loading}
            />
            &nbsp;
            <Button
              type='button'
              inputClasses={{ 'btn btn-primary ml-1 mb-1': true }}
              disabled={this.state.loading}
              onClick={this.search.bind(this)}
            >
              搜尋
              <Spinner space='left' show={this.state.loading} />
            </Button>
          </Col>
          <Col md={12} lg={12} xl={12}>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchData}
              columns={columns}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={this.state.pageSize}
              onPageSizeChange={(pageSize) => (this.setState({ pageSize: pageSize }))}
            />
          </Col>
        </Row>
      </section>
    )
  }
}

export default UsersPage
