import React, { Component } from 'react'
import { get, put } from '../utils/httpAgent'
import { Helmet } from 'react-helmet'
import DetailsForm from './details-form'
import UserForm from './user-form'
import PasswordForm from './password-form'
import CheckboxControl from '../components/checkbox-control'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'

class UserSettingsPage extends Component {
  constructor () {
    super()
    this.input = {
      product_categories: {},
      products: {}
    }
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      product_categories: [],
      application: {
        productCategory: [],
        product: []
      },
      promise: {
        productCategory: [],
        product: []
      }
    }
    this.handleSetPromise = this.handleSetPromise.bind(this)
  }

  componentDidMount () {
    this.fetchProductData()
    this.fetchData()
  }

  fetchProductData () {
    get('/1/account/product_categories')
      .then(r => {
        this.setState({
          product_categories: r.data
        })
      })
  }

  fetchData () {
    get(`/1/admin/user/${this.props.match.params.uid}`)
      .then(r => {
        this.setState({
          application: r.data.application,
          promise: r.data.promise
        })
      })
  }

  handleSetPromise (isProduct, pid) {
    if (isProduct) {
      let product = this.state.promise.product
      if (!product.includes(pid)) {
        product.push(pid)
      } else {
        product = product.filter(pd => pd !== pid)
      }
      this.setState({
        promise: {
          ...this.state.promise,
          product: product
        }
      })
    } else {
      let productCategory = this.state.promise.productCategory
      if (!productCategory.includes(pid)) {
        productCategory.push(pid)
      } else {
        productCategory = productCategory.filter(pc => pc !== pid)
      }
      this.setState({
        promise: {
          ...this.state.promise,
          productCategory: productCategory
        }
      })
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/user/${this.props.match.params.uid}/promise`, {
      productCategories: this.state.promise.productCategory,
      products: this.state.promise.product
    }).then(r => {
      if (r.success === true) {
        this.setState({
          success: true,
          error: '',
          loading: false,
          hasError: {}
        })
      } else {
        let state = {
          success: false,
          error: '',
          loading: false,
          hasError: {},
          help: {}
        }
        for (let key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
        this.setState(state)
      }
    })
  } // end handleSubmit

  render () {
    let productCategories = []

    if (Array.isArray(this.state.product_categories)) {
      this.state.product_categories.forEach(pc => {
        let isCategoryChecked = this.state.promise.productCategory.filter(p => p === pc._id).length > 0
        let isCategoryHighlight = this.state.application.productCategory.filter(p => p === pc._id).length > 0

        productCategories.push(
          <h4 key={`h4-${pc._id}`}>
            <CheckboxControl
              ref={(c) => (this.input[pc._id] = c)}
              name={pc._id}
              key={pc._id}
              label={pc.name}
              value={pc._id}
              checked={isCategoryChecked}
              highlight={isCategoryHighlight}
              onChange={(e) => (this.handleSetPromise(false, e.target.value))}
              disabled={this.state.loading}
            />
          </h4>)

        productCategories.push(<hr className='min-height' key={`hr-${pc._id}`} />)

        if (Array.isArray(pc.product)) {
          pc.product.forEach(pd => {
            let isProductChecked = this.state.promise.product.filter(p => p === pd._id).length > 0
            let isProductHighlight = this.state.application.product.filter(p => p === pd._id).length > 0

            if (isCategoryChecked && isProductChecked) {
              this.handleSetPromise(true, pd._id)
            }
            productCategories.push(
              <CheckboxControl
                ref={(c) => (this.input[pd._id] = c)}
                name={pd._id}
                key={pd._id}
                label={pd.name}
                value={pd._id}
                checked={isProductChecked}
                highlight={isProductHighlight}
                onChange={(e) => (this.handleSetPromise(true, e.target.value))}
                disabled={this.state.loading}
              />)
          })
        }
      }) // End Product Categories for
    }

    return (
      <section className='container'>
        <Helmet>
          <title>{this.props.match.params.username} 帳號設定</title>
        </Helmet>
        <h1 className='page-header'>{this.props.match.params.username} 帳號設定</h1>
        <div className='row'>
          <div className='col-sm-6'>
            <UserForm uid={this.props.match.params.uid} />
            <DetailsForm aid={this.props.match.params.aid} />
            <PasswordForm uid={this.props.match.params.uid} />
          </div>
          <div className='col-sm-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <legend>技術文件審核</legend>
              <p>(藍字選項為使用者欲申請之項目)</p>
              <br />
              {productCategories}
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-primary': true }}
                  disabled={this.state.loading}>
                  提交
                  <Spinner
                    space='left'
                    show={this.state.loading}
                  />
                </Button>
              </ControlGroup>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default UserSettingsPage
