import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import ReactCrop from 'react-image-crop'
import { postFormData } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'
import DateTimeControl from '../../components/datetime-control'
import config from '../../config'

class BasicInformationPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.imageRef = {}
    this.state = {
      uid: this.props.uid,
      photoSrc: null,
      crop: {
        unit: '%',
        width: 90,
        aspect: 0.78 // 3.5 / 4.5
      },
      photoBlob: null,
      uploadLoading: false,

      photo: this.props.photo,
      chName: this.props.chName,
      enName: this.props.enName,
      applyPosition: this.props.applyPosition, // 應徵職位

      idNumber: this.props.idNumber, // 身分證字號
      idNumberConfirm: this.props.idNumber, // 身分證字號

      marital: [null, '', '已婚', '未婚'].includes(this.props.marital) ? this.props.marital : '其他', // 婚姻狀況
      maritalForOther: ![null, '', '已婚', '未婚'].includes(this.props.marital) ? this.props.marital : '',
      // gender: this.props.gender, // 性別，女、男、其他

      birthday: this.props.birthday ? new Date(this.props.birthday) : '', // 出生日期
      birthdayConfirm: this.props.birthday ? new Date(this.props.birthday) : '', // 出生日期

      majorDiseases: this.props.majorDiseases, // 重大疾病 是或否
      majorDiseasesReason: this.props.majorDiseasesReason, // 重大疾病 是的原因

      residenceAddress: this.props.residenceAddress, // 戶籍地址
      mailingAddress: this.props.mailingAddress, // 通訊地址
      daytimeNumber: this.props.daytimeNumber, // 日間電話
      nighttimeNumber: this.props.nighttimeNumber, // 夜間電話
      permanentNumber: this.props.permanentNumber, // 永久電話
      otherContact: this.props.otherContact, // 其他聯絡方式
      veteran: this.props.veteran, // 兵役狀況
      retirementDay: this.props.retirementDay ? new Date(this.props.retirementDay) : '', // 退伍日
      exemptionReason: this.props.exemptionReason, // 免役原因
      expertise: this.props.expertise, // 專長
      interest: this.props.interest, // 興趣

      expectedSalary: this.props.expectedSalary, // 期望待遇
      checkInDay: this.props.checkInDay // 預計可報到日
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onSelectFile = this.onSelectFile.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.onCropChange = this.onCropChange.bind(this)
    this.onCropComplete = this.onCropComplete.bind(this)
    this.handleUploadPhoto = this.handleUploadPhoto.bind(this)
  }

  handleSubmit (event, isTemporary) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      photo: this.state.photo,
      chName: this.state.chName,
      enName: this.state.enName,
      applyPosition: this.state.applyPosition,
      idNumber: this.state.idNumber,
      marital: ['', '已婚', '未婚'].includes(this.state.marital) ? this.state.marital : this.state.maritalForOther,
      // gender: this.state.gender,
      birthday: this.state.birthday,
      majorDiseases: this.state.majorDiseases,
      majorDiseasesReason: this.state.majorDiseases === '是' ? this.state.majorDiseasesReason : '',
      residenceAddress: this.state.residenceAddress,
      mailingAddress: this.state.mailingAddress,
      daytimeNumber: this.state.daytimeNumber,
      nighttimeNumber: this.state.nighttimeNumber,
      permanentNumber: this.state.permanentNumber,
      otherContact: this.state.otherContact,
      veteran: this.state.veteran,
      retirementDay: this.state.veteran === '已役' || this.state.veteran === '服役中' ? this.state.retirementDay : '',
      exemptionReason: this.state.exemptionReason === '未役' ? this.state.exemptionReason : '',
      expertise: this.state.expertise,
      interest: this.state.interest,
      expectedSalary: this.state.expectedSalary,
      checkInDay: this.state.checkInDay
    }, isTemporary ? 1 : 2, isTemporary)
  } // end handleSubmit

  onSelectFile (e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        this.setState({ photoSrc: reader.result })
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // If you setState the crop in here you should return false.
  onImageLoaded (image) {
    this.imageRef = image
  }

  onCropComplete (crop) {
    this.makeClientCrop(crop)
  }

  onCropChange (crop, percentCrop) {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop })
    this.setState({ crop })
  }

  async makeClientCrop (crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg (image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          // reject(new Error('Canvas is empty'))
          console.error('Canvas is empty')
          return
        }
        blob.name = fileName
        window.URL.revokeObjectURL(this.fileUrl)
        this.fileUrl = window.URL.createObjectURL(blob)
        this.setState({
          photoBlob: blob
        })
        resolve(this.fileUrl)
      }, 'image/jpeg')
    })
  }

  handleUploadPhoto () {
    this.setState({
      uploadLoading: true
    })
    postFormData('/1/personnel/storage', {
      uid: this.state.uid,
      file: this.state.photoBlob
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            photo: r.data.uri,
            photoSrc: null,
            uploadLoading: false
          })
          this.input.file.value = null
        } else {
          this.setState({
            uploadLoading: false
          })
        }
      }
    )
  }

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>1 / 7</p>
                <Progress value='13' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>基本資料</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.chName = c)}
                    name='chName'
                    label='中文姓名'
                    value={this.state.chName}
                    onChange={(e) => (this.setState({ chName: e.target.value }))}
                    hasError={this.props.hasError.chName}
                    help={this.props.help.chName}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.enName = c)}
                    name='enName'
                    label='英文姓名'
                    value={this.state.enName}
                    onChange={(e) => (this.setState({ enName: e.target.value }))}
                    hasError={this.props.hasError.enName}
                    help={this.props.help.enName}
                    disabled={this.props.loading}
                  />
                </Col>

                {/* <Col md='4'>
                  <SelectControl
                    ref={(c) => (this.input.gender = c)}
                    name='gender'
                    label='性別'
                    value={this.state.gender}
                    onChange={(e) => (this.setState({ gender: e.target.value }))}
                    hasError={this.props.hasError.gender}
                    help={this.props.help.gender}
                    disabled={this.props.loading}
                  >
                    <option value='男'>
                      男
                    </option>
                    <option value='女'>
                      女
                    </option>
                    <option value='其他'>
                      其他
                    </option>
                  </SelectControl>
                </Col> */}

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.idNumber = c)}
                    name='idNumber'
                    label='身分證字號'
                    type='password'
                    value={this.state.idNumber}
                    onChange={(e) => (this.setState({ idNumber: e.target.value }))}
                    hasError={this.props.hasError.idNumber ||
                      (this.state.idNumber !== this.state.idNumberConfirm)}
                    help={this.props.help.idNumber}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <DateTimeControl
                    ref={(c) => (this.input.birthday = c)}
                    name='birthday'
                    label='出生日期'
                    timeFormat={false}
                    value={this.state.birthday}
                    initialViewDate={new Date(new Date().getUTCFullYear() - 30, 5, 1)}
                    onChange={(e) => {
                      this.setState({ birthday: e.valueOf() ? new Date(e.valueOf()) : null })
                    }}
                    hasError={this.props.hasError.birthday ||
                      ((this.state.birthday ? this.state.birthday.toLocaleDateString() : '') !== (this.state.birthdayConfirm ? this.state.birthdayConfirm.toLocaleDateString() : ''))}
                    help={this.props.help.birthday}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.idNumberConfirm = c)}
                    name='idNumberConfirm'
                    label='再次輸入身分證字號'
                    type='password'
                    value={this.state.idNumberConfirm}
                    onChange={(e) => (this.setState({ idNumberConfirm: e.target.value }))}
                    hasError={this.props.hasError.idNumberConfirm ||
                      (this.state.idNumber !== this.state.idNumberConfirm)}
                    help={this.props.help.idNumberConfirm}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='6'>
                  <DateTimeControl
                    ref={(c) => (this.input.birthdayConfirm = c)}
                    name='birthdayConfirm'
                    label='再次輸入出生日期'
                    timeFormat={false}
                    value={this.state.birthdayConfirm}
                    initialViewDate={new Date(new Date().getUTCFullYear() - 30, 5, 1)}
                    onChange={(e) => {
                      this.setState({ birthdayConfirm: e.valueOf() ? new Date(e.valueOf()) : null })
                    }}
                    hasError={this.props.hasError.birthdayConfirm ||
                      ((this.state.birthday ? this.state.birthday.toLocaleDateString() : '') !== (this.state.birthdayConfirm ? this.state.birthdayConfirm.toLocaleDateString() : ''))}
                    help={this.props.help.birthdayConfirm}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.marital = c)}
                    name='marital'
                    label='婚姻狀況'
                    value={this.state.marital}
                    onChange={(e) => (this.setState({ marital: e.target.value }))}
                    hasError={this.props.hasError.marital}
                    help={this.props.help.marital}
                    disabled={this.props.loading}
                  >
                    <option value='已婚'>
                      已婚
                    </option>
                    <option value='未婚'>
                      未婚
                    </option>
                    <option value='其他'>
                      其他
                    </option>
                  </SelectControl>

                  {this.state.marital === '其他' &&
                    <TextControl
                      ref={(c) => (this.input.maritalForOther = c)}
                      name='maritalForOther'
                      label='請說明婚姻狀況'
                      value={this.state.maritalForOther}
                      onChange={(e) => (this.setState({ maritalForOther: e.target.value }))}
                      hasError={this.props.hasError.maritalForOther}
                      help={this.props.help.maritalForOther}
                      disabled={this.props.loading}
                    />}
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.residenceAddress = c)}
                    name='residenceAddress'
                    label='戶籍地址'
                    value={this.state.residenceAddress}
                    onChange={(e) => (this.setState({ residenceAddress: e.target.value }))}
                    hasError={this.props.hasError.residenceAddress}
                    help={this.props.help.residenceAddress}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.mailingAddress = c)}
                    name='mailingAddress'
                    label='通訊地址'
                    value={this.state.mailingAddress}
                    onChange={(e) => (this.setState({ mailingAddress: e.target.value }))}
                    hasError={this.props.hasError.mailingAddress}
                    help={this.props.help.mailingAddress}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.daytimeNumber = c)}
                    name='daytimeNumber'
                    label='日間電話'
                    value={this.state.daytimeNumber}
                    onChange={(e) => (this.setState({ daytimeNumber: e.target.value }))}
                    hasError={this.props.hasError.daytimeNumber}
                    help={this.props.help.daytimeNumber}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.nighttimeNumber = c)}
                    name='nighttimeNumber'
                    label='夜間電話'
                    value={this.state.nighttimeNumber}
                    onChange={(e) => (this.setState({ nighttimeNumber: e.target.value }))}
                    hasError={this.props.hasError.nighttimeNumber}
                    help={this.props.help.nighttimeNumber}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.permanentNumber = c)}
                    name='permanentNumber'
                    label='永久電話'
                    value={this.state.permanentNumber}
                    onChange={(e) => (this.setState({ permanentNumber: e.target.value }))}
                    hasError={this.props.hasError.permanentNumber}
                    help={this.props.help.permanentNumber}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.otherContact = c)}
                    name='otherContact'
                    label='其他聯絡方式'
                    value={this.state.otherContact}
                    onChange={(e) => (this.setState({ otherContact: e.target.value }))}
                    hasError={this.props.hasError.otherContact}
                    help={this.props.help.otherContact}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.veteran = c)}
                    name='veteran'
                    label='兵役狀況'
                    value={this.state.veteran}
                    onChange={(e) => (this.setState({ veteran: e.target.value }))}
                    hasError={this.props.hasError.veteran}
                    help={this.props.help.veteran}
                    disabled={this.props.loading}
                  >
                    <option value='已役'>
                      已役
                    </option>
                    <option value='服役中'>
                      服役中
                    </option>
                    <option value='免役'>
                      免役
                    </option>
                    <option value='未役'>
                      未役
                    </option>
                  </SelectControl>

                  {this.state.veteran === '免役' &&
                    <TextControl
                      ref={(c) => (this.input.exemptionReason = c)}
                      name='exemptionReason'
                      label='免役原因'
                      value={this.state.exemptionReason}
                      onChange={(e) => (this.setState({ exemptionReason: e.target.value }))}
                      hasError={this.props.hasError.exemptionReason}
                      help={this.props.help.exemptionReason}
                      disabled={this.props.loading}
                    />}

                  {(this.state.veteran === '已役' || this.state.veteran === '服役中') &&
                    <DateTimeControl
                      ref={(c) => (this.input.retirementDay = c)}
                      name='retirementDay'
                      label='退伍日'
                      timeFormat={false}
                      value={this.state.retirementDay}
                      onChange={(e) => {
                        this.setState({ retirementDay: e.valueOf() ? new Date(e.valueOf()) : null })
                      }}
                      hasError={this.props.hasError.retirementDay}
                      help={this.props.help.retirementDay}
                      disabled={this.props.loading}
                    />}
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.interest = c)}
                    name='interest'
                    label='興趣'
                    value={this.state.interest}
                    onChange={(e) => (this.setState({ interest: e.target.value }))}
                    hasError={this.props.hasError.interest}
                    help={this.props.help.interest}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.majorDiseases = c)}
                    name='majorDiseases'
                    label='是否曾發生重大疾病？'
                    value={this.state.majorDiseases}
                    onChange={(e) => (this.setState({ majorDiseases: e.target.value }))}
                    hasError={this.props.hasError.majorDiseases}
                    help={this.props.help.majorDiseases}
                    disabled={this.props.loading}
                  >
                    <option value='是'>
                      是
                    </option>
                    <option value='否'>
                      否
                    </option>
                  </SelectControl>

                  {this.state.majorDiseases === '是' &&
                    <TextControl
                      ref={(c) => (this.input.majorDiseasesReason = c)}
                      name='majorDiseasesReason'
                      label='請說明曾發生重大疾病的原因'
                      value={this.state.majorDiseasesReason}
                      onChange={(e) => (this.setState({ majorDiseasesReason: e.target.value }))}
                      hasError={this.props.hasError.majorDiseasesReason}
                      help={this.props.help.majorDiseasesReason}
                      disabled={this.props.loading}
                    />}
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>個人照片</h3>
                  <input
                    ref={(c) => (this.input.file = c)}
                    type='file'
                    accept='image/*'
                    onChange={this.onSelectFile}
                  />
                  {this.state.photoSrc && (
                    <div className='mt-3'>
                      <ReactCrop
                        src={this.state.photoSrc}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                      />
                      <Col md='12' className='text-center mt-4'>
                        <ControlGroup hideLabel hideHelp>
                          <Button
                            type='button'
                            inputClasses={{ 'btn-success mr-4': true }}
                            onClick={e => this.handleUploadPhoto(e)}
                            disabled={this.state.uploadLoading}
                          >
                            剪裁
                            <Spinner
                              space='left'
                              show={this.state.uploadLoading}
                            />
                          </Button>

                          <Button
                            type='button'
                            inputClasses={{ 'btn btn-light': true }}
                            onClick={e => {
                              this.setState({ photoSrc: null })
                              this.input.file.value = null
                            }}
                            disabled={this.state.uploadLoading}
                          >
                            取消
                            <Spinner
                              space='left'
                              show={this.state.uploadLoading}
                            />
                          </Button>
                        </ControlGroup>
                      </Col>
                    </div>
                  )}

                  {this.state.photo && !this.state.photoSrc && (
                    <img alt='Crop' className='mt-3' style={{ maxWidth: '100%' }} src={`${config.awsUrl}${this.state.photo}`} />
                  )}
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>應徵資訊</h3>
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.applyPosition = c)}
                    name='applyPosition'
                    label='應徵職位'
                    value={this.state.applyPosition}
                    onChange={(e) => (this.setState({ applyPosition: e.target.value }))}
                    hasError={this.props.hasError.applyPosition}
                    help={this.props.help.applyPosition}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.expectedSalary = c)}
                    name='expectedSalary'
                    label='期望待遇'
                    value={this.state.expectedSalary}
                    onChange={(e) => (this.setState({ expectedSalary: e.target.value }))}
                    hasError={this.props.hasError.expectedSalary}
                    help={this.props.help.expectedSalary}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.checkInDay = c)}
                    name='checkInDay'
                    label='預計可報到日'
                    value={this.state.checkInDay}
                    onChange={(e) => (this.setState({ checkInDay: e.target.value }))}
                    hasError={this.props.hasError.checkInDay}
                    help={this.props.help.checkInDay}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.expertise = c)}
                    name='expertise'
                    label='專長'
                    value={this.state.expertise}
                    onChange={(e) => (this.setState({ expertise: e.target.value }))}
                    hasError={this.props.hasError.expertise}
                    help={this.props.help.expertise}
                    disabled={this.props.loading}
                  />
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, false)}
                    inputClasses={{ 'btn-primary': true }}
                    disabled={
                      this.props.loading ||
                      (this.state.idNumber !== this.state.idNumberConfirm) ||
                      ((this.state.birthday ? this.state.birthday.toLocaleDateString() : '') !== (this.state.birthdayConfirm ? this.state.birthdayConfirm.toLocaleDateString() : ''))
                    }
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default BasicInformationPage
