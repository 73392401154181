import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { loginSuccess, loginError } from '../utils/userAction'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import { post, get } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import TextareaControl from '../components/textarea-control'
import SelectControl from '../components/select-control'
import config from '../config'

const Signup = ({ t, role, loginSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [help, setHelp] = useState({})
  const [productCategories, setProductCategories] = useState([])
  const inputRef = useRef({})
  const recaptchaRef = useRef()

  useEffect(() => {
    fetchProductCategoriesList()
    if (inputRef.current.username) {
      inputRef.current.username.focus()
    }
  }, [])

  const fetchProductCategoriesList = () => {
    setLoading(true)

    get('/1/product_categories?page=1&limit=999')
      .then(r => {
        if (r.success === true) {
          setLoading(false)
          setProductCategories(r.data)
        } else {
          setLoading(false)
          setProductCategories([])

          if (r.errors[0] !== undefined) {
            setError(r.errors[0])
          }
        }
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    const recaptcha = recaptchaRef.current.getValue()

    post('/1/signup/', {
      first: inputRef.current.first.value(),
      last: inputRef.current.last.value(),
      phone: inputRef.current.phone.value(),
      company: inputRef.current.company.value(),
      username: inputRef.current.username.value(),
      email: inputRef.current.email.value(),
      password: inputRef.current.password.value() ? encrypt(inputRef.current.password.value()) : '',
      reqProdCategory: inputRef.current.productCategory.value(),
      remarks: inputRef.current.remarks.value(),
      hasEncrypt: 'true',
      recaptcha
    }).then(
      r => {
        recaptchaRef.current.reset()
        if (r.success === true) {
          loginSuccess(r.data)
          setSuccess(true)
          setError('')
          setLoading(false)
        } else {
          setSuccess(false)
          setError('')
          setLoading(false)
          setHasError({})
          setHelp({})

          for (const key in r.errfor) {
            setHasError(prevState => ({ ...prevState, [key]: true }))
            setHelp(prevState => ({ ...prevState, [key]: r.errfor[key] }))
          }

          if (r.errors[0] !== undefined) {
            setError(r.errors[0])
          }
        }
      }
    )
  }

  if (role === 'account') {
    return <Redirect to='/app/account' />
  } else if (role === 'admin') {
    return <Redirect to='/app/admin' />
  }

  const productCategory = productCategories.map(pc => (
    <option value={pc._id} key={pc._id}>
      {pc.name}
    </option>
  ))

  let oneName = (
    <TextControl
      ref={(c) => (inputRef.current.first = c)}
      name='first'
      label={`${t('first_name')}*`}
      hasError={hasError.first}
      help={help.first}
      disabled={loading}
    />
  )

  let twoName = (
    <TextControl
      ref={(c) => (inputRef.current.last = c)}
      name='last'
      label={`${t('last_name')}*`}
      hasError={hasError.last}
      help={help.last}
      disabled={loading}
    />
  )

  if (localStorage.getItem('lang') === 'zh') {
    oneName = (
      <TextControl
        ref={(c) => (inputRef.current.last = c)}
        name='last'
        label={`${t('last_name')}*`}
        hasError={hasError.last}
        help={help.last}
        disabled={loading}
      />
    )

    twoName = (
      <TextControl
        ref={(c) => (inputRef.current.first = c)}
        name='first'
        label={`${t('first_name')}*`}
        hasError={hasError.first}
        help={help.first}
        disabled={loading}
      />
    )
  }

  let alert = []

  if (success) {
    alert = (
      <Alert
        type='success'
        message={t('send_verification_link')}
      />
    )
  } else if (error) {
    alert = (
      <Alert
        type='danger'
        message={error}
      />
    )
  }

  return (
    <section className='section-home container'>
      <Helmet>
        <title>{t('sign_up')}</title>
      </Helmet>
      <div className='row'>
        <div className='col-sm-6'>
          <section>
            <h1 className='page-header'>{t('sign_up')}</h1>
            <form onSubmit={handleSubmit}>
              {alert}
              <span>
                <div className='row'>
                  <div className='col-sm-6'>
                    {oneName}
                  </div>
                  <div className='col-sm-6'>
                    {twoName}
                  </div>
                </div>
                <TextControl
                  ref={(c) => (inputRef.current.phone = c)}
                  name='phone'
                  label={`${t('phone')}*`}
                  hasError={hasError.phone}
                  help={help.phone}
                  disabled={loading}
                />
                <TextControl
                  ref={(c) => (inputRef.current.company = c)}
                  name='company'
                  label={t('company')}
                  hasError={hasError.company}
                  help={help.company}
                  disabled={loading}
                />
                <TextControl
                  ref={(c) => (inputRef.current.username = c)}
                  name='username'
                  label={`${t('username')}*`}
                  hasError={hasError.username}
                  help={help.username}
                  disabled={loading}
                />
                <TextControl
                  ref={(c) => (inputRef.current.email = c)}
                  name='email'
                  label='Email*'
                  hasError={hasError.email}
                  help={help.email}
                  disabled={loading}
                />
                <TextControl
                  ref={(c) => (inputRef.current.password = c)}
                  name='password'
                  label={`${t('password')}*`}
                  type='password'
                  hasError={hasError.password}
                  help={help.password}
                  disabled={loading}
                />
                <SelectControl
                  ref={(c) => (inputRef.current.productCategory = c)}
                  name='productCategory'
                  label={t('product_line')}
                  hasError={hasError.productCategory}
                  help={help.productCategory}
                  disabled={loading}
                >
                  {productCategory}
                </SelectControl>
                <TextareaControl
                  ref={(c) => (inputRef.current.remarks = c)}
                  name='remarks'
                  label={t('requirement')}
                  rows='5'
                  hasError={hasError.remarks}
                  help={help.remarks}
                  disabled={loading}
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config.reCAPTCHA}
                />
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-success mt-3': true }}
                    disabled={loading}
                  >
                    {t('create_account')}
                    <Spinner space='left' show={loading} />
                  </Button>
                </ControlGroup>
              </span>
            </form>
          </section>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role,
  lang: state.index.lang
})

const mapDispatchToProps = dispatch => ({
  loginSuccess (user) {
    dispatch(loginSuccess(user))
  },
  loginError () {
    dispatch(loginError())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
