import React, { useState, useRef, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import TextareaControl from '../components/textarea-control'
import config from '../config'

const ContactPage = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [help, setHelp] = useState({})

  const inputName = useRef(null)
  const inputEmail = useRef(null)
  const inputMessage = useRef(null)
  const recaptchaRef = useRef()

  useEffect(() => {
    if (inputName.current) {
      inputName.current.focus()
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    const recaptcha = recaptchaRef.current.getValue()

    const header = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded'
    })

    const data = new URLSearchParams({
      name: inputName.current.value(),
      email: inputEmail.current.value(),
      message: inputMessage.current.value(),
      recaptcha
    })

    const sentData = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      header: header,
      body: data
    }

    fetch('/app/1/contact/', sentData)
      .then(r => r.json())
      .then(r => {
        recaptchaRef.current.reset()
        if (r.success === true) {
          setSuccess(true)
          setError('')
          setLoading(false)
        } else {
          const newHasError = {}
          const newHelp = {}

          for (const key in r.errfor) {
            newHasError[key] = true
            newHelp[key] = r.errfor[key]
          }

          setHasError(newHasError)
          setHelp(newHelp)

          if (r.errors[0] !== undefined) {
            setError(r.errors[0])
          }

          setSuccess(false)
          setLoading(false)
        }
      }).catch(e => {
        console.error(e)
      })
  }

  let alert = []

  if (success) {
    alert = (
      <Alert
        type='success'
        message='訊息已成功傳送。'
      />
    )
  } else if (error) {
    alert = (
      <Alert
        type='danger'
        message={error}
      />
    )
  }

  return (
    <section className='section-contact container'>
      <Helmet>
        <title>連絡我們</title>
      </Helmet>
      <div className='row'>
        <div className='col-sm-6'>
          <section>
            <h1 className='page-header'>傳送訊息</h1>
            <form onSubmit={handleSubmit}>
              {alert}
              <TextControl
                ref={inputName}
                name='稱呼'
                label='您的稱呼'
                hasError={hasError.name}
                help={help.name}
                disabled={loading}
              />
              <TextControl
                ref={inputEmail}
                name='email'
                label='您的 email'
                hasError={hasError.email}
                help={help.email}
                disabled={loading}
              />
              <TextareaControl
                ref={inputMessage}
                name='message'
                label='訊息'
                rows='5'
                hasError={hasError.message}
                help={help.message}
                disabled={loading}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.reCAPTCHA}
              />
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-primary mt-3': true }}
                  disabled={loading}
                >
                  傳送
                  <Spinner space='left' show={loading} />
                </Button>
              </ControlGroup>
            </form>
          </section>
        </div>
      </div>
    </section>
  )
}

export default ContactPage
