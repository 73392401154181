import React, { Component } from 'react'
import { Container, Row, Col, Card } from 'reactstrap'
import { postFormData } from '../../utils/httpAgent'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import FileControl from '../../components/file-control'

class UploadDocPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.imageRef = {}
    this.state = {
      hasError: {},
      help: {},
      idCard: '',
      householdCertificate: '',
      highestEducation: '',
      resignationCertificate: '',
      payroll: '',
      healthInsurance: '',
      bank: '',
      dischargeCertification: '',
      healthReport: '',
      idCardLoading: false,
      householdCertificateLoading: false,
      highestEducationLoading: false,
      resignationCertificateLoading: false,
      payrollLoading: false,
      healthInsuranceLoading: false,
      bankLoading: false,
      dischargeCertificationLoading: false,
      healthReportLoading: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel()
  } // end handleSubmit

  handleUpload (doc) {
    let state = {}
    const loadingStateName = `${doc}Loading`

    state[loadingStateName] = true
    this.setState(state)

    postFormData('/1/personnel/storage/report_doc', {
      email: this.props.email,
      fillingToken: this.props.fillingToken,
      fileField: doc,
      file: this.input[doc].value()
    }).then(r => {
      state = {
        hasError: {},
        help: {}
      }
      state[loadingStateName] = false

      if (r.success === true) {
        state[doc] = true
        state.error = ''
      } else {
        state[doc] = false
        for (const key in r.errfor) {
          state.hasError[key] = true
          state.help[key] = r.errfor[key]
        }

        if (r.errors[0] !== undefined) {
          state.error = r.errors[0]
        }
      }

      this.setState(state)
    })
  }

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.state.error || this.props.error) {
      alerts = (
        <div className='mt-4'>
          <Alert
            type='danger'
            message={this.props.error || this.state.error}
          />
        </div>
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                {alerts}
              </Col>
            </Row>
            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>身份証正反面</h3>
                  <FileControl
                    ref={(c) => (this.input.idCard = c)}
                    name='idCard'
                    label='請掃描後放置於同一份 PDF 文件中。'
                    hasError={this.state.hasError.idCard}
                    help={this.state.help.idCard}
                  />

                  {!!(!!this.props.idCard ^ !!this.state.idCard) &&
                    <p>已上傳完成。</p>}
                  {this.props.idCard && this.state.idCard &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('idCard')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.idCardLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>戶口名簿</h3>
                  <FileControl
                    ref={(c) => (this.input.householdCertificate = c)}
                    name='householdCertificate'
                    label=''
                    hasError={this.state.hasError.householdCertificate}
                    help={this.state.help.householdCertificate}
                  />

                  {!!(!!this.props.householdCertificate ^ !!this.state.householdCertificate) &&
                    <p>已上傳完成。</p>}
                  {this.props.householdCertificate && this.state.householdCertificate &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('householdCertificate')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.householdCertificateLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>最高學歷証件</h3>
                  <FileControl
                    ref={(c) => (this.input.highestEducation = c)}
                    name='highestEducation'
                    label=''
                    hasError={this.state.hasError.highestEducation}
                    help={this.state.help.highestEducation}
                  />

                  {!!(!!this.props.highestEducation ^ !!this.state.highestEducation) &&
                    <p>已上傳完成。</p>}
                  {this.props.highestEducation && this.state.highestEducation &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('highestEducation')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.highestEducationLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>最後服務公司離職証明</h3>
                  <FileControl
                    ref={(c) => (this.input.resignationCertificate = c)}
                    name='resignationCertificate'
                    label='社會新鮮人免繳'
                    hasError={this.state.hasError.resignationCertificate}
                    help={this.state.help.resignationCertificate}
                  />

                  {!!(!!this.props.resignationCertificate ^ !!this.state.resignationCertificate) &&
                    <p>已上傳完成。</p>}
                  {this.props.resignationCertificate && this.state.resignationCertificate &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('resignationCertificate')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.resignationCertificateLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>最後服務公司薪資單或薪資入帳存摺明細</h3>
                  <FileControl
                    ref={(c) => (this.input.payroll = c)}
                    name='payroll'
                    label='社會新鮮人免繳'
                    hasError={this.state.hasError.payroll}
                    help={this.state.help.payroll}
                  />

                  {!!(!!this.props.payroll ^ !!this.state.payroll) &&
                    <p>已上傳完成。</p>}
                  {this.props.payroll && this.state.payroll &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('payroll')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.payrollLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>全民健保轉出申報表</h3>
                  <FileControl
                    ref={(c) => (this.input.healthInsurance = c)}
                    name='healthInsurance'
                    label=''
                    hasError={this.state.hasError.healthInsurance}
                    help={this.state.help.healthInsurance}
                  />

                  {!!(!!this.props.healthInsurance ^ !!this.state.healthInsurance) &&
                    <p>已上傳完成。</p>}
                  {this.props.healthInsurance && this.state.healthInsurance &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('healthInsurance')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.healthInsuranceLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>中國信託商業銀行存摺封面</h3>
                  <FileControl
                    ref={(c) => (this.input.bank = c)}
                    name='bank'
                    label=''
                    hasError={this.state.hasError.bank}
                    help={this.state.help.bank}
                  />

                  {!!(!!this.props.bank ^ !!this.state.bank) &&
                    <p>已上傳完成。</p>}
                  {this.props.bank && this.state.bank &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('bank')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.bankLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>退伍令影本</h3>
                  <FileControl
                    ref={(c) => (this.input.dischargeCertification = c)}
                    name='dischargeCertification'
                    label='男性；年齡超過四十歲以上或已除役者免付'
                    hasError={this.state.hasError.dischargeCertification}
                    help={this.state.help.dischargeCertification}
                  />

                  {!!(!!this.props.dischargeCertification ^ !!this.state.dischargeCertification) &&
                    <p>已上傳完成。</p>}
                  {this.props.dischargeCertification && this.state.dischargeCertification &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('dischargeCertification')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.dischargeCertificationLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12' className='text-center'>
                  <h3 className='text-center mt-4'>新進人員體格檢查報告</h3>
                  <FileControl
                    ref={(c) => (this.input.healthReport = c)}
                    name='healthReport'
                    label=''
                    hasError={this.state.hasError.healthReport}
                    help={this.state.help.healthReport}
                  />

                  {!!(!!this.props.healthReport ^ !!this.state.healthReport) &&
                    <p>已上傳完成。</p>}
                  {this.props.healthReport && this.state.healthReport &&
                    <p>已上重新傳完成。</p>}

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info': true }}
                    onClick={e => this.handleUpload('healthReport')}
                  >
                    上傳
                    <Spinner space='left' show={this.state.healthReportLoading} />
                  </Button>
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, false)}
                    inputClasses={{ 'btn-primary': true }}
                    disabled={this.props.loading}
                  >
                    必要檔案均已上傳完成，確定送出
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default UploadDocPage
