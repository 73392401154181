import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ReactTable from 'react-table'
import moment from 'moment'
import { get, put, deleteItem } from '../utils/httpAgent'
import SelectControl from '../components/select-control-lite'

class ClientIsssuesPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0
    }
    this.handleFetchIssuesList = this.handleFetchIssuesList.bind(this)
    this.handleSetIssueStatus = this.handleSetIssueStatus.bind(this)
    this.handleDeleteIssue = this.handleDeleteIssue.bind(this)
  }

  handleFetchIssuesList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/client_issues?page=${page}&limit=${limit}&type=1&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  handleSetIssueStatus (id, status) {
    put(`/1/admin/client_issue/${id}`, {
      status: status
    }).then(r => {
      if (r.success === true) {
        this.handleFetchIssuesList()
      }
    })
  }

  handleDeleteIssue (uid) {
    if (!window.confirm('是否確定要刪除此問題？')) {
      return -1
    }

    deleteItem(`/1/admin/client_issue/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchIssuesList()
        }
      })
  }

  render () {
    const columns = [{
      Header: '提問者',
      accessor: 'questioner',
      Cell: row => (
        row.value &&
          <span>
            {row.value.email}
          </span>
      )
    }, {
      Header: '產品',
      accessor: 'product',
      Cell: row => (
        <span>
          {row.value.name}
        </span>
      )
    }, {
      Header: '標題',
      accessor: 'title'
    }, {
      Header: '狀態',
      accessor: 'status',
      width: 110,
      Cell: row => (
        <SelectControl
          value={row.value}
          onChange={(e) => (this.handleSetIssueStatus(row.original._id, e.target.value))}
        >
          <option value='0'>
            待處理
          </option>
          <option value='1'>
            處理中
          </option>
          <option value='2'>
            已解決
          </option>
          <option value='3'>
            已關閉
          </option>
        </SelectControl>
      )
    }, {
      Header: '更新日期',
      accessor: 'updateTime',
      width: 100
    }, {
      Header: '建立日期',
      accessor: 'createTime',
      width: 100
    }, {
      Header: '',
      accessor: '_id',
      width: 50,
      Cell: row => (
        <button className='btn btn-danger btn-sm' onClick={(e) => (this.handleDeleteIssue(row.value))}>
          <i className='lnr lnr-cross' />
        </button>
      )
    }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            客戶需求
          </title>
        </Helmet>

        <h1 className='page-header'>客戶問題與需求</h1>
        <div className='row'>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchIssuesList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    {row.original.questioner &&
                      <p>
                        提問者帳號：{row.original.questioner.username}
                      </p>
                    }
                    <p>
                      內文：{row.original.content}
                    </p>
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'createTime', desc: true }]}
            />
          </div>
        </div>

      </section>
    )
  }
}

export default ClientIsssuesPage
