import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import ReactTable from 'react-table'
import moment from 'moment'
import { get } from '../utils/httpAgent'

class ActLogsPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0
    }
    this.handleFetchDataList = this.handleFetchDataList.bind(this)
  }

  handleFetchDataList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/act_logs?page=${page}&limit=${limit}&type=1&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach(item => {
            item.date = moment(item.date).format('YYYY/MM/DD HH:mm:ss')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  render () {
    const columns = [{
      Header: '用戶',
      accessor: 'actor',
      Cell: row => (
        row.value &&
          <span>
            {row.value.name} ({row.value.ip})
          </span>
      )
    }, {
      Header: '動作',
      accessor: 'verb',
      Cell: row => (
        row.value &&
          <span>
            {row.value.display} ({row.value.http})
          </span>
      )
    }, {
      Header: '目標',
      accessor: 'object',
      Cell: row => (
        row.value &&
          <span>
            {row.value.definition}
          </span>
      )
    }, {
      Header: '成功',
      accessor: 'result',
      width: 110,
      Cell: row => (
        <span>
          <span style={{
            color: row.value === true ? '#0000FF'
              : row.value === false ? '#FF0000'
                : '#000',
            transition: 'all .3s ease'
          }}
          >
            &#x25cf;
          </span>&nbsp;
          {row.value === true ? '成功'
            : row.value === false ? '失敗'
              : '異常狀態'}
        </span>
      )
    }, {
      Header: '附加訊息',
      accessor: 'extensions'
    }, {
      Header: '發生時間',
      accessor: 'date',
      width: 165
    }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            系統記錄
          </title>
        </Helmet>

        <h1 className='page-header'>系統記錄</h1>
        <div className='row'>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchDataList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12' style={{ padding: '20px' }}>
                    {row.original.extensions &&
                      <p>
                        附加訊息：{row.original.extensions}
                      </p>
                    }
                    {row.original &&
                      <p>
                        <pre>{JSON.stringify(row.original, null, 4)}</pre>
                      </p>
                    }
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'date', desc: true }]}
            />
          </div>
        </div>

      </section>
    )
  }
}

export default ActLogsPage
