import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Card } from 'reactstrap'
import { get } from '../utils/httpAgent'
import styles from './css/admin.module.css'

class index extends Component {
  constructor () {
    super()
    this.state = {
      User0: 0,
      Admin1: 0,
      User2: 0,
      StoragePromise3: 0,
      ClientIssue4: 0,
      ClientIssue5: 0
    }
  }

  componentDidMount () {
    this.fetchData()
  }

  fetchData () {
    get('/1/admin/count')
      .then(r => {
        if (r.success === true) {
          this.setState({ ...r.data })
        }
      })
  }

  render () {
    return (
      <section className='section-home container'>
        <Helmet>
          <title>
            系統資訊
          </title>
        </Helmet>
        <div className='row'>
          <div className='col-md-12'>
            <h1 className='page-header'>系統資訊</h1>
            <div className='row'>
              <div className='col-md-3 mb-4'>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.User0}
                  </div>
                  <div className={styles['stat-label']}>使用者</div>
                </Card>
              </div>

              <div className='col-md-3 mb-4'>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.Admin1}
                  </div>
                  <div className={styles['stat-label']}>管理者</div>
                </Card>
              </div>

              <div className='col-md-3 mb-4'>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.User2}
                  </div>
                  <div className={styles['stat-label']}>待審核</div>
                </Card>
              </div>

              <div className='col-md-3 mb-4'>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.StoragePromise3}
                  </div>
                  <div className={styles['stat-label']}>今日申請下載量</div>
                </Card>
              </div>

              <div className='col-md-3 mb-4'>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.ClientIssue4}
                  </div>
                  <div className={styles['stat-label']}>待處理問題</div>
                </Card>
              </div>

              <div className='col-md-3 mb-4'>
                <Card body inverse className='dark-card text-center'>
                  <div className={styles['stat-value']}>
                    {this.state.ClientIssue5}
                  </div>
                  <div className={styles['stat-label']}>處理中問題</div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default index
