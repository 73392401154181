import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'
import DateTimeControl from '../../components/datetime-control'
import TextareaControl from '../../components/textarea-control'

class QualificationPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      isNewGraduate: this.props.isNewGraduate.toString() ? this.props.isNewGraduate.toString() : '', // 是否為首次工作
      lastQualificationOrganization: this.props.lastQualificationOrganization, // 最後資歷 任職機構
      lastQualificationIndustryNature: this.props.lastQualificationIndustryNature, // 最後資歷 行業性質
      lastQualificationPhone: this.props.lastQualificationPhone, // 最後資歷 聯絡電話
      lastQualificationSupervisor: this.props.lastQualificationSupervisor, // 最後資歷 主管姓名
      lastQualificationSupervisorTitle: this.props.lastQualificationSupervisorTitle, // 最後資歷 主管職稱
      lastQualificationTakeOfficeDate: this.props.lastQualificationTakeOfficeDate ? new Date(this.props.lastQualificationTakeOfficeDate) : '', // 最後資歷 到職日期
      lastQualificationSalary: this.props.lastQualificationSalary, // 最後資歷 到職薪資
      lastQualificationTakeOfficeTitle: this.props.lastQualificationTakeOfficeTitle, // 最後資歷 到職時職務
      lastQualificationCurrentlyTitle: this.props.lastQualificationCurrentlyTitle, // 最後資歷 目前職務
      lastQualificationContent: this.props.lastQualificationContent, // 最後資歷 工作內容
      lastQualificationCurrentSalary: this.props.lastQualificationCurrentSalary, // 最後資歷 目前本薪
      lastQualificationAllowance: this.props.lastQualificationAllowance, // 最後資歷 津貼
      lastQualificationHasBonus: this.props.lastQualificationHasBonus, // 最後資歷 有無獎金
      lastQualificationBonus: this.props.lastQualificationBonus, // 最後資歷 固定每年 N 個月
      lastQualificationBonusReason: this.props.lastQualificationBonusReason, // 最後資歷 不固定說明原因
      lastQualificationHasOtherPay: this.props.lastQualificationHasOtherPay, // 最後資歷 有無其他給付
      lastQualificationOtherPayReason: this.props.lastQualificationOtherPayReason, // 最後資歷 有其他給付的說明
      lastQualificationLastRaiseYear: this.props.lastQualificationLastRaiseYear, // 最後資歷 上次調薪年
      lastQualificationLastRaiseMonth: this.props.lastQualificationLastRaiseMonth, // 最後資歷 上次調薪月
      lastQualificationNextRaiseYear: this.props.lastQualificationNextRaiseYear, // 最後資歷 下次調薪年
      lastQualificationNextRaiseMonth: this.props.lastQualificationNextRaiseMonth, // 最後資歷 下次調薪月
      salaryIncreaseProportion: this.props.salaryIncreaseProportion, // 調薪幅度
      salaryIncrease: this.props.salaryIncrease, // 調薪金額
      lastQualificationOnJob: this.props.lastQualificationOnJob, // 最後資歷 是否在職
      lastQualificationResignDays: this.props.lastQualificationResignDays, // 最後資歷 離職預告幾天
      lastQualificationLeaveOfficeDate: this.props.lastQualificationLeaveOfficeDate ? new Date(this.props.lastQualificationLeaveOfficeDate) : '', // 最後資歷 離職日期
      lastQualificationLeavingReason: this.props.lastQualificationLeavingReason, // 最後資歷 轉職動機
      qualification1Organization: this.props.qualification1Organization, // 其他資歷 1 任職機構
      qualification1IndustryNature: this.props.qualification1IndustryNature, // 其他資歷 1 行業性質
      qualification1Phone: this.props.qualification1Phone, // 其他資歷 1 聯絡電話
      qualification1Supervisor: this.props.qualification1Supervisor, // 其他資歷 1 主管姓名
      qualification1SupervisorTitle: this.props.qualification1SupervisorTitle, // 其他資歷 1 主管職稱
      qualification1TakeOfficeDate: this.props.qualification1TakeOfficeDate ? new Date(this.props.qualification1TakeOfficeDate) : '', // 其他資歷 1 到職日期
      qualification1LeaveOfficeDate: this.props.qualification1LeaveOfficeDate ? new Date(this.props.qualification1LeaveOfficeDate) : '', // 其他資歷 1 離職日期
      qualification1TakeOfficeTitle: this.props.qualification1TakeOfficeTitle, // 其他資歷 1 到職時職務
      qualification1LeaveOfficeTitle: this.props.qualification1LeaveOfficeTitle, // 其他資歷 1 離職時職務
      qualification1TakeOfficeSalary: this.props.qualification1TakeOfficeSalary, // 其他資歷 1 到職時薪資
      qualification1LeaveOfficSalary: this.props.qualification1LeaveOfficSalary, // 其他資歷 1 離職時薪資
      qualification1Content: this.props.qualification1Content, // 其他資歷 1 工作內容
      qualification1LeavingReason: this.props.qualification1LeavingReason, // 其他資歷 1 離職原因
      qualification2Organization: this.props.qualification2Organization, // 其他資歷 2 任職機構
      qualification2IndustryNature: this.props.qualification2IndustryNature, // 其他資歷 2 行業性質
      qualification2Phone: this.props.qualification2Phone, // 其他資歷 2 聯絡電話
      qualification2Supervisor: this.props.qualification2Supervisor, // 其他資歷 2 主管姓名
      qualification2SupervisorTitle: this.props.qualification2SupervisorTitle, // 其他資歷 2 主管職稱
      qualification2TakeOfficeDate: this.props.qualification2TakeOfficeDate ? new Date(this.props.qualification2TakeOfficeDate) : '', // 其他資歷 2 到職日期
      qualification2LeaveOfficeDate: this.props.qualification2LeaveOfficeDate ? new Date(this.props.qualification2LeaveOfficeDate) : '', // 其他資歷 2 離職日期
      qualification2TakeOfficeTitle: this.props.qualification2TakeOfficeTitle, // 其他資歷 2 到職時職務
      qualification2LeaveOfficeTitle: this.props.qualification2LeaveOfficeTitle, // 其他資歷 2 離職時職務
      qualification2TakeOfficeSalary: this.props.qualification2TakeOfficeSalary, // 其他資歷 2 到職時薪資
      qualification2LeaveOfficSalary: this.props.qualification2LeaveOfficSalary, // 其他資歷 2 離職時薪資
      qualification2Content: this.props.qualification2Content, // 其他資歷 2 工作內容
      qualification2LeavingReason: this.props.qualification2LeavingReason, // 其他資歷 2 離職原因
      qualification3Organization: this.props.qualification3Organization, // 其他資歷 3 任職機構
      qualification3IndustryNature: this.props.qualification3IndustryNature, // 其他資歷 3 行業性質
      qualification3Phone: this.props.qualification3Phone, // 其他資歷 3 聯絡電話
      qualification3Supervisor: this.props.qualification3Supervisor, // 其他資歷 3 主管姓名
      qualification3SupervisorTitle: this.props.qualification3SupervisorTitle, // 其他資歷 3 主管職稱
      qualification3TakeOfficeDate: this.props.qualification3TakeOfficeDate ? new Date(this.props.qualification3TakeOfficeDate) : '', // 其他資歷 3 到職日期
      qualification3LeaveOfficeDate: this.props.qualification3LeaveOfficeDate ? new Date(this.props.qualification3LeaveOfficeDate) : '', // 其他資歷 3 離職日期
      qualification3TakeOfficeTitle: this.props.qualification3TakeOfficeTitle, // 其他資歷 3 到職時職務
      qualification3LeaveOfficeTitle: this.props.qualification3LeaveOfficeTitle, // 其他資歷 3 離職時職務
      qualification3TakeOfficeSalary: this.props.qualification3TakeOfficeSalary, // 其他資歷 3 到職時薪資
      qualification3LeaveOfficSalary: this.props.qualification3LeaveOfficSalary, // 其他資歷 3 離職時薪資
      qualification3Content: this.props.qualification3Content, // 其他資歷 3 工作內容
      qualification3LeavingReason: this.props.qualification3LeavingReason // 其他資歷 3 離職原因
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 5, isTemporary) {
    event.preventDefault()
    event.stopPropagation()

    this.props.setPersonnel({
      isNewGraduate: this.state.isNewGraduate,
      lastQualificationOrganization: this.state.lastQualificationOrganization,
      lastQualificationIndustryNature: this.state.lastQualificationIndustryNature,
      lastQualificationPhone: this.state.lastQualificationPhone,
      lastQualificationSupervisor: this.state.lastQualificationSupervisor,
      lastQualificationSupervisorTitle: this.state.lastQualificationSupervisorTitle,
      lastQualificationTakeOfficeDate: this.state.lastQualificationTakeOfficeDate,
      lastQualificationSalary: this.state.lastQualificationSalary,
      lastQualificationTakeOfficeTitle: this.state.lastQualificationTakeOfficeTitle,
      lastQualificationCurrentlyTitle: this.state.lastQualificationCurrentlyTitle,
      lastQualificationContent: this.state.lastQualificationContent,
      lastQualificationCurrentSalary: this.state.lastQualificationCurrentSalary,
      lastQualificationAllowance: this.state.lastQualificationAllowance,
      lastQualificationHasBonus: this.state.lastQualificationHasBonus,
      lastQualificationBonus: this.state.lastQualificationBonus,
      lastQualificationBonusReason: this.state.lastQualificationBonusReason,
      lastQualificationHasOtherPay: this.state.lastQualificationHasOtherPay,
      lastQualificationOtherPayReason: this.state.lastQualificationOtherPayReason,
      lastQualificationLastRaiseYear: this.state.lastQualificationLastRaiseYear,
      lastQualificationLastRaiseMonth: this.state.lastQualificationLastRaiseMonth,
      lastQualificationNextRaiseYear: this.state.lastQualificationNextRaiseYear,
      lastQualificationNextRaiseMonth: this.state.lastQualificationNextRaiseMonth,
      salaryIncreaseProportion: this.state.salaryIncreaseProportion,
      salaryIncrease: this.state.salaryIncrease,
      lastQualificationOnJob: this.state.lastQualificationOnJob,
      lastQualificationResignDays: this.state.lastQualificationResignDays,
      lastQualificationLeaveOfficeDate: this.state.lastQualificationLeaveOfficeDate,
      lastQualificationLeavingReason: this.state.lastQualificationLeavingReason,
      qualification1Organization: this.state.qualification1Organization,
      qualification1IndustryNature: this.state.qualification1IndustryNature,
      qualification1Phone: this.state.qualification1Phone,
      qualification1Supervisor: this.state.qualification1Supervisor,
      qualification1SupervisorTitle: this.state.qualification1SupervisorTitle,
      qualification1TakeOfficeDate: this.state.qualification1TakeOfficeDate,
      qualification1LeaveOfficeDate: this.state.qualification1LeaveOfficeDate,
      qualification1TakeOfficeTitle: this.state.qualification1TakeOfficeTitle,
      qualification1LeaveOfficeTitle: this.state.qualification1LeaveOfficeTitle,
      qualification1TakeOfficeSalary: this.state.qualification1TakeOfficeSalary,
      qualification1LeaveOfficSalary: this.state.qualification1LeaveOfficSalary,
      qualification1Content: this.state.qualification1Content,
      qualification1LeavingReason: this.state.qualification1LeavingReason,
      qualification2Organization: this.state.qualification2Organization,
      qualification2IndustryNature: this.state.qualification2IndustryNature,
      qualification2Phone: this.state.qualification2Phone,
      qualification2Supervisor: this.state.qualification2Supervisor,
      qualification2SupervisorTitle: this.state.qualification2SupervisorTitle,
      qualification2TakeOfficeDate: this.state.qualification2TakeOfficeDate,
      qualification2LeaveOfficeDate: this.state.qualification2LeaveOfficeDate,
      qualification2TakeOfficeTitle: this.state.qualification2TakeOfficeTitle,
      qualification2LeaveOfficeTitle: this.state.qualification2LeaveOfficeTitle,
      qualification2TakeOfficeSalary: this.state.qualification2TakeOfficeSalary,
      qualification2LeaveOfficSalary: this.state.qualification2LeaveOfficSalary,
      qualification2Content: this.state.qualification2Content,
      qualification2LeavingReason: this.state.qualification2LeavingReason,
      qualification3Organization: this.state.qualification3Organization,
      qualification3IndustryNature: this.state.qualification3IndustryNature,
      qualification3Phone: this.state.qualification3Phone,
      qualification3Supervisor: this.state.qualification3Supervisor,
      qualification3SupervisorTitle: this.state.qualification3SupervisorTitle,
      qualification3TakeOfficeDate: this.state.qualification3TakeOfficeDate,
      qualification3LeaveOfficeDate: this.state.qualification3LeaveOfficeDate,
      qualification3TakeOfficeTitle: this.state.qualification3TakeOfficeTitle,
      qualification3LeaveOfficeTitle: this.state.qualification3LeaveOfficeTitle,
      qualification3TakeOfficeSalary: this.state.qualification3TakeOfficeSalary,
      qualification3LeaveOfficSalary: this.state.qualification3LeaveOfficSalary,
      qualification3Content: this.state.qualification3Content,
      qualification3LeavingReason: this.state.qualification3LeavingReason
    }, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>6 / 7</p>
                <Progress value='90' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>

            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <SelectControl
                    ref={(c) => (this.input.isNewGraduate = c)}
                    name='isNewGraduate'
                    label='是否為首次工作'
                    value={this.state.isNewGraduate}
                    onChange={(e) => (this.setState({ isNewGraduate: e.target.value }))}
                    hasError={this.props.hasError.isNewGraduate}
                    help={this.props.help.isNewGraduate}
                    disabled={this.props.loading}
                  >
                    <option value='true'>
                      是
                    </option>
                    <option value='false'>
                      否
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            {this.state.isNewGraduate === 'false' &&
              <Card body className='mt-4'>
                <Row className='justify-content-md-center'>
                  <Col md='12'>
                    <h3 className='text-center mt-4'>目前 (或最後資歷）任職狀況</h3>
                  </Col>

                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationOrganization = c)}
                      name='lastQualificationOrganization'
                      label='任職機構'
                      value={this.state.lastQualificationOrganization}
                      onChange={(e) => (this.setState({ lastQualificationOrganization: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationOrganization}
                      help={this.props.help.lastQualificationOrganization}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationTakeOfficeTitle = c)}
                      name='lastQualificationTakeOfficeTitle'
                      label='到職時職務'
                      value={this.state.lastQualificationTakeOfficeTitle}
                      onChange={(e) => (this.setState({ lastQualificationTakeOfficeTitle: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationTakeOfficeTitle}
                      help={this.props.help.lastQualificationTakeOfficeTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationCurrentlyTitle = c)}
                      name='lastQualificationCurrentlyTitle'
                      label='目前職務'
                      value={this.state.lastQualificationCurrentlyTitle}
                      onChange={(e) => (this.setState({ lastQualificationCurrentlyTitle: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationCurrentlyTitle}
                      help={this.props.help.lastQualificationCurrentlyTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationSupervisor = c)}
                      name='lastQualificationSupervisor'
                      label='主管姓名'
                      value={this.state.lastQualificationSupervisor}
                      onChange={(e) => (this.setState({ lastQualificationSupervisor: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationSupervisor}
                      help={this.props.help.lastQualificationSupervisor}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationSupervisorTitle = c)}
                      name='lastQualificationSupervisorTitle'
                      label='主管職稱'
                      value={this.state.lastQualificationSupervisorTitle}
                      onChange={(e) => (this.setState({ lastQualificationSupervisorTitle: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationSupervisorTitle}
                      help={this.props.help.lastQualificationSupervisorTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationIndustryNature = c)}
                      name='lastQualificationIndustryNature'
                      label='行業性質'
                      value={this.state.lastQualificationIndustryNature}
                      onChange={(e) => (this.setState({ lastQualificationIndustryNature: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationIndustryNature}
                      help={this.props.help.lastQualificationIndustryNature}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationPhone = c)}
                      name='lastQualificationPhone'
                      label='聯絡電話'
                      value={this.state.lastQualificationPhone}
                      onChange={(e) => (this.setState({ lastQualificationPhone: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationPhone}
                      help={this.props.help.lastQualificationPhone}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <DateTimeControl
                      ref={(c) => (this.input.lastQualificationTakeOfficeDate = c)}
                      name='lastQualificationTakeOfficeDate'
                      label='到職日期'
                      timeFormat={false}
                      value={this.state.lastQualificationTakeOfficeDate}
                      onChange={(e) => {
                        this.setState({ lastQualificationTakeOfficeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                      }}
                      hasError={this.props.hasError.lastQualificationTakeOfficeDate}
                      help={this.props.help.lastQualificationTakeOfficeDate}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationSalary = c)}
                      name='lastQualificationSalary'
                      label='到職薪資'
                      value={this.state.lastQualificationSalary}
                      onChange={(e) => (this.setState({ lastQualificationSalary: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationSalary}
                      help={this.props.help.lastQualificationSalary}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationCurrentSalary = c)}
                      name='lastQualificationCurrentSalary'
                      label='本薪(每月)'
                      type='password'
                      value={this.state.lastQualificationCurrentSalary}
                      onChange={(e) => (this.setState({ lastQualificationCurrentSalary: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationCurrentSalary}
                      help={this.props.help.lastQualificationCurrentSalary}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationAllowance = c)}
                      name='lastQualificationAllowance'
                      label='津貼(每月)'
                      value={this.state.lastQualificationAllowance}
                      onChange={(e) => (this.setState({ lastQualificationAllowance: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationAllowance}
                      help={this.props.help.lastQualificationAllowance}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='12'>
                    <SelectControl
                      ref={(c) => (this.input.lastQualificationHasBonus = c)}
                      name='lastQualificationHasBonus'
                      label='有無獎金'
                      value={this.state.lastQualificationHasBonus}
                      onChange={(e) => (this.setState({ lastQualificationHasBonus: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationHasBonus}
                      help={this.props.help.lastQualificationHasBonus}
                      disabled={this.props.loading}
                    >
                      <option value='有'>
                        有
                      </option>
                      <option value='無'>
                        無
                      </option>
                    </SelectControl>
                  </Col>

                  {this.state.lastQualificationHasBonus === '有' &&
                    <Col md='4'>
                      <TextControl
                        ref={(c) => (this.input.lastQualificationBonus = c)}
                        name='lastQualificationBonus'
                        label='固定獎金，每年幾個月？'
                        value={this.state.lastQualificationBonus}
                        onChange={(e) => (this.setState({ lastQualificationBonus: e.target.value }))}
                        hasError={this.props.hasError.lastQualificationBonus}
                        help={this.props.help.lastQualificationBonus}
                        disabled={this.props.loading}
                      />
                    </Col>}

                  {this.state.lastQualificationHasBonus === '有' &&
                    <Col md='8'>
                      <TextControl
                        ref={(c) => (this.input.lastQualificationBonusReason = c)}
                        name='lastQualificationBonusReason'
                        label='不固定獎金，請說明'
                        value={this.state.lastQualificationBonusReason}
                        onChange={(e) => (this.setState({ lastQualificationBonusReason: e.target.value }))}
                        hasError={this.props.hasError.lastQualificationBonusReason}
                        help={this.props.help.lastQualificationBonusReason}
                        disabled={this.props.loading}
                      />
                    </Col>}

                  <Col md='12'>
                    <SelectControl
                      ref={(c) => (this.input.lastQualificationHasOtherPay = c)}
                      name='lastQualificationHasOtherPay'
                      label='其他給付'
                      value={this.state.lastQualificationHasOtherPay}
                      onChange={(e) => (this.setState({ lastQualificationHasOtherPay: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationHasOtherPay}
                      help={this.props.help.lastQualificationHasOtherPay}
                      disabled={this.props.loading}
                    >
                      <option value='有'>
                        有
                      </option>
                      <option value='無'>
                        無
                      </option>
                    </SelectControl>
                  </Col>

                  {this.state.lastQualificationHasOtherPay === '有' &&
                    <Col md='12'>
                      <TextControl
                        ref={(c) => (this.input.lastQualificationOtherPayReason = c)}
                        name='lastQualificationOtherPayReason'
                        label='請說明其他給付'
                        value={this.state.lastQualificationOtherPayReason}
                        onChange={(e) => (this.setState({ lastQualificationOtherPayReason: e.target.value }))}
                        hasError={this.props.hasError.lastQualificationOtherPayReason}
                        help={this.props.help.lastQualificationOtherPayReason}
                        disabled={this.props.loading}
                      />
                    </Col>}

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationLastRaiseYear = c)}
                      name='lastQualificationLastRaiseYear'
                      label='上次調薪(年)'
                      value={this.state.lastQualificationLastRaiseYear}
                      onChange={(e) => (this.setState({ lastQualificationLastRaiseYear: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationLastRaiseYear}
                      help={this.props.help.lastQualificationLastRaiseYear}
                      disabled={this.props.loading}
                    />
                  </Col>
                  <Col md='3'>
                    <SelectControl
                      ref={(c) => (this.input.lastQualificationLastRaiseMonth = c)}
                      name='lastQualificationLastRaiseMonth'
                      label='上次調薪(月)'
                      value={this.state.lastQualificationLastRaiseMonth}
                      onChange={(e) => (this.setState({ lastQualificationLastRaiseMonth: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationLastRaiseMonth}
                      help={this.props.help.lastQualificationLastRaiseMonth}
                      disabled={this.props.loading}
                    >
                      <option value='1'>
                        1
                      </option>
                      <option value='2'>
                        2
                      </option>
                      <option value='3'>
                        3
                      </option>
                      <option value='4'>
                        4
                      </option>
                      <option value='5'>
                        5
                      </option>
                      <option value='6'>
                        6
                      </option>
                      <option value='7'>
                        7
                      </option>
                      <option value='8'>
                        8
                      </option>
                      <option value='9'>
                        9
                      </option>
                      <option value='10'>
                        10
                      </option>
                      <option value='11'>
                        11
                      </option>
                      <option value='12'>
                        12
                      </option>
                    </SelectControl>
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationNextRaiseYear = c)}
                      name='lastQualificationNextRaiseYear'
                      label='下次調薪(年)'
                      value={this.state.lastQualificationNextRaiseYear}
                      onChange={(e) => (this.setState({ lastQualificationNextRaiseYear: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationNextRaiseYear}
                      help={this.props.help.lastQualificationNextRaiseYear}
                      disabled={this.props.loading}
                    />
                  </Col>
                  <Col md='3'>
                    <SelectControl
                      ref={(c) => (this.input.lastQualificationNextRaiseMonth = c)}
                      name='lastQualificationNextRaiseMonth'
                      label='下次調薪(月)'
                      value={this.state.lastQualificationNextRaiseMonth}
                      onChange={(e) => (this.setState({ lastQualificationNextRaiseMonth: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationNextRaiseMonth}
                      help={this.props.help.lastQualificationNextRaiseMonth}
                      disabled={this.props.loading}
                    >
                      <option value='1'>
                        1
                      </option>
                      <option value='2'>
                        2
                      </option>
                      <option value='3'>
                        3
                      </option>
                      <option value='4'>
                        4
                      </option>
                      <option value='5'>
                        5
                      </option>
                      <option value='6'>
                        6
                      </option>
                      <option value='7'>
                        7
                      </option>
                      <option value='8'>
                        8
                      </option>
                      <option value='9'>
                        9
                      </option>
                      <option value='10'>
                        10
                      </option>
                      <option value='11'>
                        11
                      </option>
                      <option value='12'>
                        12
                      </option>
                    </SelectControl>
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.salaryIncreaseProportion = c)}
                      name='salaryIncreaseProportion'
                      label='上次調薪幅度(%)'
                      value={this.state.salaryIncreaseProportion}
                      onChange={(e) => (this.setState({ salaryIncreaseProportion: e.target.value }))}
                      hasError={this.props.hasError.salaryIncreaseProportion}
                      help={this.props.help.salaryIncreaseProportion}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.salaryIncrease = c)}
                      name='salaryIncrease'
                      label='上次調薪金額(NT$)'
                      value={this.state.salaryIncrease}
                      onChange={(e) => (this.setState({ salaryIncrease: e.target.value }))}
                      hasError={this.props.hasError.salaryIncrease}
                      help={this.props.help.salaryIncrease}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.lastQualificationLeavingReason = c)}
                      name='lastQualificationLeavingReason'
                      label='轉職動機'
                      value={this.state.lastQualificationLeavingReason}
                      onChange={(e) => (this.setState({ lastQualificationLeavingReason: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationLeavingReason}
                      help={this.props.help.lastQualificationLeavingReason}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='12'>
                    <SelectControl
                      ref={(c) => (this.input.lastQualificationOnJob = c)}
                      name='lastQualificationOnJob'
                      label='是否仍在職？'
                      value={this.state.lastQualificationOnJob}
                      onChange={(e) => (this.setState({ lastQualificationOnJob: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationOnJob}
                      help={this.props.help.lastQualificationOnJob}
                      disabled={this.props.loading}
                    >
                      <option value='是'>
                        是
                      </option>
                      <option value='否'>
                        否
                      </option>
                    </SelectControl>
                  </Col>

                  {this.state.lastQualificationOnJob === '是' &&
                    <Col md='12'>
                      <TextControl
                        ref={(c) => (this.input.lastQualificationResignDays = c)}
                        name='lastQualificationResignDays'
                        label='離職預告期幾天？'
                        value={this.state.lastQualificationResignDays}
                        onChange={(e) => (this.setState({ lastQualificationResignDays: e.target.value }))}
                        hasError={this.props.hasError.lastQualificationResignDays}
                        help={this.props.help.lastQualificationResignDays}
                        disabled={this.props.loading}
                      />
                    </Col>}

                  {this.state.lastQualificationOnJob === '否' &&
                    <Col md='12'>
                      <DateTimeControl
                        ref={(c) => (this.input.lastQualificationLeaveOfficeDate = c)}
                        name='lastQualificationLeaveOfficeDate'
                        label='離職日期'
                        timeFormat={false}
                        value={this.state.lastQualificationLeaveOfficeDate}
                        onChange={(e) => {
                          this.setState({ lastQualificationLeaveOfficeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                        }}
                        hasError={this.props.hasError.lastQualificationLeaveOfficeDate}
                        help={this.props.help.lastQualificationLeaveOfficeDate}
                        disabled={this.props.loading}
                      />
                    </Col>}

                  <Col md='12'>
                    <TextareaControl
                      ref={(c) => (this.input.lastQualificationContent = c)}
                      name='lastQualificationContent'
                      label='請詳述工作內容、職責及重大成果'
                      rows='5'
                      value={this.state.lastQualificationContent}
                      onChange={(e) => (this.setState({ lastQualificationContent: e.target.value }))}
                      hasError={this.props.hasError.lastQualificationContent}
                      help={this.props.help.lastQualificationContent}
                      disabled={this.props.loading}
                    />
                  </Col>
                </Row>
              </Card>}

            {this.state.isNewGraduate === 'false' &&
              <Card body className='mt-4'>
                <Row className='justify-content-md-center'>
                  <Col md='12'>
                    <h3 className='text-center mt-4'>其他工作資歷 1</h3>
                  </Col>

                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.qualification1Organization = c)}
                      name='qualification1Organization'
                      label='任職機構'
                      value={this.state.qualification1Organization}
                      onChange={(e) => (this.setState({ qualification1Organization: e.target.value }))}
                      hasError={this.props.hasError.qualification1Organization}
                      help={this.props.help.qualification1Organization}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification1TakeOfficeTitle = c)}
                      name='qualification1TakeOfficeTitle'
                      label='到職時職務'
                      value={this.state.qualification1TakeOfficeTitle}
                      onChange={(e) => (this.setState({ qualification1TakeOfficeTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification1TakeOfficeTitle}
                      help={this.props.help.qualification1TakeOfficeTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification1LeaveOfficeTitle = c)}
                      name='qualification1LeaveOfficeTitle'
                      label='離職時職務'
                      value={this.state.qualification1LeaveOfficeTitle}
                      onChange={(e) => (this.setState({ qualification1LeaveOfficeTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification1LeaveOfficeTitle}
                      help={this.props.help.qualification1LeaveOfficeTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification1Supervisor = c)}
                      name='qualification1Supervisor'
                      label='主管姓名'
                      value={this.state.qualification1Supervisor}
                      onChange={(e) => (this.setState({ qualification1Supervisor: e.target.value }))}
                      hasError={this.props.hasError.qualification1Supervisor}
                      help={this.props.help.qualification1Supervisor}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification1SupervisorTitle = c)}
                      name='qualification1SupervisorTitle'
                      label='主管職稱'
                      value={this.state.qualification1SupervisorTitle}
                      onChange={(e) => (this.setState({ qualification1SupervisorTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification1SupervisorTitle}
                      help={this.props.help.qualification1SupervisorTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification1IndustryNature = c)}
                      name='qualification1IndustryNature'
                      label='行業性質'
                      value={this.state.qualification1IndustryNature}
                      onChange={(e) => (this.setState({ qualification1IndustryNature: e.target.value }))}
                      hasError={this.props.hasError.qualification1IndustryNature}
                      help={this.props.help.qualification1IndustryNature}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification1Phone = c)}
                      name='qualification1Phone'
                      label='聯絡電話'
                      value={this.state.qualification1Phone}
                      onChange={(e) => (this.setState({ qualification1Phone: e.target.value }))}
                      hasError={this.props.hasError.qualification1Phone}
                      help={this.props.help.qualification1Phone}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <DateTimeControl
                      ref={(c) => (this.input.qualification1TakeOfficeDate = c)}
                      name='qualification1TakeOfficeDate'
                      label='到職日期'
                      timeFormat={false}
                      value={this.state.qualification1TakeOfficeDate}
                      onChange={(e) => {
                        this.setState({ qualification1TakeOfficeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                      }}
                      hasError={this.props.hasError.qualification1TakeOfficeDate}
                      help={this.props.help.qualification1TakeOfficeDate}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <DateTimeControl
                      ref={(c) => (this.input.qualification1LeaveOfficeDate = c)}
                      name='qualification1LeaveOfficeDate'
                      label='離職日期'
                      timeFormat={false}
                      value={this.state.qualification1LeaveOfficeDate}
                      onChange={(e) => {
                        this.setState({ qualification1LeaveOfficeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                      }}
                      hasError={this.props.hasError.qualification1LeaveOfficeDate}
                      help={this.props.help.qualification1LeaveOfficeDate}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification1TakeOfficeSalary = c)}
                      name='qualification1TakeOfficeSalary'
                      label='到職時薪資'
                      value={this.state.qualification1TakeOfficeSalary}
                      onChange={(e) => (this.setState({ qualification1TakeOfficeSalary: e.target.value }))}
                      hasError={this.props.hasError.qualification1TakeOfficeSalary}
                      help={this.props.help.qualification1TakeOfficeSalary}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification1LeaveOfficSalary = c)}
                      name='qualification1LeaveOfficSalary'
                      label='離職時薪資'
                      value={this.state.qualification1LeaveOfficSalary}
                      onChange={(e) => (this.setState({ qualification1LeaveOfficSalary: e.target.value }))}
                      hasError={this.props.hasError.qualification1LeaveOfficSalary}
                      help={this.props.help.qualification1LeaveOfficSalary}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification1LeavingReason = c)}
                      name='qualification1LeavingReason'
                      label='離職原因'
                      value={this.state.qualification1LeavingReason}
                      onChange={(e) => (this.setState({ qualification1LeavingReason: e.target.value }))}
                      hasError={this.props.hasError.qualification1LeavingReason}
                      help={this.props.help.qualification1LeavingReason}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='12'>
                    <TextareaControl
                      ref={(c) => (this.input.qualification1Content = c)}
                      name='qualification1Content'
                      label='請詳述工作內容、職責及重大成果'
                      rows='5'
                      value={this.state.qualification1Content}
                      onChange={(e) => (this.setState({ qualification1Content: e.target.value }))}
                      hasError={this.props.hasError.qualification1Content}
                      help={this.props.help.qualification1Content}
                      disabled={this.props.loading}
                    />
                  </Col>
                </Row>
              </Card>}

            {this.state.isNewGraduate === 'false' &&
              <Card body className='mt-4'>
                <Row className='justify-content-md-center'>
                  <Col md='12'>
                    <h3 className='text-center mt-4'>其他工作資歷 2</h3>
                  </Col>

                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.qualification2Organization = c)}
                      name='qualification2Organization'
                      label='任職機構'
                      value={this.state.qualification2Organization}
                      onChange={(e) => (this.setState({ qualification2Organization: e.target.value }))}
                      hasError={this.props.hasError.qualification2Organization}
                      help={this.props.help.qualification2Organization}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification2TakeOfficeTitle = c)}
                      name='qualification2TakeOfficeTitle'
                      label='到職時職務'
                      value={this.state.qualification2TakeOfficeTitle}
                      onChange={(e) => (this.setState({ qualification2TakeOfficeTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification2TakeOfficeTitle}
                      help={this.props.help.qualification2TakeOfficeTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification2LeaveOfficeTitle = c)}
                      name='qualification2LeaveOfficeTitle'
                      label='離職時職務'
                      value={this.state.qualification2LeaveOfficeTitle}
                      onChange={(e) => (this.setState({ qualification2LeaveOfficeTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification2LeaveOfficeTitle}
                      help={this.props.help.qualification2LeaveOfficeTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification2Supervisor = c)}
                      name='qualification2Supervisor'
                      label='主管姓名'
                      value={this.state.qualification2Supervisor}
                      onChange={(e) => (this.setState({ qualification2Supervisor: e.target.value }))}
                      hasError={this.props.hasError.qualification2Supervisor}
                      help={this.props.help.qualification2Supervisor}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification2SupervisorTitle = c)}
                      name='qualification2SupervisorTitle'
                      label='主管職稱'
                      value={this.state.qualification2SupervisorTitle}
                      onChange={(e) => (this.setState({ qualification2SupervisorTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification2SupervisorTitle}
                      help={this.props.help.qualification2SupervisorTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification2IndustryNature = c)}
                      name='qualification2IndustryNature'
                      label='行業性質'
                      value={this.state.qualification2IndustryNature}
                      onChange={(e) => (this.setState({ qualification2IndustryNature: e.target.value }))}
                      hasError={this.props.hasError.qualification2IndustryNature}
                      help={this.props.help.qualification2IndustryNature}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification2Phone = c)}
                      name='qualification2Phone'
                      label='聯絡電話'
                      value={this.state.qualification2Phone}
                      onChange={(e) => (this.setState({ qualification2Phone: e.target.value }))}
                      hasError={this.props.hasError.qualification2Phone}
                      help={this.props.help.qualification2Phone}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <DateTimeControl
                      ref={(c) => (this.input.qualification2TakeOfficeDate = c)}
                      name='qualification2TakeOfficeDate'
                      label='到職日期'
                      timeFormat={false}
                      value={this.state.qualification2TakeOfficeDate}
                      onChange={(e) => {
                        this.setState({ qualification2TakeOfficeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                      }}
                      hasError={this.props.hasError.qualification2TakeOfficeDate}
                      help={this.props.help.qualification2TakeOfficeDate}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <DateTimeControl
                      ref={(c) => (this.input.qualification2LeaveOfficeDate = c)}
                      name='qualification2LeaveOfficeDate'
                      label='離職日期'
                      timeFormat={false}
                      value={this.state.qualification2LeaveOfficeDate}
                      onChange={(e) => {
                        this.setState({ qualification2LeaveOfficeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                      }}
                      hasError={this.props.hasError.qualification2LeaveOfficeDate}
                      help={this.props.help.qualification2LeaveOfficeDate}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification2TakeOfficeSalary = c)}
                      name='qualification2TakeOfficeSalary'
                      label='到職時薪資'
                      value={this.state.qualification2TakeOfficeSalary}
                      onChange={(e) => (this.setState({ qualification2TakeOfficeSalary: e.target.value }))}
                      hasError={this.props.hasError.qualification2TakeOfficeSalary}
                      help={this.props.help.qualification2TakeOfficeSalary}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification2LeaveOfficSalary = c)}
                      name='qualification2LeaveOfficSalary'
                      label='離職時薪資'
                      value={this.state.qualification2LeaveOfficSalary}
                      onChange={(e) => (this.setState({ qualification2LeaveOfficSalary: e.target.value }))}
                      hasError={this.props.hasError.qualification2LeaveOfficSalary}
                      help={this.props.help.qualification2LeaveOfficSalary}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification2LeavingReason = c)}
                      name='qualification2LeavingReason'
                      label='離職原因'
                      value={this.state.qualification2LeavingReason}
                      onChange={(e) => (this.setState({ qualification2LeavingReason: e.target.value }))}
                      hasError={this.props.hasError.qualification2LeavingReason}
                      help={this.props.help.qualification2LeavingReason}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='12'>
                    <TextareaControl
                      ref={(c) => (this.input.qualification2Content = c)}
                      name='qualification2Content'
                      label='請詳述工作內容、職責及重大成果'
                      rows='5'
                      value={this.state.qualification2Content}
                      onChange={(e) => (this.setState({ qualification2Content: e.target.value }))}
                      hasError={this.props.hasError.qualification2Content}
                      help={this.props.help.qualification2Content}
                      disabled={this.props.loading}
                    />
                  </Col>
                </Row>
              </Card>}

            {this.state.isNewGraduate === 'false' &&
              <Card body className='mt-4'>
                <Row className='justify-content-md-center'>
                  <Col md='12'>
                    <h3 className='text-center mt-4'>其他工作資歷 3</h3>
                  </Col>

                  <Col md='12'>
                    <TextControl
                      ref={(c) => (this.input.qualification3Organization = c)}
                      name='qualification3Organization'
                      label='任職機構'
                      value={this.state.qualification3Organization}
                      onChange={(e) => (this.setState({ qualification3Organization: e.target.value }))}
                      hasError={this.props.hasError.qualification3Organization}
                      help={this.props.help.qualification3Organization}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification3TakeOfficeTitle = c)}
                      name='qualification3TakeOfficeTitle'
                      label='到職時職務'
                      value={this.state.qualification3TakeOfficeTitle}
                      onChange={(e) => (this.setState({ qualification3TakeOfficeTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification3TakeOfficeTitle}
                      help={this.props.help.qualification3TakeOfficeTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification3LeaveOfficeTitle = c)}
                      name='qualification3LeaveOfficeTitle'
                      label='離職時職務'
                      value={this.state.qualification3LeaveOfficeTitle}
                      onChange={(e) => (this.setState({ qualification3LeaveOfficeTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification3LeaveOfficeTitle}
                      help={this.props.help.qualification3LeaveOfficeTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification3Supervisor = c)}
                      name='qualification3Supervisor'
                      label='主管姓名'
                      value={this.state.qualification3Supervisor}
                      onChange={(e) => (this.setState({ qualification3Supervisor: e.target.value }))}
                      hasError={this.props.hasError.qualification3Supervisor}
                      help={this.props.help.qualification3Supervisor}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification3SupervisorTitle = c)}
                      name='qualification3SupervisorTitle'
                      label='主管職稱'
                      value={this.state.qualification3SupervisorTitle}
                      onChange={(e) => (this.setState({ qualification3SupervisorTitle: e.target.value }))}
                      hasError={this.props.hasError.qualification3SupervisorTitle}
                      help={this.props.help.qualification3SupervisorTitle}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification3IndustryNature = c)}
                      name='qualification3IndustryNature'
                      label='行業性質'
                      value={this.state.qualification3IndustryNature}
                      onChange={(e) => (this.setState({ qualification3IndustryNature: e.target.value }))}
                      hasError={this.props.hasError.qualification3IndustryNature}
                      help={this.props.help.qualification3IndustryNature}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification3Phone = c)}
                      name='qualification3Phone'
                      label='聯絡電話'
                      value={this.state.qualification3Phone}
                      onChange={(e) => (this.setState({ qualification3Phone: e.target.value }))}
                      hasError={this.props.hasError.qualification3Phone}
                      help={this.props.help.qualification3Phone}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <DateTimeControl
                      ref={(c) => (this.input.qualification3TakeOfficeDate = c)}
                      name='qualification3TakeOfficeDate'
                      label='到職日期'
                      timeFormat={false}
                      value={this.state.qualification3TakeOfficeDate}
                      onChange={(e) => {
                        this.setState({ qualification3TakeOfficeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                      }}
                      hasError={this.props.hasError.qualification3TakeOfficeDate}
                      help={this.props.help.qualification3TakeOfficeDate}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <DateTimeControl
                      ref={(c) => (this.input.qualification3LeaveOfficeDate = c)}
                      name='qualification3LeaveOfficeDate'
                      label='離職日期'
                      timeFormat={false}
                      value={this.state.qualification3LeaveOfficeDate}
                      onChange={(e) => {
                        this.setState({ qualification3LeaveOfficeDate: e.valueOf() ? new Date(e.valueOf()) : null })
                      }}
                      hasError={this.props.hasError.qualification3LeaveOfficeDate}
                      help={this.props.help.qualification3LeaveOfficeDate}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification3TakeOfficeSalary = c)}
                      name='qualification3TakeOfficeSalary'
                      label='到職時薪資'
                      value={this.state.qualification3TakeOfficeSalary}
                      onChange={(e) => (this.setState({ qualification3TakeOfficeSalary: e.target.value }))}
                      hasError={this.props.hasError.qualification3TakeOfficeSalary}
                      help={this.props.help.qualification3TakeOfficeSalary}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='3'>
                    <TextControl
                      ref={(c) => (this.input.qualification3LeaveOfficSalary = c)}
                      name='qualification3LeaveOfficSalary'
                      label='離職時薪資'
                      value={this.state.qualification3LeaveOfficSalary}
                      onChange={(e) => (this.setState({ qualification3LeaveOfficSalary: e.target.value }))}
                      hasError={this.props.hasError.qualification3LeaveOfficSalary}
                      help={this.props.help.qualification3LeaveOfficSalary}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='6'>
                    <TextControl
                      ref={(c) => (this.input.qualification3LeavingReason = c)}
                      name='qualification3LeavingReason'
                      label='離職原因'
                      value={this.state.qualification3LeavingReason}
                      onChange={(e) => (this.setState({ qualification3LeavingReason: e.target.value }))}
                      hasError={this.props.hasError.qualification3LeavingReason}
                      help={this.props.help.qualification3LeavingReason}
                      disabled={this.props.loading}
                    />
                  </Col>

                  <Col md='12'>
                    <TextareaControl
                      ref={(c) => (this.input.qualification3Content = c)}
                      name='qualification3Content'
                      label='請詳述工作內容、職責及重大成果'
                      rows='5'
                      value={this.state.qualification3Content}
                      onChange={(e) => (this.setState({ qualification3Content: e.target.value }))}
                      hasError={this.props.hasError.qualification3Content}
                      help={this.props.help.qualification3Content}
                      disabled={this.props.loading}
                    />
                  </Col>
                </Row>
              </Card>}

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 6, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 5, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 7, false)}
                    disabled={this.props.loading}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default QualificationPage
