import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loginSuccess, loginError } from './userAction'

const requireWeakAuth = (Component) => {
  const AuthenticatedComponent = ({ loginError, ...restProps }) => {
    useEffect(() => {
      const token = localStorage.getItem('token')
      if (token) {
        const jwtPayload = JSON.parse(window.atob(token.split('.')[1]))
        if (!jwtPayload._id || !jwtPayload.roles.account) {
          loginError()
        } else {
          let role = 'account'
          if (jwtPayload.roles.admin) {
            role = 'admin'
          }
          restProps.loginSuccess({
            authenticated: true,
            user: jwtPayload.username,
            email: jwtPayload.email,
            role: role
          })
        }
      } else {
        loginError()
      }
    }, [])

    return <Component {...restProps} />
  }

  const mapDispatchToProps = (dispatch) => ({
    loginSuccess: (user) => dispatch(loginSuccess(user)),
    loginError: () => dispatch(loginError())
  })

  return connect(null, mapDispatchToProps)(AuthenticatedComponent)
}

export default requireWeakAuth
