import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { get, deleteItem } from '../utils/httpAgent'
import config from '../config'

class FinancialInfoPage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0
    }
    this.handleFetchFinancialInfoList = this.handleFetchFinancialInfoList.bind(this)
  }

  handleFetchFinancialInfoList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/financial_infos?page=${page}&limit=${limit}&type=1&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')

            r.data[i].monthlyData = [
              {
                month: '1',
                revenue: item.monthlyRevenue.m1,
                growthRate: item.annualGrowthRateForMonthly.m1,
                cumulative: item.cumulativeAmount.m1,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m1
              }, {
                month: '2',
                revenue: item.monthlyRevenue.m2,
                growthRate: item.annualGrowthRateForMonthly.m2,
                cumulative: item.cumulativeAmount.m2,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m2
              }, {
                month: '3',
                revenue: item.monthlyRevenue.m3,
                growthRate: item.annualGrowthRateForMonthly.m3,
                cumulative: item.cumulativeAmount.m3,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m3
              }, {
                month: '4',
                revenue: item.monthlyRevenue.m4,
                growthRate: item.annualGrowthRateForMonthly.m4,
                cumulative: item.cumulativeAmount.m4,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m4
              }, {
                month: '5',
                revenue: item.monthlyRevenue.m5,
                growthRate: item.annualGrowthRateForMonthly.m5,
                cumulative: item.cumulativeAmount.m5,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m5
              }, {
                month: '6',
                revenue: item.monthlyRevenue.m6,
                growthRate: item.annualGrowthRateForMonthly.m6,
                cumulative: item.cumulativeAmount.m6,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m6
              }, {
                month: '7',
                revenue: item.monthlyRevenue.m7,
                growthRate: item.annualGrowthRateForMonthly.m7,
                cumulative: item.cumulativeAmount.m7,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m7
              }, {
                month: '8',
                revenue: item.monthlyRevenue.m8,
                growthRate: item.annualGrowthRateForMonthly.m8,
                cumulative: item.cumulativeAmount.m8,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m8
              }, {
                month: '9',
                revenue: item.monthlyRevenue.m9,
                growthRate: item.annualGrowthRateForMonthly.m9,
                cumulative: item.cumulativeAmount.m9,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m9
              }, {
                month: '10',
                revenue: item.monthlyRevenue.m10,
                growthRate: item.annualGrowthRateForMonthly.m10,
                cumulative: item.cumulativeAmount.m10,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m10
              }, {
                month: '11',
                revenue: item.monthlyRevenue.m11,
                growthRate: item.annualGrowthRateForMonthly.m11,
                cumulative: item.cumulativeAmount.m11,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m11
              }, {
                month: '12',
                revenue: item.monthlyRevenue.m12,
                growthRate: item.annualGrowthRateForMonthly.m12,
                cumulative: item.cumulativeAmount.m12,
                cumulativeGrowth: item.annualGrowthRateForCumulative.m12
              }
            ]
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteFetchFinancial (uid) {
    if (!window.confirm('是否確定要刪除此年度財務資訊？')) {
      return -1
    }

    deleteItem(`/1/admin/financial_info/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchFinancialInfoList()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '編輯',
        accessor: 'years',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/financial_info/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '年度',
        accessor: 'years'
      }, {
        Header: '年度營收',
        accessor: 'annualRevenue'
      }, {
        Header: '年度淨利',
        accessor: 'annualNetProfit'
      }, {
        Header: '每股盈餘',
        accessor: 'earningsPerShare'
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF'
                : row.value === false ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用'
              : row.value === false ? '關閉'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm' onClick={this.deleteFetchFinancial.bind(this, row.value)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    const columns2 = [
      {
        Header: '月份',
        accessor: 'month'
      }, {
        Header: '月營收',
        accessor: 'revenue'
      }, {
        Header: '年度增(減)比率',
        accessor: 'growthRate',
        Cell: row => (
          <span>
            {row.value ? row.value.toFixed(2) : ''}
          </span>
        )
      }, {
        Header: '累計金額',
        accessor: 'cumulative'
      }, {
        Header: '年度增(減)比率',
        accessor: 'cumulativeGrowth',
        Cell: row => (
          <span>
            {row.value ? row.value.toFixed(2) : ''}
          </span>
        )
      }]

    return (
      <section className='container'>
        <Helmet>
          <title>
            財務資訊管理
          </title>
        </Helmet>

        <h1 className='page-header'>財務資訊管理</h1>
        <div className='row'>
          <div className='col-md-12'>
            <Link to='/app/admin/create_financial_info' className='btn btn-success'>
              建立年度財務資訊
            </Link>
            <br /><br />
          </div>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchFinancialInfoList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-8' style={{ padding: '20px' }}>
                    <ReactTable
                      data={row.original.monthlyData}
                      columns={columns2}
                      defaultPageSize={row.original.monthlyData.length}
                      showPagination={false}
                    />
                  </div>
                  <div className='col-md-4' style={{ padding: '20px' }}>
                    { row.original.q1Report.tw &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.q1Report.tw}`} target='_blank' rel='noopener noreferrer'>
                          Q1 財報 (繁體中文)</a></p>
                    }
                    { row.original.q2Report.tw &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.q2Report.tw}`} target='_blank' rel='noopener noreferrer'>
                          Q2 財報 (繁體中文)
                        </a>
                      </p>
                    }
                    { row.original.q3Report.tw &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.q3Report.tw}`} target='_blank' rel='noopener noreferrer'>
                          Q3 財報 (繁體中文)
                        </a>
                      </p>
                    }
                    { row.original.q4Report.tw &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.q4Report.tw}`} target='_blank' rel='noopener noreferrer'>
                          Q4 財報 (繁體中文)
                        </a>
                      </p>
                    }
                    { row.original.annualReport.tw &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.annualReport.tw}`} target='_blank' rel='noopener noreferrer'>
                          年報 (繁體中文)
                        </a>
                      </p>
                    }

                    { row.original.q1Report.en &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.q1Report.en}`} target='_blank' rel='noopener noreferrer'>
                          Q1 財報 (英文)</a></p>
                    }
                    { row.original.q2Report.en &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.q2Report.en}`} target='_blank' rel='noopener noreferrer'>
                          Q2 財報 (英文)
                        </a>
                      </p>
                    }
                    { row.original.q3Report.en &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.q3Report.en}`} target='_blank' rel='noopener noreferrer'>
                          Q3 財報 (英文)
                        </a>
                      </p>
                    }
                    { row.original.q4Report.en &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.q4Report.en}`} target='_blank' rel='noopener noreferrer'>
                          Q4 財報 (英文)
                        </a>
                      </p>
                    }
                    { row.original.annualReport.en &&
                      <p>
                        <a href={`${config.awsUrl}${row.original.annualReport.en}`} target='_blank' rel='noopener noreferrer'>
                          年報 (英文)
                        </a>
                      </p>
                    }
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'years', desc: true }]}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default FinancialInfoPage
