import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { Card, CardBody } from 'reactstrap'
import { put, get, postFormData } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'
import FileControl from '../components/file-control'
import config from '../config'

class EditFinancialInfoPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      loading: false,
      loadingForQ1Tw: false,
      loadingForQ2Tw: false,
      loadingForQ3Tw: false,
      loadingForQ4Tw: false,
      loadingForAnnualTw: false,
      loadingForQ1En: false,
      loadingForQ2En: false,
      loadingForQ3En: false,
      loadingForQ4En: false,
      loadingForAnnualEn: false,
      success: false,
      successForQcTw: false,
      successForQ2Tw: false,
      successForQ3Tw: false,
      successForQ4Tw: false,
      successForAnnualTw: false,
      successForQ1En: false,
      successForQ2En: false,
      successForQ3En: false,
      successForQ4En: false,
      successForAnnualEn: false,
      error: undefined,
      hasError: {},
      help: {},
      id: '',
      years: this.props.match.params.years,
      m1: 0,
      m2: 0,
      m3: 0,
      m4: 0,
      m5: 0,
      m6: 0,
      m7: 0,
      m8: 0,
      m9: 0,
      m10: 0,
      m11: 0,
      m12: 0,
      annualRevenue: 0,
      annualRevenueOrigin: 0,
      annualNetProfit: 0,
      earningsPerShare: 0,
      q1ReportTw: '',
      q2ReportTw: '',
      q3ReportTw: '',
      q4ReportTw: '',
      annualReportTw: '',
      q1ReportEn: '',
      q2ReportEn: '',
      q3ReportEn: '',
      q4ReportEn: '',
      annualReportEn: ''
    }
    this.handleReportSubmit = this.handleReportSubmit.bind(this)
  }

  componentDidMount () {
    this.fetchData()
    if (this.input.years) {
      this.input.years.focus()
    }
  }

  fetchData () {
    get(`/1/financial_info/${this.props.match.params.years}`)
      .then(r => {
        this.setState({
          id: r.data._id,
          years: r.data.years,
          m1: r.data.monthlyRevenue.m1,
          m2: r.data.monthlyRevenue.m2,
          m3: r.data.monthlyRevenue.m3,
          m4: r.data.monthlyRevenue.m4,
          m5: r.data.monthlyRevenue.m5,
          m6: r.data.monthlyRevenue.m6,
          m7: r.data.monthlyRevenue.m7,
          m8: r.data.monthlyRevenue.m8,
          m9: r.data.monthlyRevenue.m9,
          m10: r.data.monthlyRevenue.m10,
          m11: r.data.monthlyRevenue.m11,
          m12: r.data.monthlyRevenue.m12,
          annualRevenue: r.data.annualRevenue,
          annualRevenueOrigin: r.data.annualRevenue,
          annualNetProfit: r.data.annualNetProfit,
          earningsPerShare: r.data.earningsPerShare,
          q1ReportTw: r.data.q1Report.tw,
          q2ReportTw: r.data.q2Report.tw,
          q3ReportTw: r.data.q3Report.tw,
          q4ReportTw: r.data.q4Report.tw,
          annualReportTw: r.data.annualReport.tw,
          q1ReportEn: r.data.q1Report.en,
          q2ReportEn: r.data.q2Report.en,
          q3ReportEn: r.data.q3Report.en,
          q4ReportEn: r.data.q4Report.en,
          annualReportEn: r.data.annualReport.en,
          isActive: r.data.isActive.toString()
        })
      })
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    put(`/1/admin/financial_info/${this.state.id}`, {
      years: this.input.years.value(),
      m1: this.input.m1.value(),
      m2: this.input.m2.value(),
      m3: this.input.m3.value(),
      m4: this.input.m4.value(),
      m5: this.input.m5.value(),
      m6: this.input.m6.value(),
      m7: this.input.m7.value(),
      m8: this.input.m8.value(),
      m9: this.input.m9.value(),
      m10: this.input.m10.value(),
      m11: this.input.m11.value(),
      m12: this.input.m12.value(),
      annualRevenue: this.input.annualRevenue.value() === this.state.annualRevenueOrigin.toString() ? 0 : this.input.annualRevenue.value(),
      annualNetProfit: this.input.annualNetProfit.value(),
      earningsPerShare: this.input.earningsPerShare.value(),
      isActive: this.input.isActive.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  handleReportSubmit (quarter = 0, lang = 'tw') {
    const langText = lang.substring(0, 1).toUpperCase() + lang.substring(1)
    let state = {}
    let type = 4
    let fileFieldName = `q${quarter}Report${langText}`
    let loadingStateName = `loadingForQ${quarter}${langText}`
    let successStateName = `successForQ${quarter}${langText}`

    if (quarter === 0) {
      type = 3
      fileFieldName = 'annualReport' + langText
      loadingStateName = 'loadingForAnnual' + langText
      successStateName = 'successForAnnual' + langText
    }
    state[loadingStateName] = true
    this.setState(state)

    postFormData(`/1/admin/report/storage`, {
      years: this.props.match.params.years,
      file: this.input[fileFieldName].value(),
      lang: lang,
      quarter: quarter,
      type: type
    }).then(r => {
      state = {
        hasError: {},
        help: {}
      }
      state[loadingStateName] = false

      if (r.success === true) {
        state[successStateName] = true
        state[fileFieldName] = r.data.uri
      } else {
        state[successStateName] = false
        state.hasError[fileFieldName] = true
        if (r.errfor) {
          if (r.errfor.file) {
            state.help[fileFieldName] = r.errfor.file
          }
        } else {
          state.help[fileFieldName] = '發生未知的錯誤'
        }
      }

      this.setState(state)
    })
  }

  render () {
    let alert = []
    let alertForReport =
      <Alert
        type='success'
        message='上傳成功'
      />

    if (this.state.success) {
      return (<Redirect to='/app/admin/financial_info' />)
    }

    if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>{this.props.match.params.years} 年財務資訊</title>
        </Helmet>

        <h1 className='page-header'>編輯 {this.state.years} 年財務資訊</h1>
        <div className='row'>
          <div className='col-md-4'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <fieldset>
                <TextControl
                  ref={(c) => (this.input.years = c)}
                  name='years'
                  label='年份'
                  value={this.state.years}
                  onChange={(e) => (this.setState({ years: e.target.value }))}
                  hasError={this.state.hasError.years}
                  help={this.state.help.years}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m1 = c)}
                  name='m1'
                  label='1 月'
                  value={this.state.m1}
                  onChange={(e) => (this.setState({ m1: e.target.value }))}
                  hasError={this.state.hasError.m1}
                  help={this.state.help.m1}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m2 = c)}
                  name='m2'
                  label='2 月'
                  value={this.state.m2}
                  onChange={(e) => (this.setState({ m2: e.target.value }))}
                  hasError={this.state.hasError.m2}
                  help={this.state.help.m2}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m3 = c)}
                  name='m3'
                  label='3 月'
                  value={this.state.m3}
                  onChange={(e) => (this.setState({ m3: e.target.value }))}
                  hasError={this.state.hasError.m3}
                  help={this.state.help.m3}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m4 = c)}
                  name='m4'
                  label='4 月'
                  value={this.state.m4}
                  onChange={(e) => (this.setState({ m4: e.target.value }))}
                  hasError={this.state.hasError.m4}
                  help={this.state.help.m4}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m5 = c)}
                  name='m5'
                  label='5 月'
                  value={this.state.m5}
                  onChange={(e) => (this.setState({ m5: e.target.value }))}
                  hasError={this.state.hasError.m5}
                  help={this.state.help.m5}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m6 = c)}
                  name='m6'
                  label='6 月'
                  value={this.state.m6}
                  onChange={(e) => (this.setState({ m6: e.target.value }))}
                  hasError={this.state.hasError.m6}
                  help={this.state.help.m6}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m7 = c)}
                  name='m7'
                  label='7 月'
                  value={this.state.m7}
                  onChange={(e) => (this.setState({ m7: e.target.value }))}
                  hasError={this.state.hasError.m7}
                  help={this.state.help.m7}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m8 = c)}
                  name='m8'
                  label='8 月'
                  value={this.state.m8}
                  onChange={(e) => (this.setState({ m8: e.target.value }))}
                  hasError={this.state.hasError.m8}
                  help={this.state.help.m8}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m9 = c)}
                  name='m9'
                  label='9 月'
                  value={this.state.m9}
                  onChange={(e) => (this.setState({ m9: e.target.value }))}
                  hasError={this.state.hasError.m9}
                  help={this.state.help.m9}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m10 = c)}
                  name='m10'
                  label='10 月'
                  value={this.state.m10}
                  onChange={(e) => (this.setState({ m10: e.target.value }))}
                  hasError={this.state.hasError.m10}
                  help={this.state.help.m10}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m11 = c)}
                  name='m11'
                  label='11 月'
                  value={this.state.m11}
                  onChange={(e) => (this.setState({ m11: e.target.value }))}
                  hasError={this.state.hasError.m11}
                  help={this.state.help.m11}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.m12 = c)}
                  name='m12'
                  label='12 月'
                  value={this.state.m12}
                  onChange={(e) => (this.setState({ m12: e.target.value }))}
                  hasError={this.state.hasError.m12}
                  help={this.state.help.m12}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.annualRevenue = c)}
                  name='annualRevenue'
                  label='年度營收 (若未異動，則將自動計算)'
                  value={this.state.annualRevenue}
                  onChange={(e) => (this.setState({ annualRevenue: e.target.value }))}
                  hasError={this.state.hasError.annualRevenue}
                  help={this.state.help.annualRevenue}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.annualNetProfit = c)}
                  name='annualNetProfit'
                  label='年度淨利'
                  value={this.state.annualNetProfit}
                  onChange={(e) => (this.setState({ annualNetProfit: e.target.value }))}
                  hasError={this.state.hasError.annualNetProfit}
                  help={this.state.help.annualNetProfit}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.earningsPerShare = c)}
                  name='earningsPerShare'
                  label='每股盈餘'
                  value={this.state.earningsPerShare}
                  onChange={(e) => (this.setState({ earningsPerShare: e.target.value }))}
                  hasError={this.state.hasError.earningsPerShare}
                  help={this.state.help.earningsPerShare}
                  disabled={this.state.loading}
                />
                <SelectControl
                  ref={(c) => (this.input.isActive = c)}
                  name='isActive'
                  label='是否啟用'
                  value={this.state.isActive}
                  onChange={(e) => (this.setState({ isActive: e.target.value }))}
                  hasError={this.state.hasError.isActive}
                  help={this.state.help.isActive}
                  disabled={this.state.loading}
                >
                  <option value='true'>
                    啟用
                  </option>
                  <option value='false'>
                    關閉
                  </option>
                </SelectControl>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-success': true }}
                    disabled={this.state.loading}
                  >
                    更新
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </ControlGroup>
              </fieldset>
            </form>
          </div>
          <div className='col-md-4'>
            <Card>
              <CardBody>
                {this.state.successForQ1Tw ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.q1ReportTw = c)}
                  name='q1ReportTw'
                  label='Q1 財報(中文繁體)'
                  hasError={this.state.hasError.q1ReportTw}
                  help={this.state.help.q1ReportTw}
                  disabled={this.state.loadingForQ1Tw}
                />

                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForQ1Tw}
                  onClick={e => this.handleReportSubmit(1, 'tw')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForQ1Tw} />
                </Button>
                &nbsp;
                { this.state.q1ReportTw &&
                  <a href={`${config.awsUrl}${this.state.q1ReportTw}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForQ2Tw ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.q2ReportTw = c)}
                  name='q2ReportTw'
                  label='Q2 財報(中文繁體)'
                  hasError={this.state.hasError.q2ReportTw}
                  help={this.state.help.q2ReportTw}
                  disabled={this.state.loadingForQ2Tw}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForQ2Tw}
                  onClick={e => this.handleReportSubmit(2, 'tw')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForQ2Tw} />
                </Button>
                &nbsp;
                { this.state.q2ReportTw &&
                  <a href={`${config.awsUrl}${this.state.q2ReportTw}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForQ3Tw ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.q3ReportTw = c)}
                  name='q3ReportTw'
                  label='Q3 財報(中文繁體)'
                  hasError={this.state.hasError.q3ReportTw}
                  help={this.state.help.q3ReportTw}
                  disabled={this.state.loadingForQ3Tw}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForQ3Tw}
                  onClick={e => this.handleReportSubmit(3, 'tw')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForQ3Tw} />
                </Button>
                &nbsp;
                { this.state.q3ReportTw &&
                  <a href={`${config.awsUrl}${this.state.q3ReportTw}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForQ4Tw ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.q4ReportTw = c)}
                  name='q4ReportTw'
                  label='Q4 財報(中文繁體)'
                  hasError={this.state.hasError.q4ReportTw}
                  help={this.state.help.q4ReportTw}
                  disabled={this.state.loadingForQ4Tw}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForQ4}
                  onClick={e => this.handleReportSubmit(4, 'tw')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForQ4Tw} />
                </Button>
                &nbsp;
                { this.state.q4ReportTw &&
                  <a href={`${config.awsUrl}${this.state.q4ReportTw}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForAnnualTw ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.annualReportTw = c)}
                  name='annualReportTw'
                  label='年報(中文繁體)'
                  hasError={this.state.hasError.annualReportTw}
                  help={this.state.help.annualReportTw}
                  disabled={this.state.loadingForAnnualTw}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForAnnualTw}
                  onClick={e => this.handleReportSubmit(0, 'tw')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForAnnualTw} />
                </Button>
                &nbsp;
                { this.state.annualReportTw &&
                  <a href={`${config.awsUrl}${this.state.annualReportTw}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>
          </div>

          <div className='col-md-4'>
            <Card>
              <CardBody>
                {this.state.successForQ1En ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.q1ReportEn = c)}
                  name='q1ReportEn'
                  label='Q1 財報(英文)'
                  hasError={this.state.hasError.q1ReportEn}
                  help={this.state.help.q1ReportEn}
                  disabled={this.state.loadingForQ1En}
                />

                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForQ1En}
                  onClick={e => this.handleReportSubmit(1, 'en')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForQ1En} />
                </Button>
                &nbsp;
                { this.state.q1ReportEn &&
                  <a href={`${config.awsUrl}${this.state.q1ReportEn}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForQ2En ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.q2ReportEn = c)}
                  name='q2ReportEn'
                  label='Q2 財報(英文)'
                  hasError={this.state.hasError.q2ReportEn}
                  help={this.state.help.q2ReportEn}
                  disabled={this.state.loadingForQ2En}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForQ2En}
                  onClick={e => this.handleReportSubmit(2, 'en')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForQ2En} />
                </Button>
                &nbsp;
                { this.state.q2ReportEn &&
                  <a href={`${config.awsUrl}${this.state.q2ReportEn}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForQ3En ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.q3ReportEn = c)}
                  name='q3ReportEn'
                  label='Q3 財報(英文)'
                  hasError={this.state.hasError.q3ReportEn}
                  help={this.state.help.q3ReportEn}
                  disabled={this.state.loadingForQ3En}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForQ3En}
                  onClick={e => this.handleReportSubmit(3, 'en')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForQ3En} />
                </Button>
                &nbsp;
                { this.state.q3ReportEn &&
                  <a href={`${config.awsUrl}${this.state.q3ReportEn}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForQ4En ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.q4ReportEn = c)}
                  name='q4ReportEn'
                  label='Q4 財報(英文)'
                  hasError={this.state.hasError.q4ReportEn}
                  help={this.state.help.q4ReportEn}
                  disabled={this.state.loadingForQ4En}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForQ4En}
                  onClick={e => this.handleReportSubmit(4, 'en')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForQ4En} />
                </Button>
                &nbsp;
                { this.state.q4ReportEn &&
                  <a href={`${config.awsUrl}${this.state.q4ReportEn}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>

            <Card className='mt-4'>
              <CardBody>
                {this.state.successForAnnualEn ? alertForReport : ''}
                <FileControl
                  ref={(c) => (this.input.annualReportEn = c)}
                  name='annualReport'
                  label='年報(英文)'
                  hasError={this.state.hasError.annualReportEn}
                  help={this.state.help.annualReportEn}
                  disabled={this.state.loadingForAnnualEn}
                />
                <Button
                  type='button'
                  inputClasses={{ 'btn-info': true }}
                  disabled={this.state.loadingForAnnualEn}
                  onClick={e => this.handleReportSubmit(0, 'en')}
                >
                  上傳
                  <Spinner space='left' show={this.state.loadingForAnnualEn} />
                </Button>
                &nbsp;
                { this.state.annualReportEn &&
                  <a href={`${config.awsUrl}${this.state.annualReportEn}`} target='_blank' rel='noopener noreferrer'>
                    當前檔案
                  </a>
                }
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
    )
  }
}

export default EditFinancialInfoPage
