import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { post } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import SelectControl from '../components/select-control'

class Signup extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.username) {
      this.input.username.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/signup/', {
      username: this.input.username.value(),
      email: this.input.email.value(),
      password: encrypt(this.input.password.value()),
      userRole: this.input.userRole.value(),
      hasEncrypt: 'true'
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      alert =
        <Alert
          type='success'
          message='成功，請稍後...'
        />
      return (<Redirect to='/app/admin/users' />)
    } else if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>建立用戶</title>
        </Helmet>

        <div className='row'>
          <div className='col-md-12'>
            <section>
              <h1 className='page-header'>建立用戶</h1>
              <form onSubmit={this.handleSubmit.bind(this)}>
                {alert}
                <TextControl
                  ref={(c) => (this.input.username = c)}
                  name='username'
                  label='帳號'
                  hasError={this.state.hasError.username}
                  help={this.state.help.username}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.email = c)}
                  name='email'
                  label='Email (非必填，但會產生一組虛擬 Email)'
                  hasError={this.state.hasError.email}
                  help={this.state.help.email}
                  disabled={this.state.loading}
                />
                <TextControl
                  ref={(c) => (this.input.password = c)}
                  name='password'
                  label='密碼'
                  type='password'
                  hasError={this.state.hasError.password}
                  help={this.state.help.password}
                  disabled={this.state.loading}
                />
                <SelectControl
                  ref={(c) => (this.input.userRole = c)}
                  name='userRole'
                  label='平台角色'
                  hasError={this.state.hasError.userRole}
                  help={this.state.help.userRole}
                  disabled={this.state.loading}>
                  <option value='client'>
                    客戶 (可申請檔案權限)
                  </option>
                  <option value='hunter'>
                    獵人頭 (可管理寄出履歷)
                  </option>
                  <option value='cupola360ota'>
                    Cupola360 OTA
                  </option>
                  <option value='false'>
                    關閉
                  </option>
                </SelectControl>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='submit'
                    inputClasses={{ 'btn-success': true }}
                    disabled={this.state.loading}
                  >
                    建立
                    <Spinner space='left' show={this.state.loading} />
                  </Button>
                </ControlGroup>
              </form>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

export default Signup
