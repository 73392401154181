import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { postFormData } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import FileControl from '../components/file-control'
import SelectControl from '../components/select-control'

class CreateStoragePage extends Component {
  constructor () {
    super()
    this.input = {
      subordinate: []
    }
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.name) {
      this.input.name.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    postFormData('/1/admin/storage/cg', {
      name: this.input.name.value(),
      lang: this.input.lang.value(),
      type: this.input.type.value(),
      file: this.input.file.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/cgs' />)
    } else if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    return (
      <section className='section-home container'>
        <Helmet>
          <title>建立公司治理檔案</title>
        </Helmet>

        <h1 className='page-header'>建立公司治理檔案</h1>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              <TextControl
                ref={(c) => (this.input.name = c)}
                name='name'
                label='檔案標題'
                hasError={this.state.hasError.name}
                help={this.state.help.name}
                disabled={this.state.loading}
              />
              <SelectControl
                ref={(c) => (this.input.lang = c)}
                name='lang'
                label='語系'
                onChange={(e) => (this.setState({ lang: e.target.value }))}
                hasError={this.state.hasError.lang}
                help={this.state.help.lang}
                disabled={this.state.loading}
              >
                <option value='tw'>
                  繁體中文
                </option>
                <option value='en'>
                  英文
                </option>
                <option value='all'>
                  全語系
                </option>
              </SelectControl>
              <SelectControl
                ref={(c) => (this.input.type = c)}
                name='type'
                label='類型'
                onChange={(e) => (this.setState({ type: e.target.value }))}
                hasError={this.state.hasError.type}
                help={this.state.help.type}
                disabled={this.state.loading}
              >
                <option value='1'>
                  公司治理相關檔案
                </option>
                <option value='2'>
                  企業社會責任報告書
                </option>
              </SelectControl>
              <FileControl
                ref={(c) => (this.input.file = c)}
                name='file'
                label='檔案'
                hasError={this.state.hasError.file}
                help={this.state.help.file}
                disabled={this.state.loading}
              />
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-success': true }}
                  disabled={this.state.loading}
                >
                  建立
                  <Spinner space='left' show={this.state.loading} />
                </Button>
              </ControlGroup>
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateStoragePage
