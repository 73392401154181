import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Navbar, Container } from 'reactstrap'
import { post, put } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import BasicInfo from './cv/basic-info'
import Education from './cv/education'
import Training from './cv/training'
import Relatives from './cv/relatives'
import Referrer from './cv/referrer'
import Qualification from './cv/qualification'
import Organization from './cv/organization'

const year = new Date().getFullYear()
class UserPersonnelPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.setPersonnel = this.setPersonnel.bind(this)
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {},
      page: -1,
      name: ''
    }
  }

  componentDidMount () {
    if (this.input.email) {
      this.input.email.focus()
    }
  }

  handleGetData (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/personnel', {
      email: this.input.email.value(),
      fillingToken: this.input.fillingToken.value()
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            error: '',
            loading: false,
            page: 1,
            ...r.data
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  setPersonnel (data, page, isTemporary = false) {
    this.handleSubmit(data, page, isTemporary)
    this.setState({ ...data })
  }

  handleSubmit (data, page, isTemporary = false) {
    put('/1/personnel', {
      email: this.state.email,
      fillingToken: this.state.fillingToken,
      isTemporary: isTemporary,
      ...data
    }).then(
      r => {
        this.setState({
          loading: false,
          hasError: {},
          help: {},
          error: ''
        })
        if (r.success) {
          this.setState({
            page: page
          })
          window.scrollTo(0, 0)
        } else if (r.success !== true) {
          const state = {
            hasError: {},
            help: {},
            error: ''
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }
          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
          window.scrollTo(0, 250)
        }
      }
    )
  }

  render () {
    let alerts = []

    if (this.state.success) {
      window.location.href = `${this.state.callback}?token=${this.state.appToken}`
    } else if (this.state.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.state.error}
        />
      )
    }

    return (
      <div>
        <Helmet>
          <title>人事資料表</title>
        </Helmet>

        <Navbar expand='md' className={`fixed-top ${this.state.page < 1 || this.state.page === 8 ? 'personnel-nav-1' : ''}${this.state.page > 0 && this.state.page < 8 ? 'personnel-nav-2' : ''}`}>
          <Container fluid>
            <a href='/' className='navbar-brand'>
              <img className='navbar-logo' src='/media/logo.svg' alt='' />
            </a>
          </Container>
        </Navbar>

        {this.state.page === -1 &&
          <div className='container-fluid personnel-1'>
            <div className='row justify-content-md-center'>
              <div className='col-lg-6 col-sm-8 col-11 personnel-form'>
                <h3>
                  隱私權說明
                </h3>
                <p>
                  本人了解並同意信驊企業基於人事管理之相關業務目的，在符合個人資料保護相關法令規定範圍內，進行蒐集、處理、利用或查核本人資料之正確性與完整性，作為任用評估之用。
                </p>

                <Button
                  inputClasses={{ 'btn-personnel mt-4': true }}
                  onClick={e => this.setState({ page: 0 })}
                >
                  我同意以上內容
                </Button>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {this.state.page === 0 &&
          <div className='container-fluid personnel-1'>
            <div className='row justify-content-md-center'>
              <div className='col-sm-6 personnel-form'>
                <form onSubmit={this.handleGetData.bind(this)}>
                  <h3>歡迎您加入信驊大家庭</h3>
                  <p>
                    信驊科技積極培訓優秀人才，本公司期許能夠藉由彼此合作以達成員工與公司共同成長的目標。歡迎您使用信驊科技線上登錄人事資料表系統，我們會將您的人事資料納入信驊企業專屬人才庫。請您於前來面試前先將線上人事資料表填寫完畢。謝謝您的配合！
                  </p>
                  {alerts}
                  <TextControl
                    ref={(c) => (this.input.email = c)}
                    name='email'
                    placeholder='email'
                    hasError={this.state.hasError.email}
                    help={this.state.help.email}
                    disabled={this.state.loading}
                  />
                  <TextControl
                    ref={(c) => (this.input.fillingToken = c)}
                    name='fillingToken'
                    placeholder='填表秘鑰'
                    type='password'
                    hasError={this.state.hasError.fillingToken}
                    help={this.state.help.fillingToken}
                    disabled={this.state.loading}
                  />
                  <ControlGroup hideLabel hideHelp>
                    <Button
                      type='submit'
                      inputClasses={{ 'btn-personnel': true }}
                      disabled={this.state.loading}
                    >
                      開始填寫
                      <Spinner space='left' show={this.state.loading} />
                    </Button>
                  </ControlGroup>
                </form>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {this.state.page > 0 && this.state.page < 8 &&
          <div className='personnel-hero'>
            <div className='main-img d-none d-lg-block' />
            <div className='main-img d-lg-none' />
            <div className='content'>
              <h1>人事資料表</h1>
            </div>
          </div>}

        {this.state.page === 1 &&
          <BasicInfo
            uid={this.state._id}
            photo={this.state.photo}
            chName={this.state.chName}
            enName={this.state.enName}
            applyPosition={this.state.applyPosition}
            idNumber={this.state.idNumber}
            marital={this.state.marital}
            // gender={this.state.gender}
            birthday={this.state.birthday}
            majorDiseases={this.state.majorDiseases}
            majorDiseasesReason={this.state.majorDiseasesReason}
            residenceAddress={this.state.residenceAddress}
            mailingAddress={this.state.mailingAddress}
            daytimeNumber={this.state.daytimeNumber}
            nighttimeNumber={this.state.nighttimeNumber}
            permanentNumber={this.state.permanentNumber}
            otherContact={this.state.otherContact}
            veteran={this.state.veteran}
            retirementDay={this.state.retirementDay}
            exemptionReason={this.state.exemptionReason}
            expertise={this.state.expertise}
            interest={this.state.interest}
            expectedSalary={this.state.expectedSalary}
            checkInDay={this.state.checkInDay}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 2 &&
          <Education
            highSchool={this.state.highSchool}
            highSchoolDepartment={this.state.highSchoolDepartment}
            highSchoolPart={this.state.highSchoolPart}
            highSchoolStatus={this.state.highSchoolStatus}
            highSchoolStartYear={this.state.highSchoolStartYear}
            highSchoolStartMonth={this.state.highSchoolStartMonth}
            highSchoolEndYear={this.state.highSchoolEndYear}
            highSchoolEndMonth={this.state.highSchoolEndMonth}
            highSchoolLocation={this.state.highSchoolLocation}
            juniorCollege={this.state.juniorCollege}
            juniorCollegeDepartment={this.state.juniorCollegeDepartment}
            juniorCollegeType={this.state.juniorCollegeType}
            juniorCollegePart={this.state.juniorCollegePart}
            juniorCollegeStatus={this.state.juniorCollegeStatus}
            juniorCollegeStartYear={this.state.juniorCollegeStartYear}
            juniorCollegeStartMonth={this.state.juniorCollegeStartMonth}
            juniorCollegeEndYear={this.state.juniorCollegeEndYear}
            juniorCollegeEndMonth={this.state.juniorCollegeEndMonth}
            juniorCollegeLocation={this.state.juniorCollegeLocation}
            university={this.state.university}
            universityDepartment={this.state.universityDepartment}
            universityType={this.state.universityType}
            universityPart={this.state.universityPart}
            universityStatus={this.state.universityStatus}
            universityStartYear={this.state.universityStartYear}
            universityStartMonth={this.state.universityStartMonth}
            universityEndYear={this.state.universityEndYear}
            universityEndMonth={this.state.universityEndMonth}
            universityLocation={this.state.universityLocation}
            graduateSchool={this.state.graduateSchool}
            graduateSchoolDepartment={this.state.graduateSchoolDepartment}
            graduateSchoolPart={this.state.graduateSchoolPart}
            graduateSchoolStatus={this.state.graduateSchoolStatus}
            graduateSchoolStartYear={this.state.graduateSchoolStartYear}
            graduateSchoolStartMonth={this.state.graduateSchoolStartMonth}
            graduateSchoolEndYear={this.state.graduateSchoolEndYear}
            graduateSchoolEndMonth={this.state.graduateSchoolEndMonth}
            graduateSchoolLocation={this.state.graduateSchoolLocation}
            otherSchool={this.state.otherSchool}
            otherSchoolDepartment={this.state.otherSchoolDepartment}
            otherSchoolPart={this.state.otherSchoolPart}
            otherSchoolStatus={this.state.otherSchoolStatus}
            otherSchoolStartYear={this.state.otherSchoolStartYear}
            otherSchoolStartMonth={this.state.otherSchoolStartMonth}
            otherSchoolEndYear={this.state.otherSchoolEndYear}
            otherSchoolEndMonth={this.state.otherSchoolEndMonth}
            otherSchoolLocation={this.state.otherSchoolLocation}
            masterThesisTitle={this.state.masterThesisTitle}
            masterThesisPublicationDate={this.state.masterThesisPublicationDate}
            masterAdvisor={this.state.masterAdvisor}
            masterThesisPublication={this.state.masterThesisPublication}
            phdTitle={this.state.phdTitle}
            phdPublicationDate={this.state.phdPublicationDate}
            doctoralSupervisor={this.state.doctoralSupervisor}
            phdThesisPublication={this.state.phdThesisPublication}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 3 &&
          <Training
            englishReading={this.state.englishReading}
            englishSpeaking={this.state.englishSpeaking}
            englishListening={this.state.englishListening}
            englishWriting={this.state.englishWriting}
            foreignLanguage={this.state.foreignLanguage}
            foreignReading={this.state.foreignReading}
            foreignSpeaking={this.state.foreignSpeaking}
            foreignListening={this.state.foreignListening}
            foreignWriting={this.state.foreignWriting}
            furtherStudy1Name={this.state.furtherStudy1Name}
            furtherStudy1StartYear={this.state.furtherStudy1StartYear}
            furtherStudy1StartMonth={this.state.furtherStudy1StartMonth}
            furtherStudy1EndYear={this.state.furtherStudy1EndYear}
            furtherStudy1EndMonth={this.state.furtherStudy1EndMonth}
            furtherStudy1Organizer={this.state.furtherStudy1Organizer}
            furtherStudy1Hours={this.state.furtherStudy1Hours}
            furtherStudy2Name={this.state.furtherStudy2Name}
            furtherStudy2StartYear={this.state.furtherStudy2StartYear}
            furtherStudy2StartMonth={this.state.furtherStudy2StartMonth}
            furtherStudy2EndYear={this.state.furtherStudy2EndYear}
            furtherStudy2EndMonth={this.state.furtherStudy2EndMonth}
            furtherStudy2Organizer={this.state.furtherStudy2Organizer}
            furtherStudy2Hours={this.state.furtherStudy2Hours}
            furtherStudy3Name={this.state.furtherStudy3Name}
            furtherStudy3StartYear={this.state.furtherStudy3StartYear}
            furtherStudy3StartMonth={this.state.furtherStudy3StartMonth}
            furtherStudy3EndYear={this.state.furtherStudy3EndYear}
            furtherStudy3EndMonth={this.state.furtherStudy3EndMonth}
            furtherStudy3Organizer={this.state.furtherStudy3Organizer}
            furtherStudy3Hours={this.state.furtherStudy3Hours}
            furtherStudy4Name={this.state.furtherStudy4Name}
            furtherStudy4StartYear={this.state.furtherStudy4StartYear}
            furtherStudy4StartMonth={this.state.furtherStudy4StartMonth}
            furtherStudy4EndYear={this.state.furtherStudy4EndYear}
            furtherStudy4EndMonth={this.state.furtherStudy4EndMonth}
            furtherStudy4Organizer={this.state.furtherStudy4Organizer}
            furtherStudy4Hours={this.state.furtherStudy4Hours}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 4 &&
          <Relatives
            father={this.state.father}
            fatherAge={this.state.fatherAge}
            fatherVitalStatus={this.state.fatherVitalStatus}
            fatherProfession={this.state.fatherProfession}
            fatherLocation={this.state.fatherLocation}
            mother={this.state.mother}
            motherAge={this.state.motherAge}
            motherVitalStatus={this.state.motherVitalStatus}
            motherProfession={this.state.motherProfession}
            motherLocation={this.state.motherLocation}
            spouse={this.state.spouse}
            spouseAge={this.state.spouseAge}
            spouseVitalStatus={this.state.spouseVitalStatus}
            spouseProfession={this.state.spouseProfession}
            spouseLocation={this.state.spouseLocation}
            relative1Relative={this.state.relative1Relative}
            relative1={this.state.relative1}
            relative1Age={this.state.relative1Age}
            relative1VitalStatus={this.state.relative1VitalStatus}
            relative1Profession={this.state.relative1Profession}
            relative1Location={this.state.relative1Location}
            relative2Relative={this.state.relative2Relative}
            relative2={this.state.relative2}
            relative2Age={this.state.relative2Age}
            relative2VitalStatus={this.state.relative2VitalStatus}
            relative2Profession={this.state.relative2Profession}
            relative2Location={this.state.relative2Location}
            relative3Relative={this.state.relative3Relative}
            relative3={this.state.relative3}
            relative3Age={this.state.relative3Age}
            relative3VitalStatus={this.state.relative3VitalStatus}
            relative3Profession={this.state.relative3Profession}
            relative3Location={this.state.relative3Location}
            relative4Relative={this.state.relative4Relative}
            relative4={this.state.relative4}
            relative4Age={this.state.relative4Age}
            relative4VitalStatus={this.state.relative4VitalStatus}
            relative4Profession={this.state.relative4Profession}
            relative4Location={this.state.relative4Location}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 5 &&
          <Referrer
            applicationPipeline={this.state.applicationPipeline}
            referrer1={this.state.referrer1}
            referrer1Company={this.state.referrer1Company}
            referrer1Title={this.state.referrer1Title}
            referrer1Phone={this.state.referrer1Phone}
            referrer2={this.state.referrer2}
            referrer2Company={this.state.referrer2Company}
            referrer2Title={this.state.referrer2Title}
            referrer2Phone={this.state.referrer2Phone}
            referrer3={this.state.referrer3}
            referrer3Company={this.state.referrer3Company}
            referrer3Title={this.state.referrer3Title}
            referrer3Phone={this.state.referrer3Phone}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 6 &&
          <Qualification
            isNewGraduate={this.state.isNewGraduate}
            lastQualificationOrganization={this.state.lastQualificationOrganization}
            lastQualificationIndustryNature={this.state.lastQualificationIndustryNature}
            lastQualificationPhone={this.state.lastQualificationPhone}
            lastQualificationSupervisor={this.state.lastQualificationSupervisor}
            lastQualificationSupervisorTitle={this.state.lastQualificationSupervisorTitle}
            lastQualificationTakeOfficeDate={this.state.lastQualificationTakeOfficeDate}
            lastQualificationSalary={this.state.lastQualificationSalary}
            lastQualificationTakeOfficeTitle={this.state.lastQualificationTakeOfficeTitle}
            lastQualificationCurrentlyTitle={this.state.lastQualificationCurrentlyTitle}
            lastQualificationContent={this.state.lastQualificationContent}
            lastQualificationCurrentSalary={this.state.lastQualificationCurrentSalary}
            lastQualificationAllowance={this.state.lastQualificationAllowance}
            lastQualificationHasBonus={this.state.lastQualificationHasBonus}
            lastQualificationBonus={this.state.lastQualificationBonus}
            lastQualificationBonusReason={this.state.lastQualificationBonusReason}
            lastQualificationHasOtherPay={this.state.lastQualificationHasOtherPay}
            lastQualificationOtherPayReason={this.state.lastQualificationOtherPayReason}
            lastQualificationLastRaiseYear={this.state.lastQualificationLastRaiseYear}
            lastQualificationLastRaiseMonth={this.state.lastQualificationLastRaiseMonth}
            lastQualificationNextRaiseYear={this.state.lastQualificationNextRaiseYear}
            lastQualificationNextRaiseMonth={this.state.lastQualificationNextRaiseMonth}
            salaryIncreaseProportion={this.state.salaryIncreaseProportion}
            salaryIncrease={this.state.salaryIncrease}
            lastQualificationOnJob={this.state.lastQualificationOnJob}
            lastQualificationResignDays={this.state.lastQualificationResignDays}
            lastQualificationLeaveOfficeDate={this.state.lastQualificationLeaveOfficeDate}
            lastQualificationLeavingReason={this.state.lastQualificationLeavingReason}
            qualification1Organization={this.state.qualification1Organization}
            qualification1IndustryNature={this.state.qualification1IndustryNature}
            qualification1Phone={this.state.qualification1Phone}
            qualification1Supervisor={this.state.qualification1Supervisor}
            qualification1SupervisorTitle={this.state.qualification1SupervisorTitle}
            qualification1TakeOfficeDate={this.state.qualification1TakeOfficeDate}
            qualification1LeaveOfficeDate={this.state.qualification1LeaveOfficeDate}
            qualification1TakeOfficeTitle={this.state.qualification1TakeOfficeTitle}
            qualification1LeaveOfficeTitle={this.state.qualification1LeaveOfficeTitle}
            qualification1TakeOfficeSalary={this.state.qualification1TakeOfficeSalary}
            qualification1LeaveOfficSalary={this.state.qualification1LeaveOfficSalary}
            qualification1Content={this.state.qualification1Content}
            qualification1LeavingReason={this.state.qualification1LeavingReason}
            qualification2Organization={this.state.qualification2Organization}
            qualification2IndustryNature={this.state.qualification2IndustryNature}
            qualification2Phone={this.state.qualification2Phone}
            qualification2Supervisor={this.state.qualification2Supervisor}
            qualification2SupervisorTitle={this.state.qualification2SupervisorTitle}
            qualification2TakeOfficeDate={this.state.qualification2TakeOfficeDate}
            qualification2LeaveOfficeDate={this.state.qualification2LeaveOfficeDate}
            qualification2TakeOfficeTitle={this.state.qualification2TakeOfficeTitle}
            qualification2LeaveOfficeTitle={this.state.qualification2LeaveOfficeTitle}
            qualification2TakeOfficeSalary={this.state.qualification2TakeOfficeSalary}
            qualification2LeaveOfficSalary={this.state.qualification2LeaveOfficSalary}
            qualification2Content={this.state.qualification2Content}
            qualification2LeavingReason={this.state.qualification2LeavingReason}
            qualification3Organization={this.state.qualification3Organization}
            qualification3IndustryNature={this.state.qualification3IndustryNature}
            qualification3Phone={this.state.qualification3Phone}
            qualification3Supervisor={this.state.qualification3Supervisor}
            qualification3SupervisorTitle={this.state.qualification3SupervisorTitle}
            qualification3TakeOfficeDate={this.state.qualification3TakeOfficeDate}
            qualification3LeaveOfficeDate={this.state.qualification3LeaveOfficeDate}
            qualification3TakeOfficeTitle={this.state.qualification3TakeOfficeTitle}
            qualification3LeaveOfficeTitle={this.state.qualification3LeaveOfficeTitle}
            qualification3TakeOfficeSalary={this.state.qualification3TakeOfficeSalary}
            qualification3LeaveOfficSalary={this.state.qualification3LeaveOfficSalary}
            qualification3Content={this.state.qualification3Content}
            qualification3LeavingReason={this.state.qualification3LeavingReason}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 7 &&
          <Organization
            adjustPosition={this.state.adjustPosition}
            adjustPositionReason={this.state.adjustPositionReason}
            workPlace={this.state.workPlace}
            workPlaceReason={this.state.workPlaceReason}
            shift={this.state.shift}
            shiftReason={this.state.shiftReason}
            overtime={this.state.overtime}
            overtimeReason={this.state.overtimeReason}
            setPersonnel={this.setPersonnel}
            hasError={this.state.hasError}
            help={this.state.help}
            error={this.state.error}
          />}

        {this.state.page === 8 &&
          <div className='container-fluid personnel-2'>
            <div className='row justify-content-md-center'>
              <div className='col-lg-6 col-sm-8 col-11 personnel-form'>
                <h3>
                  感謝您，填寫完成！
                </h3>
                <p>
                  期待與您一同共創美好未來，信驊科技誠摯歡迎您的加入。
                </p>
              </div>
              <div className='col-12 copyright-gird'>
                <span className='copyright float-right'>
                  © {year} ASPEED Technology Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>}

        {this.state.page > 0 && this.state.page < 8 &&
          <div className='footer personnel-footer'>
            <div className='container'>
              <span className='copyright float-right'>© {year} ASPEED Technology Inc. All rights reserved.</span>

              <div className='clearfix' />
            </div>
          </div>}
      </div>
    )
  }
}

export default UserPersonnelPage
