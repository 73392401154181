import React from 'react'
import { Helmet } from 'react-helmet'

class NotFoundPage extends React.Component {
  render () {
    const { t } = this.props
    return (
      <section className='container'>
        <Helmet>
          <title>404</title>
        </Helmet>
        <h1 className='page-header'>404</h1>
        <p>{t('not_found_page')}</p>
      </section>
    )
  }
}

export default NotFoundPage
