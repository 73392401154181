import React, { useState, useRef, useEffect } from 'react'
import { loginSuccess, loginError } from '../utils/userAction'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { post } from '../utils/httpAgent'
import { encrypt } from '../utils/rsa'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import config from '../config'

const Login = ({ t, user, authenticated, role, loginSuccess, loginError }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [hasError, setHasError] = useState({})
  const [help, setHelp] = useState({})
  const [roleState, setRole] = useState('')
  const getParameterByName = (name) => {
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(window.location.href)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }
  const [returnUrl] = useState(getParameterByName('returnUrl'))
  const [errorCounter, setErrorCounter] = useState(0)
  const [type, setType] = useState('')
  const inputRef = useRef({})
  const recaptchaRef = useRef()

  useEffect(() => {
    if (inputRef.current.username) {
      inputRef.current.username.focus()
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    setLoading(true)

    const recaptcha = recaptchaRef.current.getValue()

    post('/1/login', {
      username: inputRef.current.username.value(),
      password: inputRef.current.password.value() ? encrypt(inputRef.current.password.value()) : '',
      hasEncrypt: 'true',
      recaptcha
    }).then(
      r => {
        recaptchaRef.current.reset()
        if (r.success === true && r.data) {
          localStorage.setItem('token', r.data.token)
          delete r.data.token
          loginSuccess(r.data)
          setSuccess(true)
          setError('')
          setLoading(false)
          setRole(r.data.role)
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {},
            type: r.type ? r.type : ''
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
            state.errorCounter = errorCounter + 1
          }
          setErrorCounter(state.errorCounter)
          setSuccess(state.success)
          setError(state.error)
          setLoading(state.loading)
          setHasError(state.hasError)
          setHelp(state.help)
          setType(state.type)
          loginError()
        }
      }
    )
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e)
    }
  }

  let alerts = []

  if (success) {
    if (returnUrl) {
      return (<Redirect to={returnUrl} />)
    } else if (roleState) {
      return (<Redirect to={`/app/${roleState}`} />)
    }
  } else if (role) {
    if (returnUrl) {
      return (<Redirect to={returnUrl} />)
    } else {
      return (<Redirect to={`/app/${role}`} />)
    }
  } else if (error) {
    if (errorCounter > 2 && type !== 'expired') {
      return (<Redirect to='/app/login/forgot' />)
    } else {
      alerts = (
        <Alert
          type='danger'
          message={error}
        />
      )
    }
  } else if (getParameterByName('msg') === 'activate_user_error') {
    alerts = (
      <Alert
        type='danger'
        message={t('activate_user_error')}
      />
    )
  } else if (getParameterByName('msg') === 'client_is_unreviewed') {
    alerts = (
      <Alert
        type='info'
        message={t('client_is_unreviewed')}
      />
    )
  }

  return (
    <section className='container'>
      <Helmet>
        <title>{t('login')}</title>
      </Helmet>
      <div className='container'>
        <h1 className='page-header'>{t('login')}</h1>
        <div className='row'>
          <div className='col-sm-6'>
            <form onSubmit={handleSubmit}>
              {alerts}
              <TextControl
                ref={(c) => (inputRef.current.username = c)}
                name='username'
                label={t('username')}
                hasError={hasError.username}
                help={help.username}
                disabled={loading}
              />
              <TextControl
                ref={(c) => (inputRef.current.password = c)}
                name='password'
                label={t('password')}
                type='password'
                hasError={hasError.password}
                help={help.password}
                disabled={loading}
                onKeyPress={handleKeyPress}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.reCAPTCHA}
              />
              <ControlGroup hideLabel hideHelp>
                <Button
                  type='submit'
                  inputClasses={{ 'btn-primary mt-3': true }}
                  disabled={loading}
                >
                  {t('login')}
                  <Spinner space='left' show={loading} />
                </Button>
                <Link to='/app/login/forgot' className='btn btn-link'>{t('forgot_password')}</Link>
              </ControlGroup>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  user: state.index.user,
  authenticated: state.index.authenticated,
  role: state.index.role
})

const mapDispatchToProps = dispatch => ({
  loginSuccess: (user) => {
    dispatch(loginSuccess(user))
  },
  loginError: () => {
    dispatch(loginError())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
