import React, { Component } from 'react'
import { Container, Row, Col, Progress, Card } from 'reactstrap'
import Alert from '../../shared/alert'
import Button from '../../components/button'
import Spinner from '../../components/spinner'
import ControlGroup from '../../components/control-group'
import TextControl from '../../components/text-control'
import SelectControl from '../../components/select-control'

class TrainingPage extends Component {
  constructor (props) {
    super(props)
    this.input = {}
    this.state = {
      englishReading: this.props.englishReading, // 英文讀
      englishSpeaking: this.props.englishSpeaking, // 英文說
      englishListening: this.props.englishListening, // 英文聽
      englishWriting: this.props.englishWriting, // 英文寫
      foreignLanguage: this.props.foreignLanguage, // 第二外語
      foreignReading: this.props.foreignReading, // 第二外語讀
      foreignSpeaking: this.props.foreignSpeaking, // 第二外語說
      foreignListening: this.props.foreignListening, // 第二外語聽
      foreignWriting: this.props.foreignWriting, // 第二外語寫
      furtherStudy1Name: this.props.furtherStudy1Name, // 受訓 1 名稱
      furtherStudy1StartYear: this.props.furtherStudy1StartYear, // 受訓 1 起年
      furtherStudy1StartMonth: this.props.furtherStudy1StartMonth, // 受訓 1 起月
      furtherStudy1EndYear: this.props.furtherStudy1EndYear, // 受訓 1 訖年
      furtherStudy1EndMonth: this.props.furtherStudy1EndMonth, // 受訓 1 訖月
      furtherStudy1Organizer: this.props.furtherStudy1Organizer, // 受訓 1 主辦單位
      furtherStudy1Hours: this.props.furtherStudy1Hours, // 受訓 1 訓練時數
      furtherStudy2Name: this.props.furtherStudy2Name, // 受訓 2 名稱
      furtherStudy2StartYear: this.props.furtherStudy2StartYear, // 受訓 2 起年
      furtherStudy2StartMonth: this.props.furtherStudy2StartMonth, // 受訓 2 起月
      furtherStudy2EndYear: this.props.furtherStudy2EndYear, // 受訓 2 訖年
      furtherStudy2EndMonth: this.props.furtherStudy2EndMonth, // 受訓 2 訖月
      furtherStudy2Organizer: this.props.furtherStudy2Organizer, // 受訓 2 主辦單位
      furtherStudy2Hours: this.props.furtherStudy2Hours, // 受訓 2 訓練時數
      furtherStudy3Name: this.props.furtherStudy3Name, // 受訓 3 名稱
      furtherStudy3StartYear: this.props.furtherStudy3StartYear, // 受訓 3 起年
      furtherStudy3StartMonth: this.props.furtherStudy3StartMonth, // 受訓 3 起月
      furtherStudy3EndYear: this.props.furtherStudy3EndYear, // 受訓 3 訖年
      furtherStudy3EndMonth: this.props.furtherStudy3EndMonth, // 受訓 3 訖月
      furtherStudy3Organizer: this.props.furtherStudy3Organizer, // 受訓 3 主辦單位
      furtherStudy3Hours: this.props.furtherStudy3Hours, // 受訓 3 訓練時數
      furtherStudy4Name: this.props.furtherStudy4Name, // 受訓 4 名稱
      furtherStudy4StartYear: this.props.furtherStudy4StartYear, // 受訓 4 起年
      furtherStudy4StartMonth: this.props.furtherStudy4StartMonth, // 受訓 4 起月
      furtherStudy4EndYear: this.props.furtherStudy4EndYear, // 受訓 4 訖年
      furtherStudy4EndMonth: this.props.furtherStudy4EndMonth, // 受訓 4 訖月
      furtherStudy4Organizer: this.props.furtherStudy4Organizer, // 受訓 4 主辦單位
      furtherStudy4Hours: this.props.furtherStudy4Hours // 受訓 4 訓練時數
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (event, page = 3, isTemporary) {
    event.preventDefault()
    event.stopPropagation()
    this.props.setPersonnel({
      englishReading: this.state.englishReading,
      englishSpeaking: this.state.englishSpeaking,
      englishListening: this.state.englishListening,
      englishWriting: this.state.englishWriting,
      foreignLanguage: this.state.foreignLanguage,
      foreignReading: this.state.foreignReading,
      foreignSpeaking: this.state.foreignSpeaking,
      foreignListening: this.state.foreignListening,
      foreignWriting: this.state.foreignWriting,
      furtherStudy1Name: this.state.furtherStudy1Name,
      furtherStudy1StartYear: this.state.furtherStudy1StartYear,
      furtherStudy1StartMonth: this.state.furtherStudy1StartMonth,
      furtherStudy1EndYear: this.state.furtherStudy1EndYear,
      furtherStudy1EndMonth: this.state.furtherStudy1EndMonth,
      furtherStudy1Organizer: this.state.furtherStudy1Organizer,
      furtherStudy1Hours: this.state.furtherStudy1Hours,
      furtherStudy2Name: this.state.furtherStudy2Name,
      furtherStudy2StartYear: this.state.furtherStudy2StartYear,
      furtherStudy2StartMonth: this.state.furtherStudy2StartMonth,
      furtherStudy2EndYear: this.state.furtherStudy2EndYear,
      furtherStudy2EndMonth: this.state.furtherStudy2EndMonth,
      furtherStudy2Organizer: this.state.furtherStudy2Organizer,
      furtherStudy2Hours: this.state.furtherStudy2Hours,
      furtherStudy3Name: this.state.furtherStudy3Name,
      furtherStudy3StartYear: this.state.furtherStudy3StartYear,
      furtherStudy3StartMonth: this.state.furtherStudy3StartMonth,
      furtherStudy3EndYear: this.state.furtherStudy3EndYear,
      furtherStudy3EndMonth: this.state.furtherStudy3EndMonth,
      furtherStudy3Organizer: this.state.furtherStudy3Organizer,
      furtherStudy3Hours: this.state.furtherStudy3Hours,
      furtherStudy4Name: this.state.furtherStudy4Name,
      furtherStudy4StartYear: this.state.furtherStudy4StartYear,
      furtherStudy4StartMonth: this.state.furtherStudy4StartMonth,
      furtherStudy4EndYear: this.state.furtherStudy4EndYear,
      furtherStudy4EndMonth: this.state.furtherStudy4EndMonth,
      furtherStudy4Organizer: this.state.furtherStudy4Organizer,
      furtherStudy4Hours: this.state.furtherStudy4Hours
    }, page, isTemporary)
  } // end handleSubmit

  render () {
    // const { t } = this.props
    let alerts = []
    if (this.props.error) {
      alerts = (
        <Alert
          type='danger'
          message={this.props.error}
        />
      )
    }

    return (
      <Container>
        <Row className='justify-content-md-center'>
          <Col md='8'>
            <Row className='justify-content-md-center'>
              <Col md='12'>
                <p className='page-header text-center'>3 / 7</p>
                <Progress value='39' max='100' className='mb-4' />
                {alerts}
              </Col>
            </Row>
            <Card body>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>英文能力</h3>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.englishReading = c)}
                    name='englishReading'
                    label='讀'
                    value={this.state.englishReading}
                    onChange={(e) => (this.setState({ englishReading: e.target.value }))}
                    hasError={this.props.hasError.englishReading}
                    help={this.props.help.englishReading}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.englishSpeaking = c)}
                    name='englishSpeaking'
                    label='說'
                    value={this.state.englishSpeaking}
                    onChange={(e) => (this.setState({ englishSpeaking: e.target.value }))}
                    hasError={this.props.hasError.englishSpeaking}
                    help={this.props.help.englishSpeaking}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.englishListening = c)}
                    name='englishListening'
                    label='聽'
                    value={this.state.englishListening}
                    onChange={(e) => (this.setState({ englishListening: e.target.value }))}
                    hasError={this.props.hasError.englishListening}
                    help={this.props.help.englishListening}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.englishWriting = c)}
                    name='englishWriting'
                    label='寫'
                    value={this.state.englishWriting}
                    onChange={(e) => (this.setState({ englishWriting: e.target.value }))}
                    hasError={this.props.hasError.englishWriting}
                    help={this.props.help.englishWriting}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>第二外語能力</h3>
                </Col>

                <Col md='12'>
                  <TextControl
                    ref={(c) => (this.input.foreignLanguage = c)}
                    name='foreignLanguage'
                    label='名稱'
                    value={this.state.foreignLanguage}
                    onChange={(e) => (this.setState({ foreignLanguage: e.target.value }))}
                    hasError={this.props.hasError.foreignLanguage}
                    help={this.props.help.foreignLanguage}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.foreignReading = c)}
                    name='foreignReading'
                    label='讀'
                    value={this.state.foreignReading}
                    onChange={(e) => (this.setState({ foreignReading: e.target.value }))}
                    hasError={this.props.hasError.foreignReading}
                    help={this.props.help.foreignReading}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.foreignSpeaking = c)}
                    name='foreignSpeaking'
                    label='說'
                    value={this.state.foreignSpeaking}
                    onChange={(e) => (this.setState({ foreignSpeaking: e.target.value }))}
                    hasError={this.props.hasError.foreignSpeaking}
                    help={this.props.help.foreignSpeaking}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.foreignListening = c)}
                    name='foreignListening'
                    label='聽'
                    value={this.state.foreignListening}
                    onChange={(e) => (this.setState({ foreignListening: e.target.value }))}
                    hasError={this.props.hasError.foreignListening}
                    help={this.props.help.foreignListening}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.foreignWriting = c)}
                    name='foreignWriting'
                    label='寫'
                    value={this.state.foreignWriting}
                    onChange={(e) => (this.setState({ foreignWriting: e.target.value }))}
                    hasError={this.props.hasError.foreignWriting}
                    help={this.props.help.foreignWriting}
                    disabled={this.props.loading}
                  >
                    <option value='優'>
                      優
                    </option>
                    <option value='良'>
                      良
                    </option>
                    <option value='平'>
                      平
                    </option>
                    <option value='差'>
                      差
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>受訓資訊 1</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy1Name = c)}
                    name='furtherStudy1Name'
                    label='名稱'
                    value={this.state.furtherStudy1Name}
                    onChange={(e) => (this.setState({ furtherStudy1Name: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy1Name}
                    help={this.props.help.furtherStudy1Name}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy1Organizer = c)}
                    name='furtherStudy1Organizer'
                    label='主辦單位'
                    value={this.state.furtherStudy1Organizer}
                    onChange={(e) => (this.setState({ furtherStudy1Organizer: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy1Organizer}
                    help={this.props.help.furtherStudy1Organizer}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy1Hours = c)}
                    name='furtherStudy1Hours'
                    label='時數'
                    value={this.state.furtherStudy1Hours}
                    onChange={(e) => (this.setState({ furtherStudy1Hours: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy1Hours}
                    help={this.props.help.furtherStudy1Hours}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy1StartYear = c)}
                    name='furtherStudy1StartYear'
                    label='起年'
                    value={this.state.furtherStudy1StartYear}
                    onChange={(e) => (this.setState({ furtherStudy1StartYear: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy1StartYear}
                    help={this.props.help.furtherStudy1StartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.furtherStudy1StartMonth = c)}
                    name='furtherStudy1StartMonth'
                    label='起月'
                    value={this.state.furtherStudy1StartMonth}
                    onChange={(e) => (this.setState({ furtherStudy1StartMonth: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy1StartMonth}
                    help={this.props.help.furtherStudy1StartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy1EndYear = c)}
                    name='furtherStudy1EndYear'
                    label='訖年'
                    value={this.state.furtherStudy1EndYear}
                    onChange={(e) => (this.setState({ furtherStudy1EndYear: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy1EndYear}
                    help={this.props.help.furtherStudy1EndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.furtherStudy1EndMonth = c)}
                    name='furtherStudy1EndMonth'
                    label='訖月'
                    value={this.state.furtherStudy1EndMonth}
                    onChange={(e) => (this.setState({ furtherStudy1EndMonth: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy1EndMonth}
                    help={this.props.help.furtherStudy1EndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Card body className='mt-4'>
              <Row className='justify-content-md-center'>
                <Col md='12'>
                  <h3 className='text-center mt-4'>受訓資訊 2</h3>
                </Col>

                <Col md='6'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy2Name = c)}
                    name='furtherStudy2Name'
                    label='名稱'
                    value={this.state.furtherStudy2Name}
                    onChange={(e) => (this.setState({ furtherStudy2Name: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy2Name}
                    help={this.props.help.furtherStudy2Name}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='4'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy2Organizer = c)}
                    name='furtherStudy2Organizer'
                    label='主辦單位'
                    value={this.state.furtherStudy2Organizer}
                    onChange={(e) => (this.setState({ furtherStudy2Organizer: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy2Organizer}
                    help={this.props.help.furtherStudy2Organizer}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='2'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy2Hours = c)}
                    name='furtherStudy2Hours'
                    label='時數'
                    value={this.state.furtherStudy2Hours}
                    onChange={(e) => (this.setState({ furtherStudy2Hours: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy2Hours}
                    help={this.props.help.furtherStudy2Hours}
                    disabled={this.props.loading}
                  />
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy2StartYear = c)}
                    name='furtherStudy2StartYear'
                    label='起年'
                    value={this.state.furtherStudy2StartYear}
                    onChange={(e) => (this.setState({ furtherStudy2StartYear: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy2StartYear}
                    help={this.props.help.furtherStudy2StartYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.furtherStudy2StartMonth = c)}
                    name='furtherStudy2StartMonth'
                    label='起月'
                    value={this.state.furtherStudy2StartMonth}
                    onChange={(e) => (this.setState({ furtherStudy2StartMonth: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy2StartMonth}
                    help={this.props.help.furtherStudy2StartMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>

                <Col md='3'>
                  <TextControl
                    ref={(c) => (this.input.furtherStudy2EndYear = c)}
                    name='furtherStudy2EndYear'
                    label='訖年'
                    value={this.state.furtherStudy2EndYear}
                    onChange={(e) => (this.setState({ furtherStudy2EndYear: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy2EndYear}
                    help={this.props.help.furtherStudy2EndYear}
                    disabled={this.props.loading}
                  />
                </Col>
                <Col md='3'>
                  <SelectControl
                    ref={(c) => (this.input.furtherStudy2EndMonth = c)}
                    name='furtherStudy2EndMonth'
                    label='訖月'
                    value={this.state.furtherStudy2EndMonth}
                    onChange={(e) => (this.setState({ furtherStudy2EndMonth: e.target.value }))}
                    hasError={this.props.hasError.furtherStudy2EndMonth}
                    help={this.props.help.furtherStudy2EndMonth}
                    disabled={this.props.loading}
                  >
                    <option value='1'>
                      1
                    </option>
                    <option value='2'>
                      2
                    </option>
                    <option value='3'>
                      3
                    </option>
                    <option value='4'>
                      4
                    </option>
                    <option value='5'>
                      5
                    </option>
                    <option value='6'>
                      6
                    </option>
                    <option value='7'>
                      7
                    </option>
                    <option value='8'>
                      8
                    </option>
                    <option value='9'>
                      9
                    </option>
                    <option value='10'>
                      10
                    </option>
                    <option value='11'>
                      11
                    </option>
                    <option value='12'>
                      12
                    </option>
                  </SelectControl>
                </Col>
              </Row>
            </Card>

            <Row className='justify-content-md-center'>
              <Col md='12' className='text-center mt-4'>
                <ControlGroup hideLabel hideHelp>
                  <Button
                    type='button'
                    onClick={e => this.handleSubmit(e, 3, true)}
                    inputClasses={{ 'btn-success mr-3': true }}
                    disabled={this.props.loading}
                  >
                    暫存
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-info mr-4': true }}
                    onClick={e => this.handleSubmit(e, 2, false)}
                    disabled={this.props.loading}
                  >
                    上一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>

                  <Button
                    type='button'
                    inputClasses={{ 'btn-primary': true }}
                    onClick={e => this.handleSubmit(e, 4, false)}
                    disabled={this.props.loading}
                  >
                    下一步
                    <Spinner
                      space='left'
                      show={this.props.loading}
                    />
                  </Button>
                </ControlGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default TrainingPage
