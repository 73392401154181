import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router'
import { post } from '../utils/httpAgent'
import Alert from '../shared/alert'
import Button from '../components/button'
import Spinner from '../components/spinner'
import ControlGroup from '../components/control-group'
import TextControl from '../components/text-control'
import DateTimeControl from '../components/datetime-control'

class CreateShareholderPage extends Component {
  constructor () {
    super()
    this.input = {}
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      help: {}
    }
  }

  componentDidMount () {
    if (this.input.years) {
      this.input.years.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    this.setState({
      loading: true
    })

    post('/1/admin/shareholder', {
      years: this.input.years.value(),
      chashDividend: this.input.chashDividend.value(),
      stockDividend: this.input.stockDividend.value(),
      recordDate: this.state.recordDate ? this.state.recordDate.getTime() : '',
      exDividendDate: this.state.exDividendDate ? this.state.exDividendDate.getTime() : '',
      cashDividendPaymentDate: this.state.cashDividendPaymentDate ? this.state.cashDividendPaymentDate.getTime() : '',
      stockDividendPaymentDate: this.state.stockDividendPaymentDate ? this.state.stockDividendPaymentDate.getTime() : ''
    }).then(
      r => {
        if (r.success === true) {
          this.setState({
            success: true,
            error: '',
            loading: false
          })
        } else {
          const state = {
            success: false,
            error: '',
            loading: false,
            hasError: {},
            help: {}
          }
          for (const key in r.errfor) {
            state.hasError[key] = true
            state.help[key] = r.errfor[key]
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      }
    )
  }

  render () {
    let alert = []

    if (this.state.success) {
      return (<Redirect to='/app/admin/shareholders' />)
    } else if (this.state.error) {
      alert =
        <Alert
          type='danger'
          message={this.state.error}
        />
    }

    let formElements

    if (!this.state.success) {
      formElements =
        <fieldset>
          <TextControl
            ref={(c) => (this.input.years = c)}
            name='years'
            label='年份'
            hasError={this.state.hasError.years}
            help={this.state.help.years}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.chashDividend = c)}
            name='chashDividend'
            label='現金股利'
            hasError={this.state.hasError.chashDividend}
            help={this.state.help.chashDividend}
            disabled={this.state.loading}
          />
          <TextControl
            ref={(c) => (this.input.stockDividend = c)}
            name='stockDividend'
            label='股票股利'
            hasError={this.state.hasError.stockDividend}
            help={this.state.help.stockDividend}
            disabled={this.state.loading}
          />

          <DateTimeControl
            ref={(c) => (this.input.recordDate = c)}
            name='recordDate'
            label='除權息基準日'
            placeholder=''
            timeFormat={false}
            value={this.state.recordDate}
            onChange={(e) => this.setState({ recordDate: new Date(e.valueOf()) })}
            hasError={this.state.hasError.recordDate}
            help={this.state.help.recordDate}
            disabled={this.state.loading}
          />

          <DateTimeControl
            ref={(c) => (this.input.exDividendDate = c)}
            name='exDividendDate'
            label='除權息交易日'
            placeholder=''
            timeFormat={false}
            value={this.state.exDividendDate}
            onChange={(e) => this.setState({ exDividendDate: new Date(e.valueOf()) })}
            hasError={this.state.hasError.exDividendDate}
            help={this.state.help.exDividendDate}
            disabled={this.state.loading}
          />

          <DateTimeControl
            ref={(c) => (this.input.cashDividendPaymentDate = c)}
            name='cashDividendPaymentDate'
            label='現金發放日'
            placeholder=''
            timeFormat={false}
            value={this.state.cashDividendPaymentDate}
            onChange={(e) => this.setState({ cashDividendPaymentDate: new Date(e.valueOf()) })}
            hasError={this.state.hasError.cashDividendPaymentDate}
            help={this.state.help.cashDividendPaymentDate}
            disabled={this.state.loading}
          />

          <DateTimeControl
            ref={(c) => (this.input.stockDividendPaymentDate = c)}
            name='stockDividendPaymentDate'
            label='股票發放日'
            placeholder=''
            timeFormat={false}
            value={this.state.stockDividendPaymentDate}
            onChange={(e) => this.setState({ stockDividendPaymentDate: new Date(e.valueOf()) })}
            hasError={this.state.hasError.stockDividendPaymentDate}
            help={this.state.help.stockDividendPaymentDate}
            disabled={this.state.loading}
          />

          <ControlGroup hideLabel hideHelp>
            <Button
              type='submit'
              inputClasses={{ 'btn-success': true }}
              disabled={this.state.loading}
            >
              建立
              <Spinner space='left' show={this.state.loading} />
            </Button>
          </ControlGroup>
        </fieldset>
    }

    return (
      <section className='container'>
        <Helmet>
          <title>建立年度財務資訊</title>
        </Helmet>

        <h1 className='page-header'>建立年度財務資訊</h1>
        <div className='row'>
          <div className='col-md-6'>
            <form onSubmit={this.handleSubmit.bind(this)}>
              {alert}
              {formElements}
            </form>
          </div>
        </div>
      </section>
    )
  }
}

export default CreateShareholderPage
