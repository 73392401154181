import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import moment from 'moment'
import { Container } from 'reactstrap'
import { get, deleteItem } from '../utils/httpAgent'

class MonthlyRevenuePage extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      success: false,
      error: undefined,
      hasError: {},
      data: [],
      page: 0
    }
    this.handleFetchNewsList = this.handleFetchNewsList.bind(this)
  }

  handleFetchNewsList (state) {
    const page = state ? state.page + 1 : 0
    const limit = state ? state.pageSize : 10
    let sorted = state ? state.sorted : []

    if (sorted.length > 0) {
      sorted = `${sorted[0].desc ? '-' : ''}${sorted[0].id}`
    }

    this.setState({
      loading: true
    })

    get(`/1/admin/news?page=${page}&limit=${limit}&type=3&sort=${sorted}`)
      .then(r => {
        if (r.success === true) {
          r.data.forEach((item, i) => {
            item.postTime = moment(item.postTime).format('YYYY/MM/DD')
            item.updateTime = moment(item.updateTime).format('YYYY/MM/DD')
            item.createTime = moment(item.createTime).format('YYYY/MM/DD')
          })

          this.setState({
            loading: false,
            success: true,
            error: '',
            data: r.data,
            pages: r.pages.total
          })
        } else {
          const state = {
            loading: false,
            success: false,
            error: '',
            hasError: {},
            help: {}
          }

          if (r.errors[0] !== undefined) {
            state.error = r.errors[0]
          }
          this.setState(state)
        }
      })
  }

  deleteData (uid) {
    if (!window.confirm('是否確定要刪除此營收公告？')) {
      return -1
    }

    deleteItem(`/1/admin/monthly_revenue/${uid}`)
      .then(r => {
        if (r.success === true) {
          this.handleFetchNewsList()
        }
      })
  }

  render () {
    const columns = [
      {
        Header: '編輯',
        accessor: '_id',
        width: 55,
        Cell: row => (
          <Link to={`/app/admin/monthly_revenue/${row.value}`} className='btn btn-sm'>
            <i className='lnr lnr-pencil' />
          </Link>
        )
      }, {
        Header: '發布日期',
        accessor: 'postTime',
        width: 100
      }, {
        Header: '標題',
        accessor: 'name'
      }, {
        Header: '語系',
        accessor: 'lang',
        width: 80,
        Cell: row => (
          <span>
            {row.value === 'tw' ? '繁中'
              : row.value === 'en' ? '英文'
                : '未設定'}
          </span>
        )
      }, {
        Header: '是否啟用',
        accessor: 'isActive',
        width: 80,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === true ? '#0000FF'
                : row.value === false ? '#FF0000'
                  : '#000',
              transition: 'all .3s ease'
            }}
            >
              &#x25cf;
            </span>&nbsp;
            {row.value === true ? '啟用'
              : row.value === false ? '關閉'
                : '異常狀態'}
          </span>
        )
      }, {
        Header: '更新日期',
        accessor: 'updateTime',
        width: 100
      }, {
        Header: '建立日期',
        accessor: 'createTime',
        width: 100
      }, {
        Header: '',
        accessor: '_id',
        width: 50,
        Cell: row => (
          <button className='btn btn-danger btn-sm btn-sm' onClick={this.deleteData.bind(this, row.value)}>
            <i className='lnr lnr-cross' />
          </button>
        )
      }]

    return (
      <Container>
        <Helmet>
          <title>
            營收公告管理
          </title>
        </Helmet>

        <h1 className='page-header'>營收公告管理</h1>
        <div className='row'>
          <div className='col-md-12'>
            <Link to='/app/admin/create_monthly_revenue' className='btn btn-success'>
              建立營收公告
            </Link>
            <br /><br />
          </div>
          <div className='col-md-12'>
            <ReactTable
              manual
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              onFetchData={this.handleFetchNewsList}
              columns={columns}
              SubComponent={row => (
                <div className='row' style={{ padding: '20px' }}>
                  <div className='col-md-12 news' style={{ padding: '20px' }}>
                    <div dangerouslySetInnerHTML={{ __html: row.original.content }} />
                  </div>
                </div>
              )}
              previousText='上一頁'
              nextText='下一頁'
              pageText='頁'
              ofText='/'
              rowsText='筆'
              noDataText='目前尚無任何資料'
              className='-striped -highlight'
              defaultPageSize={10}
              defaultSorted={[{ id: 'postTime', desc: true }]}
            />
          </div>
        </div>
      </Container>
    )
  }
}

export default MonthlyRevenuePage
